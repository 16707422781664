import React, { useState } from "react";
import verify from "../../Assets/images/verify.png";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import { LoadingButton } from "@mui/lab";
import {
  TextField,
  Autocomplete,
  Badge,
  Grid,
  Box,
  Avatar,
} from "@mui/material";
function StorageRequestedTableMobile({
  request,
  index,
  storage,
  SmallAvatar,
  rejectStorage,
  assignStorage,
  setStorageValue,
  storageInfo,
}) {
  const availableStorage = storageInfo.providedStorage - storageInfo.storage;
  const [loading, setLoading] = useState(false);
  return (
    <Grid
      xs={12}
      sm={5.8}
      md={6}
      lg={6}
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.1)",
        padding: "8px",
        paddingLeft: "10px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <Badge
          overlap="circular"
          style={{ zIndex: "0" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={
            request.status !== "pending" && (
              <SmallAvatar
                alt="icon"
                src={
                  (request.status === "rejected" && unpublished_icon) ||
                  (request.status === "approved" && verify)
                }
              />
            )
          }
        >
          <Avatar>{request.name.charAt(0).toUpperCase()}</Avatar>
        </Badge>
        <p
          className="data-teacher"
          style={{
            margin: "0px",
            display: "inline-block",
            marginTop: "8px",
            marginLeft: "10px",
          }}
        >
          {request.name}
        </p>
      </Box>
      <Box sx={{ marginLeft: "20px" }}>
        <p className="email-wrap">{request.email}</p>
      </Box>
      <Box sx={{ display: "flex", gap: "15px", marginLeft: "20px" }}>
        <p>{request.role}</p>
        <p>{request.storage}</p>
        <Autocomplete
          className="combo-box-demo-mobile"
          options={storage}
          freeSolo
          value={request.storageToAssign || 0}
          sx={{
            minWidth: 90,
            marginTop: "-5px",
            height: "30px !important",
          }}
          size="small"
          onInputChange={(e, newValue) => {
            setStorageValue(parseInt(newValue) || 0, request);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              type="number"
              label="Select Storage"
              error={
                request.storageToAssign > availableStorage ||
                availableStorage < request.storageToAssign ||
                request.storageToAssign < 0
              }
              helperText={
                (request.storageToAssign > availableStorage &&
                  "Exceed your storage limits") ||
                (request.storageToAssign < 0 && "Enter a valid storage") ||
                (availableStorage < request.storageToAssign &&
                  "Insufficient storage")
              }
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0px",
          justifyContent: "end",
        }}
      >
        {request.status === "approved" || request.status === "rejected" ? (
          <LoadingButton
            disabled
            loadingPosition="start"
            className={
              request.status === "approved" ? "iconButton" : "rejectIcon"
            }
            sx={{
              color: request.status === "approved" ? "green" : "red",
              height: "35px",
              textTransform: "capitalize",
            }}
            endIcon={
              request.status === "approved" ? (
                <img
                  src={verify}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              ) : (
                <img
                  src={unpublished_icon}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              )
            }
          >
            {request.status === "rejected"
              ? "Rejected"
              : request.id
              ? "Approved"
              : "Assigned"}
          </LoadingButton>
        ) : (
          <>
            <LoadingButton
              className="assigned"
              disabled={
                request.storageToAssign > 5000 || request.storageToAssign < 0
              }
              loading={loading}
              loadingPosition="start"
              variant="contained"
              style={{
                backgroundColor:
                  request.status === "pending"
                    ? "#ffcc00"
                    : "rgb(98, 100, 167)",
                height: "28px",
                alignItems: "center",
                textTransform: "capitalize",
                marginTop: "10px",
                marginBottom: "10px",
                width: "5px",
                fontSize: "11px",
              }}
              onClick={() =>
                assignStorage({ ...request, status: "approved" }, setLoading)
              }
            >
              {loading
                ? ""
                : request.status === "pending"
                ? "Approve"
                : "Assign"}
            </LoadingButton>
            <LoadingButton
              loadingPosition="start"
              style={{
                borderColor: "red",
                color: "red",
                height: "28px",
                textTransform: "capitalize",
                marginTop: "10px",
                marginBottom: "10px",
                width: "5px",
                fontSize: "11px",
              }}
              onClick={() => rejectStorage({ ...request, status: "rejected" })}
            >
              {request.status === "pending" && "Reject"}
            </LoadingButton>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default StorageRequestedTableMobile;
