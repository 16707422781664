import React, { useEffect, useState } from "react";
import "./TutorCard.scss"; // Create a separate CSS file for custom styles
import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Image,
  Label,
  StarIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { useBooking } from "../../../../../context/BookingContext";
import userSvg from "../../../../../Assets/images/svg/user.svg";
import moment from "moment";

const TutorCard = ({ tutor, isStarred, isFromStarredPage }) => {
  const { removeStarredTutor, addTutorToStarred } = useBooking();
  const history = useHistory();
  const [isOpenStarredConfirmDialog, setIsOpenStarredConfirmDialog] =
    useState(false);

  return (
    <>
      <Dialog
        header="Remove From Favorites?"
        open={isOpenStarredConfirmDialog}
        style={{ maxWidth: "500px" }}
        confirmButton="Yes, Remove"
        content={
          <Flex column gap="gap.medium">
            <Text>
              Are You Sure You Want to Remove This Tutor From Favorites?
            </Text>
          </Flex>
        }
        onConfirm={() =>
          removeStarredTutor(
            isFromStarredPage ? tutor.tutorEmailId : tutor.email
          )
        }
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setIsOpenStarredConfirmDialog(false);
          },
        }}
      />
      <div
        className="tutor-card"
        onClick={() => {
          history.push(`/tutor/${tutor.email}/${tutor.role}`);
        }}
      >
        <Image
          src={
            tutor?.image
              ? `${tutor.image}${process.env.REACT_APP_AZURE_SAS_TOKEN.replace(
                  /"/g,
                  ""
                )}&${moment()}`
              : userSvg
          }
          className="tutor-image"
        />
        <Flex
          vAlign="center"
          hAlign="center"
          className="star-wrapper"
          onClick={(e) => {
            e.stopPropagation();
            isStarred
              ? setIsOpenStarredConfirmDialog(true)
              : addTutorToStarred(tutor.email);
          }}
        >
          {isStarred ? (
            <StarIcon style={{ color: "#FFB900" }} />
          ) : (
            <StarIcon outline />
          )}
        </Flex>
        <div className="tutor-info overlay">
          <Flex
            column
            gap="gap.small"
            space="between"
            style={{ height: "100%" }}
          >
            <Flex column gap="gap.smaller">
              <Tooltip
                trigger={<p className="tutor-name">{tutor?.name || "Tutor"}</p>}
                content={tutor?.name || "Tutor"}
              />
              {isFromStarredPage ? (
                <Text content={tutor.role} />
              ) : tutor?.isAvailableSlot ? (
                <Label
                  content="Slots Available"
                  className="slots-status"
                  circular
                />
              ) : (
                <Label
                  content="No Slots For Today"
                  className="no-slots-status"
                  color=""
                  circular
                />
              )}

              <Flex wrap gap="gap.smaller">
                {tutor?.specialities?.length
                  ? tutor?.specialities.map(
                      (speciality, i) =>
                        i < 3 && (
                          <Tooltip
                            trigger={
                              <span
                                key={`tutor-speciality-${i}`}
                                className="tutor-speciality"
                              >{`# ${speciality}`}</span>
                            }
                            content={`# ${speciality}`}
                          />
                        )
                    )
                  : "Not Provided"}
              </Flex>
            </Flex>
            <Button className="view-profile-button">
              <Text weight="semibold" content={`View Profile`} />
            </Button>
          </Flex>
        </div>
      </div>
      {/* <div
        className="tutor-card"
        style={{
          background: `
      linear-gradient(0deg, rgba(0, 0, 0, 0.79) 7.37%, rgba(0, 0, 0, 0) 56.84%),
      url('${tutor?.image ? `${tutor.image}?${moment()}` : userSvg}')
    `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat", // Ensures no repeating of the image
        }}
        onClick={() => {
          history.push(`/tutor/${tutor.email}/${tutor.role}`);
        }}
      >
        <Flex
          vAlign="center"
          hAlign="center"
          className="star-wrapper"
          onClick={(e) => {
            e.stopPropagation();
            isStarred
              ? removeStarredTutor(
                  isFromStarredPage ? tutor.tutorEmailId : tutor.email
                )
              : addTutorToStarred(tutor.email);
          }}
        >
          {isStarred ? (
            <StarIcon style={{ color: "#FFB900" }} />
          ) : (
            <StarIcon outline />
          )}
        </Flex>
        <div className="tutor-info">
          <Flex column gap="gap.small">
            <Tooltip
              trigger={<p className="tutor-name">{tutor?.name || "Tutor"}</p>}
              content={tutor?.name || "Tutor"}
            />

            <Flex vAlign="center" gap="gap.medium">
              <Flex gap="gap.small" vAlign="center" style={{ width: "50%" }}>
                <SchoolIcon htmlColor="#fff" />
                <Tooltip
                  trigger={
                    <span className="tutor-description">
                      {tutor?.yearOfStudy
                        ? `${tutor?.yearOfStudy} Year`
                        : "Not Provided"}
                    </span>
                  }
                  content={
                    tutor?.yearOfStudy
                      ? `${tutor?.yearOfStudy} Year`
                      : "Not Provided"
                  }
                />
              </Flex>
              <Flex gap="gap.small" vAlign="center" style={{ width: "50%" }}>
                <FiberManualRecordIcon
                  style={{ fontSize: "10px" }}
                  htmlColor="#fff"
                />
                <Tooltip
                  trigger={
                    <span className="tutor-description">
                      {tutor?.fieldOfStudy || "Not Provided"}
                    </span>
                  }
                  content={tutor?.fieldOfStudy || "Not Provided"}
                />
              </Flex>
            </Flex>
            <Flex vAlign="center" gap="gap.small">
              <ChatBubbleIcon htmlColor="#fff" />
              <Tooltip
                trigger={
                  <span className="tutor-description ">
                    {tutor?.specialities?.length
                      ? tutor.specialities.join(", ")
                      : "Not Provided"}
                  </span>
                }
                content={
                  tutor?.specialities?.length
                    ? tutor.specialities.join(", ")
                    : "Not Provided"
                }
              />
            </Flex>
          </Flex>
        </div>
      </div> */}
    </>
  );
};

export default TutorCard;
