import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles({
  dialog: {
    boxShadow: "-10px 12px 40px rgba(121, 121, 121, 0.12)",
    borderRadius: "12px !important",
    padding: "16px 20px 32px 20px",
  },
});

export const StorageRequestDialog = withTranslation()(
  ({ handleClose, open, user, t, message = null, title = null }) => {
    const [alreadyRequested, setAlreadyRequested] = React.useState(false);
    const translation = t("alertDialog");

    const warningMessageText =
      user.actualRole === "SuperAdmin"
        ? "Your storage is full. To increase your storage space, please buy it from storage plan"
        : !alreadyRequested
        ? "Your storage is full. To increase your storage space, please contact your super admin for assistance."
        : "Your request for additional storage is currently being processed. Please wait for approval from your super admin.";
    const topMessageText =
      user.actualRole === "SuperAdmin"
        ? "Storage Full - Buy It"
        : !alreadyRequested
        ? "Storage Full - Contact Super Admin"
        : "Storage Request Pending";

    const classes = useStyles();

    const sendStorageRequet = async () => {
      if (user.actualRole === "SuperAdmin") {
        // window.open("https://localhost:3003/storage", "_blank");
        window.open("https://www.acv.app/storage", "_blank");
      } else {
        const finalPayload = {
          role: user.role,
          email: user.mail,
          name: user.displayName,
          slug: user.slug,
        };

        let response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_EP_URL}/api/storage/request${user.slug}`,
          data: finalPayload,
        });

        if (response.data.isAlreadyRequested) {
          setAlreadyRequested(true);
        } else {
          handleClose();
        }
      }
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: 84,
                width: 84,
                background: "#f6efef",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="ACV - Storage"
                src="https://acvstorageproduc.blob.core.windows.net/react-images/storage.png"
                className="img-fluid"
                style={{ width: 40, height: 40 }}
              />
            </Box>
          </Box>
          <Typography
            variant="h3"
            fontWeight={600}
            mt={3}
            sx={{
              textAlign: "center",
              fontSize: 18,
              lineHeight: "22px",
              whiteSpace: { md: "nowrap" },
            }}
          >
            {title ? title : topMessageText}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            display={"grid"}
            alignItems="center"
            justifyContent={"center"}
            mb={1}
          >
            <Typography
              variant="subtitle4"
              sx={{
                fontWeight: 600,
                fontSize: 15,
                textAlign: "center",
              }}
            >
              {message ? message : warningMessageText}
            </Typography>
          </Box>
        </DialogContent>
        <Divider sx={{ borderColor: "#EAEEF4" }} />
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            sx={{
              mr: 2,
              color: "black",
              fontSize: 12,
              background: "#ffffff",
              border: `1px solid #0000`,
              fontWeight: 600,
              textTransform: "capitalize",
              ":hover": {
                background: "#ffffff",
              },
            }}
          >
            {translation.cancel}
          </Button>
          <Button
            onClick={async (e) => {
              e.stopPropagation();
              if (alreadyRequested) {
                handleClose();
                return;
              }
              await sendStorageRequet();
            }}
            sx={{
              color: "#ffff",
              fontSize: 12,
              background: "rgb(219, 103, 95)",
              fontWeight: 600,
              textTransform: "capitalize",
              ":hover": {
                background: "rgb(219, 103, 95)",
              },
            }}
          >
            {alreadyRequested
              ? translation.yes
              : user.actualRole === "SuperAdmin"
              ? "Buy it"
              : "Request More Storage"}
          </Button>
        </Box>
      </Dialog>
    );
  }
);
