import axios from "axios";
import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_EP_URL;

const TutorRequest = createContext();

export const useTutorRequest = () => {
  return useContext(TutorRequest);
};

export const TutorRequestContext = (props) => {
  const { children, user } = props;

  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfileData, setUserProfileData] = useState();

  const [isTutorRequestsLoading, setIsTutorRequestsLoading] = useState(false);
  const [tutorRequests, setTutorRequests] = useState([]);

  const [pendingTutorRequest, setPendingTutorRequest] = useState();

  // tutor request to update
  const [tutorRequestToUpdate, setTutorRequestToUpdate] = useState({
    id: "",
    status: "", // pending, approved, cancelled
  });
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  // Tutor info modal
  const [isOpenTutorInfoModal, setIsOpenTutorInfoModal] = useState(false);
  // used for tutor info modal
  const [tutorInfo, setTutorInfo] = useState({});
  const [isLoadingTutorInfo, setIsLoadingTutorInfo] = useState(false);

  const createTutorRequest = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${url}/api/tutor-request${user.slug}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      setIsLoading(false);
      if (response.data.success) {
        setIsRequested(true);
        toast.success(response.data.message);
        setPendingTutorRequest(response.data.tutorRequest);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error.response);
      toast.error("Failed to create tutor request. Please try again.");
    }
  };

  const getAllPendingTutorRequests = async (search = "") => {
    try {
      setIsTutorRequestsLoading(true);
      const response = await axios.get(
        `${url}/api/tutor-request${user.slug}&status=pending&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      setIsTutorRequestsLoading(false);
      if (response?.data?.success) {
        const tutorRequests = response?.data?.tutorRequests || [];
        setTutorRequests(tutorRequests);
      } else {
        setTutorRequests([]);
      }
    } catch (error) {
      setIsTutorRequestsLoading(false);
      setTutorRequests([]);
    }
  };

  const getProfileData = async () => {
    try {
      setIsLoading(true);
      const { slug, mail, accessToken } = user;
      const { data } = await axios.get(
        `${url}/api/profile/get-profile/${mail}${slug}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsLoading(false);
      if (data?.data) {
        setUserProfileData(data.data);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const checkTutorRequest = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${url}/api/tutor-request/check-request${user.slug}`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    if (response.data.success) {
      setIsRequested(response.data.isRequested);
      setPendingTutorRequest(response.data.tutorRequest);
    }
    setIsLoading(false);
  };

  const updateTutorRequest = async (tutorRequestId = "", status = "") => {
    try {
      setIsLoading(true);
      if (!!tutorRequestId && !!status) {
        const response = await axios.put(
          `${url}/api/tutor-request/${tutorRequestId}${user.slug}`,
          { status },
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );
        setIsLoading(false);
        if (response.data.success) {
          setIsOpenConfirmationModal(false);
          setTutorRequestToUpdate({
            id: "",
            status: "", // pending, approved, cancelled
          });
          setIsRequested(false);

          // refresh tutor requests
          let cloneTutorRequests = [...tutorRequests];
          cloneTutorRequests = cloneTutorRequests.filter(
            (request) => request.id !== tutorRequestId
          );
          setTutorRequests(cloneTutorRequests);
          toast.success(response.data.message);
        } else {
          toast.error("Failed to update tutor request. Please try again.");
        }
      } else {
        setIsLoading(false);
        toast.error("Failed to update tutor request. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to update tutor request. Please try again.");
    }
  };

  const getTutorInfo = async (tutorEmail, role) => {
    try {
      setIsLoadingTutorInfo(true);
      const { slug, accessToken } = user;
      const { data } = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-tutor-info/${tutorEmail}${slug}&role=${role}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      setIsLoadingTutorInfo(false);
      if (data) {
        let profileData = {
          ...data,
          skills: data.ProfileSkills,
          interests: data.ProfileInterests,
          askMeAbout: data.ProfileSpecialities,
        };
        setTutorInfo(profileData);
      } else {
        setTutorInfo({});
      }
    } catch (error) {
      console.log(error);
      setIsLoadingTutorInfo(false);
    }
  };

  // handle Confirmation Modal cancel
  const onCancel = () => {
    setIsOpenConfirmationModal(false);
    setTutorRequestToUpdate({
      id: "",
      status: "", // pending, approved, cancelled
    });
  };

  // handle Confirmation Modal confirm
  const onConfirm = () => {
    updateTutorRequest(tutorRequestToUpdate.id, tutorRequestToUpdate.status);
  };

  return (
    <TutorRequest.Provider
      value={{
        user,
        isRequested,
        isLoading,
        createTutorRequest,
        getProfileData,
        getAllPendingTutorRequests,
        checkTutorRequest,
        tutorRequests,
        isTutorRequestsLoading,
        userProfileData,
        updateTutorRequest,
        isOpenConfirmationModal,
        setIsOpenConfirmationModal,
        tutorRequestToUpdate,
        setTutorRequestToUpdate,
        pendingTutorRequest,
        onCancel,
        onConfirm,
        getTutorInfo,
        tutorInfo,
        isLoadingTutorInfo,
        isOpenTutorInfoModal,
        setIsOpenTutorInfoModal,
      }}
    >
      {children}
    </TutorRequest.Provider>
  );
};
