import React, { useState } from "react";
import verify from "../../Assets/images/verify.png";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import { LoadingButton } from "@mui/lab";
import { Avatar, TextField, Autocomplete, Badge } from "@mui/material";

function StorageRequestedDataTable({
  request,
  index,
  storage,
  SmallAvatar,
  rejectStorage,
  assignStorage,
  setStorageValue,
  isTabletView,
  storageInfo,
}) {
  const [loading, setLoading] = useState(false);
  const availableStorage = storageInfo.providedStorage - storageInfo.storage;
  return (
    <li className="table-row" key={request.email}>
      <div className="col col-1" data-label="Index" style={{ padding: "18px" }}>
        {index + 1}
      </div>
      <div
        className="col col-2"
        data-label="Image"
        style={{
          padding: "8px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Badge
          overlap="circular"
          style={{ zIndex: "0" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={
            request.status !== "pending" && (
              <SmallAvatar
                alt="icon"
                src={
                  (request.status === "rejected" && unpublished_icon) ||
                  (request.status === "approved" && verify)
                }
              />
            )
          }
        >
          <Avatar>{request.name.charAt(0).toUpperCase()}</Avatar>
        </Badge>
      </div>
      <div className="col col-3" data-label="Name" style={{ padding: "18px" }}>
        {request.name}
      </div>
      <div className="col col-4" data-label="Email" style={{ padding: "18px" }}>
        {request.email}
      </div>
      <div className="col col-5" data-label="Role" style={{ padding: "18px" }}>
        {request.role}
      </div>

      <div className="col col-6" data-label="Assign Storage">
        <Autocomplete
          id="combo-box-demo"
          options={storage}
          freeSolo
          value={request.storage === 0 ? 0 : request.storageToAssign || 0}
          sx={{ m: 1, minWidth: 120 }}
          size="small"
          onInputChange={(e, newValue) => {
            setStorageValue(parseInt(newValue) || 0, request);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              type="number"
              label="Select storage in GB"
              error={
                storageInfo.storage === 0
                  ? null
                  : request.storageToAssign > availableStorage ||
                    availableStorage < request.storageToAssign ||
                    request.storageToAssign <= 0
              }
              helperText={
                (storageInfo.storage === 0
                  ? null
                  : request.storageToAssign > availableStorage &&
                    "Exceed your storage limits") ||
                (storageInfo.storage === 0
                  ? null
                  : request.storageToAssign < 0 && "Enter a valid storage") ||
                (availableStorage < request.storageToAssign &&
                  "Insufficient storage")
              }
            />
          )}
        />
      </div>
      <div
        className={isTabletView ? "col col-9" : "col col-8"}
        data-label="Action"
        style={{
          padding: "10px",
          paddingRight: request.status
            ? request.status === "approved"
              ? "51px"
              : "56px"
            : "10px",
        }}
      >
        {request.status === "approved" || request.status === "rejected" ? (
          <LoadingButton
            disabled
            loadingPosition="start"
            className={
              request.status === "approved" ? "iconButton" : "rejectIcon"
            }
            sx={{
              color: request.status === "approved" ? "green" : "red",
              height: "35px",
              textTransform: "capitalize",
            }}
            endIcon={
              request.status === "approved" ? (
                <img
                  src={verify}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              ) : (
                <img
                  src={unpublished_icon}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              )
            }
          >
            {request.status === "rejected"
              ? "Rejected"
              : request.id
              ? "Approved"
              : "Assigned"}
          </LoadingButton>
        ) : (
          <>
            <LoadingButton
              className="assigned"
              disabled={
                request.storageToAssign > availableStorage ||
                availableStorage < request.storageToAssign ||
                request.storageToAssign <= 0
              }
              loading={loading}
              loadingPosition="start"
              variant="contained"
              style={{
                backgroundColor:
                  request.status === "pending"
                    ? "#ffcc00"
                    : "rgb(98, 100, 167)",
                height: "35px",
                alignItems: "baseline",
                textTransform: "capitalize",
                width: "86px",
              }}
              onClick={() => {
                assignStorage({ ...request, status: "approved" }, setLoading);
              }}
            >
              {loading
                ? ""
                : request.status === "pending"
                ? "Approve"
                : "Assign"}
            </LoadingButton>
            {request.status === "pending" && (
              <LoadingButton
                loadingPosition="start"
                style={{
                  borderColor: "red",
                  color: "red",
                  height: "35px",
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  rejectStorage({ ...request, status: "rejected" })
                }
              >
                {request.status === "pending" && "Reject"}
              </LoadingButton>
            )}
          </>
        )}
      </div>
    </li>
  );
}

export default StorageRequestedDataTable;
