import {
  ArchiveIcon,
  Attachment,
  Flex,
  ShareGenericIcon,
  Text,
} from "@fluentui/react-northstar";
import { FileUploader } from "react-drag-drop-files";
import { withTranslation } from "react-i18next";

const FileAttachment = ({
  isEditable,
  handleFileChange,
  file,
  setFile,
  t,
  extension = [],
}) => {
  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  function extractFileName(url) {
    // Parse the URL
    let parsedUrl = new URL(url);
    // Get the pathname (path component of the URL)
    let pathname = parsedUrl.pathname;
    // Split the pathname by '/' and get the last part which is the file name
    let parts = pathname.split("/");
    let fileName = parts[parts.length - 1];
    // Decode the URI encoding in the file name
    fileName = decodeURIComponent(fileName);
    return fileName;
  }

  return (
    <>
      {isEditable && <Text content={attachmentTranslation.file.header} />}

      {isEditable && (
        <FileUploader
          className="file-browse-section-outer"
          multiple
          handleChange={handleFileChange}
          types={extension.length > 0 ? extension : ["docx", "pptx", "xlsx", "pdf", "txt"]}
          //   value={file}
          children={
            <div className="file-browse-section-outer mt-2">
              <div className="file-browse-section-inner">
                <Text weight="bold">
                  <span className="file-browse-section-file">
                    {attachmentTranslation.file.browse}
                    {"  "}
                  </span>
                  {attachmentTranslation.file.or} {"  "}
                  {attachmentTranslation.file.dragAndDropHere}
                </Text>
              </div>
            </div>
          }
          name="file"
          fluid
        />
      )}

      {isEditable ? (
        <div className="attachment-file-outer">
          <Flex gap="gap.small" column>
            {file && file.length > 0
              ? file.map((f, index) => {
                  return (
                    <Attachment
                      key={index}
                      className="attachment-file-inner"
                      header={
                        f?.file?.name ||
                        `${extractFileName(f)}` ||
                        `${attachmentTranslation.file.document}${index + 1}`
                      }
                      actionable
                      action={{
                        icon: (
                          <ArchiveIcon
                            style={{ color: "black" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const data = file.filter(
                                (k, ind) => ind != index
                              );
                              setFile(data);
                            }}
                          />
                        ),

                        title: translation.close,
                      }}
                      onClick={() => {
                        if (
                          typeof f === "string" &&
                          f?.includes(
                            "https://acvstorageprod.blob.core.windows.net"
                          )
                        ) {
                          window.open(f);
                        } else {
                          try {
                            let fileUrl = URL.createObjectURL(f?.file);
                            window.open(fileUrl);
                          } catch (error) {}
                        }
                      }}
                    />
                  );
                })
              : ""}
          </Flex>
        </div>
      ) : (
        <div className="attachment-file-outer">
          <Flex gap="gap.small" column>
            {file && file.length > 0
              ? file.map((f, index) => {
                  return (
                    <Attachment
                      key={index}
                      className="attachment-file-inner"
                      header={
                        `${extractFileName(f)}` ||
                        `${attachmentTranslation.file.file} ${index + 1}`
                      }
                      actionable
                      action={{
                        icon: (
                          <ShareGenericIcon
                            style={{ color: "black" }}
                            onClick={() => {
                              window.open(f);
                            }}
                          />
                        ),

                        title: attachmentTranslation.file.openFile,
                      }}
                    />
                  );
                })
              : ""}
          </Flex>
        </div>
      )}
    </>
  );
};

export default withTranslation()(FileAttachment);
