import { Tabs } from "antd";
import React, { useEffect } from "react";
import "./index.scss";
import {
  findBiggestEndTime,
  findSmallestStartTime,
  generateAvailabilityDays,
  generateTimeSlots,
} from "./utils/utils";
import BlockedDates from "./components/BlockedDates/BlockedDates";
import { useBooking } from "../../../../context/BookingContext";
import moment from "moment";
import { toast } from "react-toastify";
import PublicProfile from "./components/PublicProfile/PublicProfile";
import AvailableHours from "./components/AvailableHours/AvailableHours";
import { useTranslation } from "react-i18next";

const Setting = (props) => {
  const { t } = useTranslation();
  const {
    durationOfSlot,
    getScheduledSlots,
    getMinStartMaxEndFromSlotsByWeekDay,
    getProfileData,
    availabilityTimes,
    setAvailabilityTimes,
    isEditing,
    isShowingScheduledSlots,
    selectedDates,
  } = useBooking();

  const translation = t("booking").setting;

  const handleToggleWeekDay = (checked, index) => {
    let clonedAvailabilityTimes = [...availabilityTimes];

    if (checked) {
      if (isEditing || isShowingScheduledSlots) {
        const oldTimeSlots = clonedAvailabilityTimes[index].timeSlots;

        clonedAvailabilityTimes[index].isSelected = checked;
        if (
          oldTimeSlots.length &&
          !clonedAvailabilityTimes[index].isCommonSlots
        ) {
          const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
          const oldRemovedSlots = oldTimeSlots.map((slot) => ({
            ...slot,
            isDeleted: false,
            isRemoved: false,
          }));

          const updatedTimeSlots = [...oldRemovedSlots, ...timeSlots];
          clonedAvailabilityTimes[index].timeSlots = updatedTimeSlots;
          const newTimeSlotsKeys = [
            ...new Set(updatedTimeSlots.map((slot) => slot.key)),
          ];

          newTimeSlotsKeys.forEach((newTimeSlotsKey) => {
            clonedAvailabilityTimes[index].selectedTimeSlots[newTimeSlotsKey] =
              {
                startTime: findSmallestStartTime(
                  updatedTimeSlots,
                  newTimeSlotsKey
                ),
                endTime: findBiggestEndTime(updatedTimeSlots, newTimeSlotsKey),
              };
          });
          clonedAvailabilityTimes[index].isCommonSlots = true;
        } else if (oldTimeSlots.length) {
          clonedAvailabilityTimes[index].timeSlots = oldTimeSlots.map(
            (slot) => ({
              ...slot,
              isDeleted: false,
              isRemoved: false,
            })
          );
        } else {
          const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
          clonedAvailabilityTimes[index].timeSlots = timeSlots;
          clonedAvailabilityTimes[index].selectedTimeSlots = {
            1: {
              startTime: timeSlots[0].startTime,
              endTime: timeSlots[timeSlots.length - 1].endTime,
            },
          };
        }
      } else {
        const oldTimeSlots = clonedAvailabilityTimes[index].timeSlots;
        const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
        if (
          oldTimeSlots.length &&
          !clonedAvailabilityTimes[index].isCommonSlots
        ) {
          clonedAvailabilityTimes[index].timeSlots = [
            ...oldTimeSlots,
            ...timeSlots,
          ];
        } else {
          clonedAvailabilityTimes[index].timeSlots = timeSlots;
        }
        const newTimeSlotsKeys = [
          ...new Set([...oldTimeSlots, ...timeSlots].map((slot) => slot.key)),
        ];

        newTimeSlotsKeys.forEach((newTimeSlotsKey) => {
          clonedAvailabilityTimes[index].selectedTimeSlots[newTimeSlotsKey] = {
            startTime: findSmallestStartTime(
              [...oldTimeSlots, ...timeSlots],
              newTimeSlotsKey
            ),
            endTime: findBiggestEndTime(
              [...oldTimeSlots, ...timeSlots],
              newTimeSlotsKey
            ),
          };
        });

        clonedAvailabilityTimes[index].isSelected = checked;
        clonedAvailabilityTimes[index].isCommonSlots = true;
      }
    } else {
      if (isEditing || isShowingScheduledSlots) {
        clonedAvailabilityTimes[index].isSelected = checked;
        clonedAvailabilityTimes[index].timeSlots = clonedAvailabilityTimes[
          index
        ].timeSlots.map((slot) => ({
          ...slot,
          isDeleted: true,
        }));
      } else {
        clonedAvailabilityTimes[index].isSelected = checked;
        clonedAvailabilityTimes[index].selectedTimeSlots = {};
        clonedAvailabilityTimes[index].timeSlots = [];
      }
    }
    setAvailabilityTimes(clonedAvailabilityTimes);
  };

  const handleToggleAllWeekDays = (checked) => {
    let clonedAvailabilityTimes = [...availabilityTimes];
    if (isEditing || isShowingScheduledSlots) {
      if (checked) {
        clonedAvailabilityTimes.forEach((availabilityTime) => {
          const oldTimeSlots = availabilityTime.timeSlots;

          availabilityTime.isSelected = checked;
          if (oldTimeSlots.length && !availabilityTime.isCommonSlots) {
            const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
            const oldRemovedSlots = oldTimeSlots.map((slot) => ({
              ...slot,
              isDeleted: false,
              isRemoved: false,
            }));

            const updatedTimeSlots = [...oldRemovedSlots, ...timeSlots];
            availabilityTime.timeSlots = updatedTimeSlots;
            const newTimeSlotsKeys = [
              ...new Set(updatedTimeSlots.map((slot) => slot.key)),
            ];

            newTimeSlotsKeys.forEach((newTimeSlotsKey) => {
              availabilityTime.selectedTimeSlots[newTimeSlotsKey] = {
                startTime: findSmallestStartTime(
                  updatedTimeSlots,
                  newTimeSlotsKey
                ),
                endTime: findBiggestEndTime(updatedTimeSlots, newTimeSlotsKey),
              };
            });
          } else if (oldTimeSlots.length) {
            availabilityTime.timeSlots = oldTimeSlots.map((slot) => ({
              ...slot,
              isDeleted: false,
              isRemoved: false,
            }));
          } else {
            const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
            availabilityTime.timeSlots = timeSlots;
            availabilityTime.selectedTimeSlots = {
              1: {
                startTime: timeSlots[0].startTime,
                endTime: timeSlots[timeSlots.length - 1].endTime,
              },
            };
          }
          availabilityTime.isCommonSlots = true;
        });
      } else {
        clonedAvailabilityTimes.forEach((availabilityTime) => {
          availabilityTime.isSelected = checked;
          availabilityTime.timeSlots = availabilityTime.timeSlots.map(
            (slot) => ({
              ...slot,
              isDeleted: true,
            })
          );
          availabilityTime.isCommonSlots = true;
        });
      }
    } else {
      const timeSlots = generateTimeSlots("06:00 AM", durationOfSlot);
      if (checked) {
        clonedAvailabilityTimes.forEach((availabilityTime) => {
          availabilityTime.isSelected = checked;
          if (
            availabilityTime.timeSlots.length &&
            !availabilityTime.isCommonSlots
          ) {
            availabilityTime.timeSlots = [
              ...availabilityTime.timeSlots,
              ...timeSlots,
            ];
            availabilityTime.isCommonSlots = true;
          } else {
            availabilityTime.timeSlots = timeSlots;
          }
          availabilityTime.selectedTimeSlots = {
            1: {
              startTime: timeSlots[0].startTime,
              endTime: timeSlots[timeSlots.length - 1].endTime,
            },
          };
          availabilityTime.isCommonSlots = true;
        });
      } else {
        clonedAvailabilityTimes.forEach((availabilityTime) => {
          availabilityTime.isSelected = checked;
          availabilityTime.timeSlots = [];
          availabilityTime.selectedTimeSlots = {};
        });
      }
    }
    setAvailabilityTimes(clonedAvailabilityTimes);
  };

  const handleAddTime = (index) => {
    // const nearestHourFromCurrentTime = moment()
    //   .startOf("hour")
    //   .add(1, "hours")
    //   .format("h:mm A");

    let clonedAvailabilityTimes = [...availabilityTimes];
    if (isEditing || isShowingScheduledSlots) {
      const oldTimeSlots = clonedAvailabilityTimes[index].timeSlots;
      const oldRemovedTimeSlots = oldTimeSlots.filter(
        (timeSlot) => timeSlot.isRemoved
      );
      if (oldRemovedTimeSlots.length > 0) {
        const keysOfRemovedTimeSlots = [
          ...new Set(oldRemovedTimeSlots.map((timeSlot) => timeSlot.key)),
        ];
        const smallestKeyOfRemovedTimeSlots = Math.min(
          ...keysOfRemovedTimeSlots
        );
        clonedAvailabilityTimes[index].timeSlots = oldTimeSlots.map(
          (timeSlot) => {
            if (
              Number(timeSlot.key) === Number(smallestKeyOfRemovedTimeSlots)
            ) {
              return {
                ...timeSlot,
                isRemoved: false,
                isDeleted: false,
              };
            }
            return timeSlot;
          }
        );
      } else {
        const timeSlots = clonedAvailabilityTimes[index].timeSlots;
        // Find the latest end time

        const commonSlots = timeSlots.filter((timeSlot) => timeSlot.isCommon);
        let latestEndTime = commonSlots[0].isNew
          ? commonSlots[0].endTime
          : moment(commonSlots[0].endTime).format("h:mm A");
        latestEndTime = commonSlots.reduce((latest, slot) => {
          const slotEndTime = slot.isNew
            ? moment(slot.endTime, "h:mm A")
            : moment(slot.endTime);
          return slotEndTime.isAfter(moment(latest, "h:mm A"), "minutes")
            ? slot.isNew
              ? slot.endTime
              : moment(slot.endTime).format("h:mm A")
            : latest;
        }, latestEndTime);
        // Generate new time slots starting from the latest end time
        const newTimeSlots = generateTimeSlots(latestEndTime, durationOfSlot);

        // Get the key of the new time slots
        const newTimeSlotsKeys = [
          ...new Set(newTimeSlots.map((slot) => slot.key)),
        ];

        newTimeSlotsKeys.forEach((newTimeSlotsKey) => {
          clonedAvailabilityTimes[index].selectedTimeSlots[newTimeSlotsKey] = {
            startTime: findSmallestStartTime(
              [...timeSlots, ...newTimeSlots],
              newTimeSlotsKey
            ),
            endTime: findBiggestEndTime(
              [...timeSlots, ...newTimeSlots],
              newTimeSlotsKey
            ),
          };
        });

        clonedAvailabilityTimes[index].timeSlots = [
          ...timeSlots,
          ...newTimeSlots,
        ];
      }
      setAvailabilityTimes(clonedAvailabilityTimes);
    } else {
      const timeSlots = clonedAvailabilityTimes[index].timeSlots;
      // Find the latest end time
      let latestEndTime = timeSlots[0].isNew
        ? timeSlots[0].endTime
        : moment(timeSlots[0].endTime).format("h:mm A");
      latestEndTime = timeSlots.reduce((latest, slot) => {
        const slotEndTime = moment(slot.endTime, "h:mm A");
        return slotEndTime.isAfter(moment(latest, "h:mm A"))
          ? slot.endTime
          : latest;
      }, latestEndTime);

      // Generate new time slots starting from the latest end time
      const newTimeSlots = generateTimeSlots(latestEndTime, durationOfSlot);

      // Get the key of the new time slots
      const newTimeSlotsKey = newTimeSlots[0].key;

      // Get the selected start and end time of the new time slots
      const newTimeSlotsSelectedStartTime = newTimeSlots[0].startTime;
      const newTimeSlotsSelectedEndTime =
        newTimeSlots[newTimeSlots.length - 1].endTime;

      // Add the new time slots to the selected time slots
      clonedAvailabilityTimes[index].selectedTimeSlots[newTimeSlotsKey] = {
        startTime: newTimeSlotsSelectedStartTime,
        endTime: newTimeSlotsSelectedEndTime,
      };

      // Find all available new time slots
      const availableTimeSlots = newTimeSlots.filter(
        (newSlot) =>
          !timeSlots.some(
            (existingSlot) =>
              existingSlot.startTime === newSlot.startTime &&
              existingSlot.endTime === newSlot.endTime
          )
      );

      if (availableTimeSlots.length > 0) {
        const updatedTimeSlots = [...timeSlots, ...availableTimeSlots].sort(
          (a, b) =>
            moment(a.startTime, "h:mm A").diff(moment(b.startTime, "h:mm A"))
        );
        clonedAvailabilityTimes[index].timeSlots = updatedTimeSlots;
        setAvailabilityTimes(clonedAvailabilityTimes);
      } else {
        console.log("No available time slots after the latest end time.");
      }
    }
  };

  const handleRemoveTime = (index, timeSlotKey) => {
    let clonedAvailabilityTimes = [...availabilityTimes];
    const timeSlots = clonedAvailabilityTimes[index].timeSlots;
    if (isEditing || isShowingScheduledSlots) {
      const slotsToUpdate = timeSlots.filter(
        (timeSlot) =>
          (Number(timeSlot.key) === Number(timeSlotKey) && !timeSlot.isNew) ||
          Number(timeSlot.key) !== Number(timeSlotKey)
      );

      const updatedTimeSlots = slotsToUpdate.map((timeSlot) => {
        if (timeSlot.key === timeSlotKey) {
          return { ...timeSlot, isDeleted: true, isRemoved: true };
        }
        return timeSlot;
      });

      clonedAvailabilityTimes[index].timeSlots = updatedTimeSlots;
      clonedAvailabilityTimes[index].isSelected = updatedTimeSlots.some(
        (timeSlot) => !timeSlot.isRemoved
      );
    } else {
      const newTimeSlots = timeSlots.filter(
        (timeSlot) => timeSlot.key !== timeSlotKey
      );

      clonedAvailabilityTimes[index].timeSlots = newTimeSlots;
      if (newTimeSlots.length === 0) {
        clonedAvailabilityTimes[index].isSelected = false;
      }
    }
    setAvailabilityTimes(clonedAvailabilityTimes);
  };

  const handleChangeTime = async (index, key, value, type) => {
    // Clone the availability times
    let clonedAvailabilityTimes = [...availabilityTimes];
    // Get the selected time slots
    let selectedTimeSlots = clonedAvailabilityTimes[index].selectedTimeSlots;
    // Get the selected time slot
    let selectedTimeSlot = selectedTimeSlots[key];

    // Update the selected time slot
    if (type === "startTime") {
      selectedTimeSlot.startTime = value;

      if (isEditing || isShowingScheduledSlots) {
        // Update the timeSlots where the startTime is smaller than the selected startTime
        const updatedTimeSlots = await Promise.all(
          clonedAvailabilityTimes[index].timeSlots.map((timeSlot) => {
            const currentTime = moment();
            const timeSlotStartTime = timeSlot.isNew
              ? currentTime.clone().set({
                  hour: moment(timeSlot.startTime, "h:mm A").hour(),
                  minute: moment(timeSlot.startTime, "h:mm A").minute(),
                })
              : currentTime.clone().set({
                  hour: moment(timeSlot.startTime).hour(),
                  minute: moment(timeSlot.startTime).minute(),
                });
            const timeSlotEndTime = timeSlot.isNew
              ? currentTime.clone().set({
                  hour: moment(timeSlot.endTime, "h:mm A").hour(),
                  minute: moment(timeSlot.endTime, "h:mm A").minute(),
                })
              : currentTime.clone().set({
                  hour: moment(timeSlot.endTime).hour(),
                  minute: moment(timeSlot.endTime).minute(),
                });
            const selectedStartTime = currentTime.clone().set({
              hour: moment(value, "h:mm A").hour(),
              minute: moment(value, "h:mm A").minute(),
            });
            const selectedEndTime = currentTime.clone().set({
              hour: moment(
                selectedTimeSlots[timeSlot.key].endTime,
                "h:mm A"
              ).hour(),
              minute: moment(
                selectedTimeSlots[timeSlot.key].endTime,
                "h:mm A"
              ).minute(),
            });

            // If timeSlot startTime is before the selected startTime, mark it as deleted
            if (
              timeSlotStartTime.isBefore(selectedStartTime, "minutes") &&
              Number(key) === Number(timeSlot.key)
            ) {
              return { ...timeSlot, isDeleted: true };
            }
            // Restore timeSlot if it was deleted and is within the updated range
            if (
              timeSlot.isDeleted &&
              Number(key) === Number(timeSlot.key) &&
              timeSlotStartTime.isAfter(selectedStartTime, "minutes") &&
              timeSlotEndTime.isBefore(selectedEndTime, "minutes")
            ) {
              return { ...timeSlot, isDeleted: false };
            }

            return timeSlot;
          })
        );
        clonedAvailabilityTimes[index].timeSlots = updatedTimeSlots;
      }
    } else {
      selectedTimeSlot.endTime = value;

      if (isEditing || isShowingScheduledSlots) {
        // Update the timeSlots where the endTime is larger than the selected endTime
        const updatedTimeSlots = await Promise.all(
          clonedAvailabilityTimes[index].timeSlots.map((timeSlot) => {
            const currentTime = moment();
            const timeSlotStartTime = timeSlot.isNew
              ? currentTime.clone().set({
                  hour: moment(timeSlot.startTime, "h:mm A").hour(),
                  minute: moment(timeSlot.startTime, "h:mm A").minute(),
                })
              : currentTime.clone().set({
                  hour: moment(timeSlot.startTime).hour(),
                  minute: moment(timeSlot.startTime).minute(),
                });
            const timeSlotEndTime = timeSlot.isNew
              ? currentTime.clone().set({
                  hour: moment(timeSlot.endTime, "h:mm A").hour(),
                  minute: moment(timeSlot.endTime, "h:mm A").minute(),
                })
              : currentTime.clone().set({
                  hour: moment(timeSlot.endTime).hour(),
                  minute: moment(timeSlot.endTime).minute(),
                });
            const selectedEndTime = currentTime.clone().set({
              hour: moment(value, "h:mm A").hour(),
              minute: moment(value, "h:mm A").minute(),
            });
            const selectedStartTime = currentTime.clone().set({
              hour: moment(
                selectedTimeSlots[timeSlot.key].startTime,
                "h:mm A"
              ).hour(),
              minute: moment(
                selectedTimeSlots[timeSlot.key].startTime,
                "h:mm A"
              ).minute(),
            });

            // If timeSlot endTime is after the selected endTime, mark it as deleted
            if (
              timeSlotEndTime.isAfter(selectedEndTime, "minutes") &&
              Number(key) === Number(timeSlot.key)
            ) {
              return { ...timeSlot, isDeleted: true };
            }
            // Restore timeSlot if it was deleted and is within the updated range
            if (
              Number(key) === Number(timeSlot.key) &&
              timeSlotEndTime.isBefore(selectedEndTime, "minutes") &&
              timeSlotStartTime.isAfter(selectedStartTime, "minutes") &&
              timeSlot.isDeleted
            ) {
              return { ...timeSlot, isDeleted: false };
            }

            return timeSlot;
          })
        );
        clonedAvailabilityTimes[index].timeSlots = updatedTimeSlots;
      }
    }

    // Update the selected time slot
    selectedTimeSlots[key] = selectedTimeSlot;
    // Update the availability times
    clonedAvailabilityTimes[index].selectedTimeSlots = selectedTimeSlots;
    setAvailabilityTimes(clonedAvailabilityTimes);
  };

  const handleUpdateDuration = (value) => {
    const duration = parseInt(value);
    if (duration >= 15 && duration <= 60 && duration % 15 === 0) {
      let clonedAvailabilityTimes = [...availabilityTimes];

      clonedAvailabilityTimes.forEach((availabilityTime) => {
        let smallestStartTime = "06:00 AM";
        let biggestEndTime = "08:00 AM";
        const validSlots = availabilityTime.timeSlots.filter((slot) => {
          const slotStart = moment(slot.startTime, "h:mm A");
          const slotEnd = moment(slot.endTime, "h:mm A");
          return (
            slotStart.isBetween(
              moment("06:00 AM", "h:mm A"),
              moment("06:00 PM", "h:mm A"),
              null,
              "[]"
            ) &&
            slotEnd.isBetween(
              moment("06:00 AM", "h:mm A"),
              moment("06:00 PM", "h:mm A"),
              null,
              "[]"
            )
          );
        });

        if (validSlots.length > 0) {
          const slotWithSmallestStartTime = validSlots.reduce(
            (earliest, slot) =>
              moment(slot.startTime, "h:mm A").isBefore(
                moment(earliest.startTime, "h:mm A")
              )
                ? slot
                : earliest
          );

          const slotWithLargestEndTime = validSlots.reduce((latest, slot) =>
            moment(slot.endTime, "h:mm A").isAfter(
              moment(latest.endTime, "h:mm A")
            )
              ? slot
              : latest
          );

          smallestStartTime = slotWithSmallestStartTime.startTime;
          biggestEndTime = slotWithLargestEndTime.endTime;
        }
        availabilityTime.timeSlots = generateTimeSlots(
          smallestStartTime,
          duration,
          biggestEndTime
        );
      });

      setAvailabilityTimes(clonedAvailabilityTimes);
      toast.success("Duration updated successfully");
    } else {
      toast.warn(
        "Invalid duration. Must be between 15 and 60 minutes, in multiples of 15."
      );
    }
  };

  useEffect(() => {
    setAvailabilityTimes(
      generateAvailabilityDays().map((availability) => {
        return {
          ...availability,
          isDisabled: !selectedDates.some(
            (date) =>
              moment(date).format("dddd").toLowerCase() === availability.day
          ),
        };
      })
    );
    getProfileData();
    getScheduledSlots("", "", selectedDates, props.user.mail);
    // getMinStartMaxEndFromSlotsByWeekDay(generatedAvailabilityDays);
  }, []);

  return (
    <Tabs defaultActiveKey="1" className="setting-tabs">
      <Tabs.TabPane tab={translation.availableHours} key="1">
        <AvailableHours
          availabilityTimes={availabilityTimes}
          handleToggleWeekDay={handleToggleWeekDay}
          handleAddTime={handleAddTime}
          handleRemoveTime={handleRemoveTime}
          handleChangeTime={handleChangeTime}
          handleUpdateDuration={handleUpdateDuration}
          handleToggleAllWeekDays={handleToggleAllWeekDays}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={translation.blockedDates} key="2">
        <BlockedDates />
      </Tabs.TabPane>
      <Tabs.TabPane tab={translation.publicProfile} key="3">
        <PublicProfile {...props} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Setting;
