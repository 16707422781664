import {
  Button,
  EditIcon,
  Flex,
  Loader,
  Tooltip,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "./TutorsTable.scss";
import { useExternalTutors } from "../../context/ExternalTutorsContext";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const TutorsTable = (props) => {
  const { tutors, isLoading, setIsOpenConfirmModel, setTutorToDelete } =
    useExternalTutors();

  const { t } = useTranslation();

  const bookingTranslation = t("booking").manageTutors;

  return (
    <>
      <Flex column>
        <div style={{ padding: "0 20px 20px" }}>
          <Card className="ms-Grid-row my-3">
            <div className="ms-Grid-col ms-sm12 ms-lg5">
              {bookingTranslation.email}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg5">
              {bookingTranslation.fullName}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2">
              {bookingTranslation.actions}
            </div>
          </Card>
        </div>
        <div
          style={{ padding: "0 20px 20px" }}
          className="external-tutors-lists-wrapper"
        >
          {isLoading ? (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              <Loader />
            </Flex>
          ) : tutors.length ? (
            tutors.map((tutor, i) => {
              return (
                <Card className="ms-Grid-row my-3" key={`tutor-${i}`}>
                  <div className="ms-Grid-col ms-sm12 ms-lg5">
                    <Tooltip
                      content={tutor.email}
                      trigger={
                        <p className="external-tutor-info">{tutor.email}</p>
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg5">
                    <Tooltip
                      content={tutor.name}
                      trigger={
                        <p className="external-tutor-info">{tutor.name}</p>
                      }
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-lg2 text-right pr-3">
                    <Flex gap="gap.smaller">
                      <Button
                        iconOnly
                        icon={<TrashCanIcon />}
                        onClick={() => {
                          setTutorToDelete(tutor);
                          setIsOpenConfirmModel(true);
                        }}
                      />
                    </Flex>
                  </div>
                </Card>
              );
            })
          ) : (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              {bookingTranslation.noResults}
            </Flex>
          )}
        </div>
      </Flex>
    </>
  );
};

export default TutorsTable;
