import { CloseIcon, Dialog, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTutorRequest } from "../../../../../../context/TutorRequestContext";
import { useTranslation } from "react-i18next";

const ConfirmModel = () => {
  const {
    isOpenConfirmationModal,
    onCancel,
    onConfirm,
    isLoading,
    tutorRequestToUpdate,
  } = useTutorRequest();

  const { t } = useTranslation();
  const translation = t("booking").setting.tutorRequest.confirmModel;
  return (
    <Dialog
      cancelButton={
        isLoading ? { text: translation.cancel } : translation.cancel
      }
      confirmButton={
        isLoading
          ? {
              text: translation.confirm,
              loading: isLoading,
              disabled: isLoading,
            }
          : translation.confirm
      }
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={isOpenConfirmationModal}
      header={translation.header}
      content={
        <Text
          content={
            tutorRequestToUpdate?.status === "approved"
              ? translation.approveMessage
              : translation.cancelMessage
          }
        />
      }
      className="smallDlg"
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: onCancel,
        disabled: isLoading,
      }}
    />
  );
};

export default ConfirmModel;
