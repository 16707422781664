import {
  Button,
  ChevronDownIcon,
  CloseIcon,
  Dialog,
  Flex,
  MenuButton,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import useMagicBar from "./useMagicBar";

import "./MagicBar.scss";
import { withTranslation } from "react-i18next";
import { CoinIcon } from "../../../../../Assets/icons/icons";

const DropDown = ({ options, selected, onChange, classes, label }) => {
  return (
    <Flex column>
      <label>{label}</label>
      <select
        value={selected}
        className={`gpt-form-select gpt-form-select-sm mb-3 ${classes}`}
        aria-label=".gpt-form-select-sm example"
        onChange={(e) => onChange(e.target.value)}
      >
        {Array.isArray(options) && options.length
          ? options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))
          : []}
      </select>
    </Flex>
  );
};

const FeedbackMagicBar = ({
  button,
  responseHandler,
  type,
  isMixMode,
  FEEDBACK_TYPES,
  user,
  setUser,
  t,
}) => {
  const {
    isDialogOpen,
    buttonClickHandler,
    closeDialogHandler,
    languages,

    isLoading,
    language,
    setLanguage,

    title,
    setTitle,

    feedbackTypes,
    feedbackType,
    setFeedbackType,
    noOfFeedbackQuestions,
    noOfFeedback,
    setNoOfFeedback,
    noOfOption,
    setNoOfOption,
    noOfOptions,
    generatePrompt,
    isNotMultiItem,
    prompList,
    isOpenValidationDialog,
    setIsOpenValidationDialog,
    handleGenerateGptCreditRequest,
  } = useMagicBar({
    responseHandler,
    type,
    isMixMode,
    FEEDBACK_TYPES,
    user,
    setUser,
  });

  const translation = t("feedbackModule");
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.feedbackForm;

  return (
    <>
      <Dialog
        open={isOpenValidationDialog}
        onConfirm={() => setIsOpenValidationDialog(false)}
        header={commonTranslation.warning}
        content={
          <Flex column>
            <Text>{feedbackTranslation.magicBarPlaceHolder}</Text>
          </Flex>
        }
        confirmButton={commonTranslation.okay}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenValidationDialog(false);
          },
        }}
        styles={{ maxWidth: "600px" }}
      />

      {subscription.FeedbackFromAI &&
        button({ onClickHandler: buttonClickHandler })}
      <Dialog
        open={isDialogOpen}
        content={{
          content: (
            <div className={`gpt-dialog`} id="gpt-model">
              <div className="title-wrapper">
                <h5 className="title mb-0">Magic ACV bar ✨</h5>
                <Button
                  className="close-button"
                  text
                  icon={<CloseIcon />}
                  onClick={() => {
                    closeDialogHandler();
                  }}
                />
              </div>

              <Flex
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="description d-flex">
                  <span className="mt-1">
                    {feedbackTranslation.magicBarInputTitle}
                  </span>
                </p>
                <div className="credit-limit-wrapper ">
                  <div className="credit-icon-wrapper">{CoinIcon()}</div>
                  <div
                    className="credit-score px-2 fw-bold text-muted"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "center",
                    }}
                  >
                    {user.credit}
                  </div>
                </div>
              </Flex>
              <textarea
                value={title}
                rows={3}
                className={`w-100 myTextarea`}
                placeholder={`${feedbackTranslation.magicBarPlaceHolder} ...`}
                onChange={(e) => setTitle(e.target.value)}
              ></textarea>
              <div className="dropdown-wrapper">
                {/* <span>
                  <DropDown
                    options={feedbackTypes}
                    selected={feedbackType}
                    onChange={setFeedbackType}
                    classes={""}
                    label={"Feedback Type"}
                  />
                </span> */}
                {!isNotMultiItem && (
                  <span>
                    <DropDown
                      options={noOfFeedbackQuestions}
                      selected={noOfFeedback}
                      onChange={setNoOfFeedback}
                      classes={""}
                      label={feedbackTranslation.noOfQuestions}
                    />
                  </span>
                )}

                {type?.toLowerCase() === "poll" && (
                  <span>
                    <DropDown
                      options={noOfOptions}
                      selected={noOfOption}
                      onChange={setNoOfOption}
                      classes={""}
                      label={feedbackTranslation.noOfOptions}
                    />
                  </span>
                )}

                <span>
                  <DropDown
                    options={languages}
                    selected={language}
                    onChange={setLanguage}
                    classes={""}
                    label={feedbackTranslation.language}
                  />
                </span>
              </div>
              <Flex vAlign="center">
                Uncertain about where to begin? Select from our
                <MenuButton
                  trigger={
                    <Button
                      style={{
                        color: "#585a96",
                      }}
                      text
                      iconPosition="after"
                      icon={<ChevronDownIcon />}
                      content="pre-defined theme"
                      aria-label="Hover button"
                    />
                  }
                  menu={prompList.map((item) => item.category)}
                  on="hover"
                  onMenuItemClick={(e, { index }) => {
                    const selectedPrompt = prompList[index];
                    if (selectedPrompt) {
                      setTitle(selectedPrompt.prompt);
                    }
                  }}
                />
                to kickstart your journey with Magic ACV Bar.
              </Flex>
              <div className="condition-generate-btn-wrapper">
                <p className="condition-text">
                  {feedbackTranslation.reviewAILimits}
                  <a
                    href="https://www.acv.app/terms"
                    target="_blank"
                    className="tos-text ms-1"
                  >
                    {feedbackTranslation.termOfService}
                  </a>
                  .
                </p>
                {/* <Button
                  loading={isLoading}
                  primary
                  disabled={isLoading}
                  className={`generate-button`}
                  onClick={(e) => generatePrompt(e)}
                  content={
                    isLoading
                      ? commonTranslation.loading
                      : `${feedbackTranslation.GenerateIt} 🌟`
                  }
                  // disabled={isLoading || props.appState.gptCredits === 0}
                  // style={{
                  //   background:
                  //     props.appState.gptCredits === 0 ? "lightgray" : "#585a96",
                  // }}
                /> */}

                {user.credit <= 0 ? (
                  user.role.toLowerCase() !== "superadmin" ? (
                    <Button
                      loading={isLoading}
                      primary
                      disabled={isLoading || user.isRequestedForGptCredit}
                      className={`generate-button`}
                      onClick={handleGenerateGptCreditRequest}
                      content={
                        user.isRequestedForGptCredit ? "Requested" : "Request"
                      }
                    />
                  ) : (
                    <Button
                      loading={isLoading}
                      primary
                      disabled={isLoading}
                      className={`generate-button`}
                      onClick={() => {
                        window.open("https://www.acv.app/credits", "_blank");
                      }}
                      content={"Purchase Credit"}
                    />
                  )
                ) : (
                  <Button
                    loading={isLoading}
                    primary
                    disabled={isLoading}
                    className={`generate-button`}
                    onClick={(e) => generatePrompt(e)}
                    content={
                      isLoading
                        ? commonTranslation.loading
                        : `${feedbackTranslation.GenerateIt} 🌟`
                    }
                  />
                )}
              </div>
            </div>
          ),
          styles: {
            marginBottom: 0,
          },
        }}
      />
    </>
  );
};

export default withTranslation()(FeedbackMagicBar);
