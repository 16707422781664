import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Dialog, Flex, Image, Loader } from "@fluentui/react-northstar";
import { Row, Col, Layout } from "antd";
import { TextField } from "@fluentui/react";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import Event from "../../../components/widgets/Event";
import Header from "../../../components/Header/Header";
import { ResponsiveImage } from "../../../components/blocks/Images";
import { EventsNavBar } from "../../../components/EventsNavBar";
import EventFilters, {
  EventDateFilterTypes,
} from "../../../components/widgets/EventFilters";
import RegistrationModal from "../../../components/widgets/RegistrationModal";
import EventFilterModal from "../../../components/widgets/EventFilterModal";
import { useEvent } from "../../../context/EventContext";
import UserFilter from "../UserFilter";
import UserColumnFilter from "../UserColumnFilter";
import CustomProgressIndicator from "../../../components/Loader/CustomProgressIndicator";
import Switch from "../../../components/blocks/Switch";

import "../event.css";

const url = process.env.REACT_APP_EP_URL;

const registered = "registered";
const attended = "attended";

const Registration = (props) => {
  // const query = new URLSearchParams(window.location.search);

  const [accDlg, setAccDlg] = useState(false);
  const [regstrationData, setRegistrationData] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isRegModalVisible, setIsRegModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [filterEvent, setFilterEvent] = useState(registered);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  // const [selectedDateFilter, setSelectedDateFilter] = useState(
  //   EventDateFilterTypes.today
  // );

  const filterModalProps = useEvent();

  const {
    appliedFilter,
    globalSearch,
    isSaveFilterModalOpen,
    saveUserFilter,
    setIsValidateFilterName,
    setIsSaveFilterModalOpen,
    filterName,
    setFilterName,
    isValidateFilterName,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    selectedDateFilter,
    setSelectedDateFilter,
    startDate,
    endDate,
  } = filterModalProps;

  const { t } = props;

  const translation = t("events");
  const userFilterTranslation = translation.body.userFilter;
  const eventsTranslation = translation.body.registration;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth > 767 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getEvents = () => {
    setIsLoading(true);
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    let params = {};
    if (!!startDate && moment(startDate).isValid()) {
      params.start = moment(startDate).format("MM-DD-YYYY");
    }
    if (!!endDate && moment(endDate).isValid()) {
      params.end = moment(endDate).format("MM-DD-YYYY");
    }

    if (isAudio) {
      params.isAudio = isAudio;
    }
    if (isVideoConf) {
      params.isVideoConf = isVideoConf;
    }
    if (isInplace) {
      params.isInplace = isInplace;
    }

    const selectedTags = filterModalProps.tags
      .filter((t) => !!t.checked)
      .map((t) => t.id);
    const selectedCategories = filterModalProps.categories
      .filter((c) => !!c.checked)
      .map((c) => c.id);

    if (selectedTags.length) {
      params.tags = JSON.stringify(selectedTags);
    }
    if (selectedCategories.length) {
      params.categories = JSON.stringify(selectedCategories);
    }

    if (globalSearch) {
      params.title = globalSearch;
    }

    if (selectedDateFilter) {
      params.dateFilter = selectedDateFilter;
      params.isFutureDateFilter = true;
    }

    params.status = filterEvent;

    axios
      .get(`${url}/api/registrations/${props.user.mail}${props.user.slug}`, {
        headers,
        params,
      })
      .then(({ data: { result } }) => {
        setRegistrationData(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        if (err?.response?.status === 400 || err?.response?.status === 409) {
          // setDialog(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getEvents();
  }, [appliedFilter, props, globalSearch, filterEvent, selectedDateFilter]);

  useEffect(() => {
    getTags();
    getCategories();
  }, [props]);

  const getCategories = () => {
    axios
      .get(`${url}/api/category/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setCategories(result?.length ? [...result] : []);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
        setCategories([]);
      });
  };

  const getTags = () => {
    axios
      .get(`${url}/api/tag/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setTags(result?.length ? result : []);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
        setTags([]);
      });
  };

  const updateFavourite = (data) => {
    setIsUpdating(true);
    const { id, isFavourite } = data;
    axios
      .post(
        `${url}/api/event-favourite${props.user.slug}`,
        {
          eventId: id,
          teacherId: props.user.mail,
          isFavourite,
        },
        {
          headers: {
            Authorization: `${props.user.tokenType} ${props.user.accessToken}`,
          },
        }
      )
      .then((d) => {
        getEvents();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <div>
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton={translation.common.authExpire.button}
        content={
          <p className="refreshMsg">{translation.common.authExpire.message}</p>
        }
        header={translation.common.authExpire.header}
      />
      <Dialog
        open={isSaveFilterModalOpen}
        onConfirm={() => {
          setIsValidateFilterName(true);
          if (filterName?.length) {
            saveUserFilter();
            setIsSaveFilterModalOpen(false);
          }
        }}
        confirmButton={translation.common.save}
        content={
          <TextField
            value={filterName}
            onChange={(e, value) => {
              setFilterName(value);
            }}
            label={userFilterTranslation.filterName}
            style={{ background: "#F5F5F5", borderRadius: "5px" }}
            borderless={true}
            required
            errorMessage={
              isValidateFilterName
                ? filterName?.length
                  ? ""
                  : userFilterTranslation.validation
                : null
            }
          />
        }
        header={userFilterTranslation.header}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />
      {isMobileView ? (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="registration-event-wrapper">
            <EventsNavBar {...props} isMobileView={isMobileView} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout
              className="tour-registered-event"
              style={{ alignItems: (isMobileView || isTabletView) && "end" }}
            >
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />
              <Flex
                className="event-title"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                {filterEvent === registered
                  ? eventsTranslation.registeredEventHeader
                  : eventsTranslation.attendedEventHeader}
              </Flex>
              <div
                className="wrapper"
                style={{ marginTop: "0px", marginRight: "0px", width: "100%" }}
              >
                <Flex
                  vAlign="center"
                  wrap
                  space="between"
                  style={{ justifyContent: "center" }}
                >
                  <Flex
                    wrap
                    hAlign="center"
                    className="registration-event-filter-wrapper"
                  >
                    <Switch
                      states={[
                        {
                          title: eventsTranslation.registered,
                          value: registered,
                        },
                        { title: eventsTranslation.attended, value: attended },
                      ]}
                      value={filterEvent}
                      onChange={(value) => {
                        setFilterEvent(value);
                      }}
                    />
                    {/* <UserColumnFilter component="Registration" /> */}
                  </Flex>
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {isUpdating || isLoading ? (
                  <Flex
                    hAlign="center"
                    vAlign="center"
                    style={{ height: "150px" }}
                  >
                    <Loader />
                  </Flex>
                ) : regstrationData.length ? (
                  <div className="registration-event-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {regstrationData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: `${
                                isMobileView ? 100 : 100 / selectedColumns
                              }%`,
                              minWidth: "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              data={data.event}
                              tags={tags}
                              categories={categories}
                              type={
                                filterEvent === registered
                                  ? registered
                                  : attended
                              }
                              updateFavourite={(data) => updateFavourite(data)}
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              selectedColumns={selectedColumns}
                              loading={{
                                isLoading: isUpdating,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  <div
                    className="wrapper"
                    style={{ textAlign: "center", marginTop: 82 }}
                  >
                    <Image
                      style={{ maxWidth: "280px", width: "100%" }}
                      src={"/Images/Events/events-pana.svg"}
                    />
                    <h3 style={{ marginTop: 20 }}>
                      {filterEvent === registered
                        ? eventsTranslation.noRegistrationEvent
                        : eventsTranslation.noAttendedEvent}
                    </h3>
                  </div>
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="registration-event-wrapper">
            <EventsNavBar {...props} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout className="tour-registered-event">
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />
              <div className="wrapper">
                <Flex vAlign="center" wrap space="between">
                  <Flex className="event-title">
                    {filterEvent === registered
                      ? eventsTranslation.registeredEventHeader
                      : eventsTranslation.attendedEventHeader}
                  </Flex>
                  <Flex
                    wrap
                    hAlign="center"
                    className="registration-event-filter-wrapper"
                  >
                    <Switch
                      states={[
                        {
                          title: eventsTranslation.registered,
                          value: registered,
                        },
                        { title: eventsTranslation.attended, value: attended },
                      ]}
                      value={filterEvent}
                      onChange={(value) => {
                        setFilterEvent(value);
                      }}
                    />
                    {isTabletView ? null : (
                      <UserColumnFilter component="Registration" />
                    )}
                  </Flex>
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {regstrationData.length ? (
                  <div className="registration-event-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {regstrationData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: `${100 / selectedColumns}%`,
                              minWidth: "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              data={data.event}
                              tags={tags}
                              categories={categories}
                              type={
                                filterEvent === registered
                                  ? registered
                                  : attended
                              }
                              updateFavourite={(data) => updateFavourite(data)}
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              selectedColumns={selectedColumns}
                              loading={{
                                isLoading: isUpdating,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <Image
                        style={{ maxWidth: "280px", width: "100%" }}
                        src={"/Images/Events/events-pana.svg"}
                      />
                      <h3 style={{ marginTop: 20 }}>
                        {filterEvent === registered
                          ? eventsTranslation.noRegistrationEvent
                          : eventsTranslation.noAttendedEvent}
                      </h3>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Registration);
