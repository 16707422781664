import { Checkbox, Flex, Image, Loader } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import MeetingCard from "./MeetingCard/MeetingCard";
import { useBooking } from "../../../../context/BookingContext";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./MySchedules.scss";
import NoEventFoundImage from "../../../../Assets/images/svg/NoAssignmentFound.svg";
import Switch from "../../../../components/blocks/Switch";
import moment from "moment";

const MySchedules = () => {
  const { t } = useTranslation();

  const {
    getSchedulesMeetings,
    bookings,
    isLoadingBookings,
    filteredBookings,
    setFilteredBookings,
    user,
    status,
    setStatus,
    singleUser,
    setSingleUser,
  } = useBooking();

  const handleShowMeetingsWithTutors = (isShow) => {
    if (isShow) {
      const filteredBookings = bookings.filter((booking) =>
        booking.tutorData.email !== user.mail && status === "scheduled"
          ? moment(booking.slot.endTime).isAfter()
          : moment(booking.slot.endTime).isBefore()
      );
      setFilteredBookings(filteredBookings);
    } else {
      setFilteredBookings(bookings);
    }
  };

  const handleFilterBookings = (singleUser, status) => {
    const filteredBookings = bookings.filter((booking) =>
      (singleUser ? booking.tutorData.email !== user.mail : true) &&
      status === "scheduled"
        ? moment(booking.slot.endTime).isAfter()
        : moment(booking.slot.endTime).isBefore()
    );
    setFilteredBookings(filteredBookings);
  };

  const onStatusChangeHandler = (status) => {
    setStatus(status);
    handleFilterBookings(singleUser, status);
  };

  useEffect(() => {
    getSchedulesMeetings();
  }, []);
  const translation = t("booking").mySchedules;
  return (
    <Flex column className="my-schedules-wrapper">
      <Flex space="between" vAlign="center" className="my-schedules-header">
        <h1
          style={{ color: "#6264a7", fontWeight: "bold" }}
          className="my-schedules-header-text"
        >
          {translation.header}
        </h1>
        <Flex hAlign="end" gap="gap.medium" vAlign="center">
          {user?.isTutor && (
            <Checkbox
              label={translation.singleUser}
              onChange={(e, { checked }) => {
                handleFilterBookings(checked, status);
                setSingleUser(checked);
              }}
              checked={singleUser}
              toggle
              disabled={isLoadingBookings}
              color="#6264a7"
            />
          )}
          <Switch
            value={status}
            onChange={(value) => onStatusChangeHandler(value)}
            states={[
              {
                title: "Scheduled",
                value: "scheduled",
              },
              {
                title: "Expired",
                value: "expired",
              },
            ]}
          />
        </Flex>
      </Flex>
      {isLoadingBookings ? (
        <Flex hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Loader size="medium" />
        </Flex>
      ) : filteredBookings?.length ? (
        <div className="schedule-cards-wrapper">
          <Row gutter={[20, 20]} style={{ margin: 0 }}>
            {filteredBookings.map((data, i) => (
              <Col key={i} xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                <MeetingCard data={data} index={i} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <Flex column hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Image
            style={{ maxWidth: "280px", width: "100%" }}
            src={NoEventFoundImage}
          />
          <span>{translation.noSchedulesFound}</span>
        </Flex>
      )}
    </Flex>
  );
};

export default MySchedules;
