import "./gptcredits.css";
import {
  Alert,
  Avatar,
  Badge,
  Checkbox,
  Grid,
  Snackbar,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import GptRequestedDataTable from "./GptRequestedDataTable";
import GptRequestedTableMobile from "./GptRequestedTableMobile";
import CreditSection from "./CreditSection";
import { Loader } from "@fluentui/react-northstar";

const url = process.env.REACT_APP_EP_URL;

function Gptcredits(props) {
  const [teachers, setTeachers] = useState([]);
  const credits = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
  const [requestedData, setRequestedData] = useState([]);
  const [creditPerTeacher, setCreditPerTeacher] = useState();
  const [searchTeacher, setSearchTeacher] = useState("");
  const [filteredTeacher, setFilteredTeacher] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [isRequested, setIsRequested] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [creditInfo, setCreditInfo] = useState({});
  const [checked, setChecked] = useState(false);
  const { vertical, horizontal, open } = state;
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 18,
    backgroundColor: "white",
    borderRadius: "50%",
  }));

  useEffect(() => {
    const newTeacher =
      requestedData || teachers
        ? [...requestedData, ...teachers].filter((teacher) =>
            teacher?.TeacherName?.toLowerCase()?.includes(
              searchTeacher?.toLowerCase()
            )
          )
        : [];
    setFilteredTeacher(newTeacher);
  }, [searchTeacher]);

  useEffect(() => {
    getRequestedTeacher();
    getCreditInfo();
  }, []);

  useEffect(() => {
    if (!isRequested) {
      getRequestedData();
    }
  }, [requestedData, isRequested]);
  const getCreditInfo = () => {
    const mail = props.user.mail;
    axios
      .get(
        `${url}/api/credit/get-credit-info${props.user.slug}&TeacherEmailID=${mail}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .then((response) => {
        setCreditInfo(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRequestedData = () => {
    const requestedTeachers = requestedData.filter(
      (teacher) => teacher.requested === true
    );
    const nonRequestedTeachers = requestedData.filter(
      (teacher) => teacher.requested !== true
    );
    setRequestedData([...requestedTeachers, ...nonRequestedTeachers]);
    setIsRequested(true);
  };
  const getRequestedTeacher = () => {
    setIsLoading(true);
    axios
      .get(`${url}/api/credit${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then((res) => {
        const response = res.data.result.teachers;
        const creditHistory = res.data.result.history;
        const modifiedRes = response.map((teacher) => {
          return {
            ...teacher,
            creditsToAssign: 5,
            isSelected: false,
          };
        });
        props.setCreditHistory(creditHistory);
        setRequestedData(modifiedRes);
        setIsRequested(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const updateStatus = async (payload) => {
    try {
      const res = await axios.put(
        `${url}/api/credit/update-by-role/${props.user.slug}`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const response = res.data.result;
      return response;
    } catch (error) {
      console.error("error", error);
    }
  };
  const assignCredit = async (currentTeacher, newState, setLoading) => {
    setLoading(true);
    const { status, requested, creditsToAssign, role } = currentTeacher;
    const TeacherEmailID =
      role.toLowerCase() === "student"
        ? currentTeacher.StudentEmailID
        : currentTeacher.TeacherEmailID;
    if (status && TeacherEmailID && requested !== undefined) {
      let clonedCreditHistory = [...props.creditHistory];
      let payload = {
        email: TeacherEmailID,
        role,
        status,
        credit: creditsToAssign,
        requested,
      };
      let updatedRequestedData;
      if (requested) {
        payload["id"] = currentTeacher.id;
        updatedRequestedData = requestedData.filter((teacher) =>
          role.toLowerCase() === "student"
            ? teacher.StudentEmailID !== TeacherEmailID
            : teacher.TeacherEmailID !== TeacherEmailID
        );
      }
      const res = await updateStatus(payload);
      if (res.status === "Success") {
        requested && setRequestedData(updatedRequestedData);
        clonedCreditHistory.push(currentTeacher);
        props.setCreditHistory(clonedCreditHistory);
        setState({ ...newState, open: true });
        setLoading(false);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      window.alert("Feild are missing!");
    }
  };
  const rejectCredit = async (currentTeacher, newState) => {
    const { role, status, requested } = currentTeacher;
    const TeacherEmailID =
      role.toLowerCase() === "student"
        ? currentTeacher.StudentEmailID
        : currentTeacher.TeacherEmailID;
    if (status && TeacherEmailID && requested !== undefined) {
      let clonedCreditHistory = [...props.creditHistory];
      let payload = {
        email: TeacherEmailID,
        role,
        status,
        requested,
      };
      if (requested) {
        payload["id"] = currentTeacher.id;
      }
      const updatedRequestedData = requestedData.filter((teacher) =>
        role.toLowerCase() === "student"
          ? teacher.StudentEmailID !== TeacherEmailID
          : teacher.TeacherEmailID !== TeacherEmailID
      );
      const res = await updateStatus(payload);
      if (res.status === "Success") {
        setRequestedData(updatedRequestedData);
        clonedCreditHistory.push(currentTeacher);
        props.setCreditHistory(clonedCreditHistory);
        setIsOpen(true);
      } else {
        window.alert(res.message);
      }
    }
  };
  const setCreditValue = (credit, currentTeacher) => {
    const updatedRequestedTeachers = requestedData.map((teacher) => {
      if (
        teacher.role.toLowerCase() === "student"
          ? teacher.StudentEmailID === currentTeacher.StudentEmailID
          : teacher.TeacherEmailID === currentTeacher.TeacherEmailID
      ) {
        return { ...teacher, creditsToAssign: credit };
      }
      return teacher;
    });
    setRequestedData(updatedRequestedTeachers);
  };
  const handleChange = (e) => {
    setSearchTeacher(e.target.value);
  };
  const handleClose = (newState) => {
    setState({ ...newState, open: false });
  };
  const closeSnackbar = () => {
    setIsOpen(false);
  };
  const handleSelectCheckbox = (isChecked) => {
    setChecked((prevChecked) => !prevChecked);
    const selectedTeachers = requestedData.map((teacher) => {
      return { ...teacher, isSelected: isChecked };
    });
    setRequestedData(selectedTeachers);
  };
  const teacherData = searchTeacher.trim() ? filteredTeacher : requestedData;

  return (
    <div className="d-flex flex-column">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => handleClose({ vertical: "top", horizontal: "right" })}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Credit Assigned succesfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={closeSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Credit Rejected succesfully!
        </Alert>
      </Snackbar>
      <CreditSection
        isMobileView={props.isMobileView}
        setCreditPerTeacher={setCreditPerTeacher}
        creditPerTeacher={creditPerTeacher}
        teachers={teachers}
        setTeachers={setTeachers}
        searchTeacher={searchTeacher}
        handleChange={handleChange}
        creditInfo={creditInfo}
        checked={checked}
        handleSelectCheckbox={handleSelectCheckbox}
      />
      <div className={props.isMobileView ? "mobile-container" : "container"}>
        <ul
          className={props.isMobileView ? "mobile-table" : "responsive-table"}
        >
          {!props.isMobileView && (
            <li className="table-header">
              {/* <Checkbox
              checked={checked}
              onChange={(e) => handleSelectCheckbox(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ padding: "0px", marginRight: "24px", color: "white" }}
            /> */}
              <div className="col col-1">#</div>
              <div className="col col-2">Image</div>
              <div className="col col-3">Name</div>
              <div className="col col-4">Email</div>
              <div className="col col-5">Role</div>
              <div className="col col-6">Available Credits</div>
              <div className="col col-7">Assign Credits</div>
              <div className={props.isTabletView ? "col col-9" : "col col-8"}>
                Action
              </div>
            </li>
          )}
          {isLoading ? (
            <Loader
              styles={{ justifyContent: "center", alignItems: "center" }}
            />
          ) : props.isMobileView ? (
            <>
              {teacherData.length > 0 ? (
                <div
                  style={{
                    overflow: "auto",
                    height: "calc(100vh - 330px)",
                    padding: "20px",
                    paddingRight: "7px",
                  }}
                >
                  <Grid container spacing={1} sx={{ gap: "10px" }}>
                    {teacherData.map(
                      (teacher, index) =>
                        teacher?.role.toLowerCase() !== "superadmin" && (
                          <GptRequestedTableMobile
                            teacher={teacher}
                            index={index}
                            credits={credits}
                            SmallAvatar={SmallAvatar}
                            rejectCredit={rejectCredit}
                            assignCredit={assignCredit}
                            setCreditValue={setCreditValue}
                            isMobileView={props.isMobileView}
                            creditInfo={creditInfo}
                            requestedData={requestedData}
                            setRequestedData={setRequestedData}
                          />
                        )
                    )}
                  </Grid>
                </div>
              ) : (
                <p style={{ display: "flex", justifyContent: "center" }}>
                  No Result Found
                </p>
              )}
            </>
          ) : (
            <>
              {teacherData.length > 0 ? (
                <div
                  style={{
                    overflow: "auto",
                    height: props.isTabletOrMobileView
                      ? "calc(100vh - 300px)"
                      : "calc(100vh - 470px)",
                    padding: "5px",
                  }}
                >
                  {teacherData?.map(
                    (teacher, index) =>
                      teacher?.role.toLowerCase() !== "superadmin" && (
                        <GptRequestedDataTable
                          teacher={teacher}
                          index={index}
                          credits={credits}
                          SmallAvatar={SmallAvatar}
                          rejectCredit={rejectCredit}
                          assignCredit={assignCredit}
                          setCreditValue={setCreditValue}
                          isTabletView={props.isTabletView}
                          creditInfo={creditInfo}
                          requestedData={requestedData}
                          setRequestedData={setRequestedData}
                        />
                      )
                  )}
                </div>
              ) : (
                <p style={{ display: "flex", justifyContent: "center" }}>
                  No Result Found
                </p>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Gptcredits;
