import React from "react";
import Chart from "react-apexcharts";

class ApexChart2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          id: "pie-chart",
          width: 380,
          type: "pie",
        },
        legend: {
          show: false,
        },
        labels: [],
        stroke: {
          show: false,
          width: 2,
          colors: ["transparent"],
        },
        responsive: [
          {
            breakpoint: 1060,
            options: {
              chart: {
                width: 280,
              },
            },
          },
          {
            breakpoint: 680,
            options: {
              chart: {
                width: 250,
              },
            },
          },
        ],
      },
      dataLoaded: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && nextProps.data !== prevState.prevData) {
      const data = Object.entries(nextProps.data);
      const series = data.map(([_, value]) => value);
      const labels = data.map(([key, _]) => key);

      return {
        series,
        options: {
          ...prevState.options,
          labels,
        },
        prevData: nextProps.data,
      };
    }

    return null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataLoaded: true });
    }, 5000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.series !== this.state.series ||
      nextState.options !== this.state.options
    );
  }

  render() {
    return (
      this.state.dataLoaded && (
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width="290"
          height="210"
        />
      )
    );
  }
}

export default ApexChart2;
