import { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { BlobServiceClient } from "@azure/storage-blob";
import { withTranslation } from "react-i18next";

import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";
import AssignmentDetailCard from "../AssignmentSubmissionPage/AssignmentDetail";
import AttachmentOption from "../AttachmentOption/AttachmentOption";

import Rubric from "../Rubric/RubricPage";
import { NotificationAlert } from "../../../../../components/Notification/Notification";
import NoAssignmentFound from "../../../../../Assets/images/svg/NoAssignmentFound.svg";
import CustomProgressIndicator from "../../../../../components/Loader/CustomProgressIndicator";

import "./AssignmentSubmissionPage.css";

const url = process.env.REACT_APP_EP_URL;

const AssignmentSubmission = (props) => {
  const { user, match, t } = props;

  const { assignmentId, subjectCode } = useParams();
  const history = useHistory();

  const translation = t("assignment").body;
  const submissionPageTranslation = translation.submissionPage;

  const {
    studentAttachmentsForAssignment,
    setStudentAttachmentsForAssignment,
    currentAssignmentData,
    setCurrentAssignmentData,
    setCurrentPage,
  } = useStudentSubmission();

  const [currentassignmentId, setCurrentassignmentId] = useState(null);
  const [studentAttachments, setStudentAttachments] = useState(
    currentAssignmentData?.studentAttachments || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentSubmitting, setIsAttachmentSubmitting] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(0);
  const [rubricData, setRubricData] = useState(null);
  const [havePoints, setHavePoints] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    setCurrentPage("assignmentSubmissionPage");
    const { params } = match;
    if (params?.assignmentId && params?.subjectCode) {
      getAssignment({
        assignmentId: params.assignmentId,
        lessonId: params.subjectCode,
      });
    }
  }, []);

  useEffect(() => {
    setCurrentassignmentId(assignmentId);
  }, [assignmentId]);

  const isSubmitBtnDisabledHandler = () => {
    if (currentAssignmentData.submissionStatus === "Pending") {
      return false;
    } else if (
      currentAssignmentData.submissionStatus
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes("gradespending") ||
      currentAssignmentData.submissionStatus === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitAssignmentHandler = async () => {
    try {
      let response = await axios({
        method: "put",
        url: `${url}/api/assignment/assingment-submission-by-student${user.slug}&assignmentId=${currentAssignmentData.id}&studentId=${user.mail}`,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.status === 200 && response.data.status === "success") {
        NotificationAlert(response.data.results, "success");
        setIsAttachmentSubmitting(false);
        backButtonHandler();
      }
    } catch (error) {
      console.log(error);
      setIsAttachmentSubmitting(false);
    }
  };

  const submitAttachmentHandler_ = async ({ status }) => {
    setIsAttachmentSubmitting(true);
    // 'Published', 'Draft', 'Scheduled'
    let payload = currentAssignmentData;
    delete payload.isChanged;
    let finalPayload = [];
    const uploadFileToBlob = async (file, storagePath, attachmentType) => {
      const containerName = `assignments`;
      const storageAccountName = process.env.REACT_APP_STORAGE;
      // const sasToken = user.SASToken;
      const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN.replace(
        /"/g,
        ""
      );

      const getBlobsInContainer = async (containerClient) => {
        const returnedBlobUrls = [];
        for await (const blob of containerClient.listBlobsFlat()) {
          // if image is public, just construct URL
          returnedBlobUrls.push(
            `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
          );
        }
        return returnedBlobUrls;
      };
      if (!file) return [];

      const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
      );

      const containerClient = blobService.getContainerClient(containerName);

      const blobClient = containerClient.getBlockBlobClient(storagePath);

      const option = { blobHTTPHeaders: { blobContentType: file.type } };

      let response = await blobClient.uploadBrowserData(file, option);

      // getBlobsInContainer(containerClient);

      return response._response.request.url;
    };
    let storagePath = `student-${assignmentId}-${user.username || user.mail}`;
    if (studentAttachmentsForAssignment.length) {
      let attachments = studentAttachmentsForAssignment;
      let newAttachments = [];

      for (const media of attachments) {
        if (media.type === "file") {
          if (media.info?.length) {
            let urlArray = [];
            for (const fileItem of media.info) {
              if (
                typeof fileItem === "string" &&
                fileItem?.includes(
                  "https://acvstorageprod.blob.core.windows.net"
                )
              ) {
                urlArray.push(fileItem);
              } else {
                let url = await uploadFileToBlob(
                  fileItem.file,
                  `${storagePath}/file/${`${fileItem.file?.name}`}`
                );
                urlArray.push(url);
              }
            }
            newAttachments.push({
              type: "file",
              url: urlArray.join(","),
              id: media.id,
            });
          }
        } else if (media.type === "video") {
          if (media.info) {
            if (
              typeof media.info === "string" &&
              media.info?.includes(
                "https://acvstorageprod.blob.core.windows.net"
              )
            ) {
              newAttachments.push({
                type: "video",
                url: media.info,
                id: media.id,
              });
            } else {
              let url = await uploadFileToBlob(
                await fetch(media.info).then((r) => r.blob()),
                `${storagePath}/video`
              );
              newAttachments.push({
                type: "video",
                url: url,
                id: media.id,
              });
            }
          }
        } else if (media.type === "voice") {
          if (media.info) {
            if (
              typeof media.info === "string" &&
              media.info?.includes(
                "https://acvstorageprod.blob.core.windows.net"
              )
            ) {
              newAttachments.push({
                type: "voice",
                url: media.info,
              });
            } else {
              let url = await uploadFileToBlob(
                await fetch(media.info).then((r) => r.blob()),
                `${storagePath}/audio`
              );
              newAttachments.push({
                type: "voice",
                url: url,
                id: media.id,
              });
            }
          }
        } else if (media.type === "text") {
          if (media.info?.length) {
            let url = await uploadFileToBlob(
              new Blob([media.info], { type: "text/plain" }),
              `${storagePath}/note`
            );
            newAttachments.push({
              type: "text",
              url: url,
              id: media.id,
            });
          }
        } else if (media.type === "link") {
          if (media.info?.length) {
            let url = await uploadFileToBlob(
              new Blob([media.info], { type: "text/plain" }),
              `${storagePath}/link`
            );
            newAttachments.push({
              type: "link",
              url: url,
              id: media.id,
            });
          }
        }
      }
      finalPayload = newAttachments.map((attachment) => ({
        createdBy: user.username || user.mail,
        type: attachment.type,
        url: attachment.url,
        role: "student",
        assignmentId: payload.id,
      }));
    }
    try {
      let response = await axios({
        method: "post",
        url: `${url}/api/assignment/upload-student-asignment-attechment${
          user.slug
        }&studentId=${user.username || user.mail}&assignmentId=${payload.id}`,
        data: { attachments: finalPayload },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.status === 200 && response.data.status === "success") {
        await submitAssignmentHandler();
      } else {
        setIsAttachmentSubmitting(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsAttachmentSubmitting(false);
    }
  };
  const submitAttachmentHandler = async ({ status }) => {
    setIsAttachmentSubmitting(true);

    let payload = currentAssignmentData;
    delete payload.isChanged;

    // Set up formData for the bulk submission
    const formData = new FormData();
    let storagePath = `student-${assignmentId}-${user.username || user.mail}`;

    // Append metadata fields to formData
    formData.append("assignmentId", payload.id);
    formData.append("status", status);
    formData.append("createdBy", user.username || user.mail);
    formData.append("role", "student");

    // Process attachments without immediate file upload
    if (studentAttachmentsForAssignment.length) {
      for (const media of studentAttachmentsForAssignment) {
        if (media.type === "file" && media.info?.length) {
          let urlArray = [];
          for (const fileItem of media.info) {
            if (
              typeof fileItem === "string" &&
              fileItem?.includes("blob.core.windows.net")
            ) {
              urlArray.push(fileItem);
            } else {
              // Append file directly to FormData
              formData.append("file", fileItem.file);
            }
          }
          urlArray.length &&
            formData.append(
              "oldFiles",
              JSON.stringify({
                type: "file",
                url: urlArray.join(","),
                id: media.id,
              })
            );
        } else if (media.type === "video" && media.info) {
          if (
            typeof media.info === "string" &&
            media.info?.includes("blob.core.windows.net")
          ) {
            formData.append(
              "video",
              JSON.stringify({
                type: "video",
                url: media.info,
                id: media.id,
              })
            );
          } else {
            // Append video info directly to FormData
            formData.append("video", media.info);
          }
        } else if (media.type === "voice" && media.info) {
          if (
            typeof media.info === "string" &&
            media.info?.includes("blob.core.windows.net")
          ) {
            formData.append(
              "voice",
              JSON.stringify({
                type: "voice",
                url: media.info,
              })
            );
          } else {
            // Append voice info directly to FormData
            formData.append("voice", media.info);
          }
        } else if (media.type === "text" && media.info?.length) {
          formData.append("text", media.info);
        } else if (media.type === "link" && media.info?.length) {
          formData.append("link", JSON.stringify(media.info));
        }
      }
    }

    try {
      // Submit the formData as a single bulk request
      let response = await axios.post(
        `${url}/api/assignment/upload-student-assignment-attachment${
          user.slug
        }&studentId=${user.username || user.mail}&assignmentId=${payload.id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        await submitAssignmentHandler();
      } else {
        setIsAttachmentSubmitting(false);
      }
    } catch (error) {
      console.error("Error submitting attachment:", error);
      setIsAttachmentSubmitting(false);
    }
  };

  const utcToLocalDateConvert = (utcDate) => {
    // let time = moment().utc().format("HH:mm");

    // let date = moment.utc(`${utcDate} ${time}`).local().format("DD-MM-YYYY");
    // return date;

    // let time = moment().utc().format("HH:mm");

    let date = moment.utc(utcDate).local();
    return date;
  };

  const getAssignment = async ({ assignmentId, lessonId }) => {
    try {
      setIsLoading(true);
      const response = await axios(
        `${url}/api/assignment/get-student-assignment-by-assignmentid${
          user.slug
        }&studentEmailId=${
          user.username || user.mail
        }&assignmentId=${assignmentId}`
      );
      if (response.data?.results?.length) {
        const assignment = response.data.results[0];
        let attachmentOfStudent = assignment.Attachments?.filter(
          (item) => item.role === "student"
        );
        let attachmentOfTeacher = assignment.Attachments?.filter(
          (item) => item.role === "teacher"
        );
        let data = {
          ...assignment,
          id: assignment.id,
          dueDate: assignment.dueDate,
          // dueDate: utcToLocalDateConvert(assignment.dueDate),

          assignmentTitle: assignment.title,
          instruction: assignment.instruction,

          rubricId: assignment.rubricBankId,

          studentAttachments: [
            {
              type: "text",
              info: attachmentOfStudent?.find((item) => item.type == "text")
                ?.url,
              id:
                attachmentOfStudent?.find((item) => item.type == "text")?.id ||
                0,
            },
            {
              type: "voice",
              info:
                attachmentOfStudent?.find((item) => item.type == "voice")
                  ?.url || "",
              id:
                attachmentOfStudent?.find((item) => item.type == "voice")?.id ||
                0,
            },
            {
              type: "video",
              info:
                attachmentOfStudent?.find((item) => item.type == "video")
                  ?.url || "",
              id:
                attachmentOfStudent?.find((item) => item.type == "video")?.id ||
                0,
            },
            {
              type: "link",
              info:
                attachmentOfStudent?.find((item) => item.type == "link")?.url ||
                "",
              id:
                attachmentOfStudent?.find((item) => item.type == "link")?.id ||
                0,
            },
            {
              type: "file",
              info: attachmentOfStudent
                ?.find((item) => item.type == "file")
                ?.url?.split(","),
              id:
                attachmentOfStudent?.find((item) => item.type == "file")?.id ||
                0,
            },
          ],

          attachments: [
            {
              type: "text",
              info: attachmentOfTeacher?.find((item) => item.type == "text")
                ?.url,
            },
            {
              type: "voice",
              info:
                attachmentOfTeacher?.find((item) => item.type == "voice")
                  ?.url || "",
            },
            {
              type: "video",
              info:
                attachmentOfTeacher?.find((item) => item.type == "video")
                  ?.url || "",
            },
            {
              type: "link",
              info: attachmentOfTeacher?.find((item) => item.type == "link")
                ?.url,
            },
            {
              type: "file",
              info: attachmentOfTeacher
                ?.find((item) => item.type == "file")
                ?.url?.split(","),
            },
          ],

          submittedOn: assignment.AssingmentStudents[0]?.submissionDate,
          gradedOn: assignment.AssingmentStudents[0]?.gradedOn,
          submissionStatus: assignment.AssingmentStudents[0]?.submissionStatus,
        };
        const formattedData = await formatCurrentAssignmentData(data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatAttachmentData = async (attachments) => {
    let attachment = [];
    try {
      const textInfo = await getTextContentFromUrl(
        attachments?.find((item) => item.type == "text")?.info
      );
      const linkInfo = await getLinkContentFromUrl(
        attachments?.find((item) => item.type == "link")?.info
      );
      attachment = [
        {
          type: "text",
          info: textInfo,
          id: attachments?.find((item) => item.type == "text")?.id,
        },
        {
          type: "voice",
          info: attachments?.find((item) => item.type == "voice")?.info || "",
          id: attachments?.find((item) => item.type == "voice")?.id,
        },
        {
          type: "video",
          info: attachments?.find((item) => item.type == "video")?.info || "",
          id: attachments?.find((item) => item.type == "video")?.id,
        },
        {
          type: "link",
          info: linkInfo,
          id: attachments?.find((item) => item.type == "link")?.id,
        },
        {
          type: "file",
          info: attachments?.find((item) => item.type == "file")?.info
            ? [...attachments?.find((item) => item.type == "file")?.info]
            : [],
          id: attachments?.find((item) => item.type == "file")?.id,
        },
      ];
    } catch (error) {
      console.log(error);
    } finally {
      return attachment;
    }
  };

  const formatCurrentAssignmentData = async (assign) => {
    try {
      let attachments = await formatAttachmentData(assign.attachments);
      setCurrentAssignmentData({
        ...assign,
        attachments,
      });
      let studentAttachments = await formatAttachmentData(
        assign.studentAttachments
      );
      setStudentAttachments(studentAttachments);
      setStudentAttachmentsForAssignment(studentAttachments);
    } catch (error) {
      console.log(error);
    }
  };

  const backButtonHandler = () => {
    const query = new URLSearchParams(search);
    if (query.get("pending")) {
      history.push({
        pathname: `/pending-assignments`,
      });
    } else if (currentAssignmentData.LessonDatum?.ClassName) {
      history.push({
        pathname: `/${currentAssignmentData.LessonDatum.ClassName}/assignments`,
      });
    } else {
      history.push({
        pathname: "/",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentAssignmentData?.rubricId) {
        const rubricData = await fetchRubricData(
          currentAssignmentData.rubricId
        );
        if (rubricData) {
          setRubricData(rubricData.rubricItems);
          setSelectedLevels(rubricData.rubricItems?.length);
          setHavePoints(
            rubricData?.rubricItems?.length
              ? rubricData?.rubricItems[0]?.havePoints
              : false
          );
        }
      }
    };
    assignmentId && subjectCode && fetchData();
  }, [assignmentId, subjectCode]);

  const fetchRubricData = async (id) => {
    setIsLoading(true);
    try {
      let response = await axios.get(
        // `${url}/api/rubric/get-rubric-bank-item${props.user.slug}`,
        `${process.env.REACT_APP_EP_URL}/api/rubric/get-rubric-by-rubricId${props.user.slug}&rubricId=${id}`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );
      if (response.data.status == "success") {
        let rubricData = response.data.results;
        return Object.keys(rubricData).length
          ? {
              ...rubricData,
              rubricItems: rubricData.rubricItem?.map((item) => ({
                ...item,
                havePoints: !!item.havePoints,
                id: item.rubricItemId,
                levels: item.rubricCriteria?.map((criterialData) => ({
                  ...criterialData,
                  id: criterialData.criteriaId,
                  levelTitle: criterialData.criteriaTitle,
                })),
                title: item.rubricItemTitle,
              })),
            }
          : null;
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomProgressIndicator
        isLoading={isAttachmentSubmitting}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />
      <div className="p-2 assignment-submission-page">
        {
          // location.pathname ===
          //   `/${subjectCode}/assignments/${assignmentId}/rubric` ||
          // location.pathname ===
          //   `/assignments/${subjectCode}/assignments/${assignmentId}/rubric`
          location.pathname.includes(`${assignmentId}/rubric`) ? (
            <Rubric
              {...props}
              currentAssignmentData={currentAssignmentData}
              havePoints={havePoints}
              rubricData={rubricData}
              selectedLevels={selectedLevels}
              isLoading={isLoading}
            />
          ) : (
            <>
              <Flex vAlign="center">
                <IconButton
                  iconProps={{ iconName: "ChromeBack" }}
                  title={translation.back}
                  ariaLabel={translation.back}
                  onClick={backButtonHandler}
                />
                <Text
                  color="brand"
                  size="large"
                  weight="semibold"
                  style={{ marginRight: "10px" }}
                >
                  {submissionPageTranslation.backToAssignments}
                </Text>
              </Flex>
              <div className="ms-Grid">
                {isLoading ? (
                  <Loader size="medium" style={{ marginTop: "10px" }} />
                ) : currentAssignmentData ? (
                  <>
                    <div className="ms-Grid-row attachment-submission-page-section">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 student-submission-card-container card-outer pr-3 tour-view-assignment">
                        <AssignmentDetailCard
                          currentAssignmentData={currentAssignmentData}
                          rubricData={rubricData}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 pl-3 teacher-student-attachment">
                        <div className="tour-view-assignment-attachments">
                          <Text size="large" weight="semibold" align="start">
                            {submissionPageTranslation.teacherAttachment}:
                          </Text>
                          <AttachmentOption
                            currentAssignmentData={currentAssignmentData}
                            isShowAvatarVideo={true}
                            settings={props.settings}
                            selectedExtension={props.selectedExtension}
                            user={user}
                          />
                        </div>
                        <div
                          style={{ marginTop: "30px" }}
                          className="tour-submit-assignment-attachments"
                        >
                          <Text size="large" weight="semibold" align="start">
                            {submissionPageTranslation.submitYourAssignment}
                          </Text>
                          <Text size="medium" weight="semibold" align="start">
                            {submissionPageTranslation.submitAttachments}
                          </Text>
                          <AttachmentOption
                            isSubmissionPage={true}
                            currentAssignmentData={currentAssignmentData}
                            studentAttachments={studentAttachments}
                            setStudentAttachments={setStudentAttachments}
                            studentAttachmentsForAssignment={
                              studentAttachmentsForAssignment
                            }
                            setStudentAttachmentsForAssignment={
                              setStudentAttachmentsForAssignment
                            }
                            isShowAvatarVideo={false}
                            settings={props.settings}
                            selectedExtension={props.selectedExtension}
                            user={user}
                          />
                        </div>

                        {currentAssignmentData.submissionStatus !==
                        "Completed" ? (
                          <Flex style={{ marginTop: "25px" }} hAlign="start">
                            <Button
                              content={
                                submissionPageTranslation.submitAssignment
                              }
                              disabled={
                                isSubmitBtnDisabledHandler() ||
                                isAttachmentSubmitting
                              }
                              onClick={submitAttachmentHandler}
                              className="tour-submit-assignment"
                            />
                          </Flex>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <figure
                      className="w-100 mt-5 mb-2"
                      style={{
                        display: "flex",
                        height: "100%",
                        margin: "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt="ACV - Tangible IT"
                        src={NoAssignmentFound}
                        style={{
                          maxWidth: "50%",
                        }}
                        draggable="false"
                      />
                    </figure>
                    <Text
                      size="larger"
                      weight="bold"
                      content={submissionPageTranslation.noDataAvailable}
                      align="center"
                    />
                  </div>
                )}
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

export default withTranslation()(AssignmentSubmission);
