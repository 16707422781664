import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const externalTutorsContext = createContext();

export const useExternalTutors = () => {
  return useContext(externalTutorsContext);
};

export const ExternalTutorsContext = (props) => {
  const { user } = props;
  const [search, setSearch] = useState("");
  const [tutors, setTutors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddExternalTutorsModel, setIsOpenAddExternalTutorsModel] =
    useState(false);

  // Delete tutor
  const [tutorToDelete, setTutorToDelete] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);

  const createExternalTutor = async (tutor, reset) => {
    setIsLoading(true);
    const payload = {
      externalTutors: [
        {
          name: tutor.tutorName,
          email: tutor.tutorEmail,
          provider: tutor.provider.toLowerCase(),
        },
      ],
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/external-tutors${user.slug}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      setIsLoading(false);
      const clonedTutors = [...tutors];
      if (
        response?.data?.successfulTutors?.length > 0 &&
        response?.data?.successfulTutors?.some(
          (email) => tutor.tutorEmail === email
        )
      ) {
        clonedTutors.push(
          ...response.data.successfulTutors.map((email) => ({
            email,
            name: tutor.tutorName,
            provider: tutor.provider,
          }))
        );
      }
      setTutors(clonedTutors);
      toast.success("Tutor created successfully!");
      setIsOpenAddExternalTutorsModel(false);
      reset();
    } catch (error) {
      setIsLoading(false);
      const failedTutor = error?.response?.data?.failedTutors[0];
      const errorMessage =
        failedTutor?.isExist && failedTutor?.role
          ? `This user already exists with a ${failedTutor.role} role in your school.`
          : "Failed to create tutor!";
      toast.error(errorMessage);
    }
  };

  const getTutors = async (search = "") => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/external-tutors${user.slug}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      setIsLoading(false);
      setTutors(response.data.tutors);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const deleteTutor = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/external-tutors/${tutorToDelete.email}${user.slug}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      setIsLoadingDelete(false);

      setTutors(tutors.filter((tutor) => tutor.email !== response.data.email));
      toast.success("Tutor deleted successfully!");
      setIsOpenConfirmModel(false);
      setTutorToDelete(null);
    } catch (error) {
      setIsLoadingDelete(false);
      toast.error("Failed to delete tutor!");
    }
  };

  useEffect(() => {
    getTutors();
  }, []);

  return (
    <externalTutorsContext.Provider
      value={{
        tutors,
        isLoading,
        setIsLoading,
        isOpenAddExternalTutorsModel,
        setIsOpenAddExternalTutorsModel,
        tutorToDelete,
        setTutorToDelete,
        isLoadingDelete,
        deleteTutor,
        isOpenConfirmModel,
        setIsOpenConfirmModel,
        createExternalTutor,
        search,
        setSearch,
        getTutors,
      }}
    >
      {props.children}
    </externalTutorsContext.Provider>
  );
};
