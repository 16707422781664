import { CloseIcon, Dialog } from "@fluentui/react-northstar";
import React from "react";
import { useEvent } from "../../context/EventContext";
import { useTranslation } from "react-i18next";

const WarningModel = () => {
  const { isWarningOpen, setIsWarningOpen } = useEvent();
  const { t } = useTranslation();
  const translation = t("events").warningModel;
  return (
    <Dialog
      open={isWarningOpen}
      onCancel={() => setIsWarningOpen(false)}
      onConfirm={() => setIsWarningOpen(false)}
      confirmButton={translation.confirmButton}
      content={translation.content}
      header={translation.header}
      className="smallDlg"
      headerAction={{
        icon: <CloseIcon />,
        title: translation.closeButton,
        onClick: () => setIsWarningOpen(false),
      }}
    />
  );
};

export default WarningModel;
