import { Button, Dialog, Flex, Image } from "@fluentui/react-northstar";
import { Layout } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { EventsNavBar } from "../../components/EventsNavBar";
import Header from "../../components/Header/Header";
import Switch from "../../components/blocks/Switch";
import Event from "../../components/widgets/Event";
import EventFilterModal from "../../components/widgets/EventFilterModal";
import EventFilters, {
  EventDateFilterTypes,
} from "../../components/widgets/EventFilters";
import RegistrationModal from "../../components/widgets/RegistrationModal";
import { useEvent } from "../../context/EventContext";
import UserColumnFilter from "./UserColumnFilter";

import { Subscription } from "../../components/Subscription/Subscription";
import UserFilter from "./UserFilter";

import NoEventFoundImage from "../../Assets/images/svg/NoAssignmentFound.svg";
import { AlertDialog } from "../../components/Dialog/AlertDialog";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";

import "./event.css";
import WarningModel from "../../components/WarningModel/WarningModel";

const url = process.env.REACT_APP_EP_URL;

const Events = (props) => {
  const {
    setIsWarningOpen,
    categories,
    tags,
    isLoadingCategories,
    isLoadingTags,
  } = useEvent();

  const [accDlg, setAccDlg] = useState(false);
  const [eventData, setEventData] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("active");
  const [isRegModalVisible, setIsRegModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [load, setLoad] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    data: null,
    isDeleting: false,
  });
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  // const [selectedDateFilter, setSelectedDateFilter] = useState(
  //   EventDateFilterTypes.today
  // );

  const filterModalProps = useEvent();
  const {
    appliedFilter,
    globalSearch,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    startDate,
    endDate,
    selectedDateFilter,
    setSelectedDateFilter,
  } = filterModalProps;

  const history = useHistory();

  const { t } = props;

  const translation = t("events");
  const eventsTranslation = translation.body.myEvents;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth > 767 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getEvents = async () => {
    setLoad(true);

    const teacherId = props.user.mail;
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    let params = {};
    if (!!startDate && moment(startDate).isValid()) {
      params.start = moment(startDate).format("MM-DD-YYYY");
    }
    if (!!endDate && moment(endDate).isValid()) {
      params.end = moment(endDate).format("MM-DD-YYYY");
    }
    if (status) {
      params.status = status;
    }
    if (isAudio) {
      params.isAudio = isAudio;
    }
    if (isVideoConf) {
      params.isVideoConf = isVideoConf;
    }
    if (isInplace) {
      params.isInplace = isInplace;
    }

    if (globalSearch) {
      params.title = globalSearch;
    }
    const selectedTags = filterModalProps.tags
      .filter((t) => !!t.checked)
      .map((t) => t.id);
    const selectedCategories = filterModalProps.categories
      .filter((c) => !!c.checked)
      .map((c) => c.id);
    if (selectedTags.length) {
      params.tags = JSON.stringify(selectedTags);
    }
    if (selectedCategories.length) {
      params.categories = JSON.stringify(selectedCategories);
    }

    if (selectedDateFilter) {
      params.dateFilter = selectedDateFilter;
      params.isFutureDateFilter = status === "active" ? true : false;
    }

    await axios
      .get(`${url}/api/events/${teacherId}/all${props.user.slug}`, {
        headers,
        params,
      })
      .then((res) => {
        const data = res.data;
        if (data?.result?.length) {
          setEventData([...data.result]);
        } else {
          setEventData([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        if (err?.response?.status === 400 || err?.response?.status === 409) {
          // setDialog(true);
        }
        setEventData([]);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const updateFavourite = (data) => {
    setIsUpdating(true);
    const { id, isFavourite } = data;
    axios
      .post(
        `${url}/api/event-favourite${props.user.slug}`,
        {
          eventId: id,
          teacherId: props.user.mail,
          isFavourite,
        },
        {
          headers: {
            Authorization: `${props.user.tokenType} ${props.user.accessToken}`,
          },
        }
      )
      .then(() => {
        getEvents();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log("Update Failed");
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const deleteEvent = (event) => {
    setDeleteConfirmation((prev) => ({ ...prev, isDeleting: true }));
    axios
      .delete(`${url}/api/events/${event.id}${props.user.slug}`)
      .then(({ data: { result } }) => {
        getEvents();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      })
      .finally(() => {
        setDeleteConfirmation((prev) => ({ ...prev, isDeleting: false }));
      });
  };

  // const getCategories = () => {
  //   axios
  //     .get(`${url}/api/category/all${props.user.slug}`)
  //     .then(({ data: { result } }) => {
  //       setCategories(result);
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message);
  //       console.log(err.response);
  //     });
  // };

  // const getTags = () => {
  //   axios
  //     .get(`${url}/api/tag/all${props.user.slug}`)
  //     .then(({ data: { result } }) => {
  //       setTags(result);
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message);
  //       console.log(err.response);
  //     });
  // };

  useEffect(() => {
    if (isSubscribed && !isExpire) getEvents();
  }, [
    appliedFilter,
    status,
    props,
    // startDate,
    // endDate,
    globalSearch,
    isExpire,
    isSubscribed,
    selectedDateFilter,
  ]);

  useEffect(() => {
    // getTags();
    // getCategories();
    if (props.user?.dbData?.subscription) {
      if (props.user?.dbData?.subscription?.event) {
        setIsSubscribed(true);
      } else {
        setIsSubscribed(false);
      }
    } else {
      setIsSubscribed(false);
    }

    if (props.user?.dbData?.dayRemaining <= 0) {
      setIsExpire(true);
    } else {
      setIsExpire(false);
    }
  }, [props]);

  const OnStatusChangeHandler = (value) => {
    setStatus(value);
    if (value === "draft") {
      setSelectedDateFilter(EventDateFilterTypes.all);
    } else {
      setSelectedDateFilter(EventDateFilterTypes.today);
    }
  };

  return (
    <>
      <AlertDialog
        handleClose={() => {
          setDeleteConfirmation((prev) => ({
            ...prev,
            isOpen: false,
            data: null,
          }));
        }}
        handleOk={() => {
          deleteEvent(deleteConfirmation.data);
        }}
        open={deleteConfirmation.isOpen}
      />
      <WarningModel />

      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton={translation.common.authExpire.button}
        content={
          <p className="refreshMsg">{translation.common.authExpire.message}</p>
        }
        header={translation.common.authExpire.header}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />
      {!isSubscribed || isExpire ? (
        <Subscription
          pageName="event"
          isExpire={isExpire}
          role={props.user.role}
        />
      ) : (
        <>
          {isMobileView ? (
            <div className="Attendance Whiteboard s-ctr">
              <EventFilters
                onHandlePortal={(data) => {
                  handlePortal(data);
                }}
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                selectedDateFilter={selectedDateFilter}
                onDateFilterClickHandler={(selectedOption) => {
                  setSelectedDateFilter(selectedOption);
                }}
              />
              <Layout className="myevents-event-wrapper">
                <EventsNavBar {...props} isMobileView={isMobileView} />
                <RegistrationModal
                  visible={isRegModalVisible}
                  onCancel={setIsRegModalVisible}
                  data={!!selectedEvent && selectedEvent.attendees}
                  user={props.user}
                />
                <Layout
                  className="tour-my-event"
                  style={{
                    // alignItems: (isMobileView || isTabletView) && "end",
                    height: "fit-content",
                  }}
                >
                  <CustomProgressIndicator
                    isLoading={
                      isUpdating || load || deleteConfirmation.isDeleting
                    }
                  />
                  <Flex
                    className="event-title"
                    style={{
                      justifyContent: "center",
                      marginTop: "25px",
                      width: isMobileView && "100%",
                    }}
                  >
                    {eventsTranslation.header}
                  </Flex>
                  <div className="mobile-wrapper">
                    <Flex
                      space="between"
                      wrap
                      style={{ rowGap: "15px", justifyContent: "space-around" }}
                    >
                      {/* <Flex
                        style={{ display: "inline-grid", alignItems: "center" }}
                      >
                        <Button
                          className="blank-button tour-my-event-create-event"
                          onClick={() => history.push("/event/new")}
                          style={{ padding: "12px" }}
                        >
                          {eventsTranslation.createEvent}
                        </Button>
                      </Flex> */}
                      <Flex hAlign="center">
                        <Flex
                          wrap
                          hAlign="center"
                          gap="gap.small"
                          style={{ rowGap: "10px" }}
                          vAlign="center"
                        >
                          <Switch
                            value={status}
                            onChange={(value) => OnStatusChangeHandler(value)}
                            states={[
                              {
                                title: eventsTranslation.active,
                                value: "active",
                              },
                              {
                                title: eventsTranslation.expired,
                                value: "expired",
                              },
                              {
                                title: eventsTranslation.draft,
                                value: "draft",
                              },
                            ]}
                          />
                        </Flex>
                      </Flex>
                    </Flex>

                    {isFilterApplied ? <UserFilter /> : null}
                    {eventData.length ? (
                      <div
                        style={{
                          maxHeight: "calc(100vh - 330px)",
                          overflow: "auto",
                          marginTop: isMobileView || isTabletView ? "15px" : "",
                        }}
                      >
                        <Flex column>
                          <Flex wrap>
                            {eventData.map((data, index) => (
                              <Flex
                                key={data.id + index}
                                style={{
                                  // width: `${100 / selectedColumns}%`,
                                  width: "100%",
                                  minWidth: "260px",
                                  padding: "0 10px",
                                  position: "relative",
                                }}
                              >
                                <Event
                                  {...props}
                                  type={"event"}
                                  isActiveStatus={status === "active"}
                                  selectedColumns={selectedColumns}
                                  data={data}
                                  tags={tags}
                                  categories={categories}
                                  updateFavourite={(data) =>
                                    updateFavourite(data)
                                  }
                                  onDelete={(data) => {
                                    setDeleteConfirmation({
                                      data,
                                      isOpen: true,
                                    });
                                  }}
                                  attendeesModal={(data) => {
                                    setSelectedEvent(data);
                                    setIsRegModalVisible(true);
                                  }}
                                  loading={{
                                    isDeleting: deleteConfirmation.isDeleting,
                                    isLoading: load || isUpdating,
                                  }}
                                />
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>
                      </div>
                    ) : (
                      !(isUpdating || load) && (
                        <div
                          className="wrapper"
                          style={{ textAlign: "center" }}
                        >
                          <Image
                            style={{ maxWidth: "280px", width: "100%" }}
                            src={NoEventFoundImage}
                          />
                          <h2 style={{ marginTop: 20 }}>
                            {eventsTranslation.noActiveEvents}
                          </h2>
                          <Button
                            className="blank-button"
                            disabled={isLoadingTags || isLoadingCategories}
                            onClick={() => {
                              if (
                                categories.length === 0 &&
                                tags.length === 0
                              ) {
                                setIsWarningOpen(true);
                              } else {
                                history.push("/event/new");
                              }
                            }}
                          >
                            {eventsTranslation.createEvent}
                          </Button>
                        </div>
                      )
                    )}
                  </div>
                </Layout>
              </Layout>
            </div>
          ) : (
            <div className="Attendance Whiteboard s-ctr">
              {!isExpire && isSubscribed && (
                <EventFilters
                  // startDate={startDate}
                  // endDate={endDate}
                  // changeStartDate={(value) => {
                  //   setStartDate(
                  //     value
                  //       ? moment(value, "MM-DD-YYYY").format("MM-DD-YYYY")
                  //       : null
                  //   );
                  // }}
                  // changeEndDate={(value) => {
                  //   setEndDate(
                  //     value
                  //       ? moment(value, "MM-DD-YYYY").format("MM-DD-YYYY")
                  //       : null
                  //   );
                  // }}
                  onHandlePortal={(data) => {
                    handlePortal(data);
                  }}
                  isMobileView={isMobileView}
                  isTabletView={isTabletView}
                  selectedDateFilter={selectedDateFilter}
                  onDateFilterClickHandler={(selectedOption) => {
                    setSelectedDateFilter(selectedOption);
                  }}
                />
              )}
              <Layout className="myevents-event-wrapper">
                <EventsNavBar {...props} />
                <RegistrationModal
                  visible={isRegModalVisible}
                  onCancel={setIsRegModalVisible}
                  data={!!selectedEvent && selectedEvent.attendees}
                  user={props.user}
                />
                <Layout className="tour-my-event">
                  <CustomProgressIndicator
                    isLoading={
                      isUpdating || load || deleteConfirmation.isDeleting
                    }
                  />

                  <div className="wrapper">
                    <Flex space="between" wrap style={{ rowGap: "15px" }}>
                      <Flex className="event-title">
                        {eventsTranslation.header}
                      </Flex>
                      <Flex hAlign="end">
                        <Flex
                          wrap
                          hAlign="center"
                          gap="gap.small"
                          style={{
                            rowGap: "10px",
                            gap: isTabletView && "5px",
                            paddingRight: "10px",
                          }}
                          vAlign="center"
                        >
                          <Switch
                            value={status}
                            onChange={(value) => OnStatusChangeHandler(value)}
                            states={[
                              {
                                title: eventsTranslation.active,
                                value: "active",
                              },
                              {
                                title: eventsTranslation.expired,
                                value: "expired",
                              },
                              {
                                title: eventsTranslation.draft,
                                value: "draft",
                              },
                            ]}
                          />

                          {isTabletView ? null : <UserColumnFilter />}
                          {/* <Flex>
                            <Button
                              className="blank-button tour-my-event-create-event"
                              onClick={() => history.push("/event/new")}
                            >
                              {eventsTranslation.createEvent}
                            </Button>
                          </Flex> */}
                        </Flex>
                      </Flex>
                    </Flex>

                    {isFilterApplied ? <UserFilter /> : null}
                    {eventData.length ? (
                      <div className="event-cards-wrapper">
                        <Flex column>
                          <Flex wrap>
                            {eventData.map((data, index) => (
                              <Flex
                                key={data.id + index}
                                style={{
                                  width: isTabletView
                                    ? "50%"
                                    : `${100 / selectedColumns}%`,
                                  minWidth: isTabletView ? "244px" : "260px",
                                  padding: "0 10px",
                                  position: "relative",
                                }}
                              >
                                <Event
                                  {...props}
                                  type={"event"}
                                  isActiveStatus={status === "active"}
                                  selectedColumns={selectedColumns}
                                  data={data}
                                  tags={tags}
                                  categories={categories}
                                  updateFavourite={(data) =>
                                    updateFavourite(data)
                                  }
                                  onDelete={(data) => {
                                    setDeleteConfirmation({
                                      data,
                                      isOpen: true,
                                    });
                                  }}
                                  attendeesModal={(data) => {
                                    setSelectedEvent(data);
                                    setIsRegModalVisible(true);
                                  }}
                                  loading={{
                                    isDeleting: deleteConfirmation.isDeleting,
                                    isLoading: load || isUpdating,
                                  }}
                                />
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>
                      </div>
                    ) : (
                      !(isUpdating || load) && (
                        <div
                          className="wrapper"
                          style={{ textAlign: "center", marginTop: 82 }}
                        >
                          <Image
                            style={{ maxWidth: "280px", width: "100%" }}
                            src={NoEventFoundImage}
                          />
                          <h2 style={{ marginTop: 20 }}>
                            {eventsTranslation.noActiveEvents}
                          </h2>
                          <Button
                            className="blank-button"
                            disabled={isLoadingTags || isLoadingCategories}
                            onClick={() => {
                              if (
                                categories.length === 0 &&
                                tags.length === 0
                              ) {
                                setIsWarningOpen(true);
                              } else {
                                history.push("/event/new");
                              }
                            }}
                          >
                            {eventsTranslation.createEvent}
                          </Button>
                        </div>
                      )
                    )}
                  </div>
                </Layout>
              </Layout>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(Events);
