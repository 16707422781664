import React, { useEffect, useState } from "react";
import UploadParentsModel from "./UploadParentsModel";
import ParentsTable from "./ParentsTable/ParentsTable";
import "./index.scss";
import {
  Button,
  ExcelIcon,
  FilesUploadIcon,
  Flex,
  InfoIcon,
  Input,
  SearchIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { Plus } from "react-feather";
import UploadSingleParentModel from "./UploadSingleParentModel";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Parents = (props) => {
  const [isOpenAddParent, setIsOpenAddParent] = useState(false);
  const [isOpenAddMultipleParents, setIsOpenAddMultipleParents] =
    useState(false);
  const [studentsWithoutParents, setStudentsWithoutParents] = useState([]);
  const [parentsWithStudents, setParentsWithStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const { t } = useTranslation();
  const parent = t("setting").parent;

  const getAllStudent = () => {
    axios
      .get(
        `${process.env.REACT_APP_EP_URL}/api/student/get-students${
          props.user.slug
        }&onlyNameAndEmail=${true}&withoutParent=${true}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          const students = res.data;
          const modifiedStudentsData = students.map((student) => {
            return { key: student.StudentEmailID, header: student.StudentName };
          });
          setStudentsWithoutParents(modifiedStudentsData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllParents = (search = "") => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_EP_URL}/api/parents${props.user.slug}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          const parentsWithStudents = res.data;
          setParentsWithStudents(parentsWithStudents);
        } else {
          parentsWithStudents.length && setParentsWithStudents([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllStudent();
    getAllParents();
  }, []);

  // useEffect to call detectProvider when email changes
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getAllParents(search);
    }, 500); // Debounce of 500ms to prevent API calls on every keystroke

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  return (
    <>
      <UploadParentsModel
        isOpen={isOpenAddMultipleParents}
        user={props.user}
        onClose={() => {
          setIsOpenAddMultipleParents(false);
        }}
        getAllParents={getAllParents}
      />
      <UploadSingleParentModel
        user={props.user}
        isOpen={isOpenAddParent}
        studentsWithoutParents={studentsWithoutParents}
        onClose={() => {
          setIsOpenAddParent(false);
        }}
        getAllParents={getAllParents}
        setStudentsWithoutParents={setStudentsWithoutParents}
      />
      <Flex className="parent-container" gap="gap.smaller" column>
        <Text
          color="brand"
          content={parent.message}
          weight="semibold"
          style={{ marginLeft: 12 }}
        />
        <div>
          <Flex
            space="between"
            gap="gap.medium"
            style={{ marginBottom: "20px" }}
            wrap
          >
            <Flex gap="gap.smaller">
              <Input
                icon={<SearchIcon />}
                value={search}
                onChange={(_, { value }) => {
                  setSearch(value);
                }}
                style={{ marginLeft: 12 }}
                placeholder="Search by name, email & number"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    getAllParents(search);
                  }
                }}
              />
              <Tooltip
                content="Search by name, email & number"
                trigger={
                  <Button
                    iconOnly
                    icon={<InfoIcon circular outline size="large" />}
                  />
                }
              />
            </Flex>
            <Flex gap="gap.smaller">
              <Button
                icon={<Plus />}
                content={parent.addParentButton}
                onClick={() => {
                  setIsOpenAddParent(true);
                }}
                title={parent.addParentButton}
                style={{
                  maxWidth: "209px",
                }}
                primary
              />
              <Button
                icon={<ExcelIcon />}
                content={parent.addMultipleParentsButton}
                onClick={() => {
                  setIsOpenAddMultipleParents(true);
                }}
                title={parent.addMultipleParentsButton}
                style={{
                  maxWidth: "209px",
                }}
                secondary
              />
            </Flex>
          </Flex>
          <ParentsTable
            user={props.user}
            studentsWithoutParents={studentsWithoutParents}
            setStudentsWithoutParents={setStudentsWithoutParents}
            data={parentsWithStudents}
            isLoading={isLoading}
            getAllParents={getAllParents}
          />
        </div>
      </Flex>
    </>
  );
};

export default Parents;
