import React, { useEffect } from "react";
import { useExternalTutors } from "./context/ExternalTutorsContext";
import TutorsTable from "./components/TutorsTable/TutorsTable";
import "./ExternalTutors.scss";
import {
  Button,
  Flex,
  InfoIcon,
  Input,
  ParticipantAddIcon,
  SearchIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import AddExternalTutorModel from "./components/AddExternalTutorModel/AddExternalTutorModel";
import ConfirmModel from "./components/ConfirmModel/ConfirmModel";
import { useTranslation } from "react-i18next";

const ExternalTutors = () => {
  const {
    isLoading,
    setIsOpenAddExternalTutorsModel,
    setSearch,
    search,
    getTutors,
  } = useExternalTutors();

  const { t } = useTranslation();

  const translation = t("booking").externalTutors;

  // useEffect to call detectProvider when email changes
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getTutors(search);
    }, 500); // Debounce of 500ms to prevent API calls on every keystroke

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  return (
    <>
      <Flex column className="external-tutors" gap="gap.medium">
        <h1 className="Heading-settings">{translation.heading}</h1>
        <Flex column gap="gap.smaller">
          <Text
            content={translation.message}
            color="brand"
            weight="semibold"
            style={{ marginLeft: 12, marginRight: 12 }}
          />
          <Flex space="between" className="add-tutors-button">
            <Flex gap="gap.smaller">
              <Input
                icon={<SearchIcon />}
                value={search}
                onChange={(_, { value }) => {
                  setSearch(value);
                }}
                // disabled={isLoading}
                style={{ marginLeft: 12 }}
                placeholder="Search by name, email"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    getTutors(search);
                  }
                }}
              />
              <Tooltip
                content="Search by name, email"
                trigger={
                  <Button
                    iconOnly
                    icon={<InfoIcon circular outline size="large" />}
                  />
                }
              />
            </Flex>
            <Button
              content={translation.addExternalTutor}
              primary
              icon={<ParticipantAddIcon />}
              disabled={isLoading}
              onClick={() => setIsOpenAddExternalTutorsModel(true)}
            />
          </Flex>
        </Flex>
        <TutorsTable />
      </Flex>
      <AddExternalTutorModel />
      <ConfirmModel />
    </>
  );
};

export default ExternalTutors;
