import React, { useEffect, useState } from "react";
import {
  Flex,
  CardHeader,
  CardBody,
  Avatar,
  Card,
  Text,
  Loader,
  Tooltip,
  Alert,
  InfoIcon,
  Popup,
  Button,
  Grid,
  ToDoListIcon,
} from "@fluentui/react-northstar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";

import Imgart from "../../../../../Assets/images/subjects/art.png";
import Imgcivil from "../../../../../Assets/images/subjects/civil.png";
import Imghistory from "../../../../../Assets/images/subjects/history.png";
import ImgnaturalScience from "../../../../../Assets/images/subjects/natural-science.png";
import Imgphilosophy from "../../../../../Assets/images/subjects/philosophy.png";
import ImgscienceExact from "../../../../../Assets/images/subjects/science-exact.png";
import Imgtech from "../../../../../Assets/images/subjects/tech.png";
import Imgother from "../../../../../Assets/images/subjects/other.png";
import NoAssignmentFound from "../../../../../Assets/images/svg/NoAssignmentFound.svg";

import AssignmentPopup from "./AssignmentPopup";

import "./Subjects.css";

const url = process.env.REACT_APP_EP_URL;

const CardWrapper = styled(Flex)`
  margin: 10px;
`;

const SubjectSection = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;

const SubjectCard = styled(Card)`
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 0 0 15px 0;
  box-shadow: rgb(0 0 0 / 10%) 1px 0.3rem 0.4rem -0.075rem;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: white;
    transform: scale(1.02);
  }
`;

const Subjects = (props) => {
  const { user, t } = props;
  const history = useHistory();
  const {
    setCurrentSubjectData,
    setCurrentPage,
    setSubjectListForDropdown,
    setTeacherList,
    selectedSubject,
    selectedTeacher,
    globalSearch,
    dateFilter,
    setCurrentAssignmentData,
  } = useStudentSubmission();

  const translation = t("assignment").body;
  const subjectTranslation = translation.subjects;
  const assignmentListTranslation = translation.assignmentList;

  const [isLoading, setIsLoading] = useState(false);
  const [subjectsList, setSubjectsList] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  useEffect(() => {
    setCurrentPage("subjects");
  }, []);
  useEffect(() => {
    const fetchSubjectList = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/student/student-class-for-assignment${user.slug}&studentId=${user.mail}`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });
        if (response.data?.result) {
          let { lessons = [], teacherList = [] } = response.data.result;

          setSubjectsList(lessons);

          setSubjectListForDropdown(
            Array.from(new Set(lessons.map((subject) => subject.ClassName)))
          );

          // let teacherData = data.reduce((acc, subject) => {
          //   const { LessionData } = subject;

          //   // Check if LessionData is not null or undefined
          //   if (LessionData) {
          //     const { TeacherName } = LessionData;

          //     // Ensure TeacherName is not null or undefined before adding it to acc
          //     if (TeacherName) {
          //       acc[TeacherName] = LessionData;
          //     }
          //   }

          //   return acc;
          // }, {});
          setTeacherList(Object.values(teacherList));

          // let subjects = data.map((subject) => {
          //   let statusObj = {};
          //   if (subject.Assingments?.length) {
          //     statusObj = subject.Assingments.filter(
          //       (subject) => subject.creationStatus === "Published"
          //     )?.reduce((prevStatusObj, assignment) => {
          //       let status =
          //         assignment.AssingmentStudents?.[0]?.submissionStatus;
          //       return {
          //         ...prevStatusObj,
          //         [status]: prevStatusObj[status]
          //           ? prevStatusObj[status] + 1
          //           : 1,
          //       };
          //     }, {});
          //   }
          //   return { ...subject, assignmentStatusObj: statusObj };
          // });
          // let sortedSubjects =
          //   subjects.sort((a, b) => {
          //     if (
          //       new Date(moment(a.LessonDate).local().format("MM-DD-yyyy")) -
          //         new Date(
          //           moment(b.LessonDate).local().format("MM-DD-yyyy")
          //         ) ===
          //       0
          //     ) {
          //       return (
          //         Number(moment(a.StartTime).format("hh")) -
          //         Number(moment(a.StartTime).format("hh"))
          //       );
          //     } else {
          //       return (
          //         new Date(moment(a.LessonDate).local().format("MM-DD-yyyy")) -
          //         new Date(moment(b.LessonDate).local().format("MM-DD-yyyy"))
          //       );
          //     }
          //   }) || [];
        } else {
          setSubjectsList([]);
        }
      } catch (error) {
        console.log(error);
        setSubjectsList([]);
      } finally {
        setIsLoading(false);
        setCurrentAssignmentData(null);
      }
    };
    if (user?.slug && user?.mail) fetchSubjectList();
  }, [user.slug, user.mail]);

  useEffect(() => {
    let subjectData = [];
    if (subjectsList.length) {
      if (selectedSubject || selectedTeacher || globalSearch || dateFilter) {
        let data = subjectsList;
        if (selectedSubject) {
          data = data.filter((item) => item.ClassName === selectedSubject);
        }
        if (selectedTeacher) {
          data = data.filter(
            (item) => item.LessionData?.TeacherName === selectedTeacher
          );
        }
        if (globalSearch) {
          data = data.filter((item) =>
            item.ClassName?.toLowerCase()?.includes?.(
              globalSearch.toLowerCase()
            )
          );
        }
        // if (dateFilter?.start && moment(dateFilter.start).isValid()) {
        //   data = data.filter((item) =>
        //     item.LessonDate && moment(item.LessonDate).isValid()
        //       ? moment(item.LessonDate) >= moment(dateFilter.start)
        //       : false
        //   );
        // }
        // if (dateFilter?.end && moment(dateFilter.end).isValid()) {
        //   data = data.filter((item) =>
        //     item.LessonDate && moment(item.LessonDate).isValid()
        //       ? moment(item.LessonDate) <= moment(dateFilter.end)
        //       : false
        //   );
        // }
        subjectData = data;
      } else {
        subjectData = subjectsList;
      }
      setFilteredSubjects(subjectData);

      // let groupedSubjects = subjectData.reduce((group, subject) => {
      //   const { ClassName, Assingments, lessonImage, ...rest } = subject;
      //   group[ClassName] = group[ClassName] ?? {};
      //   group[ClassName].subjects = group[ClassName].subjects ?? [];
      //   group[ClassName].Assingments = group[ClassName].Assingments ?? [];
      //   group[ClassName].subjects.push(subject);
      //   let assignments = Assingments ? [...Assingments] : [];
      //   group[ClassName].Assingments.push(...assignments);
      //   group[ClassName] = { ...group[ClassName], ...rest, ClassName };
      //   group[ClassName].lessonImage =
      //     group[ClassName].lessonImage ?? lessonImage;
      //   delete subject.Assingments;
      //   return group;
      // }, {});

      // let groupedSubjectsArray = Object.values(groupedSubjects);

      // let groupedSubjectsWithAssignmentObj = groupedSubjectsArray.map(
      //   (subject) => {
      //     let statusObj = {};
      //     if (subject.Assingments?.length) {
      //       statusObj = subject.Assingments.filter(
      //         (subject) => subject.creationStatus === "Published"
      //       )?.reduce((prevStatusObj, assignment) => {
      //         let status = assignment.AssingmentStudents?.[0]?.submissionStatus;
      //         return {
      //           ...prevStatusObj,
      //           [status]: prevStatusObj[status] ? prevStatusObj[status] + 1 : 1,
      //         };
      //       }, {});
      //     }
      //     return { ...subject, assignmentStatusObj: statusObj };
      //   }
      // );

      // setFilteredSubjects(groupedSubjectsWithAssignmentObj);

      // setFilteredSubjects(
      // subjectData.reduce((group, subject) => {
      //   const { ClassName } = subject;
      //   group[ClassName] = group[ClassName] ?? [];
      //   group[ClassName].push(subject);
      //   return group;
      // }, {})
      // );
    } else {
      setFilteredSubjects([]);
    }
  }, [
    selectedSubject,
    selectedTeacher,
    globalSearch,
    subjectsList,
    dateFilter,
  ]);

  const utcToLocalDateConvert = (utcDate) => {
    let time = moment().utc().format("HH:mm");

    let date = moment.utc(`${utcDate} ${time}`).local().format("DD-MM-YYYY");
    return date;
  };

  const convertUtcToLocalTime = (utcDate, utcTime) => {
    return moment.utc(`${utcDate} ${utcTime}`).local().format("HH:mm");
  };

  const MyCard = ({ subject }) => {
    let teacherName = "";
    let teacher = subject.LessionData?.TeacherName?.split(" ");
    if (teacher?.length) {
      teacherName = teacher.map((item) => item?.[0])?.join("");
    } else {
      let data = subject.TeacherEmailAlias?.split("@");
      let result = data?.[0]?.split(".") || [];
      if (result.length) {
        teacherName = result.map((item) => item?.[0])?.join("");
      } else {
        teacherName = data;
      }
    }

    let lessonStartTime = convertUtcToLocalTime(
      moment(subject.LessonDate).format("YYYY-MM-DD"),
      subject.StartTime
    );

    const localEndTime = convertUtcToLocalTime(
      moment(subject.LessonDate).format("YYYY-MM-DD"),
      subject.EndTime
    );

    const viewAssignmentHandler = async (selectedAssignment, subject) => {
      history.push(
        `/${subject.ClassName}/assignments/${selectedAssignment.id}`
      );
      // history.push(
      //   `/${selectedAssignment.lessonId}/assignments/${selectedAssignment.id}`
      // );
    };

    return (
      <SubjectCard
        elevated
        fluid
        onClick={() => {
          //   setSelectedSubject(subject);
          //   setIsAssignmentList(true);
          setCurrentSubjectData(subject);
          history.push(`/assignments/${subject.ClassName}/assignments`);
        }}
      >
        <CardHeader>
          <Flex gap="gap.small" column>
            <img
              alt="ACV - Tangible IT"
              src={
                // subject.ClassName?.includes("Art")
                //   ? Imgart
                //   : subject.ClassName?.includes("Civismo")
                //   ? Imgcivil
                //   : subject.ClassName?.includes("Hist")
                //   ? Imghistory
                //   : subject.ClassName?.includes("Science")
                //   ? ImgnaturalScience
                //   : subject.ClassName?.includes("losof")
                //   ? Imgphilosophy
                //   : subject.ClassName?.includes("Ciencias")
                //   ? ImgscienceExact
                //   : subject.ClassName?.includes("Tec")
                //   ? Imgtech
                //   : Imgother
                subject?.lessonImage?.ImageURL
                  ? subject.lessonImage.ImageURL + user?.SASToken
                  : subject.ClassName.includes("Art")
                  ? Imgart
                  : subject.ClassName.includes("Civismo")
                  ? Imgcivil
                  : subject.ClassName.includes("Hist")
                  ? Imghistory
                  : subject.ClassName.includes("Science")
                  ? ImgnaturalScience
                  : subject.ClassName.includes("losof")
                  ? Imgphilosophy
                  : subject.ClassName.includes("Ciencias Exactas")
                  ? ImgscienceExact
                  : subject.ClassName.includes("Tec")
                  ? Imgtech
                  : Imgother
              }
              style={{
                // maxWidth: "100%",
                width: "100%",
                height: "125px",
                borderRadius: "10px 10px 0 0",
                // padding: "10px",
              }}
              draggable="false"
              className="img-width"
            />
            <Avatar
              size="large"
              getInitials={() => (teacherName ? teacherName.toUpperCase() : "")}
              name={subject.LessionData?.TeacherName || ""}
              className={`avatar-view class${parseInt(Math.random() * 5)}`}
              style={{ zIndex: 1 }}
              image={
                subject.LessionData?.UrlPhoto
                  ? subject.LessionData.UrlPhoto + user?.SASToken
                  : null
              }
            />
          </Flex>
        </CardHeader>
        <CardBody className="header-padding">
          <Tooltip
            content={subject.ClassName}
            position={"above"}
            trapFocus
            trigger={
              <Text
                weight="bold"
                size="large"
                align="start"
                style={{
                  height: "30px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {subject.ClassName?.split("-")[1] ?? ""}
              </Text>
            }
          />

          {/* <Text
            className="mb-2"
            size="medium"
            align="start"
            style={{ height: "25px" }}
          >
            {lessonStartTime + " - " + localEndTime}
            &nbsp; | &nbsp;
            {subject.LessonDate && moment(subject.LessonDate).isValid()
              ? utcToLocalDateConvert(subject.LessonDate)
              : null}
          </Text> */}
          <Flex column wrap className="tour-subject-assignment-count">
            <Flex wrap space="between" className="mb-2">
              {/* {subject.Assingments?.length &&
            subject.assignmentStatusObj?.Completed ? (
              <AssignmentPopup
                trigger={
                  <Text className="text-blue" weight="semibold">
                    Completed:{`${subject.assignmentStatusObj?.Completed || 0}`}
                  </Text>
                }
                subject={subject}
                viewAssignmentHandler={viewAssignmentHandler}
                status={"Completed"}
                title="Completed Assignments"
              />
            ) : ( */}
              <Text className="text-blue" weight="semibold">
                {translation.studentSubmissionStatus.completed}:
                {`${subject.assignmentStatusObj?.Completed || 0}`}
              </Text>
              {/* )} */}

              {/* {subject.Assingments?.length &&
            subject.assignmentStatusObj?.Pending ? (
              <AssignmentPopup
                trigger={
                  <Text className="text-blue" weight="semibold">
                    Pending:
                    {`${Number(subject.assignmentStatusObj?.Pending) || 0}`}
                  </Text>
                }
                subject={subject}
                viewAssignmentHandler={viewAssignmentHandler}
                status={"Pending"}
                title="Pending Assignments"
              />
            ) : ( */}
              <Text className="text-blue" weight="semibold">
                {/* Submission */}
                {translation.studentSubmissionStatus.pending}:
                {`${Number(subject.assignmentStatusObj?.Pending) || 0}`}
              </Text>
              {/* )} */}
            </Flex>
            <Flex wrap space="between" style={{ marginTop: "5px" }}>
              {/* {subject.Assingments?.length &&
            subject.assignmentStatusObj?.Resubmit ? (
              <AssignmentPopup
                trigger={
                  <Text className="text-blue" weight="semibold">
                    Resubmit:
                    {`${Number(subject.assignmentStatusObj?.Resubmit) || 0}`}
                  </Text>
                }
                subject={subject}
                viewAssignmentHandler={viewAssignmentHandler}
                status={"Resubmit"}
                title="Resubmit Assignments"
              />
            ) : ( */}
              <Text className="text-blue" weight="semibold">
                {translation.studentSubmissionStatus.resubmit}:
                {`${Number(subject.assignmentStatusObj?.Resubmit) || 0}`}
              </Text>
              {/* )} */}

              {/* {subject.Assingments?.length &&
            subject.assignmentStatusObj?.["Grades Pending"] ? (
              <AssignmentPopup
                trigger={
                  <Text className="text-blue" weight="semibold">
                    Grades Pending:
                    {`${
                      Number(subject.assignmentStatusObj?.["Grades Pending"]) ||
                      0
                    }`}
                  </Text>
                }
                subject={subject}
                viewAssignmentHandler={viewAssignmentHandler}
                status={"Grades Pending"}
                title="Grades Pending Assignments"
              />
            ) : ( */}
              <Text className="text-blue" weight="semibold">
                {translation.studentSubmissionStatus.gradesPending}:
                {`${
                  Number(subject.assignmentStatusObj?.["Grades Pending"]) || 0
                }`}
              </Text>
              {/* )} */}
            </Flex>
          </Flex>
        </CardBody>
      </SubjectCard>
    );
  };

  const todosBtnHandler = () => {
    history.push("pending-assignments");
  };
  return (
    <div className="student-subject-section">
      {filteredSubjects?.length ? (
        <div className="tour-select-lesson">
          {/* <Flex hAlign="end">
            <Alert
              style={{
                backgroundColor: "#e6f7ff",
                border: "1px solid #91d5ff",
              }}
              content={
                <Text>
                  <InfoIcon outline style={{ marginRight: "7px" }} />
                  Lessons are sorted by LessonDate
                </Text>
              }
              fitted
            />
          </Flex> */}
          <div>
            <div>
              <Button
                icon={<ToDoListIcon />}
                text
                content={assignmentListTranslation.pendingAssignments}
                onClick={todosBtnHandler}
              />
            </div>
            <SubjectSection>
              {filteredSubjects.map((subject) => {
                return (
                  <CardWrapper key={subject.ClassID + subject.Title}>
                    <MyCard subject={subject} />
                  </CardWrapper>
                );
              })}
            </SubjectSection>
          </div>
        </div>
      ) : subjectsList?.length ? (
        <div>
          <figure
            className="w-100 mt-5 mb-2"
            style={{
              display: "flex",
              height: "100%",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <img
              alt="ACV - Tangible IT"
              src={NoAssignmentFound}
              style={{
                maxWidth: "50%",
              }}
              draggable="false"
            />
          </figure>
          <Text
            size="larger"
            weight="bold"
            content={subjectTranslation.noLessonForFilter}
            align="center"
          />
        </div>
      ) : isLoading ? (
        <div>
          <Loader size="medium" style={{ marginTop: "10px" }} />
        </div>
      ) : (
        <div>
          <figure
            className="w-100 mt-5 mb-2"
            style={{
              display: "flex",
              height: "100%",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <img
              alt="ACV - Tangible IT"
              src={NoAssignmentFound}
              style={{
                maxWidth: "50%",
              }}
              draggable="false"
            />
          </figure>
          <Text
            size="larger"
            weight="bold"
            content={subjectTranslation.noLessonAvailable}
            align="center"
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Subjects);
