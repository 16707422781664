import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Pill,
  Text,
} from "@fluentui/react-northstar";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { NotificationAlert } from "../../../components/Notification/Notification";

import Footer from "./Footer";

const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  color: #6264a7;
`;

const CategoryCard = styled(Card)`
  border-radius: 25px;
  background-color: #f3f2f1;
  min-height: 150px;
  box-shadow: 0px 2px 2px 1px #dcdcdc;
  width: 100%;
  :hover {
    background-color: #f3f2f1;
  }
`;

const SkillPill = styled(Pill)`
  min-width: unset;
`;

const EducationInfoSection = styled(Flex)`
  margin-top: 25px;
`;

const ContactInfoSection = styled.div`
  margin-top: ${({ isStudentRole }) => (isStudentRole ? "25px" : "0px")};
  @media screen and (max-width: 640px) {
    margin-top: 25px;
  }
`;

const SkillInfoSection = styled.div`
  .card-wrapper {
    margin-top: 25px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const InterestInfoSection = styled.div`
  .card-wrapper {
    margin-top: 25px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const baseUrl = process.env.REACT_APP_EP_URL;

const Profile = ({
  data,
  isEditProfile,
  setIsEditProfile,
  currentStep,
  totalStep,
  setCurrentStep,
  user,
  setIsRefreshData,
  refreshUserProfileData,
  t,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const translation = t("feedback").body.previewProfile;

  useEffect(() => {
    if (Array.isArray(data?.skills)) {
      setSelectedSkills(data.skills);
    } else {
      setSelectedSkills([]);
    }

    if (Array.isArray(data?.interests)) {
      setSelectedInterests(data.interests);
    } else {
      setSelectedInterests([]);
    }
    if (Array.isArray(data?.specialities)) {
      setSelectedSpecialities(data.specialities);
    } else {
      setSelectedSpecialities([]);
    }
  }, [data]);

  const headers = {
    Authorization: "Bearer " + user.accessToken,
  };

  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const saveProfileHandler = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...data,
        skills: selectedSkills.map((item) => item.id),
        interests: selectedInterests.map((item) => item.id),
        specialities: selectedSpecialities.map((item) => item.id),
      };

      const { slug, mail } = user;
      const { data: responseData } = await axios.put(
        `${baseUrl}/api/profile/update-profile/${mail}${slug}`,
        payload,
        {
          headers,
        }
      );

      if (responseData?.status?.toLowerCase() === "success") {
        setCurrentStep(1);
        setIsEditProfile(false);
        setIsRefreshData((prevData) => !prevData);
        NotificationAlert(
          typeof responseData.message === "string"
            ? responseData.message
            : translation.savedSuccessfully,
          "success"
        );
        if (refreshUserProfileData) {
          refreshUserProfileData();
        }
      } else if (responseData?.status?.toLowerCase() === "error") {
        NotificationAlert(
          typeof responseData.message === "string"
            ? responseData.message
            : translation.saveError,
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSkillChangeHandler = ({ value }) => {
    setSelectedSkills((prevData) =>
      prevData.filter((item) => item.id !== value.id)
    );
  };
  const onInterestChangeHandler = ({ value }) => {
    setSelectedInterests((prevData) =>
      prevData.filter((item) => item.id !== value.id)
    );
  };
  const onSpecialityChangeHandler = ({ value }) => {
    setSelectedSpecialities((prevData) =>
      prevData.filter((item) => item.id !== value.id)
    );
  };

  return (
    <div>
      <div className="ms-Grid">
        <div className="ms-Grid-row d-flex justify-content-center margin-device-wise">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <div className="ms-Grid">
              <div className="ms-Grid-row justify-content-center margin-device-wise">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <div className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12`}>
                    <CategoryCard className="py-4">
                      <CardHeader>
                        <Flex column gap="gap.small">
                          <TitleText>
                            {translation.personalInformation}:
                          </TitleText>
                        </Flex>
                      </CardHeader>
                      <CardBody>
                        <Flex column className="mt-3">
                          <Flex gap="gap.large" wrap style={{ rowGap: "10px" }}>
                            <Flex column>
                              <Text content={`${"Name"}:`} />
                              <Text
                                content={data?.firstName ?? user.name}
                                weight="semibold"
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex column className="mt-3">
                          <Flex gap="gap.large" wrap style={{ rowGap: "10px" }}>
                            <Flex column>
                              <Text content={`${translation.dob}:`} />
                              <Text
                                content={
                                  data?.dateOfBirth &&
                                  moment(data.dateOfBirth).isValid()
                                    ? moment(data.dateOfBirth).format(
                                        "DD-MMM-YYYY"
                                      )
                                    : ""
                                }
                                weight="semibold"
                              />
                            </Flex>
                            <Flex column>
                              <Text content={`${translation.bloodGroup}:`} />
                              <Text
                                content={data?.bloodGroup ?? ""}
                                weight="semibold"
                              />
                            </Flex>
                            <Flex column>
                              <Text content={`${translation.gender}:`} />
                              <Text
                                content={data?.gender ?? ""}
                                weight="semibold"
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex column className="mt-3">
                          <Flex gap="gap.medium">
                            <Flex column>
                              <Text content={`${translation.instituteName}:`} />
                              <Text
                                content={data?.instituteName ?? ""}
                                weight="semibold"
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </CardBody>
                    </CategoryCard>
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <ContactInfoSection
                    isStudentRole={user?.role?.toLowerCase() === "student"}
                  >
                    <CategoryCard className="py-4">
                      <CardHeader>
                        <Flex gap="gap.small">
                          <TitleText className="pl-2">
                            {translation.contactInformation}:
                          </TitleText>
                        </Flex>
                      </CardHeader>
                      <CardBody>
                        <Flex column>
                          <Flex
                            gap="gap.medium"
                            wrap
                            style={{ rowGap: "10px" }}
                          >
                            <Flex column className="mt-3">
                              <Text content={`${translation.mobile}:`} />
                              <Text
                                content={data?.mobileNumber ?? ""}
                                weight="bold"
                              />
                            </Flex>
                            <Flex column className="mt-3">
                              <Text
                                content={`${translation.emergencyMobile}:`}
                              />
                              <Text
                                content={data?.emergencyMobileNumber ?? ""}
                                weight="bold"
                              />
                            </Flex>
                          </Flex>
                          <Flex column>
                            <Flex column className="mt-3">
                              <Text content={`${translation.addressLine1}:`} />
                              <Text
                                content={data?.addressLine1 ?? ""}
                                weight="bold"
                              />
                            </Flex>
                            <Flex column className="mt-3">
                              <Text content={`${translation.addressLine2}:`} />
                              <Text
                                content={data?.addressLine2 ?? ""}
                                weight="bold"
                              />
                            </Flex>
                          </Flex>

                          <Flex gap="gap.large" wrap className="mt-3">
                            <Flex column>
                              <Text content={`${translation.city}:`} />
                              <Text content={data?.city ?? ""} weight="bold" />
                            </Flex>

                            <Flex column>
                              <Text content={`${translation.state}:`} />
                              <Text content={data?.state ?? ""} weight="bold" />
                            </Flex>

                            <Flex column>
                              <Text content={`${translation.country}:`} />
                              <Text
                                content={data?.country ?? ""}
                                weight="bold"
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </CardBody>
                    </CategoryCard>
                  </ContactInfoSection>
                </div>
              </div>

              {["student", "teacher", "externaltutor"].includes(
                user?.role?.toLowerCase()
              ) || user?.isTutor ? (
                <div>
                  <SkillInfoSection className="md-Grid-row">
                    <div className="ms-Grid-col ms-sm12 card-wrapper">
                      <CategoryCard className="py-4">
                        <CardHeader>
                          <Flex column gap="gap.small">
                            <TitleText>{translation.mySkills}:</TitleText>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Flex wrap className="mt-2">
                            {!!selectedSkills?.length ? (
                              <Flex wrap>
                                {selectedSkills.map((item) => {
                                  return (
                                    <SkillPill
                                      key={item.id}
                                      actionable={isEditProfile}
                                      onDismiss={() => {
                                        onSkillChangeHandler({ value: item });
                                      }}
                                      style={{
                                        minWidth: "initial",
                                        backgroundColor: "#6264A7",
                                        color: "#fff",
                                      }}
                                    >
                                      {item.title}
                                    </SkillPill>
                                  );
                                })}
                              </Flex>
                            ) : (
                              <Flex column fill>
                                <Text
                                  weight="semibold"
                                  size="large"
                                  align="center"
                                >
                                  {translation.noSkillsSelectedMsg}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </CardBody>
                      </CategoryCard>
                    </div>
                  </SkillInfoSection>
                  <InterestInfoSection className="md-Grid-row ">
                    <div className="ms-Grid-col ms-sm12 card-wrapper">
                      <CategoryCard className="py-4">
                        <CardHeader>
                          <Flex column gap="gap.small">
                            <TitleText>{translation.myInterest}:</TitleText>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Flex wrap className="mt-2">
                            {!!selectedInterests.length ? (
                              <Flex wrap>
                                {selectedInterests.map((item) => {
                                  return (
                                    <SkillPill
                                      key={item.id}
                                      actionable={isEditProfile}
                                      onDismiss={() => {
                                        onInterestChangeHandler({
                                          value: item,
                                        });
                                      }}
                                      style={{
                                        minWidth: "initial",
                                        backgroundColor: "#6264A7",
                                        color: "#fff",
                                      }}
                                    >
                                      {item.title}
                                    </SkillPill>
                                  );
                                })}
                              </Flex>
                            ) : (
                              <Flex column fill>
                                <Text
                                  weight="semibold"
                                  size="large"
                                  align="center"
                                >
                                  {translation.noInterestSelectedMsg}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </CardBody>
                      </CategoryCard>
                    </div>
                  </InterestInfoSection>
                  {(user?.isTutor || user.role === "ExternalTutor") && (
                    <InterestInfoSection className="md-Grid-row ">
                      <div className="ms-Grid-col ms-sm12 card-wrapper">
                        <CategoryCard className="py-4">
                          <CardHeader>
                            <Flex column gap="gap.small">
                              <TitleText>{"Specialities"}:</TitleText>
                            </Flex>
                          </CardHeader>
                          <CardBody>
                            <Flex wrap className="mt-2">
                              {!!selectedSpecialities.length ? (
                                <Flex wrap>
                                  {selectedSpecialities.map((item) => {
                                    return (
                                      <SkillPill
                                        key={item.id}
                                        actionable={isEditProfile}
                                        onDismiss={() => {
                                          onSpecialityChangeHandler({
                                            value: item,
                                          });
                                        }}
                                        style={{
                                          minWidth: "initial",
                                          backgroundColor: "#6264A7",
                                          color: "#fff",
                                        }}
                                      >
                                        {item.title}
                                      </SkillPill>
                                    );
                                  })}
                                </Flex>
                              ) : (
                                <Flex column fill>
                                  <Text
                                    weight="semibold"
                                    size="large"
                                    align="center"
                                  >
                                    No Specialities
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          </CardBody>
                        </CategoryCard>
                      </div>
                    </InterestInfoSection>
                  )}
                </div>
              ) : null}

{(["teacher", "externaltutor"].includes(
                user?.role?.toLowerCase()
              ) ||
                user?.isTutor) && (
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <EducationInfoSection>
                    <CategoryCard className="py-4">
                      <CardHeader>
                        <Flex column gap="gap.small">
                          <TitleText>
                            {translation.educationalInformation}:
                          </TitleText>
                        </Flex>
                      </CardHeader>
                      <CardBody>
                        <Flex column className="mt-3">
                          {user?.role?.toLowerCase() === "student" && (
                            <>
                              <Flex column>
                                <Text
                                  content={`${translation.fieldOfStudy}:`}
                                />
                                <Text
                                  content={data?.fieldOfStudy ?? ""}
                                  weight="semibold"
                                />
                              </Flex>
                              <Flex column className="mt-3">
                                <Text content={`${translation.yearOfStudy}:`} />
                                <Text
                                  content={data?.yearOfStudy ?? ""}
                                  weight="semibold"
                                />
                              </Flex>
                            </>
                          )}
                          {user?.isTutor && (
                            <Flex
                              column
                              className={
                                user?.role?.toLowerCase() === "student"
                                  ? "mt-3"
                                  : ""
                              }
                            >
                              <Text content={`Years of Experience:`} />
                              <Text
                                content={data?.yearOfExperience ?? ""}
                                weight="semibold"
                              />
                            </Flex>
                          )}
                        </Flex>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                        </div>
                      </CardBody>
                    </CategoryCard>
                  </EducationInfoSection>
                </div>
              )}

              {isEditProfile && (
                <div className="ms-Grid-row margin-device-wise ">
                  <div className="ms-Grid-col ms-sm12 mt-4">
                    <div className="d-flex justify-content-end">
                      <Footer
                        step={currentStep}
                        totalStep={totalStep}
                        backHandler={backHandler}
                        nextHandler={saveProfileHandler}
                        loading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Profile);
