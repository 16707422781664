import { CloseIcon, Dialog, Flex, Image } from "@fluentui/react-northstar";
import React from "react";
import { useTutorRequest } from "../../../../../context/TutorRequestContext";
import TutorInfoCard from "./TutorInfoCard/TutorInfoCard";
import "./TutorInfoModel.scss";

const TutorInfoModel = () => {
  const { isOpenTutorInfoModal, setIsOpenTutorInfoModal, isLoadingTutorInfo } =
    useTutorRequest();
  return (
    <Dialog
      cancelButton="Close"
      className="tutor-info-model"
      onCancel={() => setIsOpenTutorInfoModal(false)}
      onClose={() => setIsOpenTutorInfoModal(false)}
      open={isOpenTutorInfoModal}
      content={<TutorInfoCard />}
    />
  );
};

export default TutorInfoModel;
