import React from "react";
import "./MeetingTimeSlots.scss";
import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Text,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import { Col, Row } from "antd";
import AddIcon from "@mui/icons-material/Add";
import { useBooking } from "../../../../../../context/BookingContext";
import moment from "moment";

const MeetingTimeSlots = (props) => {
  const {
    availabilityTime,
    index,
    handleToggleWeekDay,
    handleRemoveTime,
    handleAddTime,
    handleChangeTime,
  } = props;

  const { isScheduling, isShowingScheduledSlots, isEditing, isLoadingSlots } =
    useBooking();

  const isDisabled = isShowingScheduledSlots
    ? !isEditing ||
      availabilityTime?.isDisabled ||
      isLoadingSlots ||
      isScheduling
    : isLoadingSlots || isScheduling || availabilityTime?.isDisabled;

  return (
    <Row style={{ padding: "12px 0" }} className="meeting-time-slots-wrapper">
      <Col
        xxl={{ span: 6, order: 1 }}
        xl={{ span: 6, order: 1 }}
        lg={{ span: 8, order: 1 }}
        md={{ span: 8, order: 1 }}
        sm={{ span: 12, order: 1 }}
        xs={{ span: 12, order: 1 }}
      >
        <Checkbox
          toggle
          checked={
            availabilityTime.isSelected && availabilityTime?.isCommonSlots
          }
          disabled={isDisabled}
          label={
            <Text
              size="large"
              weight="regular"
              content={availabilityTime.label}
            />
          }
          onChange={(e, val) => {
            handleToggleWeekDay(val.checked, index);
          }}
        />
      </Col>
      {availabilityTime?.isSelected && availabilityTime?.isCommonSlots && (
        <Col
          xxl={{ span: 1, order: 3 }}
          xl={{ span: 1, order: 3 }}
          lg={{ span: 1, order: 3 }}
          md={{ span: 1, order: 3 }}
          sm={{ span: 12, order: 2 }}
          xs={{ span: 12, order: 2 }}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            icon={<AddIcon />}
            text
            iconOnly
            onClick={() => handleAddTime(index)}
            disabled={isDisabled}
          />
        </Col>
      )}
      <Col
        xxl={{ span: 17, order: 2 }}
        xl={{ span: 17, order: 2 }}
        lg={{ span: 15, order: 2 }}
        md={{ span: 15, order: 2 }}
        sm={{ span: 24, order: 3 }}
        xs={{ span: 24, order: 3 }}
        className="time-slots-wrapper"
      >
        {availabilityTime?.isSelected &&
        availabilityTime?.isCommonSlots &&
        availabilityTime?.timeSlots?.length ? (
          <Flex column gap="gap.smaller">
            {(() => {
              const slotsByKey = availabilityTime.timeSlots.reduce(
                (acc, slot) => {
                  if (!slot?.isRemoved && slot?.isCommon) {
                    if (!acc[slot.key]) acc[slot.key] = [];
                    acc[slot.key].push(slot);
                  }
                  return acc;
                },
                {}
              );

              return Object.entries(slotsByKey).map(([key, slots], i) => {
                const startTimes = [
                  ...new Set(
                    slots.map((slot) => {
                      const startTime = slot.isNew
                        ? slot.startTime
                        : moment(slot.startTime).format("h:mm A");
                      return startTime;
                    })
                  ),
                ].sort((a, b) => {
                  const aTime = moment(a, "h:mm A");
                  const bTime = moment(b, "h:mm A");
                  return aTime.diff(bTime);
                });

                const endTimes = [
                  ...new Set(
                    slots.map((slot) => {
                      const endTime = slot.isNew
                        ? slot.endTime
                        : moment(slot.endTime).format("h:mm A");
                      return endTime;
                    })
                  ),
                ].sort((a, b) => {
                  const aTime = moment(a, "h:mm A");
                  const bTime = moment(b, "h:mm A");
                  return aTime.diff(bTime);
                });
                return (
                  <Flex
                    key={i}
                    gap="gap.medium"
                    vAlign="center"
                    className="time-slots"
                  >
                    <Flex gap="gap.large" vAlign="center">
                      <Text content="From" />
                      <Dropdown
                        className="time-dropdown"
                        inverted
                        disabled={isDisabled}
                        items={startTimes}
                        value={
                          availabilityTime?.selectedTimeSlots?.[key]?.startTime
                        }
                        onChange={(e, val) => {
                          handleChangeTime(
                            index,
                            Number(key),
                            val.value,
                            "startTime"
                          );
                        }}
                      />
                    </Flex>
                    <Flex gap="gap.medium" vAlign="center">
                      <Text content="To" />
                      <Dropdown
                        className="time-dropdown"
                        inverted
                        disabled={isDisabled}
                        items={endTimes}
                        value={
                          availabilityTime?.selectedTimeSlots?.[key]?.endTime
                        }
                        onChange={(e, val) => {
                          handleChangeTime(
                            index,
                            Number(key),
                            val.value,
                            "endTime"
                          );
                        }}
                      />
                    </Flex>
                    <Button
                      icon={<TrashCanIcon />}
                      text
                      style={{ color: "#c4314b" }}
                      iconOnly
                      onClick={() => handleRemoveTime(index, Number(key))}
                      disabled={isDisabled}
                    />
                  </Flex>
                );
              });
            })()}
          </Flex>
        ) : (
          <Text
            size="large"
            weight="regular"
            content="Not Available"
            style={{ color: "#c7c7c7" }}
          />
        )}
      </Col>
    </Row>
  );
};

export default MeetingTimeSlots;
