import React from "react";
import Storage from "./Storage";
import Tabs from "../Events/Tabs/Tabs";
import History from "./History";

const StorageInfo = ({
  user,
  isTabletOrMobileView,
  isMobileView,
  isTabletView,
  storageHistory,
  setStorageHistory,
}) => {
  return (
    <Tabs currentTab="Storage Request">
      {user.actualRole === "SuperAdmin" && (
        <div label="Storage Request">
          <Storage
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            storageHistory={storageHistory}
            setStorageHistory={setStorageHistory}
          />
        </div>
      )}
      {user.actualRole === "SuperAdmin" && (
        <div label="History">
          <History
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            storageHistory={storageHistory}
          />
        </div>
      )}
    </Tabs>
  );
};

export default StorageInfo;
