import {
  Box,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function StorageSection(props) {
  const [storageInfo, setStorageInfo] = useState();

  useEffect(() => {
    setStorageInfo(props?.storageInfo);
  }, [props.storageInfo]);

  return (
    <>
      {props.isMobileView ? (
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: "3px" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Karla, sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Available Storage :
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 600,
                  }}
                >
                  {storageInfo?.storage > -1 ? (
                    "$ " + storageInfo.providedStorage - storageInfo.storage >
                    -1 ? (
                      (
                        storageInfo.providedStorage - storageInfo.storage
                      ).toFixed(4) + " GB"
                    ) : (
                      0
                    )
                  ) : (
                    <Skeleton
                      width={30}
                      variant="text"
                      sx={{ fontSize: "1rem" }}
                    />
                  )}
                </Typography>
              </Box>

              <a
                href={`https://www.acv.app/storage`}
                target="_blank"
                style={{
                  color: "rgb(0, 0, 0)",
                  border: "1px solid rgb(225, 223, 221)",
                  fontFamily: "Karla, sans-serif",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem",
                  fontWeight: 600,
                  width: "147px",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "5px",
                  textTransform: "uppercase",
                  padding: "5px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                $ Increase Storage
              </a>
            </Box>
          </Grid>

          <FormControlLabel
            control={
              <Switch
                checked={props.checked}
                onChange={(e) => props.handleSelectCheckbox(e.target.checked)}
              />
            }
            label="Select All Checkboxes"
            labelPlacement="start"
            className="switch-label"
            sx={{ marginLeft: "0px" }}
          />
        </Grid>
      ) : (
        <Box
          sx={{
            // padding: "10px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <TextField
              id="search-bar"
              className="search-bar"
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={props.searchValue}
              onChange={(e) => props.handleChange(e)}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Karla, sans-serif",
              }}
            >
              Available Storage :
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Karla, sans-serif",
                fontWeight: 600,
              }}
            >
              {storageInfo?.storage > -1 ? (
                storageInfo.providedStorage - storageInfo.storage > -1 ? (
                  (storageInfo.providedStorage - storageInfo.storage).toFixed(
                    4
                  ) + " GB"
                ) : (
                  0
                )
              ) : (
                <Skeleton width={30} variant="text" sx={{ fontSize: "1rem" }} />
              )}
            </Typography>

            <a
              href={`https://www.acv.app/storage`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgb(0, 0, 0)",
                border: "1px solid rgb(225, 223, 221)",
                fontFamily: "Karla, sans-serif",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem",
                fontWeight: 600,
                padding: "10px 20px",
                borderRadius: "5px",
                fontSize: "15px",
                textTransform: "uppercase",
              }}
            >
              $ Increase Storage
            </a>
          </Box>
        </Box>
      )}
    </>
  );
}

export default StorageSection;
