import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Flex, Loader, Text, Tooltip } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { Button, CloseIcon, AcceptIcon } from "@fluentui/react-northstar";
import { LoadingButton } from "@mui/lab";

import { Edit, Trash } from "react-feather";
import { Input } from "@mui/material";
import "../event.css";
import { AlertDialog } from "../../../components/Dialog/AlertDialog";
import { useTranslation } from "react-i18next";
import "./eventCategory.scss";

const END_POINT = process.env.REACT_APP_EP_URL;

export const EventCategory = (props) => {
  const [category, setCategory] = useState([]);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({
    id: "",
    title: "",
    isEdit: false,
  });

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.user.slug && props.user.accessToken) {
      getCategory(props.user.slug, props.user.accessToken);
    }
  }, [props]);

  const getCategory = (slug, token) => {
    axios
      .get(`${END_POINT}/api/category/all${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setCategory(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleAddCategory = () => {
    setLoading(true);
    axios
      .post(
        `${END_POINT}/api/category${props.user.slug}`,
        {
          title: props.addEventCategory.title,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        props.setAddEventCategory({ isEdit: false, title: "" });
        getCategory(props.user.slug, props.user.accessToken);
      })
      .catch((err) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    axios
      .put(
        `${END_POINT}/api/category/${edit.id}${props.user.slug}`,
        {
          title: edit.title,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        const idx = category.findIndex((data) => data.id === edit.id);
        if (idx != -1) {
          category[idx].title = edit.title;
          setCategory([...category]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${END_POINT}/api/category/${edit.id}${props.user.slug}`, {
        headers: {
          Authorization: `Bearer ${props.user.token}`,
        },
      })
      .then((res) => {
        setEdit({ id: "", title: "", isEdit: false });
        setCategory(category.filter((data) => data.id !== edit.id));
      })
      .catch((err) => {
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <>
      <AlertDialog
        handleClose={handleClose}
        handleOk={handleDelete}
        open={open}
      />
      <div className="btsp setting-event-category-container">
        <Button
          content={t("setting").event[2]}
          primary
          className="ml-auto d-block"
          style={{ marginRight: 20 }}
          onClick={() => props.setAddEventCategory({ isEdit: true })}
        />
        {props.addEventCategory.isEdit && (
          <>
            <div className="main_card_div my-3 d-flex justify-content-between">
              <Flex column fill gap="gap.smaller">
                <Input
                  value={props.addEventCategory.title}
                  fullWidth
                  sx={{ fontSize: 12 }}
                  onChange={(e) => {
                    if (e.target.value.length <= 100) {
                      props.setAddEventCategory({
                        ...props.addEventCategory,
                        title: e.target.value,
                      });
                    }
                  }}
                />
                <Flex.Item align="end">
                  <Text
                    content={`${
                      props?.addEventCategory?.title?.length || 0
                    }/100`}
                  />
                </Flex.Item>
              </Flex>
              <div className="d-flex ml-2">
                <LoadingButton
                  loading={loading}
                  startIcon={!loading && <AcceptIcon />}
                  disabled={
                    !props.addEventCategory.title ||
                    !props.addEventCategory.title.trim()
                  }
                  iconOnly
                  title="Submit"
                  onClick={handleAddCategory}
                  style={{
                    color:
                      !props.addEventCategory.title ||
                      !props.addEventCategory.title.trim()
                        ? "gray"
                        : "#585A96",
                    borderRadius: 5,
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                    border: "1px solid rgb(225, 223, 221)",
                    width: 33,
                    minWidth: 0,
                  }}
                />
                <Button
                  icon={<CloseIcon />}
                  iconOnly
                  title="Cancel"
                  onClick={() =>
                    props.setAddEventCategory({
                      isEdit: false,
                      title: "",
                    })
                  }
                  className="mx-2"
                  style={{ color: "#DE5854", borderRadius: 5 }}
                />
              </div>
            </div>
          </>
        )}
        <div>
          <div className="event-categories-wrapper">
            {category.length > 0 &&
              category.map((data) => {
                return (
                  <>
                    <div className="main_card_div my-3 d-flex justify-content-between">
                      {edit.isEdit && edit.id === data.id ? (
                        <Flex column fill gap="gap.smaller">
                          <Input
                            value={edit.title}
                            fullWidth
                            sx={{ fontSize: 12 }}
                            onChange={(e) => {
                              if (e.target.value.length <= 100) {
                                setEdit({ ...edit, title: e.target.value });
                              }
                            }}
                          />
                          <Flex.Item align="end">
                            <Text content={`${edit?.title?.length || 0}/100`} />
                          </Flex.Item>
                        </Flex>
                      ) : (
                        <Tooltip
                          content={data.title}
                          trigger={
                            <span
                              className=""
                              style={{
                                display: "block",
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {data.title}
                            </span>
                          }
                        />
                      )}
                      <div className="d-flex ml-2">
                        {edit.isEdit && edit.id === data.id ? (
                          <>
                            <LoadingButton
                              loading={loading}
                              startIcon={
                                !loading && <AcceptIcon style={{ margin: 0 }} />
                              }
                              disabled={!edit.title || !edit.title.trim()}
                              iconOnly
                              title="Submit"
                              onClick={handleEdit}
                              style={{
                                color:
                                  !edit.title || !edit.title.trim()
                                    ? "gray"
                                    : "#585A96",
                                borderRadius: 5,
                                boxShadow:
                                  "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                                border: "1px solid rgb(225, 223, 221)",
                                width: 33,
                                minWidth: 0,
                              }}
                            />
                            <Button
                              icon={<CloseIcon />}
                              iconOnly
                              title="Cancel"
                              onClick={() => {
                                setEdit({
                                  id: "",
                                  title: "",
                                  isEdit: false,
                                });
                              }}
                              className="mx-2"
                              style={{ color: "#DE5854", borderRadius: 5 }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              icon={<Edit />}
                              iconOnly
                              title="Edit category"
                              onClick={() => {
                                setEdit({
                                  id: data.id,
                                  title: data.title,
                                  isEdit: true,
                                });
                              }}
                              style={{ color: "#585A96", borderRadius: 5 }}
                            />
                            <Button
                              icon={<Trash />}
                              iconOnly
                              title="Delete category"
                              onClick={() => {
                                setOpen(true);
                                setEdit({ id: data.id, isEdit: false });
                              }}
                              className="mx-2"
                              style={{ color: "#DE5854", borderRadius: 5 }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
