import { createContext, useContext, useEffect, useState } from "react";

import axios from "axios";
import moment from "moment";

import { toast } from "react-toastify";

import { NotificationAlert } from "../components/Notification/Notification";
import { EventDateFilterTypes } from "../components/widgets/EventFilters";

const url = process.env.REACT_APP_EP_URL;

const Event = createContext();

export const useEvent = () => {
  return useContext(Event);
};

export const EventContext = (props) => {
  const { children, eventPermissions } = props;

  const [tags, setTags] = useState([]);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isVideoConf, setIsVideoConf] = useState(false);
  const [isInplace, setIsInplace] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState({
    categories: [],
    tags: [],
    isAudio: false,
    isVideoConf: false,
    isInplace: false,
    userFilter: null,
  });
  const [selectedUserFilter, setSelectedUserFilter] = useState(null);
  const [userFilters, setUserFilters] = useState();
  const [refreshUserFilters, setRefreshUserFilters] = useState(false);
  const [open, setOpen] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [isSaveFilterModalOpen, setIsSaveFilterModalOpen] = useState(false);
  const [isValidateFilterName, setIsValidateFilterName] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(4);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    EventDateFilterTypes.all
  );
  const [isExcelImportOpen, setIsExcelImportOpen] = useState(false);
  const [isAIImportOpen, setIsAIImportOpen] = useState(false);
  const [createEventOptions, setCreateEventOptions] = useState([]);
  const [selectedCreater, setSelectedCreater] = useState("");

  const [isWarningOpen, setIsWarningOpen] = useState(false);

  useEffect(() => {
    getTags();
    getCategories();
  }, []);

  useEffect(() => {
    fetchFilters();
  }, [refreshUserFilters]);

  useEffect(() => {
    const data = localStorage.getItem("userEventColumnPreference");
    if (data) {
      try {
        const preferenceObj = JSON.parse(data);
        if (preferenceObj.user === props.user.mail) {
          setSelectedColumns(preferenceObj.columnPreference);
        }
      } catch (error) {}
    }
  }, []);

  const handleCreateEventOptions = (value) => {
    setCreateEventOptions(value);
  };

  const getCategories = () => {
    setIsLoadingCategories(true);
    axios
      .get(`${url}/api/category/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setIsLoadingCategories(false);
        setCategories(result);
      })
      .catch((err) => {
        setIsLoadingCategories(false);
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const getTags = () => {
    setIsLoadingTags(true);
    axios
      .get(`${url}/api/tag/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setTags(result);
        setIsLoadingTags(false);
      })
      .catch((err) => {
        setIsLoadingTags(false);
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const fetchFilters = async (title) => {
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    const response = await axios({
      method: "GET",
      url: `${url}/api/filter/all${props.user.slug}`,
      headers,
    });
    const data = response.data;
    const result = data?.result;
    let filters = [];
    if (result?.length) {
      filters = result.filter((item) => item.userId === props.user.mail);
    }
    if (title) {
      let filter = filters.find((item) => item.name === title);
      setSelectedUserFilter(filter ? filter.id : null);
      setAppliedFilter((prevData) => ({
        ...prevData,
        userFilter: filter ? filter.id : null,
      }));
    }
    setUserFilters(filters);
  };

  const saveUserFilter = async () => {
    let fName = filterName;
    let type = [];
    if (isAudio) {
      type.push("audio");
    }
    if (isVideoConf) {
      type.push("video");
    }
    if (isInplace) {
      type.push("inPlace");
    }
    let payload = {
      name: filterName,
      filters: {
        category: categories
          .filter((item) => item.checked)
          ?.map((item) => item.title),
        tags: tags.filter((item) => item.checked)?.map((item) => item.title),
        type,
        startDate,
        endDate,
      },
    };
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    const response = await axios({
      method: "POST",
      url: `${url}/api/filter${props.user.slug}`,
      data: payload,
      headers,
    });
    let data = response.data;
    if (data.status === "Success") {
      NotificationAlert(data.message, "success");
      fetchFilters(fName);
      setFilterName("");
      setIsValidateFilterName(false);
    }
  };

  const deleteUserFilter = async () => {
    const headers = {
      Authorization: `Bearer ${props.user.accessToken}`,
    };
    const response = await axios({
      method: "DELETE",
      url: `${url}/api/filter/${appliedFilter.userFilter}${props.user.slug}`,
      headers,
    });
    let data = response.data;
    if (data.status === "Success") {
      NotificationAlert(data.message, "success");
      setFilterName("");
      setRefreshUserFilters(!refreshUserFilters);
      setSelectedUserFilter(null);
      setAppliedFilter((prevData) => ({
        ...prevData,
        userFilter: null,
      }));
    }
  };

  const handlePortal = (status) => {
    setOpen(status);
  };
  const onHandleSelection = (e, id, key) => {
    if (key === "tags") {
      setTags((old) =>
        old.map((x) => (x.id === id ? { ...x, checked: e.checked } : x))
      );
    }
    if (key === "categories") {
      setCategories((old) =>
        old.map((x) => (x.id === id ? { ...x, checked: e.checked } : x))
      );
    }
    if (key === "audio") {
      setIsAudio(e.checked);
    }
    if (key === "videoConf") {
      setIsVideoConf(e.checked);
    }
    if (key === "inPlace") {
      setIsInplace(e.checked);
    }

    if (key === "userFilter") {
      setSelectedUserFilter(e.checked ? id : null);
      if (e.checked) {
        let filter = userFilters.find((item) => item.id === id);

        if (filter) {
          setCategories((prevData) =>
            prevData.map((x) =>
              filter.category.includes(x.title)
                ? { ...x, checked: e.checked }
                : x
            )
          );
          setTags((prevData) =>
            prevData.map((x) =>
              filter.tags.includes(x.title) ? { ...x, checked: e.checked } : x
            )
          );
          setIsAudio(filter.type.includes("audio"));
          setIsVideoConf(filter.type.includes("video"));
          setStartDate(filter.startDate ?? null);
          setEndDate(filter.endDate ?? null);
        }
      }
    }

    if (key !== "userFilter") {
      setSelectedUserFilter(null);
    }
  };

  const resetFilter = () => {
    setTags((old) => old.map((t) => ({ ...t, checked: false })));
    setCategories((old) => old.map((t) => ({ ...t, checked: false })));
    setIsAudio(false);
    setIsVideoConf(false);
    setSelectedUserFilter(null);
    setAppliedFilter({
      categories: [],
      tags: [],
      isAudio: false,
      isVideoConf: false,
      isInplace: false,
      userFilter: null,
    });
    setIsFilterApplied(false);

    setStartDate(null);
    setEndDate(null);
  };

  const onApplyFilter = () => {
    if (selectedUserFilter) {
      let selectedFilter = userFilters.find(
        (item) => item.id === selectedUserFilter
      );

      if (selectedFilter) {
        setAppliedFilter({
          categories: selectedFilter.category
            ?.map((item) => categories.find((data) => data.title === item))
            ?.filter((item) => item),
          tags: selectedFilter.tags
            ?.map((item) => tags.find((data) => data.title === item))
            ?.filter((item) => item),
          isAudio: selectedFilter.type?.includes("audio"),
          isVideoConf: selectedFilter.type?.includes("video"),
          isInplace: selectedFilter.type?.includes("inPlace"),
          startDate: selectedFilter.startDate ?? null,
          endDate: selectedFilter.endDate ?? null,
          userFilter: selectedUserFilter,
        });
        setIsFilterApplied(true);
      }
    } else {
      let filterObj = {
        categories: categories.filter((item) => item.checked),
        tags: tags.filter((item) => item.checked),
        isAudio: isAudio,
        isVideoConf: isVideoConf,
        isInplace: isInplace,
        startDate: startDate ?? null,
        endDate: endDate ?? null,
        userFilter: null,
      };

      if (
        !filterObj.categories.length &&
        !filterObj.tags.length &&
        !filterObj.isAudio &&
        !filterObj.isVideoConf &&
        !filterObj.isInplace &&
        !filterObj.startDate &&
        !filterObj.endDate
      ) {
        setIsFilterApplied(false);
      } else {
        setAppliedFilter(filterObj);
        setIsFilterApplied(true);
      }
    }

    setOpen(false);
  };

  const columnSelectHandler = (column) => {
    const userPreference = {
      user: props.user.mail,
      columnPreference: column,
    };
    localStorage.setItem(
      "userEventColumnPreference",
      JSON.stringify(userPreference)
    );
    setSelectedColumns(column);
  };

  useEffect(() => {
    if (
      !appliedFilter.categories?.length &&
      !appliedFilter.tags?.length &&
      !appliedFilter.isAudio &&
      !appliedFilter.isVideoConf &&
      !appliedFilter.isInplace &&
      !appliedFilter.startDate &&
      !appliedFilter.endDate
    ) {
      setIsFilterApplied(false);
      setSelectedUserFilter(null);
    }
  }, [appliedFilter]);

  useEffect(() => {
    if (
      (appliedFilter.startDate && moment(appliedFilter.startDate).isValid()) ||
      (appliedFilter.endDate && moment(appliedFilter.endDate).isValid())
    ) {
      setSelectedDateFilter(null);
    }
  }, [appliedFilter]);

  const changeStartDate = (value) => {
    setSelectedUserFilter(null);
    setStartDate(
      value ? moment(value, "MM-DD-YYYY").format("MM-DD-YYYY") : null
    );
  };
  const changeEndDate = (value) => {
    setSelectedUserFilter(null);
    setEndDate(value ? moment(value, "MM-DD-YYYY").format("MM-DD-YYYY") : null);
  };

  return (
    <Event.Provider
      value={{
        onApplyFilter,
        onResetFilter: resetFilter,
        handlePortal,
        onHandleSelection,
        tags,
        setTags,
        categories,
        setCategories,
        isAudio,
        setIsAudio,
        isVideoConf,
        setIsVideoConf,
        isInplace,
        setIsInplace,
        isFilterApplied,
        setIsFilterApplied,
        appliedFilter,
        setAppliedFilter,
        selectedUserFilter,
        setSelectedUserFilter,
        userFilters,
        setUserFilters,
        refreshUserFilters,
        setRefreshUserFilters,
        open,
        setOpen,
        globalSearch,
        setGlobalSearch,
        isSaveFilterModalOpen,
        setIsSaveFilterModalOpen,
        isValidateFilterName,
        setIsValidateFilterName,
        saveUserFilter,
        deleteUserFilter,
        filterName,
        setFilterName,
        selectedColumns,
        setSelectedColumns,
        columnSelectHandler,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        changeStartDate,
        changeEndDate,
        selectedDateFilter,
        setSelectedDateFilter,

        //Event Bulk Import states
        isExcelImportOpen,
        setIsExcelImportOpen,
        isAIImportOpen,
        setIsAIImportOpen,
        handleCreateEventOptions,
        createEventOptions,
        selectedCreater,
        setSelectedCreater,
        isLoadingTags,
        isLoadingCategories,
        isWarningOpen,
        setIsWarningOpen,
      }}
    >
      {children}
    </Event.Provider>
  );
};
