import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  Dropdown,
  Flex,
  RetryIcon,
  Segment,
  Text,
} from "@fluentui/react-northstar";

import "./controlStyles.css";

import { ExportClassData } from "../helper/ExportData";

import axios from "axios";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Tour from "../pages/Attendance/Tour";

import { Box, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import userSvg from "../Assets/images/svg/user.svg";
import Portal from "../helper/Portal";
import Drawer from "../pages/Attendance/Drawer";

const Controls = (props) => {
  const { t } = useTranslation();

  const [checkPhysical, setCheckPhysical] = useState(null);

  const url = process.env.REACT_APP_EP_URL;
  const [teacherData, setTeacherData] = useState([]);
  const [teachersNames, setTeachersNames] = useState([]);
  const [teacherClassData, setTeacherClassData] = useState(null);
  const [accDlg, setAccDlg] = useState(false);
  const [classNames, setClassNames] = useState([]);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 991);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headers = {
    Authorization: "Bearer " + props.state.accessToken,
  };

  useEffect(() => {
    setCheckPhysical(
      props?.state?.selectedLessonType === "Hybrid"
        ? true
        : props?.state?.selectedLessonType === "Virtual"
        ? false
        : null
    );
  }, [props?.state?.selectedLessonType]);

  useEffect(() => {
    props.user.role === "Admin" || props.user.role === "SuperAdmin"
      ? axios
          .post(
            `${url}/api/teacher/get-teachers${props.user.slug}`,
            {
              date: props?.state?.selectedDate,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            setTeacherData(res.data.result || null);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            console.log(err, err.response);
            if (err.response?.status === 401) {
              setAccDlg(true);
            }
          })
      : "";
  }, [props?.state?.selectedDate]);

  let dropdownKeys = [];

  const classNameHandler = (lessions) => {
    dropdownKeys = [];
    lessions &&
      setClassNames(
        lessions.length
          ? lessions
              .map((lsn) => {
                dropdownKeys.push(lsn.Title);
                return lsn.ClassName;
              })
              .filter((value, index, self) => self.indexOf(value) === index)
          : []
      );
  };

  const teachersNamesHandler = (teachers) => {
    const teachersNames =
      teachers && teachers.length
        ? teachers
            .map((teacher) => {
              dropdownKeys.push(teacher.TeacherEmailID);
              return {
                header: teacher.TeacherName,
                image: teacher.UrlPhoto
                  ? teacher.UrlPhoto + props.user.SASToken
                  : userSvg,
                content: teacher.Facultad,
                key: teacher.TeacherEmailID,
              };
            })
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];
    setTeachersNames(teachersNames);
  };

  useEffect(() => {
    if (teacherClassData === null) {
      classNameHandler(props.state.showLesson);
    }
  }, [props?.state, teacherClassData]);

  useEffect(() => {
    if (teacherData && teacherData?.length) {
      teachersNamesHandler(teacherData);
    }
  }, [teacherData]);

  const dropdownChangedHandler = (className) => {
    props.changeLsnByClass(className);
  };
  const teacherChangedHandler = (teacher) => {
    axios
      .get(`${url}/api/teacher/get-teacher/${teacher.key}${props.user.slug}`, {
        headers: headers,
      })
      .then((res) => {
        const lessonData = res.data.result ? res.data.result.LessonData : null;
        lessonData && classNameHandler(lessonData);
        setTeacherClassData(lessonData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
    props.changeLsnByTeacher(teacher.key);
  };

  const searchValue = () => {
    teacherChangedHandler();
    dropdownChangedHandler();
  };

  const getA11ySelectionMessage = {
    onAdd: (item) =>
      `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item) => `${item.header} has been removed.`,
  };

  return (
    <>
      {!props.openWorkSpace ? (
        isMobileView || isTabletView ? (
          <div className="Controls" style={{ position: "static" }}>
            <Dialog
              open={accDlg}
              onConfirm={() => location.reload()}
              confirmButton="Refresh Now"
              content={
                <p className="refreshMsg">
                  No big deal — but we need to refresh to show your content.
                </p>
              }
              header="Authorization expire"
            />
            <Flex
              gap="gap.smaller"
              wrap
              className={
                props.isLessonsAvailable
                  ? "tour-attendence-find-class tour-whiteboard-find-class"
                  : "tour-attendence-no-class-available"
              }
            >
              <Grid container>
                <Grid item xs={12}>
                  <Segment
                    content={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: props.showNote
                            ? "8px"
                            : !props.showNote
                            ? "5px"
                            : "15px",
                        }}
                      >
                        {props.showNote && (
                          <Drawer
                            isCompactMode={props.isCompactMode}
                            state={props.state}
                            myCard={props.myCard}
                            isOpen={props.isOpen}
                            openPanel={props.openPanel}
                            dismissPanel={props.dismissPanel}
                          />
                        )}
                        {isMobileView ? (
                          <>
                            {props.showNote ? (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disableMaskedInput
                                  className="date-picker-attendance-mobileview"
                                  inputFormat="MM/DD/YYYY"
                                  value={selectedDate}
                                  onChange={(value) => {
                                    let date = new Date(value);
                                    date.setHours(0);
                                    date.setMinutes(0);
                                    date.setSeconds(0);
                                    let dt = date.getDate();
                                    let month = date.getMonth() + 1;
                                    let year = date.getFullYear();
                                    if (dt < 10) {
                                      dt = "0" + dt;
                                    }
                                    if (month < 10) {
                                      month = "0" + month;
                                    }
                                    props.onDateDataChange(
                                      props.user.slug === "?slug=acvpreprod" ||
                                        props.user.slug === "?slug=acvapp"
                                        ? value.$d
                                        : date.toISOString()
                                    );
                                    setSelectedDate(date);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            ) : (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  disableMaskedInput
                                  className="date-picker-attendance-mobileview-view"
                                  inputFormat="MMMM D, YYYY"
                                  value={selectedDate}
                                  onChange={(value) => {
                                    let date = new Date(value);
                                    date.setHours(0);
                                    date.setMinutes(0);
                                    date.setSeconds(0);
                                    let dt = date.getDate();
                                    let month = date.getMonth() + 1;
                                    let year = date.getFullYear();
                                    if (dt < 10) {
                                      dt = "0" + dt;
                                    }
                                    if (month < 10) {
                                      month = "0" + month;
                                    }

                                    props.onDateDataChange(
                                      props.user.slug === "?slug=acvpreprod" ||
                                        props.user.slug === "?slug=acvapp"
                                        ? value.$d
                                        : date.toISOString()
                                    );
                                    setSelectedDate(date);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        "& fieldset": { border: "none" },
                                      }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          </>
                        ) : (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              disableMaskedInput
                              className="date-picker-attendance-mobileview"
                              inputFormat="MMMM D, YYYY"
                              value={selectedDate}
                              onChange={(value) => {
                                let date = new Date(value);
                                date.setHours(0);
                                date.setMinutes(0);
                                date.setSeconds(0);
                                let dt = date.getDate();
                                let month = date.getMonth() + 1;
                                let year = date.getFullYear();
                                if (dt < 10) {
                                  dt = "0" + dt;
                                }
                                if (month < 10) {
                                  month = "0" + month;
                                }

                                props.onDateDataChange(
                                  props.user.slug === "?slug=acvpreprod" ||
                                    props.user.slug === "?slug=acvapp"
                                    ? value.$d
                                    : date.toISOString()
                                );
                                setSelectedDate(date);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    "& fieldset": { border: "none" },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                        {props.showNote ? (
                          <div className="leftControls tour-attendence-topic">
                            <Segment
                              content={
                                <Button
                                  content={props.labels.topic[0]}
                                  disabled={!props.showTopic ? true : false}
                                  onClick={
                                    props.showTopic
                                      ? props.showTopic?.bind()
                                      : () => {}
                                  }
                                  className="btn-sm-custom"
                                />
                              }
                              className="seg2"
                              style={{ padding: "0px", marginTop: "-2px" }}
                            />
                          </div>
                        ) : null}
                        {props.showNote ? (
                          <div className="leftControls tour-attendence-homework">
                            <Segment
                              content={
                                <Button
                                  content={props.labels.homework[0]}
                                  disabled={!props.showNote ? true : false}
                                  onClick={
                                    props.showNote
                                      ? props.showNote?.bind()
                                      : () => {}
                                  }
                                  className="btn-sm-custom"
                                />
                              }
                              className="seg2"
                              style={{ padding: "0px", marginTop: "-2px" }}
                            />
                          </div>
                        ) : null}
                        {props.page === "attendance" ? (
                          <Tour
                            isClassSelected={
                              props.showNote || !!props.state?.selectedLesson
                            }
                            isLessonsAvailable={props.isLessonsAvailable}
                            page={props.page}
                          />
                        ) : null}
                        {props.page === "whiteboard" && (
                          <Segment
                            content={
                              <Button
                                content={t("workspace")}
                                iconOnly
                                onClick={() => {
                                  props?.setOpenWorkSpace();
                                  localStorage.setItem(
                                    "msg",
                                    JSON.stringify({
                                      isOpenWorkSpace: false,
                                      isReloadingWorkspace: false,
                                    })
                                  );
                                }}
                                style={{ padding: "0 5px" }}
                              />
                            }
                            className="seg2"
                          />
                        )}
                      </Box>
                    }
                    style={{
                      boxShadow: "none",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  />
                </Grid>
                <Dialog
                  open={props.isMobileViewFiltersOpen}
                  onCancel={props.handleClose}
                  header="Search Teachers & Class"
                  content={
                    <Box>
                      {teachersNames.length ? (
                        <Segment
                          content={
                            <Dropdown
                              clearable
                              onTouchCancelCapture={() => alert("cancel")}
                              search
                              items={teachersNames}
                              placeholder={props.cLabels.teacher[1]}
                              onChange={(e, { value }) => {
                                teacherChangedHandler(value || "");
                              }}
                              noResultsMessage="We couldn't find any matches."
                              className="teacherDropdown"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      ) : null}
                      {classNames.length ? (
                        <Segment
                          content={
                            <Dropdown
                              clearable
                              onTouchCancelCapture={() => alert("cancel")}
                              search
                              items={classNames.sort()}
                              placeholder={props.cLabels.class}
                              onChange={(e, { value }) => {
                                dropdownChangedHandler(value);
                              }}
                              className="classDropdown"
                              noResultsMessage="We couldn't find any matches."
                              style={{ width: "100%" }}
                            />
                          }
                          style={{
                            boxShadow: "none",
                          }}
                        />
                      ) : null}
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "18px",
                          gap: "5px",
                        }}
                      >
                        <Button
                          primary
                          size="large"
                          icon="Add"
                          content="Click Me"
                          onClick={props.handleClose}
                        >
                          Close
                        </Button>
                        <Button
                          primary
                          size="large"
                          icon="Add"
                          content="Click Me"
                          onClick={props.handleClose}
                          autoFocus
                        >
                          Ok
                        </Button>
                      </Box>
                    </Box>
                  }
                  actions={[
                    {
                      key: "ok",
                      content: "OK",
                      onClick: searchValue,
                    },
                    {
                      key: "cancel",
                      content: "Cancel",
                      onClick: props.handleClose,
                    },
                  ]}
                />
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", marginBottom: "5px" }}
                >
                  {props.showNote && (
                    <>
                      {isMobileView && (
                        <>
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(70, 71, 117, 0.04)",
                                  color: "#0d6efd",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics.virtual
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={props.labels.classType[2]}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey"
                              />
                            </Flex>
                          ) : null}
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(70, 71, 117, 0.04)",
                                  color: "#0d6efd",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics.total
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={props.labels.total}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey"
                              />
                            </Flex>
                          ) : null}
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(218, 218, 227, 0.15)",
                                  color: "#198754",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics.present
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={props.labels.present}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey"
                              />
                            </Flex>
                          ) : null}
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(211, 49, 58, 0.03)",
                                  color: "#D3313A",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics.absent
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={props.labels.absent}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey"
                              />
                            </Flex>
                          ) : null}
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(187, 107, 217, 0.03)",
                                  color: "#464775",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics.late
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={props.labels.late}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey"
                              />
                            </Flex>
                          ) : null}
                          {props.showNote ? (
                            <Flex
                              gap="gap.smaller"
                              className="an-gap"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                className="lbl"
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "rgba(23, 162, 184, 0.04)",
                                  color: "#17a2b8",
                                }}
                              >
                                <Text
                                  content={
                                    props.state.showRecordsAnalytics
                                      .justification
                                  }
                                  size="large"
                                  weight="semibold"
                                />
                              </div>
                              <Text
                                content={"Justified"}
                                style={{ marginTop: "5px" }}
                                className="Karla fs16 txt-grey justify-lbl"
                              />
                            </Flex>
                          ) : null}
                        </>
                      )}
                      {!isMobileView && (
                        <Flex wrap space="between" vAlign="center">
                          <Flex gap="gap.medium" wrap className="ctnr">
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "rgba(70, 71, 117, 0.04)",
                                    color: "#0d6efd",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics.virtual
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.classType[2]}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey"
                                />
                              </Flex>
                            ) : null}
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "rgba(70, 71, 117, 0.04)",
                                    color: "#0d6efd",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics.total
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.total}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey"
                                />
                              </Flex>
                            ) : null}
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor:
                                      "rgba(218, 218, 227, 0.15)",
                                    color: "#198754",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics.present
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.present}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey"
                                />
                              </Flex>
                            ) : null}
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "rgba(211, 49, 58, 0.03)",
                                    color: "#D3313A",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics.absent
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.absent}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey"
                                />
                              </Flex>
                            ) : null}
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor:
                                      "rgba(187, 107, 217, 0.03)",
                                    color: "#464775",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics.late
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.late}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey"
                                />
                              </Flex>
                            ) : null}
                            {props.showNote ? (
                              <Flex gap="gap.smaller" className="an-gap">
                                <div
                                  className="lbl"
                                  style={{
                                    marginRight: "4px",
                                    backgroundColor: "rgba(23, 162, 184, 0.04)",
                                    color: "#17a2b8",
                                  }}
                                >
                                  <Text
                                    content={
                                      props.state.showRecordsAnalytics
                                        .justification
                                    }
                                    size="large"
                                    weight="semibold"
                                  />
                                </div>
                                <Text
                                  content={props.labels.justification}
                                  style={{ marginTop: "5px" }}
                                  className="Karla fs16 txt-grey justify-lbl"
                                />
                              </Flex>
                            ) : null}
                            {props.showExport ? (
                              <Flex
                                gap="gap.smaller"
                                className={`an-gap ${
                                  props.state.showRecords.length
                                    ? "tour-attendence-generate-report"
                                    : ""
                                }`}
                              >
                                {props.state.showRecords.length ? (
                                  <ExportClassData
                                    dataset={props.state.showRecords}
                                  />
                                ) : null}
                              </Flex>
                            ) : null}
                          </Flex>
                        </Flex>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Flex>
          </div>
        ) : (
          <div
            className="Controls"
            style={{
              position: "static",
            }}
          >
            <Dialog
              open={accDlg}
              onConfirm={() => location.reload()}
              confirmButton="Refresh Now"
              content={
                <p className="refreshMsg">
                  No big deal — but we need to refresh to show your content.
                </p>
              }
              header="Authorization expire"
            />
            <Flex space="between" wrap vAlign="center">
              <Flex gap="gap.smaller" wrap vAlign="center">
                <Flex
                  gap="gap.smaller"
                  wrap
                  className={
                    props.isLessonsAvailable
                      ? "tour-attendence-find-class tour-whiteboard-find-class"
                      : "tour-attendence-no-class-available"
                  }
                >
                  <div
                    className="leftControls"
                    style={{
                      margin: 0,
                      marginLeft: "31px",
                      marginRight: "0.5rem",
                    }}
                  >
                    <Segment content={props.labels.date} className="seg1" />
                    <Segment
                      content={
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            disableMaskedInput
                            className="date-picker-attendance"
                            inputFormat="MMMM D, YYYY"
                            value={selectedDate}
                            onChange={(value) => {
                              let date = new Date(value);
                              date.setHours(0);
                              date.setMinutes(0);
                              date.setSeconds(0);
                              let dt = date.getDate();
                              let month = date.getMonth() + 1;
                              let year = date.getFullYear();
                              if (dt < 10) {
                                dt = "0" + dt;
                              }
                              if (month < 10) {
                                month = "0" + month;
                              }

                              props.onDateDataChange(
                                props.user.slug === "?slug=acvpreprod" ||
                                  props.user.slug === "?slug=acvapp"
                                  ? value.$d
                                  : date.toISOString()
                              );
                              setSelectedDate(date);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& fieldset": { border: "none" },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      }
                      className="seg2"
                    />
                  </div>
                  <div className="leftControls">
                    <Segment
                      content=""
                      className="seg1"
                      style={{ padding: 0 }}
                    />
                    <Segment
                      content={
                        <Button
                          icon={<RetryIcon />}
                          iconOnly
                          title="Refresh teacher and class"
                          onClick={() => {
                            props.resetSelected();
                          }}
                          id="datePicker-1"
                        />
                      }
                      className="seg2"
                    />
                  </div>
                  {teachersNames.length ? (
                    <div className="leftControls">
                      <Segment
                        content={props.cLabels.teacher[0]}
                        className="seg1"
                      />
                      <Segment
                        content={
                          <Dropdown
                            clearable
                            onTouchCancelCapture={() => alert("cancel")}
                            search
                            items={teachersNames}
                            placeholder={props.cLabels.teacher[1]}
                            onChange={(e, { value }) => {
                              teacherChangedHandler(value || "");
                            }}
                            noResultsMessage="We couldn't find any matches."
                          />
                        }
                        className="seg2 selTeacher"
                      />
                    </div>
                  ) : null}
                  {classNames.length ? (
                    <div className="leftControls">
                      <Segment content={props.cLabels.class} className="seg1" />
                      <Segment
                        content={
                          <Dropdown
                            clearable
                            onTouchCancelCapture={() => alert("cancel")}
                            search
                            items={classNames.sort()}
                            placeholder={props.cLabels.class}
                            onChange={(e, { value }) => {
                              dropdownChangedHandler(value);
                            }}
                            noResultsMessage="We couldn't find any matches."
                          />
                        }
                        className="seg2"
                      />
                    </div>
                  ) : null}
                  {props.page === "whiteboard" && (
                    <>
                      <div className="leftControls">
                        <Segment
                          content=""
                          className="seg1"
                          style={{ padding: 0 }}
                        />
                        <Segment
                          content={
                            <Button
                              content={t("workspace")}
                              iconOnly
                              onClick={() => {
                                props?.setOpenWorkSpace();
                                localStorage.setItem(
                                  "msg",
                                  JSON.stringify({
                                    isOpenWorkSpace: false,
                                    isReloadingWorkspace: false,
                                  })
                                );
                              }}
                              style={{ padding: "0 5px" }}
                            />
                          }
                          className="seg2"
                        />
                      </div>
                      <div className="leftControls">
                        <Segment
                          content=""
                          className="seg1"
                          style={{ padding: 0 }}
                        />
                      </div>
                    </>
                  )}
                </Flex>

                <Flex gap="gap.medium" wrap>
                  {props.showNote ? (
                    <div className="leftControls tour-attendence-topic">
                      <Segment content="" className="seg1 btn-h" />
                      <Segment
                        content={
                          <Button
                            content={props.labels.topic[0]}
                            disabled={!props.showTopic ? true : false}
                            onClick={
                              props.showTopic
                                ? props.showTopic?.bind()
                                : () => {}
                            }
                            className="btn-sm-custom"
                          />
                        }
                        className="seg2"
                      />
                    </div>
                  ) : null}
                  {props.showNote ? (
                    <div className="leftControls tour-attendence-homework">
                      <Segment content="" className="seg1 btn-h" />
                      <Segment
                        content={
                          <Button
                            content={props.labels.homework[0]}
                            disabled={!props.showNote ? true : false}
                            onClick={
                              props.showNote ? props.showNote?.bind() : () => {}
                            }
                            className="btn-sm-custom"
                          />
                        }
                        className="seg2"
                      />
                    </div>
                  ) : null}
                </Flex>
                <div
                  className={`andy ${
                    props.showNote ? "tour-attendence-summary" : ""
                  }`}
                  style={{
                    marginBottom: "0px",
                    marginTop: props.state?.selectedLesson ? "6px" : "6px",
                  }}
                >
                  <Flex wrap space="between" vAlign="center">
                    <Flex gap="gap.medium" wrap className="ctnr">
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(70, 71, 117, 0.04)",
                              color: "#0d6efd",
                            }}
                          >
                            <Text
                              content={props.state.showRecordsAnalytics.virtual}
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.classType[2]}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey"
                          />
                        </Flex>
                      ) : null}
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(70, 71, 117, 0.04)",
                              color: "#0d6efd",
                            }}
                          >
                            <Text
                              content={props.state.showRecordsAnalytics.total}
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.total}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey"
                          />
                        </Flex>
                      ) : null}
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(218, 218, 227, 0.15)",
                              color: "#198754",
                            }}
                          >
                            <Text
                              content={props.state.showRecordsAnalytics.present}
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.present}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey"
                          />
                        </Flex>
                      ) : null}
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(211, 49, 58, 0.03)",
                              color: "#D3313A",
                            }}
                          >
                            <Text
                              content={props.state.showRecordsAnalytics.absent}
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.absent}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey"
                          />
                        </Flex>
                      ) : null}
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(187, 107, 217, 0.03)",
                              color: "#464775",
                            }}
                          >
                            <Text
                              content={props.state.showRecordsAnalytics.late}
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.late}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey"
                          />
                        </Flex>
                      ) : null}
                      {props.showNote ? (
                        <Flex gap="gap.smaller" className="an-gap">
                          <div
                            className="lbl"
                            style={{
                              marginRight: "4px",
                              backgroundColor: "rgba(23, 162, 184, 0.04)",
                              color: "#17a2b8",
                            }}
                          >
                            <Text
                              content={
                                props.state.showRecordsAnalytics.justification
                              }
                              size="large"
                              weight="semibold"
                            />
                          </div>
                          <Text
                            content={props.labels.justification}
                            style={{ marginTop: "5px" }}
                            className="Karla fs16 txt-grey justify-lbl"
                          />
                        </Flex>
                      ) : null}
                      {props.showExport ? (
                        <Flex
                          gap="gap.smaller"
                          className={`an-gap ${
                            props.state.showRecords.length
                              ? "tour-attendence-generate-report"
                              : ""
                          }`}
                          style={{ marginRight: "5px" }}
                        >
                          {props.state.showRecords.length ? (
                            <ExportClassData
                              dataset={props.state.showRecords}
                            />
                          ) : null}
                        </Flex>
                      ) : null}
                      {props.page === "attendance" ? (
                        <Flex>
                          <Tour
                            isClassSelected={
                              props.showNote || !!props.state?.selectedLesson
                            }
                            isLessonsAvailable={props.isLessonsAvailable}
                            page={props.page}
                          />
                        </Flex>
                      ) : null}
                      <Flex
                        gap="gap.smaller"
                        vAlign="center"
                        hAlign="center"
                        className="nextBtn"
                      >
                        <Button
                          icon={<ArrowRightCircle />}
                          iconOnly
                          primary
                          onClick={() => {
                            document
                              .querySelector(".Attendance.s-ctr")
                              .classList.add("h-ctr");
                            document
                              .querySelector(".Attendance.s-ctr")
                              .classList.remove("s-ctr");
                          }}
                        />
                      </Flex>
                      <Flex
                        gap="gap.smaller"
                        vAlign="center"
                        hAlign="center"
                        className="prevBtn"
                      >
                        <Button
                          icon={<ArrowLeftCircle />}
                          iconOnly
                          primary
                          onClick={() => {
                            document
                              .querySelector(".Attendance.h-ctr")
                              .classList.add("s-ctr");
                            document
                              .querySelector(".Attendance.h-ctr")
                              .classList.remove("h-ctr");
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              </Flex>
            </Flex>
          </div>
        )
      ) : null}
      {props.openWorkSpace && (
        <Portal containerId="my-workspace">
          <p style={{ display: "none" }}>Myworkspace</p>
        </Portal>
      )}
    </>
  );
};

export default Controls;
