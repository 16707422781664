import React from "react";
import { useTutorRequest } from "../../../../context/TutorRequestContext";
import { Button, Flex, Text, Card, Skeleton } from "@fluentui/react-northstar";
import ConfirmModel from "./components/ConfirmModel/ConfirmModel";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";

const TutorRequest = () => {
  const {
    createTutorRequest,
    isLoading,
    isRequested,
    userProfileData,
    setIsOpenConfirmationModal,
    setTutorRequestToUpdate,
    pendingTutorRequest,
  } = useTutorRequest();

  const history = useHistory();
  const { t } = useTranslation();
  const translation = t("booking").setting.tutorRequest;

  const isProfileComplete =
    userProfileData?.ProfileSkills?.length > 0 &&
    userProfileData?.ProfileInterests?.length > 0;

  const pointsToShow = isRequested
    ? translation.pointsAfterRequest
    : translation.pointsBeforeRequest;
  return (
    <div>
      <h1 style={{ color: "#6264a7", fontWeight: "bold" }}>
        {translation.title}
      </h1>
      <Flex column gap="gap.small" style={{ width: "fit-content" }}>
        <div>
          <Text content={translation.subtitle} size="large" weight="bold" />
        </div>
        <div>
          <ul>
            {pointsToShow.map((point, index) => (
              <li key={index}>
                <Text content={point} size="medium" />
              </li>
            ))}
          </ul>
          {!isRequested && !isLoading && (
            <div>
              <Text
                error={!isProfileComplete}
                success={isProfileComplete}
                content={
                  isProfileComplete
                    ? translation.profileReadyMsg
                    : translation.profileWarningMsg
                }
                size="medium"
              />
            </div>
          )}
        </div>

        <Flex>
          {isLoading ? (
            <div>
              <Skeleton animation="wave">
                <Skeleton.Shape width="130px" height="31px" />
              </Skeleton>
            </div>
          ) : isRequested ? (
            <Button
              content={translation.cancelRequest}
              onClick={() => {
                setIsOpenConfirmationModal(true);
                setTutorRequestToUpdate({
                  id: pendingTutorRequest.id,
                  status: "cancelled",
                });
              }}
            />
          ) : !isProfileComplete ? (
            <Button
              content={translation.completeProfile}
              onClick={() => {
                history.push("/account");
              }}
            />
          ) : (
            <Button
              content={translation.request}
              onClick={createTutorRequest}
              loading={isLoading}
            />
          )}
        </Flex>
      </Flex>
      <ConfirmModel />
    </div>
  );
};

export default TutorRequest;
