import { IconButton } from "@fluentui/react";
import { CloseIcon, TrashCanIcon } from "@fluentui/react-icons-northstar";
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Image,
  Label,
  Table,
  Text,
  ExcelIcon,
} from "@fluentui/react-northstar";
import { Tooltip } from "antd";

import React from "react";
import { withTranslation } from "react-i18next";
import useBundles, { BUNDLE_STATUS } from "./useBundles";

import { Layout } from "antd";
import moment from "moment";
import NoEventFoundImage from "../../../Assets/images/svg/NoAssignmentFound.svg";
import { EventsNavBar } from "../../../components/EventsNavBar";
import Header from "../../../components/Header/Header";
import CustomProgressIndicator from "../../../components/Loader/CustomProgressIndicator";
import EventFilterModal from "../../../components/widgets/EventFilterModal";
import EventFilters, {
  EventDateFilterTypes,
} from "../../../components/widgets/EventFilters";

import "./Bundles.scss";

const Bundles = (props) => {
  const { t } = props;
  const {
    selectedBundles,
    setSelectedBundles,
    allBundles,
    filteredBundles,
    setFilteredBundles,
    //
    filterModalProps,
    isTabletView,
    isMobileView,
    isLoading,
    isUpdating,
    deleteConfirmation,
    setDeleteConfirmation,

    getEventImportTypeIcons,
    editBundleHandler,
    deleteBundleHandler,
    deleteMultipleBundlesHandler,
    isDeleteBundleEvents,
    setIsDeleteBundleEvents,
    exportBundleEventsToExcel,
    isExportingExcel,
  } = useBundles(props);

  const { handlePortal, selectedDateFilter, setSelectedDateFilter } =
    filterModalProps;

  const translation = t("events");
  const commonTranslation = translation.common;
  const eventsTranslation = translation.body.bundles;

  const header = {
    key: "header",
    items: [
      {
        content: (
          <Flex
            wrap
            vAlign="center"
            // className="multi-slc"
          >
            <Checkbox
              checked={
                !!filteredBundles.length &&
                filteredBundles.every((event) =>
                  selectedBundles.some((data) => data.id === event.id)
                )
              }
              onClick={(e, value) => {
                if (value.checked) {
                  setSelectedBundles([
                    ...filteredBundles.map((event) => event),
                  ]);
                } else {
                  setSelectedBundles([]);
                }
              }}
            />

            <Label
              style={{ color: "#252423", marginRight: "5px" }}
              iconPosition="start"
              content={
                <Text
                  content={eventsTranslation.headers.title}
                  size="medium"
                  weight="semibold"
                  className="stud-name-lable"
                />
              }
              color="white"
            />
          </Flex>
        ),
        key: "title",
        style: {
          flexGrow: 2,
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={eventsTranslation.headers.dateCreated}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "dateCreated",
        style: {
          justifyContent: "center",
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={eventsTranslation.headers.bundleType}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "bundleType",
        style: {
          textAlign: "center",
          justifyContent: "center",
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={eventsTranslation.headers.status}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "status",
        style: {
          justifyContent: "center",
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={eventsTranslation.headers.totalEvents}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "totalResponse",
        style: {
          textAlign: "center",
          justifyContent: "center",
        },
      },
      {
        content: (
          <Flex gap="gap.small" hAlign="center" vAlign="center">
            <Label
              style={{ color: "#252423" }}
              iconPosition="start"
              content={
                <Text
                  content={eventsTranslation.headers.actions}
                  size="medium"
                  weight="semibold"
                />
              }
              color="white"
              className="action-notes"
              styles={{ marginTop: "2px" }}
            />
          </Flex>
        ),
        key: "edit",
        style: {
          justifyContent: "center",
        },
      },
    ],
  };

  const rows = filteredBundles?.length
    ? filteredBundles.map((bundle) => {
        return {
          key: `${bundle.id}`,
          items: [
            {
              content: (
                <Flex
                  hAlign="center"
                  vAlign="center"
                  style={{ justifyContent: "flex-start" }}
                >
                  <Checkbox
                    label={{
                      truncated: true,
                      content: (
                        <Tooltip className="bundle-title" title={bundle.title}>
                          <Text content={bundle.title} />
                        </Tooltip>
                      ),
                    }}
                    checked={selectedBundles.some(
                      (data) => data.id === bundle.id
                    )}
                    onClick={() => {
                      setSelectedBundles((prevData) => {
                        return prevData.some((data) => data.id === bundle.id)
                          ? [
                              ...prevData.filter(
                                (data) => data.id !== bundle.id
                              ),
                            ]
                          : [...prevData, bundle];
                      });
                    }}
                  />
                </Flex>
              ),
              key: "title",
              styles: {
                height: "min-content",
                flexGrow: 2,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>
                    {moment(bundle.dateCreated).isValid()
                      ? moment(bundle.dateCreated).format("MMM DD, yyyy")
                      : translation.common.notApplicable}
                  </Text>
                </Flex>
              ),
              key: "dateCreated",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex hAlign="center">
                  <Flex
                    hAlign="center"
                    vAlign="center"
                    title={getEventImportTypeIcons(bundle.type).title}
                  >
                    {getEventImportTypeIcons(bundle.type).icon}
                  </Flex>
                </Flex>
              ),
              key: "bundleType",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex hAlign="center" style={{ height: "50px" }}>
                  <Flex
                    style={{
                      minWidth: "100px",
                      height: "25px",
                      padding: "3px 0",
                      textAlign: "center",
                      borderRadius: " 13px",
                      color: "white",
                      alignSelf: "center",
                      backgroundColor:
                        bundle.status === BUNDLE_STATUS.published
                          ? "#1db9c3"
                          : "#ffb900",
                    }}
                    hAlign="center"
                    vAlign="center"
                  >
                    {bundle.status === BUNDLE_STATUS.published
                      ? commonTranslation.status.published
                      : commonTranslation.status.draft}
                  </Flex>
                </Flex>
              ),
              key: "status",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.small"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>{bundle.totalEvents}</Text>
                </Flex>
              ),
              key: "totalResponse",
              styles: {
                textAlign: "center",
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.small"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <IconButton
                    iconProps={{
                      iconName: "Edit",
                      style: { fontSize: "15px", color: "black" },
                    }}
                    title={commonTranslation.status.edit}
                    // ariaLabel={feedbackTranslation.edit}
                    ariaLabel={commonTranslation.status.edit}
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      editBundleHandler(bundle);
                    }}
                  />

                  <Button
                    loading={
                      deleteConfirmation.isDeleting &&
                      deleteConfirmation.data?.id === bundle.id
                    }
                    title={commonTranslation.status.delete}
                    onClick={() => {
                      setDeleteConfirmation({
                        data: bundle,
                        isDeleting: false,
                        isOpen: true,
                      });
                    }}
                    disabled={deleteConfirmation.isDeleting}
                    icon={<TrashCanIcon outline />}
                    text
                    style={{ width: "30px", minWidth: "20px" }}
                  />

                  <Button
                    onClick={() => {
                      exportBundleEventsToExcel(bundle);
                    }}
                    icon={<ExcelIcon outline />}
                    text
                    style={{ width: "30px", minWidth: "20px" }}
                    loading={isExportingExcel}
                  />
                </Flex>
              ),
              key: "edit",
              style: {
                justifyContent: "center",
              },
            },
          ],
        };
      })
    : [];

  return (
    <>
      <Dialog
        open={deleteConfirmation.isOpen}
        onConfirm={() => {
          // If there are selected bundles for deletion, call the delete multiple bundles API. If a single bundle is selected for deletion, call the delete single bundle API.

          deleteConfirmation?.selectedBundlesIds?.length
            ? deleteMultipleBundlesHandler(
                deleteConfirmation.selectedBundlesIds
              )
            : deleteBundleHandler(deleteConfirmation.data);
        }}
        onCancel={() => {
          setDeleteConfirmation({
            isOpen: false,
            data: null,
            selectedBundlesIds: [],
            isDeleting: false,
          });
        }}
        header={eventsTranslation.deleteEventBundle}
        headerAction={{
          icon: <CloseIcon />,
          title: translation.common.close,
          onClick: () => {
            setDeleteConfirmation({
              isOpen: false,
              data: null,
              selectedBundlesIds: [],
              isDeleting: false,
            });
          },
        }}
        content={
          <Flex column>
            <Text>
              {deleteConfirmation?.selectedBundlesIds?.length
                ? eventsTranslation.deleteBundlesMessage ||
                  "Are you sure you want to delete all selected Event Bundles?"
                : eventsTranslation.deleteBundleMessage ||
                  "Are you sure you want to delete Event Bundle?"}
            </Text>
            <Checkbox
              styles={{ alignItems: "center", paddingTop: "15px" }}
              label={
                "Would you like to delete the events associated with this bundle?"
              }
              toggle
              checked={isDeleteBundleEvents}
              onChange={(e, { checked }) => setIsDeleteBundleEvents(checked)}
            />
          </Flex>
        }
        confirmButton={translation.common.delete}
        cancelButton={translation.common.cancel}
        styles={{ maxWidth: "600px" }}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />
      {isMobileView ? (
        <div className="Attendance Whiteboard s-ctr bundle-events-wrapper bundle-wrapper">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="myevents-event-wrapper">
            <EventsNavBar {...props} isMobileView={isMobileView} />

            <Layout
              className="tour-my-event"
              style={{
                height: "fit-content",
              }}
            >
              <CustomProgressIndicator
                isLoading={
                  isUpdating || isLoading || deleteConfirmation.isDeleting
                }
              />
              <Flex
                className="event-title"
                style={{
                  justifyContent: "center",
                  marginTop: "25px",
                  width: isMobileView && "100%",
                }}
              >
                {eventsTranslation.eventBundles}
              </Flex>
              <div className="mobile-wrapper">
                <div>
                  <Flex hAlign="end">
                    {selectedBundles.length ? (
                      <Button
                        loading={deleteConfirmation.isDeleting}
                        title={
                          eventsTranslation.deleteSelected || "Delete Selected"
                        }
                        onClick={() => {
                          const selectedBundlesIds = selectedBundles.map(
                            (selectedBundle) => selectedBundle.id
                          );
                          setDeleteConfirmation({
                            isOpen: true,
                            isDeleting: true,
                            selectedBundlesIds,
                          });
                        }}
                        disabled={deleteConfirmation.isDeleting}
                        icon={<TrashCanIcon outline />}
                        text
                        style={{ minWidth: "20px" }}
                        content={
                          eventsTranslation.deleteSelected || "Delete Selected"
                        }
                      />
                    ) : null}
                  </Flex>
                  <div>
                    {rows?.length ? (
                      <div className="bundle-table-wrapper">
                        <Table
                          header={header}
                          className="bundle-events-table-headers"
                        />
                        <Table
                          rows={rows}
                          className="bundle-events-table-data"
                        />
                      </div>
                    ) : (
                      !isLoading && (
                        <div
                          className="wrapper"
                          style={{ textAlign: "center", marginTop: 82 }}
                        >
                          <Image
                            style={{ maxWidth: "280px", width: "100%" }}
                            src={NoEventFoundImage}
                          />
                          <h3 style={{ marginTop: 20 }}>
                            {allBundles?.length ||
                            selectedDateFilter !== EventDateFilterTypes.all
                              ? eventsTranslation.noFilteredBundleMessage
                              : eventsTranslation.noBundleMessage}
                          </h3>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="Attendance Whiteboard s-ctr bundle-events-wrapper bundle-wrapper">
          {/* {!isExpire && isSubscribed && ( */}
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          {/* )} */}
          <Layout className="myevents-event-wrapper">
            <EventsNavBar {...props} />

            <Layout className="tour-my-event">
              <CustomProgressIndicator
                isLoading={
                  isUpdating || isLoading || deleteConfirmation.isDeleting
                }
              />

              <div className="wrapper">
                <Flex
                  className="event-title"
                  style={{
                    justifyContent: "space-between",
                    width: isMobileView && "100%",
                    minHeight: "32px",
                  }}
                >
                  {eventsTranslation.eventBundles}

                  {selectedBundles.length ? (
                    <Button
                      loading={deleteConfirmation.isDeleting}
                      title={
                        eventsTranslation.deleteSelected || "Delete Selected"
                      }
                      onClick={() => {
                        const selectedBundlesIds = selectedBundles.map(
                          (selectedBundle) => selectedBundle.id
                        );
                        setDeleteConfirmation({
                          isOpen: true,
                          isDeleting: true,
                          selectedBundlesIds,
                        });
                      }}
                      disabled={deleteConfirmation.isDeleting}
                      icon={<TrashCanIcon outline />}
                      text
                      style={{ minWidth: "20px" }}
                      content={
                        eventsTranslation.deleteSelected || "Delete Selected"
                      }
                    />
                  ) : null}
                </Flex>
                <div>
                  {rows.length ? (
                    <div className="bundle-table-wrapper">
                      <Table
                        header={header}
                        className="bundle-events-table-headers"
                      />
                      <Table rows={rows} className="bundle-events-table-data" />
                    </div>
                  ) : (
                    !isLoading && (
                      <div
                        className="wrapper"
                        style={{ textAlign: "center", marginTop: 82 }}
                      >
                        <Image
                          style={{ maxWidth: "280px", width: "100%" }}
                          src={NoEventFoundImage}
                        />
                        <h3 style={{ marginTop: 20 }}>
                          {allBundles?.length ||
                          selectedDateFilter !== EventDateFilterTypes.all
                            ? eventsTranslation.noFilteredBundleMessage
                            : eventsTranslation.noBundleMessage}
                        </h3>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};

export default withTranslation()(Bundles);
