import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Flex,
  Label,
  Table,
  Text,
  CardHeader,
  CardBody,
  Card,
  Loader,
  Dialog,
  Divider,
} from "@fluentui/react-northstar";

import {
  CallVideoIcon,
  ItalicIcon,
  PollIcon,
  TrashCanIcon,
  VolumeIcon,
  CloseIcon,
  ExcelIcon,
} from "@fluentui/react-icons-northstar";

import moment from "moment";

import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Avatar, Col, Row, Tooltip, message } from "antd";
import { withTranslation } from "react-i18next";

import { Icon, IconButton, initializeIcons } from "@fluentui/react";
import { ResponsiveImage } from "../../../../components/blocks/Images";
import noFeedbackImage from "../../../../Assets/images/conversation.png";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";
import ShareFeedback from "../Components/ShareFeedback";

import "react-toastify/dist/ReactToastify.css";
import "./AllFeedbackPage.scss";
import ReactDOM from "react-dom";
import Portal from "../../../../helper/Portal";
import * as XLSX from "xlsx";

initializeIcons();

const all = "all";
const text = "TEXT";
const audio = "AUDIO";
const video = "VIDEO";
const poll = "POLL";
const any = "ANY";
const mix = "mix";

const gridView = "gridView";
const tableView = "tableView";

const progress = "Progress";
const completed = "Completed";

const DateFilterTypes = {
  today: "today",
  last3Days: "last3Days",
  last7Days: "last7Days",
  last15Days: "last15Days",
  last30Days: "last30Days",
  last90Days: "last90Days",
  all: "all",
};

const url = process.env.REACT_APP_EP_URL;

const AllFeedback = (props) => {
  const { user, t, globalSearch, isMobileView, isCompactMode, isTabletView } =
    props;
  const history = useHistory();

  const [selectedType, setSelectedType] = useState(all);
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    DateFilterTypes.today
  );
  const [selectedView, setSelectedView] = useState(tableView);
  const [selectedFeedbacks, setSelectedFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allFeedbackData, setAllFeedbackData] = useState([]);
  const [deleteFeedback, setDeleteFeedback] = useState({
    isLoading: false,
    id: null,
    isOpen: false,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isCopyLinkLoading, setIsCopyLinkLoading] = useState({
    id: null,
    isLoading: false,
  });

  const translation = t("feedbackModule");

  const feedbackStatusTranslation = translation.body.feedbackStatus;
  const feedbackFilterTranslation = translation.body.feedbackFilter;

  const feedbackTranslation = translation.body.allFeedback;

  const getAllFeedbacks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/feedback${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        let feedbackData = data.result;

        let formattedData = feedbackData.map((feedback) => {
          const {
            feedbackRequestId,
            feedbackType,
            title,
            userID,
            requestedDate,
            endDate,
            isStopAcceptingResponse,
            everyoneInOrganization,
            status,
            videoQuestionUrl,
            groupId,
            classId,
            pollOptions,
            feedbackRequestUser,
            FeedbackSubmissions,
          } = feedback;

          return {
            ...feedback,
            id: feedbackRequestId,
            title,
            dateCreated: requestedDate,
            feedbackEndsOn: endDate,
            feedbackType: feedbackType,
            status,
            pollOptions:
              Array.isArray(pollOptions) && pollOptions.length
                ? pollOptions
                : [],
            isEveryoneInTheOrganization: everyoneInOrganization,

            response:
              Array.isArray(FeedbackSubmissions) && FeedbackSubmissions.length
                ? FeedbackSubmissions
                : [],

            videoUrl: videoQuestionUrl,
            stopAcceptingResponse: isStopAcceptingResponse,
            createdBy: feedbackRequestUser.email ?? "",
          };
        });
        setAllFeedbackData(formattedData);
      } else {
        setAllFeedbackData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFeedbackResponse = async (feedbackId) => {
    try {
      setIsLoading(true);
      const { data } = await axios({
        url: `${url}/api/feedback/${feedbackId}${user.slug}&isExporting=true`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (data.status.toLowerCase() === "success" && data?.result?.length) {
        const feedback = data.result[0];

        const {
          feedbackType,
          title,
          FeedbackSubmissions,
          description,
          FeedbackQuestions,
        } = feedback;

        const questionWithResponse = FeedbackSubmissions.length
          ? FeedbackSubmissions.map((submission) => {
              const userWiseResponses = submission.FeedbackQueSubmissions.map(
                (queSubmission) => {
                  const question = FeedbackQuestions.find(
                    (question) =>
                      queSubmission.questionId === question.feedbackQuestionId
                  );
                  return {
                    question: question.questionTitle || "N/A",
                    type: question.questionType || "N/A",
                    userName:
                      submission.users.firstName + submission.users.lastName,
                    userEmail: submission.users.email || "N/A",
                    response: queSubmission?.textResponse || "N/A",
                    submissionDate: submission.submitDate || "N/A",
                  };
                }
              );
              return userWiseResponses;
            })
          : [];

        const feedbackQuestionAndResponses = {
          title,
          description,
          type: feedbackType,
          responses: questionWithResponse,
        };

        return feedbackQuestionAndResponses;
      } else if (data.status.toLowerCase() === "error") {
        if (data?.showPopup) {
          props.setFeedbackLimitDialog({
            show: true,
            message: data.message || "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToExcel = async (jsonData) => {
    const feedBackResponseData = await getFeedbackResponse(jsonData.id);

    if (!feedBackResponseData) {
      return;
    }
    let rows = [];
    if (feedBackResponseData?.responses?.length) {
      feedBackResponseData.responses.map((response) => {
        response.map((item, i) => {
          if (i === 0) {
            const row = [
              feedBackResponseData.title,
              feedBackResponseData.description,
              feedBackResponseData.type,
              item.question,
              item.type,
              item.userName,
              item.userEmail,
              item.submissionDate,
              item.response,
            ];
            rows.push(row);
          } else {
            const row = [
              "",
              "",
              "",
              item.question,
              item.type,
              item.userName,
              item.userEmail,
              item.submissionDate,
              item.response,
            ];
            rows.push(row);
          }
        });
      });
    } else {
      const row = [
        feedBackResponseData.title,
        feedBackResponseData.description,
        feedBackResponseData.type,
        "N/A",
        "N/A",
        "N/A",
        "N/A",
        "N/A",
        "N/A",
      ];
      rows.push(row);
    }

    const data = [
      [
        "FeedbackTitle",
        "Description",
        "FeedbackType",
        "Items",
        "QuestionType",
        "Name",
        "Email",
        "SubmissionDate",
        "Response",
      ],
      ...rows,
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Feedback");

    const fileName = `${feedBackResponseData.title}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    getAllFeedbacks();
  }, [refreshData]);

  useEffect(() => {
    let feedbacks = [];

    switch (selectedType) {
      case all:
        feedbacks = allFeedbackData;
        break;
      case mix:
        feedbacks = allFeedbackData.filter(
          (feedback) => feedback.feedbackType === any && feedback.isMixMode
        );
        break;
      default:
        feedbacks = allFeedbackData.filter(
          (feedback) =>
            feedback.feedbackType === selectedType ||
            (feedback.feedbackType === any &&
              !feedback.isMixMode &&
              selectedType !== poll)
        );
    }

    if (globalSearch) {
      feedbacks = feedbacks.filter((feedback) =>
        feedback.title
          ?.replace(/\s/g, "")
          ?.toLowerCase()
          ?.includes(globalSearch)
      );
    }
    feedbacks = filterByDateHandler(feedbacks);
    setFilteredFeedbacks(feedbacks);
    setSelectedFeedbacks((prev) =>
      allFeedbackData.filter((data) => prev.some((item) => item.id === data.id))
    );
  }, [selectedType, allFeedbackData, globalSearch, selectedDateFilter]);

  const getFeedbackTypeIcons = (feedback) => {
    switch (feedback.feedbackType) {
      case "ANY":
        return feedback.isMixMode ? (
          <Text className="mixed-mode-icon">M</Text>
        ) : (
          <Icon iconName="PieSingle" />
        );
      case "TEXT":
        return <ItalicIcon outline />;
      case "AUDIO":
        return <VolumeIcon outline />;
      case "VIDEO":
        return <CallVideoIcon outline />;
      case "POLL":
        return <PollIcon outline />;
      default:
        return <Icon iconName="PieSingle" />;
    }
  };

  const deleteFeedbackHandler = async (id) => {
    try {
      setDeleteFeedback({ isLoading: true, id, isOpen: false });
      const { data } = await axios({
        url: `${url}/api/feedback/${id}${user.slug}&role=${user.actualRole}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.status?.toLowerCase() === "success") {
        setRefreshData((prev) => !prev);
        toast.success(
          typeof data.result === "string"
            ? data.result
            : feedbackTranslation.deletedSuccessFully
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteFeedback({ isLoading: false, id: null, isOpen: false });
    }
  };

  const bulkStopRecievingResponseHandler = async (e, { checked }) => {
    setSelectedFeedbacks((prev) =>
      prev.map((feedbackItem) =>
        selectedFeedbacks.some((data) => data.id === feedbackItem.id)
          ? { ...feedbackItem, stopAcceptingResponse: checked }
          : feedbackItem
      )
    );
    setIsUpdating(true);
    try {
      const { data } = await axios({
        url: `${url}/api/feedback/bulk-stop-accepting-response${user.slug}`,
        method: "PUT",
        data: {
          isStopAcceptingResponse: checked,
          feedbacks: selectedFeedbacks.map((data) => data.id),
        },
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.status?.toLowerCase() === "success") {
        NotificationAlert(
          data.message === "Response accepting status is updated"
            ? feedbackTranslation.feedbackStatusUpdated
            : data.message,
          "success"
        );
        setRefreshData((prev) => !prev);
      } else if (data?.status?.toLowerCase() === "error") {
        NotificationAlert(data.message, "error");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const editFeedbackHandler = (feedback) => {
    let path = "text-feedback";

    switch (feedback.feedbackType) {
      case "ANY":
        path = "any-feedback";
        break;
      case "TEXT":
        path = "text-feedback";
        break;
      case "AUDIO":
        path = "audio-feedback";
        break;
      case "VIDEO":
        path = "video-feedback";
        break;
      case "POLL":
        path = "poll-feedback";
        break;
      default:
        path = "text-feedback";
    }
    history.push(`/feedback/${path}?feedback=${feedback.id}`, {
      feedback: feedback,
    });
  };

  const header = {
    key: "header",
    items: [
      {
        content: (
          <Flex
            wrap
            vAlign="center"
            // className="multi-slc"
          >
            <Checkbox
              checked={
                !!filteredFeedbacks.length &&
                filteredFeedbacks.every((feedback) =>
                  selectedFeedbacks.some((data) => data.id === feedback.id)
                )
              }
              onClick={(e, value) => {
                if (value.checked) {
                  setSelectedFeedbacks([
                    ...filteredFeedbacks.map((feedback) => feedback),
                  ]);
                } else {
                  setSelectedFeedbacks([]);
                }
              }}
            />

            <Label
              style={{ color: "#252423", marginRight: "5px" }}
              iconPosition="start"
              content={
                <Text
                  content={feedbackTranslation.title}
                  size="medium"
                  weight="semibold"
                  className="stud-name-lable"
                />
              }
              color="white"
            />
            <Checkbox
              styles={{ alignItems: "center" }}
              label={feedbackTranslation.stopAcceptingResponse}
              toggle
              checked={
                !!selectedFeedbacks.length &&
                selectedFeedbacks.every(
                  (feedback) => feedback.stopAcceptingResponse
                )
              }
              onChange={bulkStopRecievingResponseHandler}
              disabled={
                isLoading ||
                isUpdating ||
                deleteFeedback.isLoading ||
                !selectedFeedbacks.length
              }
            />
          </Flex>
        ),
        key: "title",
        style: {
          flexGrow: 2,
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={feedbackTranslation.dateCreated}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "dateCreated",
        style: {
          justifyContent: "center",
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                content={feedbackTranslation.feedbackType}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "feedbackType",
        style: {
          textAlign: "center",
          justifyContent: "center",
        },
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                // align="center"
                content={feedbackTranslation.status}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "status",
        style: {
          justifyContent: "center",
        },
      },
      {
        content: (
          // <Flex>
          <Label
            style={{ color: "#252423" }}
            iconPosition="start"
            icon={<Icon iconName="BarChartVertical" />}
            content={
              <Text
                content={feedbackTranslation.totalResponse}
                size="medium"
                weight="semibold"
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
          // </Flex>
        ),
        key: "totalResponse",
        style: {
          textAlign: "center",
          justifyContent: "center",
        },
      },
      {
        content: (
          <Flex gap="gap.small" hAlign="center" vAlign="center">
            <Label
              style={{ color: "#252423" }}
              iconPosition="start"
              // icon={<ShareAltIcon outline />}
              content={
                <Text
                  content={feedbackTranslation.actions}
                  size="medium"
                  weight="semibold"
                />
              }
              color="white"
              className="action-notes"
              styles={{ marginTop: "2px" }}
            />
          </Flex>
        ),
        key: "edit",
        style: {
          justifyContent: "center",
        },
      },
    ],
  };

  const rows = filteredFeedbacks?.length
    ? filteredFeedbacks.map((feedback) => {
        return {
          key: `${feedback.id}`,
          items: [
            {
              content: (
                <Flex
                  hAlign="center"
                  vAlign="center"
                  style={{ justifyContent: "flex-start" }}
                >
                  <Checkbox
                    label={{
                      truncated: true,
                      content: (
                        <Tooltip
                          className="feedback-title"
                          title={feedback.title}
                        >
                          <Text content={feedback.title} />
                        </Tooltip>
                      ),
                    }}
                    checked={selectedFeedbacks.some(
                      (data) => data.id === feedback.id
                    )}
                    onClick={() => {
                      setSelectedFeedbacks((prevData) => {
                        return prevData.some((data) => data.id === feedback.id)
                          ? [
                              ...prevData.filter(
                                (data) => data.id !== feedback.id
                              ),
                            ]
                          : [...prevData, feedback];
                      });
                    }}
                  />
                </Flex>
              ),
              key: "title",
              styles: {
                height: "min-content",
                flexGrow: 2,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>
                    {moment(feedback.dateCreated).isValid()
                      ? moment(feedback.dateCreated).format("MMM DD, yyyy")
                      : translation.common.notApplicable}
                  </Text>
                </Flex>
              ),
              key: "dateCreated",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex hAlign="center">
                  <Flex
                    style={{
                      minWidth: "30px",
                      minHeight: "30px",
                      borderRadius: "50%",
                      backgroundColor: " #494b83",
                      outline: "3px solid white",
                      color: "white",
                    }}
                    hAlign="center"
                    vAlign="center"
                  >
                    {getFeedbackTypeIcons(feedback)}
                  </Flex>
                </Flex>
              ),
              key: "feedbackType",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex hAlign="center" style={{ height: "50px" }}>
                  <Flex
                    style={{
                      minWidth: "100px",
                      height: "25px",
                      padding: "3px 0",
                      textAlign: "center",
                      borderRadius: " 13px",
                      color: "white",
                      alignSelf: "center",
                      backgroundColor:
                        feedback.status === completed ? "#1db9c3" : "#ffb900",
                    }}
                    hAlign="center"
                    vAlign="center"
                  >
                    {feedback.status === completed
                      ? feedbackStatusTranslation.completed
                      : feedbackStatusTranslation.progress}
                  </Flex>
                </Flex>
              ),
              key: "status",
              style: {
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.small"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>
                    {!!feedback.totalSubmissions
                      ? `${feedback.response.length} / ${feedback.totalSubmissions}`
                      : 0}
                  </Text>
                </Flex>
              ),
              key: "totalResponse",
              styles: {
                textAlign: "center",
                justifyContent: "center",
              },
            },
            {
              content: (
                <Flex
                  gap="gap.small"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <IconButton
                    iconProps={{
                      iconName: "BarChart4",
                      style: { fontSize: "15px", color: "black" },
                    }}
                    title={feedbackTranslation.response}
                    ariaLabel={feedbackTranslation.response}
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      history.push(`/feedback/${feedback.id}/view-response`, {
                        feedback: feedback,
                      });
                    }}
                  />
                  <IconButton
                    iconProps={{
                      iconName: "Edit",
                      style: { fontSize: "15px", color: "black" },
                    }}
                    title={feedbackTranslation.edit}
                    ariaLabel={feedbackTranslation.edit}
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      editFeedbackHandler(feedback);
                      // history.push(
                      //   `/feedback/text-feedback?feedback=${feedback.id}`,
                      //   {
                      //     feedback: feedback,
                      //   }
                      // );
                    }}
                  />

                  <Button
                    loading={
                      deleteFeedback.isLoading &&
                      deleteFeedback.id === feedback.id
                    }
                    onClick={() => {
                      setDeleteFeedback({
                        id: feedback.id,
                        isLoading: false,
                        isOpen: true,
                      });
                      // deleteFeedbackHandler(feedback.id);
                    }}
                    disabled={deleteFeedback.isLoading}
                    icon={<TrashCanIcon outline />}
                    text
                    style={{ width: "30px", minWidth: "20px" }}
                  />
                  <Button
                    onClick={() => {
                      exportToExcel(feedback);
                      // deleteFeedbackHandler(feedback.id);
                    }}
                    icon={<ExcelIcon outline />}
                    text
                    style={{ width: "30px", minWidth: "20px" }}
                  />
                  <ShareFeedback
                    isCopyLinkLoading={isCopyLinkLoading}
                    setIsCopyLinkLoading={setIsCopyLinkLoading}
                    feedback={feedback}
                    user={user}
                  />
                </Flex>
              ),
              key: "edit",
              style: {
                justifyContent: "center",
              },
            },
          ],
        };
      })
    : null;

  const feedbackCard = ({ feedback }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        onClick={() => {
          history.push(`/feedback/${feedback.id}/view-response`, {
            feedback: feedback,
          });
        }}
      >
        <CardHeader
          className="all-feedback-card-header"
          style={{ borderRadius: "10px 10px 0 0" }}
        >
          <Flex gap="gap.small" column>
            <Flex.Item styles={{ padding: "10px" }}>
              <Text size="medium" weight="bold" color="white">
                {feedback.title}
              </Text>
            </Flex.Item>
            <Flex className="feedback-status-type-container" space="between">
              <Flex
                className="feedback-feedback-type"
                hAlign="center"
                vAlign="center"
                style={{ minWidth: "30px", minHeight: "30px" }}
              >
                {getFeedbackTypeIcons(feedback)}
              </Flex>
              <Flex
                className={`feedback-status ${
                  feedback.status === completed ? "completed" : "progress"
                } `}
                hAlign="center"
                vAlign="center"
              >
                <Text>
                  {feedback.status === completed
                    ? feedbackStatusTranslation.completed
                    : feedbackStatusTranslation.progress}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody className="all-feedback-card-body">
          <Flex
            space="between"
            vAlign="center"
            className="responses-date-section"
          >
            <Flex>
              {feedback.response?.length ? (
                <Avatar.Group
                  maxPopoverTrigger="hover"
                  maxCount={2}
                  size="default"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer",
                  }}
                >
                  {feedback.response.map((user, index) => (
                    <Avatar
                      key={index}
                      src={user.imageUrl}
                      alt={feedbackTranslation.userProfileImage}
                      style={{ fontSize: "16px" }}
                    >
                      <Tooltip title={user.name}>
                        <Text>
                          {user.name?.length
                            ? user.name[0]
                            : user.studentEmailId?.[0]}
                        </Text>
                      </Tooltip>
                    </Avatar>
                  ))}
                </Avatar.Group>
              ) : (
                <Text weight="semibold">
                  {feedbackTranslation.noResponseYet}
                </Text>
              )}
            </Flex>
            <Text align="start">
              {moment(feedback.dateCreated).isValid
                ? moment(feedback.dateCreated).format("MMM DD,yyyy")
                : translation.common.notApplicable}
            </Text>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const onFilterBtnClickHandler = (filter) => {
    setSelectedDateFilter(filter);
  };

  const dateFilters = [
    {
      content: feedbackTranslation.filter.today,
      type: DateFilterTypes.today,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment().isSame(moment(feedbackData.dateCreated), "date");
        }),
    },
    {
      content: feedbackTranslation.filter.last3Days,
      type: DateFilterTypes.last3Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(3, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last7Days,
      type: DateFilterTypes.last7Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(7, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last15Days,
      type: DateFilterTypes.last15Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(15, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last30Days,
      type: DateFilterTypes.last30Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(30, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last90Days,
      type: DateFilterTypes.last90Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(90, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.all,
      type: DateFilterTypes.all,
      filterHandler: (filteredData) => filteredData,
    },
  ];

  const filterByDateHandler = (feedbacks) => {
    let filteredData = Array.isArray(feedbacks) ? feedbacks : [];

    if (selectedDateFilter) {
      const filterHandler = dateFilters.find(
        (filterObj) => filterObj.type === selectedDateFilter
      );

      if (filterHandler) {
        filteredData = filterHandler.filterHandler(filteredData);
      }
    }

    return filteredData;
  };

  return (
    <>
      <CustomProgressIndicator
        isLoading={isUpdating || (allFeedbackData.length && isLoading)}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />
      <Dialog
        open={deleteFeedback.isOpen}
        onConfirm={() => {
          deleteFeedbackHandler(deleteFeedback.id);
        }}
        onCancel={() => {
          setDeleteFeedback({
            id: null,
            isLoading: false,
            isOpen: false,
          });
        }}
        header={feedbackTranslation.deleteFeedback}
        headerAction={{
          icon: <CloseIcon />,
          title: translation.common.close,
          onClick: () => {
            setDeleteFeedback({
              id: null,
              isLoading: false,
              isOpen: false,
            });
          },
        }}
        content={
          <Flex column>
            <Text>{feedbackTranslation.deleteConfirmation}</Text>
          </Flex>
        }
        confirmButton={translation.common.yes}
        cancelButton={translation.common.no}
        styles={{ maxWidth: "600px" }}
      />
      <div className="all-feedback-container">
        <div
          className="all-feedback"
          style={{ paddingTop: isMobileView && "0px" }}
        >
          <div className="feedback-table-card-wrapper">
            <div>
              <div className="all-feedback-header">
                {isMobileView ? (
                  <>
                    <Flex space="between" style={{ overflow: "auto" }}>
                      <Flex column gap="gap.medium">
                        <Flex column>
                          <Flex hAlign="end">
                            <Text>
                              {feedbackTranslation.filter.filterByType}
                            </Text>
                          </Flex>
                          <Flex wrap style={{ gap: "5px" }}>
                            <Button
                              icon={<Icon iconName="AreaChart" />}
                              content={feedbackFilterTranslation.all}
                              iconPosition="before"
                              primary={selectedType === all}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(all)}
                            />
                            <Button
                              icon={<ItalicIcon outline />}
                              content={feedbackFilterTranslation.text}
                              iconPosition="before"
                              primary={selectedType === text}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(text)}
                            />
                            <Button
                              icon={<VolumeIcon outline />}
                              content={feedbackFilterTranslation.audio}
                              iconPosition="before"
                              primary={selectedType === audio}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(audio)}
                            />
                            <Button
                              icon={<CallVideoIcon outline />}
                              content={feedbackFilterTranslation.video}
                              iconPosition="before"
                              primary={selectedType === video}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(video)}
                            />
                            <Button
                              icon={<PollIcon outline />}
                              content={feedbackFilterTranslation.poll}
                              iconPosition="before"
                              primary={selectedType === poll}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(poll)}
                            />
                            <Button
                              icon={
                                <Text className="mixed-mode-icon filter-icon">
                                  M
                                </Text>
                              }
                              content={feedbackFilterTranslation.mixed}
                              iconPosition="before"
                              primary={selectedType === mix}
                              style={{
                                minWidth: "30px",
                                maxWidth: "120px",
                              }}
                              onClick={() => setSelectedType(mix)}
                            />
                          </Flex>
                        </Flex>
                        <Flex column>
                          <Divider vertical size={1} />
                        </Flex>
                        <Flex column>
                          <Flex hAlign="end">
                            <Text>
                              {feedbackTranslation.filter.filterByAge}
                            </Text>
                          </Flex>
                          <Flex wrap style={{ gap: "5px" }}>
                            {dateFilters.map((filterObj) => (
                              <Button
                                content={filterObj.content}
                                primary={filterObj.type === selectedDateFilter}
                                style={{
                                  minWidth: "30px",
                                }}
                                onClick={() =>
                                  onFilterBtnClickHandler(filterObj.type)
                                }
                              />
                            ))}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Portal containerId="feedback-view-button">
                      <Flex>
                        <IconButton
                          iconProps={{
                            iconName: "BulletedList",
                            style: { fontSize: "25px", color: "black" },
                          }}
                          title={feedbackTranslation.tableView}
                          style={{ fontWeight: "bold", margin: "5px" }}
                          onClick={() => {
                            setSelectedView(tableView);
                          }}
                        />
                        <IconButton
                          iconProps={{
                            iconName: "GridViewMedium",
                            style: { fontSize: "25px" },
                          }}
                          title={feedbackTranslation.cardView}
                          style={{
                            fontWeight: "bold",
                            color: "black",
                            margin: "5px",
                          }}
                          onClick={() => {
                            setSelectedView(gridView);
                          }}
                        />
                      </Flex>
                    </Portal>
                  </>
                ) : (
                  <Flex space="between" className="feedback-action-wrapper">
                    <Flex
                      gap="gap.medium"
                      className="feedback-filter-btn-wrapper"
                    >
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByType}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          <Button
                            icon={<Icon iconName="AreaChart" />}
                            content={feedbackFilterTranslation.all}
                            iconPosition="before"
                            primary={selectedType === all}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(all)}
                          />
                          <Button
                            icon={<ItalicIcon outline />}
                            content={feedbackFilterTranslation.text}
                            iconPosition="before"
                            primary={selectedType === text}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(text)}
                          />
                          <Button
                            icon={<VolumeIcon outline />}
                            content={feedbackFilterTranslation.audio}
                            iconPosition="before"
                            primary={selectedType === audio}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(audio)}
                          />
                          <Button
                            icon={<CallVideoIcon outline />}
                            content={feedbackFilterTranslation.video}
                            iconPosition="before"
                            primary={selectedType === video}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(video)}
                          />
                          <Button
                            icon={<PollIcon outline />}
                            content={feedbackFilterTranslation.poll}
                            iconPosition="before"
                            primary={selectedType === poll}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(poll)}
                          />
                          <Button
                            icon={
                              <Text className="mixed-mode-icon filter-icon">
                                M
                              </Text>
                            }
                            content={feedbackFilterTranslation.mixed}
                            iconPosition="before"
                            primary={selectedType === mix}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(mix)}
                          />
                        </Flex>
                      </Flex>
                      <Flex column>
                        <Divider vertical size={1} />
                      </Flex>
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByAge}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          {dateFilters.map((filterObj) => (
                            <Button
                              content={filterObj.content}
                              primary={filterObj.type === selectedDateFilter}
                              style={{
                                minWidth: "30px",
                              }}
                              onClick={() =>
                                onFilterBtnClickHandler(filterObj.type)
                              }
                            />
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex vAlign="end" hAlign="end">
                      <IconButton
                        iconProps={{
                          iconName: "BulletedList",
                          style: { fontSize: "25px", color: "black" },
                        }}
                        title={feedbackTranslation.tableView}
                        style={{ fontWeight: "bold", margin: "5px" }}
                        onClick={() => {
                          setSelectedView(tableView);
                        }}
                      />
                      <IconButton
                        iconProps={{
                          iconName: "GridViewMedium",
                          style: { fontSize: "25px" },
                        }}
                        title={feedbackTranslation.cardView}
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          margin: "5px",
                        }}
                        onClick={() => {
                          setSelectedView(gridView);
                        }}
                      />
                    </Flex>
                  </Flex>
                )}
              </div>

              {selectedView === tableView ? (
                rows?.length ? (
                  <div className="all-feedback-table">
                    <Table header={header} />
                    <Table
                      rows={rows?.length ? rows : []}
                      className="all-feedback-data"
                      style={{
                        overflowY:
                          isMobileView || isTabletView
                            ? "auto"
                            : rows?.length > 12
                            ? "auto"
                            : "hidden", // Change based on row count
                        maxHeight:
                          isMobileView || isTabletView
                            ? "auto"
                            : rows?.length > 12
                            ? "500px"
                            : "auto",
                      }}
                    />
                  </div>
                ) : null
              ) : filteredFeedbacks?.length ? (
                <div className="all-feedback-grid-view-wrapper">
                  <Row
                    gutter={[10, 10]}
                    className="all-feedback-cards"
                    style={{
                      width: isMobileView
                        ? "calc(100vw - 80px)"
                        : isCompactMode
                        ? "calc(100vw - 80px)"
                        : "calc(100vw - 241px)",
                    }}
                  >
                    {filteredFeedbacks.map((feedback) => (
                      <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                        xxl={6}
                        key={feedback.id}
                      >
                        {feedbackCard({ feedback })}
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : null}
              {/* {isLoading && !allFeedbackData?.length ? (
                <Loader style={{ paddingTop: "50px" }} />
              ) : !isLoading && !rows?.length ? (
                <Flex column fill className="all-feedback-table-image">
                  <Flex
                    hAlign="center"
                    column
                    vAlign="center"
                    style={{ flexGrow: 1 }}
                  >
                    <ResponsiveImage
                      src={noFeedbackImage}
                      style={{ width: "252px" }}
                    />
                    <Text size="large" weight="semibold" align="center">
                      {allFeedbackData.length > 0
                        ? feedbackTranslation.noFeedbackForFilter
                        : feedbackTranslation.noFeedback}
                    </Text>
                  </Flex>
                </Flex>
              ) : null} */}
            </div>
            {isLoading && !allFeedbackData?.length ? (
              <Loader style={{ paddingTop: "50px" }} />
            ) : !isLoading && !rows?.length ? (
              <Flex column fill className="all-feedback-table-image">
                <Flex
                  hAlign="center"
                  column
                  vAlign="center"
                  style={{ flexGrow: 1 }}
                >
                  <ResponsiveImage
                    src={noFeedbackImage}
                    style={{ width: "252px" }}
                  />
                  <Text size="large" weight="semibold" align="center">
                    {allFeedbackData.length > 0
                      ? feedbackTranslation.noFeedbackForFilter
                      : feedbackTranslation.noFeedback}
                  </Text>
                </Flex>
              </Flex>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AllFeedback);
