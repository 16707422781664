import React, { useState } from "react";

import Logo from "../../Assets/images/logo.png";
import LogoLight from "../../Assets/images/logo.png";
import {
  Image,
  Flex,
  Segment,
  Button,
  ArrowLeftIcon,
} from "@fluentui/react-northstar";

import SelectDB from "./components/SelectDB/SelectDB";
import UploadData from "./components/UploadData/UploadData";

import { useHistory } from "react-router-dom";
import * as Icon from "react-feather";
import i18n from "../../helper/i18n";
import vector from "../../Assets/images/svg/dbvector.svg";

import "./Startup.css";
import StartupInformation from "./components/StartupInformation/StartupInformation";
import ConfirmationDialog from "./ConfirmationDialog";
import { CloseIcon, Dialog } from "@fluentui/react-northstar";

const Startup = (props) => {
  const [selLan, setSelLan] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const [openIsModal, setIsOpenModal] = useState(false);

  const history = useHistory();

  return (
    <div className={selLan === "hi" ? "Startup font-Mukta" : "Startup"}>
      <ConfirmationDialog
        openIsModal={openIsModal}
        setIsOpenModal={setIsOpenModal}
        history={history}
      />

      <Dialog
        open={open}
        onConfirm={() => {
          history.push("/attendance");
          setOpen(false);
        }}
        confirmButton="Okay"
        content={
          "Do you really want change this page? You will lose all the changes that are not saved."
        }
        header="Return to Attendance page"
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setOpen(false),
        }}
        styles={{ width: "fit-content !important" }}
      />

      <Flex gap="gap.small" padding="padding.medium">
        <Flex.Item>
          <Segment
            content={
              <Image
                src={
                  props.user?.dbData?.db_selected === "Current"
                    ? props.user?.schoolInfo?.SchoolLogo
                      ? props.user?.schoolInfo?.SchoolLogo +
                        props.user?.SASToken
                      : props.user?.mode === "dark"
                      ? LogoLight
                      : Logo
                    : props.user?.mode === "dark"
                    ? LogoLight
                    : Logo
                }
                style={{
                  maxHeight: "40px",
                  borderRadius: "2px",
                }}
                title={props.user?.schoolInfo?.School || "Tangible IT"}
                onClick={() => {
                  history.location.pathname === "/start-up/upload"
                    ? setOpen(true)
                    : history.push("/attendance");
                }}
              />
            }
            style={{ cursor: "pointer" }}
          />
        </Flex.Item>
        <Flex.Item>
          <Segment
            content={
              <ul>
                <li
                  className="nav-item nav-item-spaced"
                  data-toggle="hover"
                  style={{
                    position: "absolute",
                    top: "30px",
                    right: "40px",
                    listStyleType: "none",
                  }}
                >
                  <a
                    className="btn btn-sm btn-outline-primary btn-icon ml-3 px-2 py-1 rounded-pill"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <span className="btn-inner--icon">
                      <Icon.Globe size="16" />
                    </span>{" "}
                    <span>
                      {selLan === "en"
                        ? "EN"
                        : selLan === "es"
                        ? "ES"
                        : selLan === "fr"
                        ? "FR"
                        : selLan === "pt"
                        ? "PT"
                        : selLan === "de"
                        ? "DE"
                        : selLan === "hi"
                        ? "HI"
                        : (navigator.language || navigator.userLanguage)
                            .substr(0, 2)
                            .toUpperCase()}
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm p-0 mt-1 Karla">
                    <ul className="list-group list-group-flush px-lg-4">
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("es");
                            i18n.changeLanguage("es");
                          }}
                        >
                          Español
                        </button>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("en");
                            i18n.changeLanguage("en");
                          }}
                        >
                          English
                        </button>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("fr");
                            i18n.changeLanguage("fr");
                          }}
                        >
                          Français
                        </button>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("pt");
                            i18n.changeLanguage("pt");
                          }}
                        >
                          Português
                        </button>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("de");
                            i18n.changeLanguage("de");
                          }}
                        >
                          Deutsch
                        </button>
                      </li>
                      <li
                        className="dropdown dropdown-animate dropdown-submenu radius-none"
                        data-toggle="hover"
                      >
                        <button
                          className="afterNone list-group-item list-group-item-action radius-none px-3"
                          onClick={() => {
                            setSelLan("hi");
                            i18n.changeLanguage("hi");
                          }}
                        >
                          हिंदी
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            }
            style={{ cursor: "pointer" }}
            className="btsp"
          />
        </Flex.Item>
      </Flex>

      {props.location.pathname === "/start-up/upload" ? (
        <>
          <Flex style={{ marginTop: -12 }}>
            <Flex.Item>
              <Segment
                content={
                  <Button
                    icon={<ArrowLeftIcon />}
                    content={"Back"}
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                    style={{
                      backgroundColor: "#EFEFEF",
                      borderRadius: "0 30px 30px 0",
                      padding: 20,
                    }}
                  />
                }
                style={{ paddingLeft: 0 }}
              />
            </Flex.Item>
          </Flex>
          <Flex gap="gap.small" padding="padding.medium" className="flexBox">
            <Flex.Item hAlign="center" vAlign="center">
              <UploadData data={props} />
            </Flex.Item>
          </Flex>
        </>
      ) : props.location.pathname === "/start-up/info" ? (
        <StartupInformation data={props} getDb={props.getDb.bind()} />
      ) : (
        <Flex gap="gap.small" padding="padding.medium" className="flexBox">
          <Image src={vector} className="vectorImage" />
          <Flex.Item hAlign="center" vAlign="center">
            <SelectDB data={props} getDb={props.getDb.bind()} />
          </Flex.Item>
        </Flex>
      )}
    </div>
  );
};
export default Startup;
