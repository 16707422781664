import {
  Button,
  ChevronEndIcon,
  ChevronStartIcon,
  Flex,
  Image,
} from "@fluentui/react-northstar";
import { Layout } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import NoEventFoundImage from "../../../../Assets/images/svg/NoAssignmentFound.svg";
import { EventsNavBar } from "../../../../components/EventsNavBar";
import Header from "../../../../components/Header/Header";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";
import Event from "../../../../components/widgets/Event";
import EventFilterModal from "../../../../components/widgets/EventFilterModal";
import EventFilters from "../../../../components/widgets/EventFilters";
import RegistrationModal from "../../../../components/widgets/RegistrationModal";
import UserColumnFilter from "../../UserColumnFilter";
import UserFilter from "../../UserFilter";
import EditBundleEvent from "../EditBundleEvent/EditBundleEvent";
import useBundleEvents from "./useBundleEvents";

import "../../event.css";

import { AlertDialog } from "../../../../components/Dialog/AlertDialog";
import "./BundleEvents.scss";

const BundleEvents = (props) => {
  const { t } = props;

  const {
    isMobileView,
    isTabletView,

    // Event filter props
    appliedFilter,
    globalSearch,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    startDate,
    endDate,
    selectedDateFilter,
    setSelectedDateFilter,
    filterModalProps,
    //

    isRegModalVisible,
    setIsRegModalVisible,

    selectedEvent,
    setSelectedEvent,

    isLoading,
    isUpdating,
    deleteConfirmation,
    setDeleteConfirmation,

    history,
    eventList,
    tags,
    categories,

    scrollButtonHandler,

    editEvent,
    editButtonClickHandler,
    onSuccessFulEditHandler,
    onSuccessFulDeleteHandler,
    onEditCancelHandler,
    updateFavourite,
    deleteEvent,
  } = useBundleEvents(props);

  const translation = t("events");
  const eventsTranslation = translation.body.bundles;

  return (
    <>
      <AlertDialog
        handleClose={() => {
          setDeleteConfirmation((prev) => ({
            ...prev,
            isOpen: false,
            data: null,
          }));
        }}
        handleOk={() => {
          deleteEvent(deleteConfirmation.data);
        }}
        open={deleteConfirmation.isOpen}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />
      {/* {!isSubscribed || isExpire ? (
        <Subscription
          pageName="event"
          isExpire={isExpire}
          role={props.user.role}
        />
      ) : (
        <> */}
      {isMobileView ? (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="myevents-event-wrapper">
            <EventsNavBar {...props} isMobileView={isMobileView} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout className="tour-my-event" style={{ height: "fit-content" }}>
              <CustomProgressIndicator
                isLoading={
                  isUpdating || isLoading || deleteConfirmation.isDeleting
                }
              />
              <Flex
                className="event-title"
                style={{
                  justifyContent: "center",
                  marginTop: "25px",
                  width: isMobileView && "100%",
                }}
              >
                {eventsTranslation.header}
              </Flex>
              <div className="mobile-wrapper">
                <Flex
                  space="between"
                  wrap
                  style={{ rowGap: "15px", justifyContent: "space-around" }}
                >
                  <Flex hAlign="end">
                    <Flex
                      wrap
                      hAlign="center"
                      gap="gap.small"
                      style={{ rowGap: "10px" }}
                      vAlign="center"
                    ></Flex>
                  </Flex>
                </Flex>

                {isFilterApplied ? <UserFilter /> : null}
                {eventList.length ? (
                  <div>
                    <Flex column>
                      <Flex wrap>
                        {eventList.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: "100%",
                              minWidth: "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              type={"bundleEvent"}
                              isActiveStatus={true}
                              selectedColumns={selectedColumns}
                              data={data}
                              tags={tags}
                              categories={categories}
                              updateFavourite={(data) => updateFavourite(data)}
                              onDelete={(data) => {
                                setDeleteConfirmation({
                                  data,
                                  isOpen: true,
                                });
                              }}
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              loading={{
                                isDeleting: deleteConfirmation.isDeleting,
                                isLoading: isLoading || isUpdating,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <Image
                        style={{ maxWidth: "280px", width: "100%" }}
                        src={NoEventFoundImage}
                      />
                      <h2 style={{ marginTop: 20 }}>
                        {eventsTranslation.noEventsInBundle}
                      </h2>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="Attendance Whiteboard s-ctr bundle-events-wrapper">
          {/* {!isExpire && isSubscribed && ( */}
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          {/* )} */}
          <Layout className="myevents-event-wrapper">
            <EventsNavBar {...props} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout className="tour-my-event" style={{ height: "fit-content" }}>
              <CustomProgressIndicator
                isLoading={
                  isUpdating || isLoading || deleteConfirmation.isDeleting
                }
              />

              <div className="wrapper">
                <Flex space="between" wrap style={{ rowGap: "15px" }}>
                  <Flex className="event-title">
                    {eventsTranslation.header}
                  </Flex>
                  {/* <Flex hAlign="end">
                    <Flex
                      wrap
                      hAlign="center"
                      gap="gap.small"
                      style={{ rowGap: "10px", gap: isTabletView && "5px" }}
                      vAlign="center"
                    >
                      {isTabletView ? null : <UserColumnFilter />}
                    </Flex>
                  </Flex> */}
                </Flex>

                {isFilterApplied ? <UserFilter /> : null}
                {eventList.length ? (
                  <div>
                    <Flex vAlign="center">
                      <div className="scroll-button-wrapper">
                        <Button
                          className="scroll-button"
                          onClick={() => {
                            scrollButtonHandler(false);
                          }}
                        >
                          <ChevronStartIcon size="large" />
                        </Button>
                      </div>
                      <div
                        className="events-container"
                        id="scroll-events-container"
                      >
                        <Flex className="scroll-events-wrapper">
                          {eventList.map((data, index) => (
                            <Flex
                              key={data.id + index}
                              style={{
                                width: `calc((100vh - 320px)/${selectedColumns})`,
                                minWidth: isTabletView ? "244px" : "260px",
                                padding: "0 10px",
                                position: "relative",
                              }}
                            >
                              <Event
                                {...props}
                                type={"bundleEvent"}
                                isActiveStatus={true}
                                selectedColumns={selectedColumns}
                                data={data}
                                tags={tags}
                                categories={categories}
                                updateFavourite={(data) =>
                                  updateFavourite(data)
                                }
                                onDelete={(data) => {
                                  setDeleteConfirmation({
                                    data,
                                    isOpen: true,
                                  });
                                }}
                                attendeesModal={(data) => {
                                  setSelectedEvent(data);
                                  setIsRegModalVisible(true);
                                }}
                                loading={{
                                  isDeleting: deleteConfirmation.isDeleting,
                                  isLoading: isLoading || isUpdating,
                                }}
                                editButtonClickHandler={editButtonClickHandler}
                                isBundleEditingTheEvent={
                                  data?.id === editEvent?.id
                                }
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </div>
                      <div className="scroll-button-wrapper">
                        <Button
                          className="scroll-button"
                          onClick={() => {
                            scrollButtonHandler(true);
                          }}
                        >
                          <ChevronEndIcon size="large" />
                        </Button>
                      </div>
                    </Flex>
                    {editEvent ? (
                      <div className="bundle-event-edit-form-wrapper">
                        <EditBundleEvent
                          editEventData={editEvent}
                          tags={tags}
                          categories={categories}
                          onSuccessFulEditHandler={onSuccessFulEditHandler}
                          onSuccessFulDeleteHandler={onSuccessFulDeleteHandler}
                          onEditCancelHandler={onEditCancelHandler}
                          {...props}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <Image
                        style={{ maxWidth: "280px", width: "100%" }}
                        src={NoEventFoundImage}
                      />
                      <h2 style={{ marginTop: 20 }}>
                        {eventsTranslation.noEventsInBundle}
                      </h2>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      )}
    </>
  );
};

export default withTranslation()(BundleEvents);
