import axios from "axios";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { NotificationAlert } from "../components/Notification/Notification";
import { msalConfig } from "../App/msal-auth/msalAuth";
import { internals, PublicClientApplication } from "@azure/msal-browser";
import { Flex } from "@fluentui/react-northstar";
import {
  generateAvailabilityDays,
  generateTimeSlots as generateTimeSlotsByStartAndEndTime,
  getSlotKey,
} from "../pages/Booking/Components/Setting/utils/utils";

const Booking = createContext();
export const useBooking = () => {
  return useContext(Booking);
};

const defaultDays = [
  { label: "Sunday", value: "sun", isSelected: false },
  { label: "Monday", value: "mon", isSelected: false },
  { label: "Tuesday", value: "tue", isSelected: false },
  { label: "Wednesday", value: "wed", isSelected: false },
  { label: "Thursday", value: "thu", isSelected: false },
  { label: "Friday", value: "fri", isSelected: false },
  { label: "Saturday", value: "sat", isSelected: false },
];

export const BookingContext = (props) => {
  const { children, user } = props;
  const history = useHistory();
  const [globalSearch, setGlobalSearch] = useState("");
  const [date, setDate] = useState(moment());
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isLoadingSlots, setIsLoadingSlots] = useState([]);
  const [isLoadingBookings, setIsLoadingBookings] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [isLoadingTutors, setIsLoadingTutors] = useState(false);
  const [isLoadingStarredTutors, setIsLoadingStarredTutors] = useState(false);
  const [starredTutors, setStarredTutors] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [scheduledSlots, setScheduledSlots] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isShowingScheduledSlots, setIsShowingScheduledSlots] = useState(false);
  const [selectedTab, setSelectedTab] = useState("mySchedules");
  const [slots, setSlots] = useState([
    {
      shift: "morning",
      data: [],
    },
    { shift: "afternoon", data: [] },
    { shift: "evening", data: [] },
  ]);
  const [durationOfSlot, setDurationOfSlot] = useState(30);
  const [validRange, setValidRange] = useState([
    moment().clone().startOf("day"),
    moment().clone().endOf("month"),
  ]);
  const [selectedDates, setSelectedDates] = useState([
    moment().format("YYYY-MM-DD"),
  ]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [days, setDays] = useState({
    [moment().month()]: [...defaultDays],
  });

  // Edit Slots states
  const [isEditing, setIsEditing] = useState(false);

  // book slot states
  const [availabilityTimes, setAvailabilityTimes] = useState([]);
  const [selectedSlotId, setSelectedSlotId] = useState(NaN);
  const [step, setStep] = useState(1);
  const [meetingData, setMeetingData] = useState({
    name: "",
    note: "",
    topics: [],
  });
  const [otherTopics, setOtherTopics] = useState("");
  const [selectedMeetType, setSelectedMeetType] = useState("");
  const [allSelectedStudentForMeeting, setAllSelectedStudentForMeeting] =
    useState([]);
  const [isGroupMeeting, setIsGroupMeeting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openEventLoginModal, setOpenEventLoginModal] = useState({
    type: null,
    isOpen: false,
  });

  // Filter states
  const [isOpenBookingFiltersModel, setIsOpenBookingFiltersModel] =
    useState(false);
  const [filterTutorsParameters, setFilterTutorsParameters] = useState(null);
  const [selectedFilterTutorsParameters, setSelectedFilterTutorsParameters] =
    useState({
      specialities: [],
      fieldsOfStudy: [],
      yearsOfStudy: [],
      yearsOfExperience: [],
      skills: [],
      interests: [],
    });

  // Edit Schedule
  const [editingScheduleData, setEditingScheduleData] = useState(null);

  // Reviews
  const [reviews, setReviews] = useState([]);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);

  const [status, setStatus] = useState("scheduled");
  const [singleUser, setSingleUser] = useState(false);

  const handleResetDays = (month) => {
    let clonedDays = { ...days };
    clonedDays[month] = clonedDays[month].map((day) => ({
      ...day,
      isSelected: false,
    }));
    setDays(clonedDays);
  };

  const handleSelectDate = (value) => {
    const isSelected = Boolean(
      blockedDates.find((date) => moment(date).isSame(value, "date"))
    );
    if (isSelected) {
      const newSelectedDates = blockedDates.filter(
        (date) => !moment(date).isSame(value, "date")
      );
      setBlockedDates(newSelectedDates);
    } else {
      const newSelectedDates = [
        ...blockedDates,
        moment(value).format("YYYY-MM-DD"),
      ];
      setBlockedDates(newSelectedDates);
    }
  };

  const handleSelectDays = (days, checked) => {
    isEditing && handleCancelEditing();
    let oldDates = [];
    const newDates = days.filter(
      (date) =>
        !selectedDates.find((selectedDate) => moment(selectedDate).isSame(date))
    );
    if (!checked) {
      oldDates = days.filter((date) =>
        selectedDates.find((selectedDate) => moment(selectedDate).isSame(date))
      );
    }
    const newSelectedDates = [...selectedDates, ...newDates].filter(
      (date) => !oldDates.find((oldDate) => moment(oldDate).isSame(date))
    );
    if (newSelectedDates.length) {
      getScheduledSlotsBySelectedDates(newSelectedDates);
    } else {
      setScheduledSlots([]);
      setIsShowingScheduledSlots(false);
    }

    setSelectedDates(newSelectedDates);
  };

  function getAllDaysOfMonth(day, month, year, checked) {
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const dayIndex = daysOfWeek.indexOf(day.toLowerCase());

    if (dayIndex === -1) {
      throw new Error("Invalid day provided");
    }

    const today = moment().startOf("day");
    const startOfMonth = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    const endOfMonth = startOfMonth.clone().endOf("month");
    let currentDay = startOfMonth.clone().day(dayIndex);

    // If the first occurrence is in the previous month, move to the next week
    if (currentDay.isBefore(startOfMonth)) {
      currentDay.add(1, "week");
    }

    const dates = [];

    while (currentDay.isSameOrBefore(endOfMonth)) {
      if (currentDay.isSameOrAfter(today)) {
        dates.push(currentDay.toISOString());
      }
      currentDay.add(1, "week");
    }

    handleSelectDays(dates, checked);
  }

  function getShift(time) {
    const hour = time.hour();
    if (hour >= 6 && hour < 12) {
      return "morning";
    } else if (hour >= 12 && hour < 17) {
      return "afternoon";
    } else if (hour >= 17) {
      return "evening";
    }
  }

  function generateTimeSlots(date, duration, restTime) {
    const slots = {
      morning: [],
      afternoon: [],
      evening: [],
    };
    const currentDate = date.startOf("day");

    const endOfDay = currentDate.clone().endOf("day");
    let currentTime = currentDate.clone().hour(6).minute(0); // Start at 6 AM

    while (currentTime.isBefore(endOfDay)) {
      const endTime = currentTime.clone().add(duration, "minutes"); // assuming each slot is 30 minutes long

      if (endTime.isAfter(endOfDay)) {
        break;
      }

      const shift = getShift(currentTime);

      if (shift !== "none") {
        slots[shift].push({
          startTime: currentTime.format("hh:mm A"),
          endTime: endTime.format("hh:mm A"),
          shift: shift,
          status: "available",
        });
      }

      currentTime.add(duration + restTime, "minutes"); // move to the next slot
    }

    setSlots([
      {
        shift: "morning",
        data: slots.morning,
      },
      { shift: "afternoon", data: slots.afternoon },
      { shift: "evening", data: slots.evening },
    ]);
  }

  const getNextSixMonths = () => {
    const monthsFullNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentMonth = new Date().getMonth();
    const months = [];

    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonth + i) % 12; // Loop over months array
      months.push(monthsFullNames[monthIndex]);
    }

    return months;
  };

  const disabledDate = (currentDate) => {
    return currentDate && currentDate < moment().startOf("day");
  };

  const dateFullCellRender = (value) => {
    const today = moment().startOf("day");
    const isToday = value.isSame(today, "day");

    const isSelectedDate =
      value.isAfter() && blockedDates.includes(value.format("YYYY-MM-DD"));

    const isCurrentMonth = value.isSame(date, "month");

    return (
      isCurrentMonth && (
        <Flex
          hAlign="center"
          vAlign="center"
          style={{
            width: window.innerWidth > 830 ? "65px" : "50px",
            height: window.innerWidth > 830 ? "65px" : "50px",
          }}
        >
          <div
            style={{
              backgroundColor: isSelectedDate ? "#6264a7" : "transparent",
              borderRadius: "50%",
              color: disabledDate(value)
                ? ""
                : isSelectedDate
                ? "white"
                : "black",
              border: isToday ? "1px solid white" : "",
              outline: isToday ? "1px solid #6264a7" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: window.innerWidth > 830 ? "50px" : "100%",
              height: window.innerWidth > 830 ? "50px" : "100%",
              fontSize: window.innerWidth > 830 ? "16px" : "",
            }}
          >
            {value.date()}
          </div>
        </Flex>
      )
    );
  };

  const isSlotSelected = (startTime) => {
    if (selectedSlots.length) {
      const index = selectedSlots.findIndex((slot) =>
        moment(slot.startTime, "h:mm A").isSame(
          moment(startTime, "h:mm A"),
          "minute"
        )
      );
      return index !== -1;
    } else {
      return false;
    }
  };

  const generateSlotsForAllSelectedDatesByTimeSlots = (
    selectedTimeSlots,
    selectedDates,
    scheduledSlots,
    type = "create"
  ) => {
    let slots = [];

    selectedDates.forEach((selectedDate) => {
      const slotsForSelectedDate = selectedTimeSlots.map(
        ({ startTime, endTime, shift, status }) => {
          const startMoment = moment(selectedDate)
            .clone()
            .hour(moment(startTime, "h:mm A").hour())
            .minute(moment(startTime, "h:mm A").minute());
          const endMoment = moment(selectedDate)
            .clone()
            .hour(moment(endTime, "h:mm A").hour())
            .minute(moment(endTime, "h:mm A").minute());

          return {
            tutorEmailId: props.user.mail,
            startTime: startMoment.toISOString(),
            endTime: endMoment.toISOString(),
            shift,
            status,
          };
        }
      );
      if (type === "create") {
        // Filter out slots that already exist in scheduledSlots
        const filteredSlotsForSelectedDate = slotsForSelectedDate.filter(
          (newSlot) => {
            return !scheduledSlots.some((scheduledSlot) => {
              return (
                moment(scheduledSlot.startTime).isSame(
                  moment(newSlot.startTime)
                ) &&
                moment(scheduledSlot.endTime).isSame(moment(newSlot.endTime))
              );
            });
          }
        );

        slots = slots.concat(filteredSlotsForSelectedDate); // Merge the arrays instead of creating nested arrays
      } else if (type === "edit") {
        slots = slots.concat(slotsForSelectedDate);
      }
    });

    return slots; // Return the generated slots array
  };

  const handleScheduleSlots = async (slots) => {
    setIsScheduling(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}`,
        { slots },
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsScheduling(false);
      if (res.data.success) {
        // setAvailabilityTimes(
        //   generateAvailabilityDays().map((availability) => {
        //     return {
        //       ...availability,
        //       isDisabled: !selectedDates.some(
        //         (date) =>
        //           moment(date).format("dddd").toLowerCase() === availability.day
        //       ),
        //     };
        //   })
        // );
        const successFullyScheduledSlots = res.data?.result?.createdSlots || [];
        getScheduledSlots(
          moment(dateRange[0].startDate).format("YYYY-MM-DD"),
          moment(dateRange[0].endDate).format("YYYY-MM-DD"),
          selectedDates,
          props.user.mail
        );

        toast.success(
          `${successFullyScheduledSlots.length} Slots Scheduled SuccessFully!`
        );
      } else {
        toast.error(`Slots Scheduling Failed`);
      }
    } catch (error) {
      setIsScheduling(false);
      toast.error(`Slots Scheduling Failed`);
    }
  };

  const filterScheduledSlotsBySelectedDates = (slots, selectedDates) => {
    return slots.filter((slot) =>
      selectedDates.some((date) =>
        moment(date).isSame(moment(slot.startTime), "day")
      )
    );
  };

  const getSelectedDatesFromDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = moment(startDate);
    const endDateMoment = moment(endDate);
    while (currentDate.isSameOrBefore(endDateMoment)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }
    return dates;
  };

  const checkIsSlotScheduledForAllDatesOfDay = (
    selectedDates,
    slot,
    scheduledSlotsForDay,
    day
  ) => {
    const allTheSelectedDatesByDay = selectedDates.filter(
      (selectedDate) =>
        moment(selectedDate).format("dddd").toLowerCase() === day.toLowerCase()
    );

    const slotTime = moment(slot.startTime).format("h:mm A");

    return allTheSelectedDatesByDay.every((selectedDate) => {
      return findSlotByTime(scheduledSlotsForDay, slotTime, selectedDate);
    });
  };

  const getScheduledSlots = async (
    startDate,
    endDate,
    selectedDates_,
    mail
  ) => {
    setIsLoadingSlots(true);
    try {
      let selectedDates = selectedDates_;
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/slots${
          props.user.slug
        }&tutorEmailId=${mail}&groupBy=weekday&timeZone=${userTimeZone}${
          startDate ? `&startDate=${startDate}` : ""
        }${endDate ? `&endDate=${endDate}` : ""}`
      );
      if (res.data?.success && res.data?.slots) {
        const slots = res.data?.slots;
        if (!startDate && !endDate) {
          selectedDates = getSelectedDatesFromDateRange(
            moment(res.data.smallestDate).toDate(),
            moment(res.data.biggestDate).toDate()
          );
          setSelectedDates(selectedDates);
        }

        setScheduledSlots(slots);
        let clonedAvailabilityTimes = [...generateAvailabilityDays()];
        clonedAvailabilityTimes = await Promise.all(
          clonedAvailabilityTimes.map(async (availability) => {
            const isDisabled = !selectedDates.some(
              (date) =>
                moment(date).format("dddd").toLowerCase() ===
                availability.day.toLowerCase()
            );
            const weekDay = slots.find(
              (slot) =>
                slot.day.toLowerCase() === availability.day.toLowerCase()
            );
            if (weekDay) {
              const isCommonSlotForAllSelectedDates =
                checkIsAnySlotScheduledForAllDatesOfDay(
                  selectedDates,
                  weekDay.slots,
                  availability.day
                );
              const timeSlots = await Promise.all(
                weekDay.slots.map((timeSlot) => {
                  const key = getSlotKey(
                    moment(timeSlot.startTime).format("h:mm A")
                  );

                  const isSlotCommonInAllDatesByDay =
                    checkIsSlotScheduledForAllDatesOfDay(
                      selectedDates,
                      timeSlot,
                      weekDay.slots,
                      availability.day
                    );

                  return {
                    ...timeSlot,
                    key,
                    isCommon: isSlotCommonInAllDatesByDay,
                    isDeleted: false,
                    isRemoved: false,
                    isNew: false,
                  };
                })
              );
              const groupedTimeSlots = timeSlots.reduce((acc, slot) => {
                const slotKey = slot.key;
                if (!acc[slotKey]) {
                  acc[slotKey] = [];
                }
                acc[slotKey].push(slot);
                return acc;
              }, {});

              const selectedTimeSlots = Object.keys(groupedTimeSlots).reduce(
                (acc, key) => {
                  // Get the slots for the current key
                  const slots = groupedTimeSlots[key];

                  // Find the slot with the smallest startTime and the largest endTime
                  const result = slots.reduce(
                    (acc, slot) => {
                      const formattedStartTime = moment(slot.startTime).format(
                        "h:mm A"
                      );
                      const formattedEndTime = moment(slot.endTime).format(
                        "h:mm A"
                      );

                      // Check for smallest startTime
                      if (!acc.smallestStartTime) {
                        acc.smallestStartTime = formattedStartTime;
                      } else if (
                        acc.smallestStartTime &&
                        moment(formattedStartTime, "h:mm A").isBefore(
                          moment(acc.smallestStartTime, "h:mm A")
                        )
                      ) {
                        acc.smallestStartTime = formattedStartTime;
                      }

                      // Check for largest endTime
                      if (!acc.largestEndTime) {
                        acc.largestEndTime = formattedEndTime;
                      } else if (
                        acc.largestEndTime &&
                        moment(formattedEndTime, "h:mm A").isAfter(
                          moment(acc.largestEndTime, "h:mm A")
                        )
                      ) {
                        acc.largestEndTime = formattedEndTime;
                      }

                      return acc;
                    },
                    { smallestStartTime: null, largestEndTime: null }
                  );

                  // Store formatted results in the accumulator
                  acc[key] = {
                    startTime: result.smallestStartTime,
                    endTime: result.largestEndTime,
                  };
                  return acc;
                },
                {}
              );

              return {
                ...availability,
                timeSlots: timeSlots || [],
                isSelected: timeSlots.length > 0 || false,
                selectedTimeSlots: selectedTimeSlots || {},
                isCommonSlots: isCommonSlotForAllSelectedDates,
                isDisabled,
              };
            } else {
              return { ...availability, isDisabled };
            }
          })
        );
        setAvailabilityTimes(clonedAvailabilityTimes);

        const isCommonSlotsInAnyDay = clonedAvailabilityTimes.some(
          (availability) => availability.isCommonSlots
        );

        slots?.length && isCommonSlotsInAnyDay
          ? setIsShowingScheduledSlots(true)
          : setIsShowingScheduledSlots(false);

        if (startDate && endDate) {
          setDateRange([
            {
              startDate: moment(startDate).toDate(),
              endDate: moment(endDate).toDate(),
              key: "selection",
            },
          ]);
        } else {
          setDateRange([
            {
              startDate: moment(res.data.smallestDate).toDate(),
              endDate: moment(res.data.biggestDate).toDate(),
              key: "selection",
            },
          ]);
        }
      }
      setIsLoadingSlots(false);
    } catch (error) {
      setIsLoadingSlots(false);
      console.log(error, "error");
    }
  };
  const getMinStartMaxEndFromSlotsByWeekDay = async (availabilityTimes) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/slots/min-start-max-end${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data?.slots) {
        const slotsByWeekDays = res.data?.slots;
        let clonedAvailabilityTimes = [...availabilityTimes];
        clonedAvailabilityTimes = await Promise.all(
          clonedAvailabilityTimes.map((availability) => {
            const weekDay = slotsByWeekDays.find(
              (slot) =>
                slot.weekday.toLowerCase() === availability.day.toLowerCase()
            );
            if (weekDay) {
              const smallestStartTime = moment(
                weekDay.smallestStartTime
              ).format("h:mm A");
              const biggestEndTime = moment(weekDay.biggestEndTime).format(
                "h:mm A"
              );
              const timeSlots = generateTimeSlotsByStartAndEndTime(
                smallestStartTime,
                durationOfSlot,
                biggestEndTime
              );

              const groupedTimeSlots = timeSlots.reduce((acc, slot) => {
                const slotKey = slot.key;
                if (!acc[slotKey]) {
                  acc[slotKey] = [];
                }
                acc[slotKey].push(slot);
                return acc;
              }, {});

              const selectedTimeSlots = Object.keys(groupedTimeSlots).reduce(
                (acc, key) => {
                  acc[key] = {
                    startTime: groupedTimeSlots[key][0].startTime,
                    endTime:
                      groupedTimeSlots[key][groupedTimeSlots[key].length - 1]
                        .endTime,
                  };
                  return acc;
                },
                {}
              );

              return {
                ...availability,
                timeSlots,
                isSelected: timeSlots.length > 0,
                selectedTimeSlots,
              };
            }
            return availability;
          })
        );
        setAvailabilityTimes(clonedAvailabilityTimes);
      }
    } catch (error) {}
  };
  const getScheduledSlotsByDates = async (mail) => {
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}&timeZone=${userTimeZone}&tutorEmailId=${mail}&groupBy=day`
      );
      if (res.data?.success && res.data?.slots) {
        setScheduledSlots(res.data?.slots);
      }
    } catch (error) {}
  };

  const isScheduledSlot = (startTime) => {
    if (scheduledSlots.length && selectedDates.length) {
      return selectedDates.every((date) => {
        return scheduledSlots.some((slot) =>
          moment(slot.startTime).isSame(
            moment(date)
              .hour(moment(startTime, "h:mm A").hour())
              .minute(moment(startTime, "h:mm A").minute()),
            "minute"
          )
        );
      });
    } else {
      return false;
    }
  };

  const isSlotBooked = (isScheduled, startTime) => {
    if (isScheduled) {
      // Iterate over each selected date
      return selectedDates.every((date) => {
        // Filter scheduled slots to match the start time on the given date
        const scheduledSlotsForDate = scheduledSlots.filter((slot) =>
          moment(slot.startTime).isSame(
            moment(date)
              .hour(moment(startTime, "h:mm A").hour())
              .minute(moment(startTime, "h:mm A").minute()),
            "minute"
          )
        );
        // Check if all filtered slots are booked
        return scheduledSlotsForDate.every((slot) => slot.status === "booked");
      });
    } else {
      return false;
    }
  };

  const checkIsAnySlotScheduledForAllSelectedDate = (
    scheduledSlotsOfAllSelectedDates,
    selectedDates,
    allSlotsTimes
  ) => {
    return selectedDates.some((selectedDate) => {
      // Find Slots with selectedDate
      const scheduledSlotsBySelectedDate =
        scheduledSlotsOfAllSelectedDates.filter(({ startTime }) => {
          const selectedMoment = moment(selectedDate);
          const startMoment = moment(startTime);
          return selectedMoment.isSame(startMoment, "day");
        });
      // Check is all slots have common timeSlot or not
      return scheduledSlotsBySelectedDate.every(({ startTime }) => {
        const scheduledTime = moment(startTime); // Convert to local time
        return allSlotsTimes.some((timeSlot) => {
          const time = moment(timeSlot.startTime, "hh:mm A"); // Convert to local time
          return scheduledTime.format("hh:mm A") === time.format("hh:mm A"); // Compare based on minutes
        });
      });
    });
  };

  const findSlotByTime = (scheduledSlots, time, selectedDate) => {
    const slotScheduled = scheduledSlots.some((slot) => {
      const currentTime = moment();
      const slotStartTime = currentTime.clone().set({
        date: moment(slot.startTime).date(),
        month: moment(slot.startTime).month(),
        year: moment(slot.startTime).year(),
        hour: moment(slot.startTime).hour(),
        minute: moment(slot.startTime).minute(),
      });
      const inputTime = currentTime.clone().set({
        date: moment(selectedDate).date(),
        month: moment(selectedDate).month(),
        year: moment(selectedDate).year(),
        hour: moment(time, "h:mm A").hour(),
        minute: moment(time, "h:mm A").minute(),
      });

      return slotStartTime.isSame(inputTime);
    });

    return slotScheduled;
  };

  const checkIsAnySlotScheduledForAllDatesOfDay = (
    selectedDates,
    scheduledSlotsForDay,
    day
  ) => {
    const allTheSelectedDatesByDay = selectedDates.filter(
      (selectedDate) =>
        moment(selectedDate).format("dddd").toLowerCase() === day.toLowerCase()
    );

    // Find slot times from scheduledSlotsForDay
    const slotTimes = scheduledSlotsForDay.map((slot) => {
      return {
        startTime: moment(slot.startTime).format("hh:mm A"),
        endTime: moment(slot.endTime).format("hh:mm A"),
      };
    });

    // Remove duplicate slot times
    const uniqueSlotTimes = slotTimes.filter(
      (slot, index, self) =>
        index ===
        self.findIndex(
          (t) => t.startTime === slot.startTime && t.endTime === slot.endTime
        )
    );

    // return uniqueSlotTimes.length > 0;

    return allTheSelectedDatesByDay.every((selectedDate) => {
      return uniqueSlotTimes.some((slot) => {
        return findSlotByTime(
          scheduledSlotsForDay,
          slot.startTime,
          selectedDate
        );
      });
    });
  };

  const getCommonScheduledSlots = (
    scheduledSlotsOfAllSelectedDates,
    selectedDates,
    allSlotsTimes
  ) => {
    let commonTimeSlots = allSlotsTimes.filter((timeSlot) => {
      return selectedDates.every((selectedDate) => {
        // Find Slots with selectedDate
        const scheduledSlotsBySelectedDate =
          scheduledSlotsOfAllSelectedDates.filter(({ startTime }) => {
            const selectedMoment = moment(selectedDate);
            const startMoment = moment(startTime);
            return selectedMoment.isSame(startMoment, "day");
          });

        return scheduledSlotsBySelectedDate.some(({ startTime }) => {
          return moment(startTime).isSame(
            moment()
              .clone()
              .hour(moment(timeSlot.startTime, "hh:mm A").hour())
              .minute(moment(timeSlot.startTime, "hh:mm A").minute()),
            "minute"
          );
        });
      });
    });
    return commonTimeSlots;
  };

  const getScheduledSlotsBySelectedDates = (selectedDates) => {
    // Convert all dates to moment objects for comparison
    const momentDates = selectedDates.map((date) => moment(date));

    // Find the smallest and largest dates
    const smallestDate = moment.min(momentDates).toISOString();
    const largestDate = moment.max(momentDates).toISOString();

    // Get scheduled slots of all selected dates
    getScheduledSlots(
      moment(smallestDate).format("YYYY-MM-DD"),
      moment(largestDate).format("YYYY-MM-DD"),
      selectedDates,
      props.user.mail
    );
  };

  const handleStartEditingSlots = async () => {
    console.log("handleStartEditingSlots", selectedDates);
    let clonedAvailabilityTimes = [...availabilityTimes];
    clonedAvailabilityTimes = await Promise.all(
      clonedAvailabilityTimes.map((availabilityTimes) => {
        const isDisabled = !selectedDates.some(
          (date) =>
            moment(date).format("dddd").toLowerCase() ===
            availabilityTimes.day.toLowerCase()
        );
        return { ...availabilityTimes, isDisabled };
      })
    );
    setAvailabilityTimes(clonedAvailabilityTimes);
    setIsEditing(true);
  };

  const handleCancelEditing = async () => {
    let clonedAvailabilityTimes = [...availabilityTimes];
    clonedAvailabilityTimes = await Promise.all(
      clonedAvailabilityTimes.map((availabilityTimes) => {
        const timeSlots = availabilityTimes.timeSlots
          .map((timeSlot) => {
            return { ...timeSlot, isRemoved: false, isDeleted: false };
          })
          .filter((timeSlot) => !timeSlot.isNew);
        return {
          ...availabilityTimes,
          timeSlots,
          isSelected: timeSlots.length > 0,
        };
      })
    );
    setAvailabilityTimes(clonedAvailabilityTimes);
    setIsEditing(false);
  };

  const handleUpdateScheduledSlots = async (newSlots, oldRemovedSlotsIds) => {
    if (newSlots.length || oldRemovedSlotsIds.length) {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}`,
          { newSlots, oldRemovedSlotsIds },
          {
            headers: { Authorization: "Bearer " + props.user.accessToken },
          }
        );
        setIsScheduling(false);

        if (res.data.success) {
          const successFullyScheduledSlots =
            res.data?.result?.createdNewSlots || [];
          const removedSlotsIds = res.data?.result?.removedSlotsIds || [];
          setIsEditing(false);

          getScheduledSlots(
            moment(dateRange[0].startDate).format("YYYY-MM-DD"),
            moment(dateRange[0].endDate).format("YYYY-MM-DD"),
            selectedDates,
            props.user.mail
          );
          if (successFullyScheduledSlots.length) {
            toast.success(
              `${successFullyScheduledSlots.length} ${
                successFullyScheduledSlots.length === 1 ? "Slot" : "Slots"
              } Added SuccessFully!`
            );
          }
          removedSlotsIds.length &&
            toast.success(
              `${removedSlotsIds.length} ${
                removedSlotsIds.length === 1 ? "Slot" : "Slots"
              } Removed SuccessFully!`
            );
        } else {
          toast.error("Slots updating failed");
        }
      } catch (error) {
        toast.error("Slots updating failed");
        setIsScheduling(false);
      }
    } else {
      toast.error("Change something for update.");
      setIsScheduling(false);
    }
  };

  const handleToggleDay = (month, i) => {
    let clonedDays = { ...days };
    clonedDays[month][i].isSelected = !clonedDays[month][i].isSelected;
    setDays(clonedDays);
  };

  const handleChangeMonth = (month) => {
    setScheduledSlots([]);
    setSelectedSlots([]);
    setIsShowingScheduledSlots(false);
    setSelectedDates([]);
    const selectedDate = moment().clone().month(month);
    setDate(selectedDate);
    if (selectedDate.isSame(moment(), "month")) {
      setValidRange([moment(), selectedDate.clone().endOf("month")]);
    } else {
      if (!days[selectedDate.clone().month()]) {
        setDays({
          ...days,
          [selectedDate.clone().month()]: defaultDays.map((day) => ({
            ...day,
            isSelected: false,
          })),
        });
      }

      setValidRange([
        selectedDate.clone().startOf("month"),
        selectedDate.clone().endOf("month"),
      ]);
    }
  };

  const handleDurationChange = (e, { value }) => {
    const duration = parseInt(value.split(" ")[0], 10); // Extract the numeric value
    setDurationOfSlot(duration);
    generateTimeSlots(moment(), duration, 0);
    console.log("Selected duration:", duration);
  };

  // Tutors
  const handleGetTutors = async () => {
    setIsLoadingTutors(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingTutors(false);

      if (res.data.success) {
        const tutors = res.data.result.tutors;
        setTutors(tutors);
      }
    } catch (error) {
      setIsLoadingTutors(false);
    }
  };

  const getTutorProfileData = async (mail) => {
    try {
      //   setIsLoadingData(true);
      const { slug, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/profile/get-profile/${mail}${slug}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data?.data) {
        let profileData = {
          ...data.data,
          skills: data.data.ProfileSkills,
          interests: data.data.ProfileInterests,
        };
        // setUserProfileData(profileData);
      } else {
        // setUserProfileData({});
      }
    } catch (error) {
    } finally {
      //   setIsLoadingData(false);
    }
  };

  const handleBookSlot = async () => {
    let topics = meetingData.topics;

    if (meetingData.topics.includes("Other")) {
      const otherTopics_ = otherTopics.split(",");
      topics = [
        ...topics.filter((topic) => topic !== "Other"),
        ...otherTopics_,
      ];
    }
    if (
      meetingData.name &&
      meetingData.note &&
      topics.length &&
      selectedMeetType
    ) {
      setIsSubmitting(true);
      let meetToken = "";
      let meetData = {};
      if (selectedMeetType === "isGoogleMeetMeeting") {
        let GoogleMeetToken = JSON.parse(
          localStorage.getItem("GoogleMeetToken") || "{}"
        );
        meetToken = GoogleMeetToken?.accessToken;
        meetData = {
          meetType: "google",
          mail: GoogleMeetToken?.profileObj?.email,
        };
      } else if (selectedMeetType === "isMSTeamMeeting") {
        let MSTeamsToken = localStorage.getItem("MSTeamsToken");
        meetToken = MSTeamsToken;
        meetData = {
          meetType: "microsoft",
          mail: props.user.mail,
        };
      }
      let participants = [props.user.mail];
      allSelectedStudentForMeeting.forEach((participant) => {
        !participants.includes(participant.key) &&
          participants.push(participant.key);
      });
      const payload = {
        slotId: selectedSlotId,
        title: meetingData.name,
        note: meetingData.note,
        topics: topics.join(", "),
        meetData,
        isGroupMeeting,
        participants,
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_EP_URL}/api/bookings${props.user.slug}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + props.user.accessToken,
              meetToken,
            },
          }
        );
        setIsSubmitting(false);

        if (res.data.success) {
          toast.success("Meeting Scheduled Successfully!");
          setStep(1);
          setMeetingData({ name: "", note: "", topics: [] });
          setOtherTopics("");
          setShowError(false);
          history.push("/booking");
          setSelectedTab("mySchedules");
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleUpdateBookedSlot = async () => {
    try {
      const editingSchedule =
        JSON.parse(localStorage.getItem("editingSchedule") || "{}") || null;

      if (editingSchedule) {
        let meetToken = "";
        let meetData = {};
        if (editingSchedule.meetType === "google") {
          let GoogleMeetToken = JSON.parse(
            localStorage.getItem("GoogleMeetToken") || "{}"
          );
          if (GoogleMeetToken) {
            meetToken = GoogleMeetToken?.accessToken;
            meetData = {
              meetType: "google",
              mail: GoogleMeetToken?.profileObj?.email,
            };
          } else {
            return handleLogin("isGoogleMeetMeeting");
          }
        } else if (editingSchedule.meetType === "microsoft") {
          let MSTeamsToken = localStorage.getItem("MSTeamsToken");
          if (MSTeamsToken) {
            meetToken = MSTeamsToken;
            meetData = {
              meetType: "microsoft",
              mail: props.user.mail,
            };
          } else {
            return handleLogin("isMSTeamMeeting");
          }
        }

        let payload = {
          bookingId: editingSchedule.id,
          newSlotId: selectedSlotId,
          meetData,
        };
        if (meetToken && meetData && selectedSlotId && editingSchedule.id) {
          setIsSubmitting(true);
          const res = await axios.put(
            `${process.env.REACT_APP_EP_URL}/api/bookings${props.user.slug}`,
            payload,
            {
              headers: {
                Authorization: "Bearer " + props.user.accessToken,
                meetToken,
              },
            }
          );
          setIsSubmitting(false);
          if (res.data.success) {
            toast.success("Schedule updated successfully!");
            history.push("/booking");
            setSelectedTab("mySchedules");
          } else {
            toast.error("Schedule updating failed!");
          }
        } else {
          toast.error("Fields are missing!");
        }
      }
    } catch (error) {
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  const getSchedulesMeetings = async () => {
    try {
      setIsLoadingBookings(true);
      const endPoint = props.user.isTutor
        ? `/get-by-tutor`
        : `/get-by-participant`;
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/bookings${endPoint}/${props.user.mail}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingBookings(false);

      if (res.data.success) {
        setBookings(res.data.result);
        setFilteredBookings(
          res.data.result.filter((booking) =>
            status === "scheduled"
              ? moment(booking.slot.endTime).isAfter()
              : moment(booking.slot.endTime).isBefore()
          )
        );
      }
    } catch (error) {
      setIsLoadingBookings(false);
    }
  };

  const addTutorToStarred = async (tutorEmailId) => {
    const payload = { userEmailId: user.mail, tutorEmailId };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/tutor/add-to-stared/${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        toast.success("Tutor Added to starred Successfully!");
        const starredTutor = res.data.result;
        setStarredTutors((prev) => [...prev, starredTutor]);
      } else {
        toast.error("Tutor Adding to starred Failed!");
      }
    } catch (error) {
      toast.error("Tutor Adding to starred Failed!");
    }
  };
  const getStarredTutors = async () => {
    try {
      setIsLoadingStarredTutors(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-stared-tutors/${props.user.mail}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingStarredTutors(false);
      if (res.data.success) {
        const starredTutor = res.data.result;
        setStarredTutors(starredTutor.length ? starredTutor : []);
      } else {
        setStarredTutors([]);
      }
    } catch (error) {
      setIsLoadingStarredTutors(false);
    }
  };

  const removeStarredTutor = async (tutorEmailId) => {
    try {
      const tutor = starredTutors.find(
        (tutor) => tutor.tutorEmailId === tutorEmailId
      );
      console.log("tutor", tutor);
      const res = await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/tutor/remove-stared-tutor/${tutor.id}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        toast.success("Tutor removed from starred Successfully!");
        setStarredTutors((pre) => {
          const filteredTutors = pre.length
            ? pre.filter((tutor_) => tutor?.id !== tutor_?.id)
            : [];
          return filteredTutors;
        });
      } else {
        toast.error("Tutor removing from starred Failed!");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Tutor removing from starred Failed!");
    }
  };

  const checkIsTutorStarred = (tutorEmailId) => {
    return starredTutors.some((tutor) => tutor.tutorEmailId === tutorEmailId);
  };

  const onSuccess = (res) => {
    localStorage.setItem("GoogleMeetToken", JSON.stringify(res));
  };

  const onFailure = (res) => {
    setSelectedMeetType("");
    if (typeof res?.details === "string") {
      if (res.error === "idpiframe_initialization_failed") {
        // NotificationAlert(eventTranslation.disabledCookiesWarning, "warning");
        NotificationAlert(
          "Cookies are not enabled. Please enable it if you want to create Google Meet.",
          "warning"
        );
      } else {
        NotificationAlert(res.details, "warning");
      }
    }
  };

  const handleMSLogin = (value) => {
    const instance = new PublicClientApplication({ ...msalConfig, cache: {} });
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        localStorage.setItem("MSTeamsToken", JSON.stringify(data));
      })
      .catch((e) => {
        setSelectedMeetType("");
      });
  };

  const googleLoginHandler = () => {
    const googleLoginBtn = document.querySelector(".google-login-btn");
    if (googleLoginBtn) {
      googleLoginBtn.click();
    }
  };

  const handleLogin = (type) => {
    if (type === "isMSTeamMeeting") {
      const isMSLogin = props.user.authType === "microsoft";
      if (isMSLogin) {
        localStorage.setItem("MSTeamsToken", props.user.accessToken);
      } else {
        let token = localStorage.getItem("MSTeamsToken");
        if (token) {
          handleMSLogin();
        } else {
          setOpenEventLoginModal({
            type: "isMSTeamMeeting",
            isOpen: true,
          });
        }
      }
    } else if (type === "isGoogleMeetMeeting") {
      const isGoogleLogin = props.user.authType === "google";
      if (isGoogleLogin) {
        googleLoginHandler();
      } else {
        let tokenData = localStorage.getItem("GoogleMeetToken");
        let token = null;

        if (tokenData) {
          try {
            token = JSON.parse(tokenData)?.accessToken;
          } catch (error) {}
        }

        if (token) {
          googleLoginHandler();
        } else {
          setOpenEventLoginModal({
            type: "isGoogleMeetMeeting",
            isOpen: true,
          });
        }
      }
    }
  };

  const getFilterTutorsParameters = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/bookings/get-filter-tutor-parameters${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        setFilterTutorsParameters(res.data.result);
      }
    } catch (error) {}
  };

  const filterTutors = async (isAvailableToday = false) => {
    const payload = {
      filterOptions: {
        fieldsOfStudy: selectedFilterTutorsParameters.fieldsOfStudy,
        yearsOfStudy: selectedFilterTutorsParameters.yearsOfStudy,
        yearOfExperience: selectedFilterTutorsParameters.yearsOfExperience,
        specialities: selectedFilterTutorsParameters.specialities,
        skills: selectedFilterTutorsParameters.skills,
        interests: selectedFilterTutorsParameters.interests,
        isAvailableToday,
      },
    };
    setIsLoadingTutors(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/tutor/filter-tutors${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingTutors(false);

      if (res.data.success) {
        const tutors = res.data.result;
        setTutors(tutors);
      }
    } catch (error) {
      setIsLoadingTutors(false);
    }
  };

  const onResetFilter = () => {
    setSelectedFilterTutorsParameters({
      specialities: [],
      fieldsOfStudy: [],
      yearsOfStudy: [],
      yearsOfExperience: [],
      skills: [],
      interests: [],
      isAvailableToday: false,
    });
  };

  const onApplyFilter = () => {
    const isAvailableToday = selectedFilterTutorsParameters.isAvailableToday;
    filterTutors(isAvailableToday);
  };

  const getReviewsByTutor = async (tutorMailId) => {
    try {
      setIsLoadingReviews(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/booking-review/get-by-tutor/${tutorMailId}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingReviews(false);

      if (res.data.success) {
        const reviews = res.data.result;
        setReviews(reviews);
      }
    } catch (error) {
      setIsLoadingReviews(false);
    }
  };

  const [userProfileData, setUserProfileData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, accessToken, role, mail } = props.user;
      const { data } = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-tutor-info/${mail}${slug}&role=${role}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data) {
        let profileData = {
          ...data,
          skills: data.ProfileSkills,
          interests: data.ProfileInterests,
          askMeAbout: data.ProfileSpecialities,
        };
        setUserProfileData(profileData);
        setBlockedDates(
          data?.blockedDates
            ? data?.blockedDates
                ?.split(",")
                .map((date) => moment(date).format("YYYY-MM-DD"))
            : []
        );
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  return (
    <Booking.Provider
      value={{
        date,
        bookings,
        setBookings,
        dateFullCellRender,
        validRange,
        selectedSlots,
        setSelectedSlots,
        generateTimeSlots,
        slots,
        setSlots,
        durationOfSlot,
        isSlotSelected,
        handleScheduleSlots,
        getScheduledSlots,
        isScheduling,
        setIsScheduling,
        isScheduledSlot,
        isShowingScheduledSlots,
        handleSelectDate,
        isEditing,
        setIsEditing,
        handleUpdateScheduledSlots,
        handleStartEditingSlots,
        days,
        setDays,
        handleToggleDay,
        getAllDaysOfMonth,
        handleChangeMonth,
        handleResetDays,
        selectedDates,
        isSlotBooked,
        handleDurationChange,
        handleCancelEditing,
        handleGetTutors,
        tutors,
        selectedTab,
        setSelectedTab,
        getTutorProfileData,
        user,
        scheduledSlots,
        selectedSlotId,
        setSelectedSlotId,
        step,
        setStep,
        meetingData,
        setMeetingData,
        otherTopics,
        setOtherTopics,
        showError,
        handleBookSlot,
        getSchedulesMeetings,
        starredTutors,
        getStarredTutors,
        removeStarredTutor,
        addTutorToStarred,
        checkIsTutorStarred,
        handleMSLogin,
        onFailure,
        onSuccess,
        googleLoginHandler,
        selectedMeetType,
        setSelectedMeetType,
        openEventLoginModal,
        setOpenEventLoginModal,
        handleLogin,
        isOpenBookingFiltersModel,
        setIsOpenBookingFiltersModel,
        isLoadingTutors,
        getFilterTutorsParameters,
        filterTutorsParameters,
        selectedFilterTutorsParameters,
        setSelectedFilterTutorsParameters,
        onResetFilter,
        onApplyFilter,
        globalSearch,
        setGlobalSearch,
        isLoadingStarredTutors,
        isLoadingBookings,
        isSubmitting,
        editingScheduleData,
        setEditingScheduleData,
        setSelectedDates,
        handleUpdateBookedSlot,
        getReviewsByTutor,
        isLoadingReviews,
        reviews,
        isGroupMeeting,
        setIsGroupMeeting,
        allSelectedStudentForMeeting,
        setAllSelectedStudentForMeeting,
        getNextSixMonths,
        filteredBookings,
        setFilteredBookings,
        getScheduledSlotsByDates,
        setDurationOfSlot,
        blockedDates,
        setBlockedDates,
        getProfileData,
        isLoadingData,
        userProfileData,
        setUserProfileData,
        getMinStartMaxEndFromSlotsByWeekDay,
        availabilityTimes,
        setAvailabilityTimes,
        filterTutors,
        dateRange,
        setDateRange,
        isLoadingSlots,
        setIsLoadingSlots,
        status,
        setStatus,
        singleUser,
        setSingleUser,
      }}
    >
      {children}
    </Booking.Provider>
  );
};
