import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Dialog, Flex, Image, Loader } from "@fluentui/react-northstar";
import { TextField } from "@fluentui/react";
import { Row, Col, Layout } from "antd";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import Event from "../../components/widgets/Event";
import Header from "../../components/Header/Header";
import EventFilters, {
  EventDateFilterTypes,
} from "../../components/widgets/EventFilters";
import EventsNavBar from "../../components/EventsNavBar";
import EventFilterModal from "../../components/widgets/EventFilterModal";

import { useEvent } from "../../context/EventContext";
import UserFilter from "./UserFilter";
import UserColumnFilter from "./UserColumnFilter";
import RegistrationModal from "../../components/widgets/RegistrationModal";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";

import { ResponsiveImage } from "../../components/blocks/Images";
import "./event.css";

const url = process.env.REACT_APP_EP_URL;

const EventFavourites = (props) => {
  const [accDlg, setAccDlg] = useState(false);
  const [eventData, setEventData] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isRegModalVisible, setIsRegModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  // const [selectedDateFilter, setSelectedDateFilter] = useState(
  //   EventDateFilterTypes.today
  // );

  const filterModalProps = useEvent();

  const { t } = props;

  const translation = t("events");
  const userFilterTranslation = translation.body.userFilter;
  const eventsTranslation = translation.body.favorite;

  const {
    appliedFilter,
    globalSearch,
    isSaveFilterModalOpen,
    saveUserFilter,
    setIsValidateFilterName,
    setIsSaveFilterModalOpen,
    filterName,
    setFilterName,
    isValidateFilterName,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    selectedDateFilter,
    setSelectedDateFilter,
    startDate,
    endDate,
  } = filterModalProps;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth > 767 && window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getEvents = () => {
    setIsLoading(true);
    const teacherId = props.user.mail;
    const headers = {
      Authorization: "Bearer " + props.user.accessToken,
    };
    let params = {
      isFavourite: 1,
    };
    if (!!startDate && moment(startDate).isValid()) {
      params.start = moment(startDate).format("MM-DD-YYYY");
    }
    if (!!endDate && moment(endDate).isValid()) {
      params.end = moment(endDate).format("MM-DD-YYYY");
    }

    if (isAudio) {
      params.isAudio = isAudio;
    }
    if (isVideoConf) {
      params.isVideoConf = isVideoConf;
    }
    if (isInplace) {
      params.isInplace = isInplace;
    }

    const selectedTags = filterModalProps.tags
      .filter((t) => !!t.checked)
      .map((t) => t.id);
    const selectedCategories = filterModalProps.categories
      .filter((c) => !!c.checked)
      .map((c) => c.id);

    if (selectedTags.length) {
      params.tags = JSON.stringify(selectedTags);
    }
    if (selectedCategories.length) {
      params.categories = JSON.stringify(selectedCategories);
    }

    if (globalSearch) {
      params.title = globalSearch;
    }

    if (selectedDateFilter) {
      params.dateFilter = selectedDateFilter;
      params.isFutureDateFilter = true;
    }

    axios
      .get(`${url}/api/events/discover/${teacherId}/all${props.user.slug}`, {
        headers,
        params,
      })
      .then(({ data: { result } }) => {
        setEventData(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        if (err?.response?.status === 400 || err?.response?.status === 409) {
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCategories = () => {
    axios
      .get(`${url}/api/category/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setCategories(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const getTags = () => {
    axios
      .get(`${url}/api/tag/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setTags(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const updateFavourite = (data) => {
    setIsUpdating(true);
    const { id, isFavourite } = data;
    axios
      .post(
        `${url}/api/event-favourite${props.user.slug}`,
        {
          eventId: id,
          teacherId: props.user.mail,
          isFavourite,
        },
        {
          headers: {
            Authorization: `${props.user.tokenType} ${props.user.accessToken}`,
          },
        }
      )
      .then(() => {
        getEvents();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  useEffect(() => {
    getEvents();
  }, [appliedFilter, props, globalSearch, selectedDateFilter]);

  useEffect(() => {
    getTags();
    getCategories();
  }, [props]);

  return (
    <div>
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton={translation.common.authExpire.button}
        content={
          <p className="refreshMsg">{translation.common.authExpire.message}</p>
        }
        header={translation.common.authExpire.header}
      />
      <Dialog
        open={isSaveFilterModalOpen}
        onConfirm={() => {
          setIsValidateFilterName(true);
          if (filterName?.length) {
            saveUserFilter();
            setIsSaveFilterModalOpen(false);
          }
        }}
        confirmButton={translation.common.save}
        content={
          <TextField
            value={filterName}
            onChange={(e, value) => {
              setFilterName(value);
            }}
            label={userFilterTranslation.filterName}
            style={{ background: "#F5F5F5", borderRadius: "5px" }}
            borderless={true}
            required
            errorMessage={
              isValidateFilterName
                ? filterName?.length
                  ? ""
                  : userFilterTranslation.validation
                : null
            }
          />
        }
        header={userFilterTranslation.header}
      />
      <EventFilterModal
        {...filterModalProps}
        onHandlePortal={(data) => {
          handlePortal(data);
        }}
      />
      {isMobileView ? (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="favorite-event-wrapper">
            <EventsNavBar {...props} isMobileView={isMobileView} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout
              className="tour-bookmarked-event"
              style={{ alignItems: (isMobileView || isTabletView) && "end" }}
            >
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />

              <Flex
                className="event-title"
                style={{
                  justifyContent: "center",
                  marginTop: "25px",
                  width: isMobileView && "100%",
                }}
              >
                {eventsTranslation.header}
              </Flex>
              <div className="wrapper" style={{ marginTop: "0px" }}>
                <Flex
                  vAlign="center"
                  wrap
                  space="between"
                  style={{ justifyContent: "center" }}
                >
                  <UserColumnFilter component="Favourites" />
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {eventData.length ? (
                  <div className="favorite-events-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {eventData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: `${100 / selectedColumns}%`,
                              minWidth: "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              data={data}
                              tags={tags}
                              categories={categories}
                              type={"favourite"}
                              updateFavourite={(data) => updateFavourite(data)}
                              selectedColumns={selectedColumns}
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              loading={{
                                isLoading: isUpdating,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <Image
                        style={{ maxWidth: "280px", width: "100%" }}
                        src={"/Images/Events/startled-pana.svg"}
                      />
                      <h3 style={{ marginTop: 20 }}>
                        {eventsTranslation.noBookmarkedEventMessage}
                      </h3>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      ) : (
        <div className="Attendance Whiteboard s-ctr">
          <EventFilters
            onHandlePortal={(data) => {
              handlePortal(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            selectedDateFilter={selectedDateFilter}
            onDateFilterClickHandler={(selectedOption) => {
              setSelectedDateFilter(selectedOption);
            }}
          />
          <Layout className="favorite-event-wrapper">
            <EventsNavBar {...props} />
            <RegistrationModal
              visible={isRegModalVisible}
              onCancel={setIsRegModalVisible}
              data={!!selectedEvent && selectedEvent.attendees}
              user={props.user}
            />
            <Layout className="tour-bookmarked-event">
              <CustomProgressIndicator isLoading={isUpdating || isLoading} />

              <div className="wrapper">
                <Flex vAlign="center" wrap space="between">
                  <Flex
                    className="event-title"
                    style={{ marginBottom: isTabletView && "0px" }}
                  >
                    {eventsTranslation.header}
                  </Flex>

                  <UserColumnFilter component="Favourites" />
                </Flex>
                {isFilterApplied ? <UserFilter /> : null}
                {eventData.length ? (
                  <div className="favorite-events-cards-wrapper">
                    <Flex column>
                      <Flex wrap>
                        {eventData.map((data) => (
                          <Flex
                            key={data.id}
                            style={{
                              width: `${100 / selectedColumns}%`,
                              minWidth: "260px",
                              padding: "0 10px",
                              position: "relative",
                            }}
                          >
                            <Event
                              {...props}
                              data={data}
                              tags={tags}
                              categories={categories}
                              type={"favourite"}
                              updateFavourite={(data) => updateFavourite(data)}
                              selectedColumns={selectedColumns}
                              attendeesModal={(data) => {
                                setSelectedEvent(data);
                                setIsRegModalVisible(true);
                              }}
                              loading={{
                                isLoading: isUpdating,
                              }}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  </div>
                ) : (
                  !(isUpdating || isLoading) && (
                    <div
                      className="wrapper"
                      style={{ textAlign: "center", marginTop: 82 }}
                    >
                      <Image
                        // className="events-pana"
                        style={{ maxWidth: "280px", width: "100%" }}
                        src={"/Images/Events/startled-pana.svg"}
                      />
                      <h3 style={{ marginTop: 20 }}>
                        {eventsTranslation.noBookmarkedEventMessage}
                      </h3>
                      {/* <h2 style={{ marginTop: 20 }}>{eventsTranslation.noEvents}</h2>
              <h4>{eventsTranslation.noEventMessage}</h4> */}
                    </div>
                  )
                )}
              </div>
            </Layout>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(EventFavourites);
