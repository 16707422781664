import React, { useState } from "react";
import verify from "../../Assets/images/verify.png";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  TextField,
  Autocomplete,
  Badge,
  Grid,
  Box,
  Checkbox,
} from "@mui/material";
function GptRequestedTableMobile({
  teacher,
  credits,
  SmallAvatar,
  rejectCredit,
  assignCredit,
  setCreditValue,
  index,
  requestedData,
  setRequestedData,
  creditInfo,
}) {
  const [loading, setLoading] = useState(false);

  const handleSelectCheckboxLocal = () => {
    const selectedTeachers = requestedData.map((teacher, currentIndex) => {
      if (currentIndex === index) {
        return { ...teacher, isSelected: !teacher.isSelected };
      }
      return teacher;
    });
    setRequestedData(selectedTeachers);
  };

  return (
    <Grid
      xs={12}
      sm={5.8}
      md={6}
      lg={6}
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.1)",
        padding: "8px",
        paddingLeft: "10px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <Badge
          overlap="circular"
          style={{ zIndex: "0" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={
            teacher.status !== "pending" && (
              <SmallAvatar
                alt="icon"
                src={
                  (teacher.status === "rejected" && unpublished_icon) ||
                  (teacher.status === "approved" && verify)
                }
              />
            )
          }
        >
          <Avatar>{teacher.UrlPhoto}</Avatar>
        </Badge>
        <p
          className="data-teacher"
          style={{
            margin: "0px",
            display: "inline-block",
            marginTop: "8px",
            marginLeft: "10px",
          }}
        >
          {teacher.role.toLowerCase() === "student"
            ? teacher.StudentName
            : teacher.TeacherName}
        </p>
        {/* <Checkbox
          checked={teacher.isSelected}
          onChange={handleSelectCheckboxLocal}
          inputProps={{ "aria-label": "controlled" }}
        /> */}
      </Box>
      <Box sx={{ marginLeft: "20px" }}>
        <p className="email-wrap">
          {teacher.role.toLowerCase() === "student"
            ? teacher.StudentEmailID
            : teacher.TeacherEmailID}
        </p>
      </Box>
      <Box sx={{ display: "flex", gap: "15px", marginLeft: "20px" }}>
        <p>{teacher.role}</p>
        <p>
          {/* {teacher.creditsToAssign && 5000 - teacher.creditsToAssign} */}
          {teacher.credit}
        </p>
        <Autocomplete
          className="combo-box-demo-mobile"
          options={credits}
          getOptionLabel={(option) => option.toString()} // Ensure all options are strings
          freeSolo
          value={teacher.creditsToAssign || 0}
          sx={{
            minWidth: 90,
            marginTop: "-5px",
            height: "30px !important",
          }}
          size="small"
          onInputChange={(e, newValue) => {
            setCreditValue(parseInt(newValue) || 0, teacher);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              type="number"
              label="Select Credit"
              error={
                teacher.creditsToAssign > Number(creditInfo.credit) ||
                teacher.creditsToAssign <= 0
              }
              helperText={
                (teacher.creditsToAssign > Number(creditInfo.credit) &&
                  "Exceed your credit limits") ||
                (teacher.creditsToAssign <= 0 && "Enter a valid credit")
              }
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "0px",
          justifyContent: "end",
        }}
      >
        {teacher.status === "approved" || teacher.status === "rejected" ? (
          <LoadingButton
            disabled
            loadingPosition="start"
            className={
              teacher.status === "approved" ? "iconButton" : "rejectIcon"
            }
            sx={{
              color: teacher.status === "approved" ? "green" : "red",
              height: "35px",
              textTransform: "capitalize",
            }}
            endIcon={
              teacher.status === "approved" ? (
                <img
                  src={verify}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              ) : (
                <img
                  src={unpublished_icon}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              )
            }
          >
            {teacher.status === "rejected"
              ? "Rejected"
              : teacher.id
              ? "Approved"
              : "Assigned"}
          </LoadingButton>
        ) : (
          <>
            <LoadingButton
              className="assigned"
              disabled={
                teacher.creditsToAssign > 5000 || teacher.creditsToAssign <= 0
              }
              loading={loading}
              loadingPosition="start"
              variant="contained"
              style={{
                backgroundColor:
                  teacher.requested === true ? "#ffcc00" : "rgb(98, 100, 167)",
                height: "28px",
                alignItems: "center",
                textTransform: "capitalize",
                marginTop: "10px",
                marginBottom: "10px",
                width: "5px",
                fontSize: "11px",
              }}
              onClick={() =>
                assignCredit(
                  { ...teacher, status: "approved" },
                  !teacher.requested === true && {
                    vertical: "top",
                    horizontal: "right",
                  },
                  setLoading
                )
              }
            >
              {loading ? "" : teacher.requested === true ? "Approve" : "Assign"}
            </LoadingButton>
            <LoadingButton
              loadingPosition="start"
              style={{
                borderColor: "red",
                color: "red",
                height: "28px",
                textTransform: "capitalize",
                marginTop: "10px",
                marginBottom: "10px",
                width: "5px",
                fontSize: "11px",
              }}
              onClick={() =>
                rejectCredit(
                  { ...teacher, status: "rejected" },
                  !teacher.requested === true && {
                    vertical: "top",
                    horizontal: "right",
                  }
                )
              }
            >
              {teacher.requested === true && "Reject"}
            </LoadingButton>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default GptRequestedTableMobile;
