import ProfileInterest from "./Interests";
import ProfileSkills from "./Skills";
import Tabs from "./Tabs/Tabs";

import "./Profile.scss";
import { useTranslation } from "react-i18next";

const ProfileSetting = ({ user, addSkillForProfile, setAddSkillForProfile,addInterestForProfile,setAddInterestForProfile }) => {
  const { t } = useTranslation();
  return (
    <Tabs>
      <div label={t("setting").profile[0]}>
        <ProfileSkills
          user={user}
          addSkillForProfile={addSkillForProfile}
          setAddSkillForProfile={setAddSkillForProfile}
        />
      </div>
      <div label={t("setting").profile[1]}>
        <ProfileInterest
          user={user}
          addInterestForProfile={addInterestForProfile}
          setAddInterestForProfile={setAddInterestForProfile}
        />
      </div>
    </Tabs>
  );
};
export default ProfileSetting;
