import axios from "axios";

export async function fetchBlob(blobUrl) {
  try {
    const response = await axios.get(blobUrl, { responseType: "blob" });
    return response.data;
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
