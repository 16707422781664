import Tabs from "./Tabs/Tabs";

import { EventCategory } from "./EventCantegory/eventCategory";
import { EventTag } from "./EventTag/eventTag";
import { useTranslation } from "react-i18next";

export const Events = ({
  user,
  isMobileView,
  addEventCategory,
  setAddEventCategory,
  addEventsTag,
  setAddEventsTag,
}) => {
  const { t } = useTranslation();

  return (
    <div className="events-setting-tabs-wrapper">
      <Tabs currentTab={t("setting").event[0]}>
        <div label={t("setting").event[0]}>
          <EventCategory
            user={user}
            isMobileView={isMobileView}
            addEventCategory={addEventCategory}
            setAddEventCategory={setAddEventCategory}
          />
        </div>
        <div label={t("setting").event[1]}>
          <EventTag
            user={user}
            isMobileView={isMobileView}
            addEventsTag={addEventsTag}
            setAddEventsTag={setAddEventsTag}
          />
        </div>
      </Tabs>
    </div>
  );
};
