import React, { useState } from "react";
import verify from "../../Assets/images/verify.png";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  TextField,
  Autocomplete,
  Badge,
  Checkbox,
} from "@mui/material";

function GptRequestedDataTable({
  teacher,
  index,
  credits,
  SmallAvatar,
  rejectCredit,
  assignCredit,
  setCreditValue,
  isTabletView,
  creditInfo,
  requestedData,
  setRequestedData,
}) {
  const [loading, setLoading] = useState(false);

  const handleSelectCheckboxLocal = () => {
    const selectedTeachers = requestedData.map((teacher, currentIndex) => {
      if (currentIndex === index) {
        return { ...teacher, isSelected: !teacher.isSelected };
      }
      return teacher;
    });
    setRequestedData(selectedTeachers);
  };

  return (
    <li className="table-row" key={teacher.TeacherEmailID}>
      {/* <Checkbox
        checked={teacher.isSelected}
        onChange={handleSelectCheckboxLocal}
        inputProps={{ "aria-label": "controlled" }}
      /> */}
      <div className="col col-1" data-label="Index" style={{ padding: "18px" }}>
        {index + 1}
      </div>
      <div
        className="col col-2"
        data-label="Image"
        style={{
          padding: "8px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Badge
          overlap="circular"
          style={{ zIndex: "0" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={
            teacher.status !== "pending" && (
              <SmallAvatar
                alt="icon"
                src={
                  (teacher.status === "rejected" && unpublished_icon) ||
                  (teacher.status === "approved" && verify)
                }
              />
            )
          }
        >
          <Avatar src={teacher.UrlPhoto} />
        </Badge>
      </div>
      <div className="col col-3" data-label="Name" style={{ padding: "18px" }}>
        {teacher.role.toLowerCase() === "student"
          ? teacher.StudentName
          : teacher.TeacherName}
      </div>
      <div className="col col-4" data-label="Email" style={{ padding: "18px" }}>
        {teacher.role.toLowerCase() === "student"
          ? teacher.StudentEmailID
          : teacher.TeacherEmailID}
      </div>
      <div className="col col-5" data-label="Role" style={{ padding: "18px" }}>
        {teacher.role}
      </div>
      <div
        className="col col-6"
        data-label="Available Credits"
        style={{ padding: "18px" }}
      >
        {teacher.credit}
      </div>
      <div className="col col-7" data-label="Assign Credits">
        <Autocomplete
          id="combo-box-demo"
          options={credits}
          getOptionLabel={(option) => option.toString()} // Ensure all options are strings
          freeSolo
          value={creditInfo.credit === 0 ? 0 : teacher.creditsToAssign || 0}
          sx={{ m: 1, minWidth: 120 }}
          size="small"
          onInputChange={(e, newValue) => {
            setCreditValue(parseInt(newValue) || 0, teacher);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              type="number"
              label="Select Credit"
              error={
                creditInfo.credit === 0
                  ? null
                  : teacher.creditsToAssign > creditInfo.credit ||
                    teacher.creditsToAssign <= 0
              }
              helperText={
                (creditInfo.credit === 0
                  ? null
                  : teacher.creditsToAssign > creditInfo.credit &&
                    "Exceed your credit limits") ||
                (creditInfo.credit === 0
                  ? null
                  : teacher.creditsToAssign <= 0 && "Enter a valid credit")
              }
            />
          )}
        />
      </div>
      <div
        className={isTabletView ? "col col-9" : "col col-8"}
        data-label="Action"
        style={{
          padding: "10px",
          paddingRight: teacher.status
            ? teacher.status === "approved"
              ? "51px"
              : "56px"
            : "10px",
        }}
      >
        {teacher.status === "approved" || teacher.status === "rejected" ? (
          <LoadingButton
            disabled
            loadingPosition="start"
            className={
              teacher.status === "approved" ? "iconButton" : "rejectIcon"
            }
            sx={{
              color: teacher.status === "approved" ? "green" : "red",
              height: "35px",
              textTransform: "capitalize",
            }}
            endIcon={
              teacher.status === "approved" ? (
                <img
                  src={verify}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              ) : (
                <img
                  src={unpublished_icon}
                  style={{
                    marginRight: "5px",
                    width: "22px",
                    height: "22px",
                  }}
                />
              )
            }
          >
            {teacher.status === "rejected"
              ? "Rejected"
              : teacher.id
              ? "Approved"
              : "Assigned"}
          </LoadingButton>
        ) : (
          <>
            <LoadingButton
              className="assigned"
              disabled={
                teacher.creditsToAssign > creditInfo.credit ||
                teacher.creditsToAssign <= 0
              }
              loading={loading}
              loadingPosition="start"
              variant="contained"
              style={{
                backgroundColor:
                  teacher.requested === true ? "#ffcc00" : "rgb(98, 100, 167)",
                height: "35px",
                alignItems: "baseline",
                textTransform: "capitalize",
                width: "86px",
              }}
              onClick={() => {
                assignCredit(
                  { ...teacher, status: "approved" },
                  !teacher.requested === true && {
                    vertical: "top",
                    horizontal: "right",
                  },
                  setLoading
                );
              }}
            >
              {loading ? "" : teacher.requested === true ? "Approve" : "Assign"}
            </LoadingButton>
            {teacher.requested === true && (
              <LoadingButton
                loadingPosition="start"
                style={{
                  borderColor: "red",
                  color: "red",
                  height: "35px",
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  rejectCredit(
                    { ...teacher, status: "rejected" },
                    !teacher.requested === true && {
                      vertical: "top",
                      horizontal: "right",
                    }
                  )
                }
              >
                {teacher.requested === true && "Reject"}
              </LoadingButton>
            )}
          </>
        )}
      </div>
    </li>
  );
}

export default GptRequestedDataTable;
