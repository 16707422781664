import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";

const DonutChart = ({ data, total, providedStorage, setSelectedExtension }) => {
  const [series, setSeries] = useState([]); // For chart series
  const [options, setOptions] = useState({
    chart: {
      type: "donut",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedIndex = config.dataPointIndex;
          const selectedLabel = config.w.config.labels[selectedIndex];
          setSelectedExtension(selectedLabel); // Update selected label
        },
      },
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  });

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      // If data is available, update series and labels
      const updatedSeries = Object.values(data); // Extract values for the series
      const updatedLabels = Object.keys(data); // Extract keys for the labels

      setSeries(updatedSeries);
      setOptions((prevOptions) => ({
        ...prevOptions,
        labels: updatedLabels,
      }));
    } else {
      // Default values if data is not available or empty
      const defaultLabels = ["Assignment", "Whiteboard", "Event", "Feedback"];
      const defaultSeries = [0, 0, 0, 0]; // Default values for series

      setSeries(defaultSeries);
      setOptions((prevOptions) => ({
        ...prevOptions,
        labels: defaultLabels,
      }));
    }
  }, [data]);

  const totalStorage = useMemo(() => {
    const totalAvailable = total?.toFixed(4) || 0;
    return `${totalAvailable}/${providedStorage} GB`;
  }, [total, providedStorage]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Chart
        options={options}
        series={series}
        type="donut"
        width="400"
        height="350"
      />
      {/* Dynamic Centered Text */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
          fontSize: "16px",
          fontWeight: "bold",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => setSelectedExtension("all")}
      >
        {totalStorage}
      </div>
    </div>
  );
};

export default DonutChart;
