import React from "react";
import userSvg from "../../../../../../Assets/images/svg/user.svg";
import {
  Divider,
  Flex,
  Image,
  Label,
  Loader,
  Status,
  Text,
} from "@fluentui/react-northstar";
import { useTutorRequest } from "../../../../../../context/TutorRequestContext";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import "./TutorInfoCard.scss";

const TutorInfoCard = () => {
  const { isLoadingTutorInfo, tutorInfo } = useTutorRequest();

  const name =
    (
      (tutorInfo?.firstName?.trim() || "") +
      " " +
      (tutorInfo?.lastName?.trim() || "")
    ).trim() ||
    tutorInfo?.email?.split("@")[0]?.split(".")[0] ||
    "";
  return !isLoadingTutorInfo ? (
    <Flex gap="gap.medium" fill className="tutor-info-card">
      <Flex column gap="gap.medium">
     
        <Image
          src={
            tutorInfo?.image
              ? tutorInfo.image +
                process.env.REACT_APP_AZURE_SAS_TOKEN.replace(/"/g, "")
              : userSvg
          }
          style={{ width: "150px" }}
        />
        <div>
          <Flex gap="gap.medium">
            <Text content={name} style={{ textTransform: "capitalize" }} />
            <Text
              style={{ textTransform: "capitalize" }}
              content={
                <>
                  <Status
                    style={{
                      backgroundColor: "#FFAA44",
                      margin: "0 3px 3px 0",
                    }}
                  />
                  {tutorInfo?.role}
                </>
              }
            />
          </Flex>
          <Text content={tutorInfo?.email} weight="regular" />
        </div>
      </Flex>
      <Flex column gap="gap.small">
        <Divider content="Skills" />
        <Flex gap="gap.smaller" wrap>
          {tutorInfo?.skills?.length > 0 ? (
            tutorInfo?.skills?.map((skill) => (
              <Label
                key={skill.id}
                content={skill.title}
                circular
                className="skill-label"
              />
            ))
          ) : (
            <Text
              content="No data"
              align="center"
              style={{ width: "100%" }}
              weight="bold"
            />
          )}
        </Flex>
        <Divider content="Interests" />
        <Flex gap="gap.smaller" wrap>
          {tutorInfo?.interests?.length > 0 ? (
            tutorInfo?.interests?.map((interest) => (
              <Label
                key={interest.id}
                content={interest.title}
                circular
                className="interest-label"
              />
            ))
          ) : (
            <Text content="No data" align="center" style={{ width: "100%" }} />
          )}
        </Flex>
        <Divider content="Ask Me About" />
        <Flex gap="gap.smaller" wrap>
          {tutorInfo?.askMeAbout?.length > 0 ? (
            tutorInfo?.askMeAbout?.map((askMeAbout) => (
              <Label
                key={askMeAbout.id}
                content={askMeAbout.title}
                circular
                className="ask-me-about-label"
              />
            ))
          ) : (
            <Text content="No data" align="center" style={{ width: "100%" }} />
          )}
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <Flex hAlign="center" vAlign="center" fill style={{ height: "100%" }}>
      <Loader />
    </Flex>
  );
};

export default TutorInfoCard;
