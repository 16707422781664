import "./storage.css";
import { Alert, Avatar, Grid, Snackbar, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import StorageRequestedDataTable from "./StorageRequestedDataTable";
import StorageRequestedTableMobile from "./StorageRequestedTableMobile";
import StorageSection from "./StorageSection";
import { Loader } from "@fluentui/react-northstar";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_EP_URL;

function Storage(props) {
  const storage = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
  const [requestedData, setRequestedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [searchValue, setSearchValue] = useState("");
  const [storageRequest, setStorageRequests] = useState([]);
  const [isRequested, setIsRequested] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [storageInfo, setStorageInfo] = useState({});
  const [checked, setChecked] = useState(false);
  const { vertical, horizontal, open } = state;
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 18,
    backgroundColor: "white",
    borderRadius: "50%",
  }));

  useEffect(() => {
    getStorageRequest();
    getStorageInfo();
  }, []);
  useEffect(() => {
    getStorageInfo();
  }, []);
  useEffect(() => {
    if (!isRequested) {
      getRequestedData();
    }
  }, [requestedData, isRequested]);
  const getStorageInfo = () => {
    const mail = props.user.mail;
    axios
      .get(`${url}/api/storage/info${props.user.slug}&email=${mail}`, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`,
        },
      })
      .then((response) => {
        setStorageInfo(response.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRequestedData = () => {
    const requestedTeachers = requestedData.filter(
      (teacher) => teacher.requested === true
    );
    const nonRequestedTeachers = requestedData.filter(
      (teacher) => teacher.requested !== true
    );
    setRequestedData([...requestedTeachers, ...nonRequestedTeachers]);
    setIsRequested(true);
  };
  const getStorageRequest = () => {
    setIsLoading(true);
    axios
      .get(`${url}/api/storage/request${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then((res) => {
        const response = res.data.result;
        const modifiedRes = response.map((request) => {
          return {
            ...request,
            storageToAssign: 5,
            isSelected: false,
          };
        });
        const storageHistory = modifiedRes.filter(
          (data) => data.status !== "pending"
        );
        props.setStorageHistory(storageHistory);
        setStorageRequests(modifiedRes);
        setRequestedData(modifiedRes);
        setIsRequested(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const updateStatus = async (payload) => {
    try {
      const res = await axios.put(
        `${url}/api/storage/request/${props.user.slug}`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const response = res.data.result;
      return response;
    } catch (error) {
      console.error("error", error);
    }
  };
  const assignStorage = async (currentRequest, setLoading) => {
    try {
      setLoading(true);
      const { status, storageToAssign, role, email, id } = currentRequest;

      if (status && email) {
        let payload = {
          email,
          role,
          status,
          storage: storageToAssign,
        };
        payload["id"] = currentRequest.id;
        const newData = requestedData.filter((request) => request.id !== id);
        setRequestedData([...newData]);

        const res = await updateStatus(payload);
        if (!res) {
          toast.error("Something went wrong");
          setLoading(false);
          return;
        }
        props.setStorageHistory([...props.storageHistory, currentRequest]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong");
    }
  };
  const rejectStorage = async (currentRequest) => {
    console.log("currentRequest", currentRequest);
    const { role, status, email, id } = currentRequest;

    if (status && email) {
      let payload = {
        email,
        role,
        status,
        storage: 0,
      };
      payload["id"] = currentRequest.id;
      const res = await updateStatus(payload);

      const newData = requestedData.filter((request) => request.id !== id);
      setRequestedData([...newData]);
      props.setStorageHistory([...props.storageHistory, currentRequest]);
    }
  };
  const setStorageValue = (storage, currentRequest) => {
    const updatedRequestedRequest = requestedData.map((request) => {
      if (request.email === currentRequest.email) {
        return { ...request, storageToAssign: storage };
      }
      return request;
    });
    setRequestedData(updatedRequestedRequest);
  };
  const handleChange = (e) => {
    //search by name and email
    const searchValue = e.target.value.toLowerCase();
    setSearchValue(searchValue);
    if (searchValue === "") {
      setRequestedData([...storageRequest]);
      return;
    }
    const searchedData = requestedData.filter(
      (data) =>
        data.name.toLowerCase().includes(searchValue) ||
        data.email.toLowerCase().includes(searchValue)
    );
    setRequestedData([...searchedData]);
  };
  const handleClose = (newState) => {
    setState({ ...newState, open: false });
  };
  const closeSnackbar = () => {
    setIsOpen(false);
  };
  const handleSelectCheckbox = (isChecked) => {
    setChecked((prevChecked) => !prevChecked);
    const selectedTeachers = requestedData.map((teacher) => {
      return { ...teacher, isSelected: isChecked };
    });
    setRequestedData(selectedTeachers);
  };

  return (
    <div className="d-flex flex-column">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => handleClose({ vertical: "top", horizontal: "right" })}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Storage Assigned succesfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={closeSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Storage Rejected succesfully!
        </Alert>
      </Snackbar>
      <StorageSection
        isMobileView={props.isMobileView}
        handleChange={handleChange}
        storageInfo={storageInfo}
        checked={checked}
        handleSelectCheckbox={handleSelectCheckbox}
        searchValue={searchValue}
      />
      <div className={props.isMobileView ? "mobile-container" : "container"}>
        <ul
          className={props.isMobileView ? "mobile-table" : "responsive-table"}
        >
          {!props.isMobileView && (
            <li className="table-header">
              <div className="col col-1">#</div>
              <div className="col col-2">Image</div>
              <div className="col col-3">Name</div>
              <div className="col col-4">Email</div>
              <div className="col col-5">Role</div>
              <div className="col col-6">Assign Storage</div>
              <div className={props.isTabletView ? "col col-8" : "col col-7"}>
                Action
              </div>
            </li>
          )}
          {isLoading ? (
            <Loader
              styles={{ justifyContent: "center", alignItems: "center" }}
            />
          ) : props.isMobileView ? (
            <>
              {requestedData.length > 0 ? (
                <div
                  style={{
                    overflow: "auto",
                    height: "calc(100vh - 330px)",
                    padding: "20px",
                    paddingRight: "7px",
                  }}
                >
                  <Grid container spacing={1} sx={{ gap: "10px" }}>
                    {requestedData.map(
                      (request, index) =>
                        request?.role.toLowerCase() !== "superadmin" &&
                        request.status === "pending" && (
                          <StorageRequestedTableMobile
                            request={request}
                            index={index}
                            storage={storage}
                            SmallAvatar={SmallAvatar}
                            rejectStorage={rejectStorage}
                            assignStorage={assignStorage}
                            setStorageValue={setStorageValue}
                            isMobileView={props.isMobileView}
                            storageInfo={storageInfo}
                          />
                        )
                    )}
                  </Grid>
                </div>
              ) : (
                <p style={{ display: "flex", justifyContent: "center" }}>
                  No Result Found
                </p>
              )}
            </>
          ) : (
            <>
              {requestedData.length > 0 ? (
                <div
                  style={{
                    overflow: "auto",
                    height: props.isTabletOrMobileView
                      ? "calc(100vh - 300px)"
                      : "calc(100vh - 470px)",
                    padding: "5px",
                  }}
                >
                  {requestedData?.map(
                    (request, index) =>
                      request?.role.toLowerCase() !== "superadmin" &&
                      request.status === "pending" && (
                        <StorageRequestedDataTable
                          request={request}
                          index={index}
                          storage={storage}
                          SmallAvatar={SmallAvatar}
                          rejectStorage={rejectStorage}
                          assignStorage={assignStorage}
                          setStorageValue={setStorageValue}
                          isTabletView={props.isTabletView}
                          storageInfo={storageInfo}
                        />
                      )
                  )}
                </div>
              ) : (
                <p style={{ display: "flex", justifyContent: "center" }}>
                  No Result Found
                </p>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Storage;
