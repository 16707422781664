import React, { useEffect, useState } from "react";
import {
  CallVideoIcon,
  CloseIcon,
  FilesTextColoredIcon,
  Flex,
  LinkIcon,
  MicIcon,
  OcrOnIcon,
  PauseIcon,
  PlayIcon,
  Text,
  TextArea,
  Button,
  Card,
  CardFooter,
  ArchiveIcon,
  Attachment,
  ToDoListIcon,
  ShareGenericIcon,
  SpeakerPersonIcon,
} from "@fluentui/react-northstar";
import DialogVoice from "../Dialog/DialogVoice";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { TextField } from "@fluentui/react/lib/TextField";
import { FileUploader } from "react-drag-drop-files";
import { ToastContainer, toast } from "react-toastify";
// import { useReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "react-video-recorder";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../../../../components/Notification/Notification";

import "./AttachmentOption.css";
import "react-toastify/dist/ReactToastify.css";
import { getUserUsedStorage } from "../../../../../Assets/api/api";
import { StorageRequestDialog } from "../../../../../components/Storage/StorageRequestDialog";

export const getUniqueId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

const ReturnTextContent = ({
  val,
  setVal,
  isActive,
  attachmentTranslation,
}) => {
  return (
    <>
      <div>
        <TextArea
          fluid
          value={val}
          rows={12}
          readOnly={!isActive}
          style={{ color: "black" }}
          placeholder={attachmentTranslation.text.placeholder}
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
      </div>
    </>
  );
};

const LinkTextField = ({ val, setVal, attachmentTranslation }) => {
  return (
    <TextField
      style={{
        background: "#F5F5F5",
        width: "100%",
        borderRadius: "5px",
        minWidth: "176px",
        height: "30px",
      }}
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
      }}
      borderless
      placeholder={attachmentTranslation.link.placeholder}
    />
  );
};

const ReturnLinkContent = ({
  val,
  setVal,
  linkVal,
  handleAdd,
  attachmentTranslation,
}) => {
  return (
    <div>
      <Text content={attachmentTranslation.link.header} />
      <Flex wrap space="between" style={{ marginTop: "10px", rowGap: "15px" }}>
        <LinkTextField
          val={val}
          setVal={(val) => setVal(val)}
          attachmentTranslation={attachmentTranslation}
        />
        <Button
          content={attachmentTranslation.add}
          style={{ width: "30px" }}
          onClick={handleAdd}
        />
      </Flex>
    </div>
  );
};

const AttachmentOption = (props) => {
  const {
    isSubmissionPage,
    isShowAvatarVideo,
    currentAssignmentData,
    setStudentAttachments,
    studentAttachments,
    studentAttachmentsForAssignment,
    setStudentAttachmentsForAssignment,
    t,
    selectedExtension,
  } = props;

  const [linkText, setLinkText] = useState("");
  const [links, setLinks] = useState([]);
  const [linkState, setLinkState] = useState({
    open: false,
    isSave: false,
  });

  const [text, setText] = useState("");
  const [textState, setTextState] = useState({
    open: false,
    isSave: false,
    isChanged: false,
  });
  const [file, setFile] = useState([]);
  const [fileState, setFileState] = useState({
    open: false,
    isSave: false,
  });

  const [voiceState, setVoiceState] = useState({
    open: false,
    isStart: false,
    isSaved: false,
    isPause: false,
    isDone: false,
    audioUrl: "",
  });

  const [videoState, setVideoState] = useState({
    open: false,
    isStart: false,
    isSaved: false,
    isDone: false,
    videoUrl: "",
  });

  const [isConfirmDelete, setisConfirmDelete] = useState(false);

  const [confirmTextDelete, setConfirmTextDelete] = useState(false);
  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false);
  const [isAssignmentSubmitted, setIsAssignmentSubmitted] = useState(false);
  const [avatarVideoUrl, setAvatarVideoUrl] = useState("");
  const [isOpenAvatarVideo, setIsOpenAvatarVideo] = useState(false);
  const [storageRequestDialog, setStorageRequestDialog] = useState(false);
  const [storage, setStorage] = useState(null);
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorder();

  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  useEffect(() => {
    if (isShowAvatarVideo && currentAssignmentData.Attachments.length) {
      const avatarVideoUrl = currentAssignmentData.Attachments.find(
        (attachment) => attachment.type === "avatarVideo"
      )?.url;
      setAvatarVideoUrl(avatarVideoUrl);
    }
  }, [currentAssignmentData.Attachments, isShowAvatarVideo]);

  useEffect(() => {
    setIsAssignmentSubmitted(
      ["Completed", "Grades Pending"].includes(
        currentAssignmentData.submissionStatus
      )
    );
    if (isSubmissionPage) {
      setLinks(
        studentAttachmentsForAssignment?.find((item) => item.type === "link")
          ?.info || []
      );

      setLinkState({
        open: false,
        isSave: !!studentAttachmentsForAssignment?.find(
          (item) => item.type === "link"
        )?.info?.length,
      });

      setText(
        studentAttachmentsForAssignment?.find((item) => item.type === "text")
          ?.info || ""
      );
      setTextState(() =>
        studentAttachmentsForAssignment?.find((item) => item.type === "text")
          ?.info
          ? {
              open: false,
              isSave: true,
              isChanged: false,
            }
          : {
              open: false,
              isSave: false,
              isChanged: false,
            }
      );
      setFile(
        studentAttachmentsForAssignment?.find((item) => item.type === "file")
          ?.info || []
      );
      setFileState({
        open: false,
        isSave: false,
      });

      setVoiceState(() => {
        let voiceInfoFromCurrentAssignmentData =
          studentAttachmentsForAssignment?.find(
            (item) => item.type === "voice"
          )?.info;
        return voiceInfoFromCurrentAssignmentData
          ? {
              open: false,
              isStart: false,
              isSaved: true,
              isPause: false,
              isDone: true,
              audioUrl: voiceInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              isStart: false,
              isSaved: false,
              isPause: false,
              isDone: false,
              audioUrl: "",
            };
      });

      setVideoState(() => {
        let videoInfoFromCurrentAssignmentData =
          studentAttachmentsForAssignment?.find(
            (item) => item.type === "video"
          )?.info;
        return videoInfoFromCurrentAssignmentData
          ? {
              open: false,
              isStart: false,
              isSaved: true,
              isDone: true,
              videoUrl: videoInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              isStart: false,
              isSaved: false,
              isDone: false,
              videoUrl: "",
            };
      });
    } else {
      let teacherAttachments = currentAssignmentData?.attachments;
      setLinks(
        teacherAttachments?.find((item) => item.type === "link")?.info || []
      );

      setLinkState({
        open: false,
        isSave: !!teacherAttachments?.find((item) => item.type === "link")?.info
          ?.length,
      });

      setText(
        teacherAttachments?.find((item) => item.type === "text")?.info || ""
      );
      setTextState({
        open: false,
      });
      setFile(
        teacherAttachments?.find((item) => item.type === "file")?.info || []
      );
      setFileState({
        open: false,
        isSave: false,
      });

      setVoiceState(() => {
        let voiceInfoFromCurrentAssignmentData = teacherAttachments?.find(
          (item) => item.type === "voice"
        )?.info;
        return voiceInfoFromCurrentAssignmentData
          ? {
              open: false,
              audioUrl: voiceInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              audioUrl: "",
            };
      });

      setVideoState(() => {
        let videoInfoFromCurrentAssignmentData = teacherAttachments?.find(
          (item) => item.type === "video"
        )?.info;
        return videoInfoFromCurrentAssignmentData
          ? {
              open: false,
              videoUrl: videoInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              videoUrl: "",
            };
      });
    }
  }, []);

  useEffect(() => {
    console.log("props.user", props.user);
    const getStorage = async () => {
      const storage = await getUserUsedStorage(
        props.user.slug,
        props.user.mail,
        props.user.actualRole
      );
      if (storage?.result) {
        setStorage({
          usedStorage: storage?.result?.usedStorage,
          providedStorage: storage?.result?.providedStorage,
        });
      }
    };
    getStorage();
  }, [props.user]);

  useEffect(() => {
    if (audioResult) {
      setVoiceState((prev) => ({
        ...prev,
        audioUrl: audioResult,
      }));
    }
  }, [audioResult]);

  useEffect(() => {
    if (
      studentAttachmentsForAssignment?.find((item) => item.type === "text")
        ?.info != text
    ) {
      setTextState((prevState) => ({ ...prevState, isChanged: true }));
    }
  }, [text]);

  const handleChange = (newFile) => {
    Array.from(newFile)?.forEach((item) => {
      if (typeof item === "object" && item?.size / 1048576 > 25) {
        NotificationAlert(attachmentTranslation.file.sizeValidation, "warning");
      } else {
        const sizeInGB = item.size / (1024 * 1024 * 1024);
        if (storage?.usedStorage + sizeInGB >= storage?.providedStorage) {
          setFileState({
            open: false,
            isSave: false,
          });
          setStorageRequestDialog(true);
          return;
        } else {
          setFile((prevData) => [
            ...prevData,
            {
              id: getUniqueId(),
              file: item,
            },
          ]);
        }
      }
    });
  };

  const ReturnAttachmentContent = () => {
    return (
      <>
        {isSubmissionPage && !isAssignmentSubmitted && (
          <Text content={attachmentTranslation.file.header} />
        )}

        {isSubmissionPage && !isAssignmentSubmitted && (
          <FileUploader
            className="file-browse-section-outer"
            multiple={true}
            handleChange={handleChange}
            types={[
              "docx",
              "pptx",
              "xlsx",
              "pdf",
              "txt",
              "zip",
              "c",
              "py",
              "js",
            ]}
            //   value={file}
            children={
              <div className="file-browse-section-outer mt-2">
                <div className="file-browse-section-inner">
                  <Text weight="bold">
                    <span className="file-browse-section-file">
                      {attachmentTranslation.file.browse}
                      {"  "}
                    </span>
                    {attachmentTranslation.file.or} {"  "}
                    {attachmentTranslation.file.dragAndDropHere}
                  </Text>
                </div>
              </div>
            }
            name="file"
            fluid
          />
        )}

        {isSubmissionPage ? (
          <div className="attachment-file-outer">
            <Flex gap="gap.small" column>
              {file && file.length > 0
                ? file.map((f, index) => {
                    return (
                      <Attachment
                        key={index}
                        className="attachment-file-inner"
                        header={
                          f?.file?.name ||
                          `${attachmentTranslation.file.document}${index + 1}`
                        }
                        actionable
                        action={{
                          icon: (
                            <ArchiveIcon
                              style={{ color: "black" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = file.filter(
                                  (k, ind) => ind != index
                                );
                                setFile(data);
                              }}
                            />
                          ),
                          onClick: () => {
                            const data = file.filter((k) => k.id != f.id);
                            setFile(data);
                          },
                          title: translation.close,
                        }}
                        onClick={() => {
                          if (
                            typeof f === "string" &&
                            f?.includes(
                              "https://acvstorageprod.blob.core.windows.net"
                            )
                          ) {
                            window.open(f);
                          } else {
                            try {
                              let fileUrl = URL.createObjectURL(f?.file);
                              window.open(fileUrl);
                            } catch (error) {}
                          }
                        }}
                      />
                    );
                  })
                : ""}
            </Flex>
          </div>
        ) : (
          <div className="attachment-file-outer">
            <Flex gap="gap.small" column>
              {file && file.length > 0
                ? file.map((f, index) => {
                    return (
                      <Attachment
                        key={index}
                        className="attachment-file-inner"
                        header={attachmentTranslation.file.file}
                        actionable
                        action={{
                          icon: (
                            <ShareGenericIcon
                              style={{ color: "black" }}
                              onClick={() => {
                                window.open(f);
                              }}
                            />
                          ),

                          title: attachmentTranslation.file.openFile,
                        }}
                      />
                    );
                  })
                : ""}
            </Flex>
          </div>
        )}
      </>
    );
  };

  const handleRecordConfirm = () => {
    if (isSubmissionPage) {
      if (voiceState.isDone) {
        setisConfirmDelete(true);
      } else if (voiceState.isSaved) {
        const audioFile = new File([audioResult], "audio-file.mp3", {
          type: "audio/mp3",
        });
        const sizeInGB = audioFile.size / (1024 * 1024 * 1024);
        if (storage?.usedStorage + sizeInGB >= storage?.providedStorage) {
          setVoiceState({
            ...voiceState,
            isDone: false,
            open: false,
            isSaved: false,
            isPause: false,
            isStart: false,
            audioUrl: "",
          });
          setStorageRequestDialog(true);
          return;
        }
        setVoiceState({
          ...voiceState,
          isDone: true,
          open: false,
          audioUrl: audioResult,
        });
        NotificationAlert(attachmentTranslation.voice.added, "success");
      } else {
        stopRecording();
        if (timer && timer / 60 > 5) {
          setVoiceState({
            ...voiceState,
            isDone: false,
            open: false,
            isSaved: false,
            isPause: false,
            isStart: false,
            audioUrl: "",
          });
          NotificationAlert(
            attachmentTranslation.voice.sizeValidation,
            "warning"
          );
        } else {
          setVoiceState({
            ...voiceState,
            isSaved: true,
            isStart: false,
            isPause: false,
          });
        }
      }
    }
  };

  const handleVideoRecordConfirm = () => {
    if (isSubmissionPage) {
      if (videoState.isDone) {
        setConfirmVideoDelete(true);
      } else if (!videoState.isSaved) {
        setVideoState({
          ...videoState,
          isSaved: true,
          isClosedWithoutSaved: false,
        });
      } else if (videoState.videoUrl) {
        setVideoState({
          ...videoState,
          isDone: true,
          open: false,
          isClosedWithoutSaved: false,
          isVideoRecorded: false,
        });
        NotificationAlert(attachmentTranslation.video.added, "success");
        setStudentAttachments((prevData) => [
          ...prevData?.map((item) =>
            item.type === "video"
              ? { ...item, info: videoState.videoUrl }
              : item
          ),
        ]);
        setStudentAttachmentsForAssignment((prevData) => [
          ...prevData?.map((item) =>
            item.type === "video"
              ? {
                  ...item,
                  info: videoState.videoUrl,
                  isAttachmentContentChanged: true,
                }
              : item
          ),
        ]);
      } else {
        setVideoState({
          ...videoState,
          isDone: false,
          isSaved: false,
          open: false,
        });
      }
    }
  };

  const handleDeleteText = (val) => {
    setText("");
    setStudentAttachments((prevData) => [
      ...prevData?.map((item) =>
        item.type === "text" ? { ...item, info: "" } : item
      ),
    ]);
    setStudentAttachmentsForAssignment((prevData) => [
      ...prevData?.map((item) =>
        item.type === "text"
          ? { ...item, info: "", isAttachmentContentChanged: true }
          : item
      ),
    ]);
    setTextState({ ...textState, isSave: false, open: false });
    setConfirmTextDelete(false);
    NotificationAlert(attachmentTranslation.text.deleted, "success");
  };

  const handleTextConfirm = (val) => {
    if (
      textState.isSave &&
      !textState.isChanged &&
      studentAttachmentsForAssignment?.find((item) => item.type === "text")
        ?.info
    ) {
      setConfirmTextDelete(true);
    } else {
      setStudentAttachments((prevData) => [
        ...prevData?.map((item) =>
          item.type === "text" ? { ...item, info: text } : item
        ),
      ]);
      setStudentAttachmentsForAssignment((prevData) => [
        ...prevData?.map((item) =>
          item.type === "text"
            ? { ...item, info: text, isAttachmentContentChanged: true }
            : item
        ),
      ]);
      setTextState({ ...textState, isSave: true, open: false });
      NotificationAlert(attachmentTranslation.text.added, "success");
    }
  };

  const linkCards = (link) => {
    return (
      <Card elevated style={{ maxWidth: "170px" }}>
        <a
          href={link?.includes("http") ? link : `http://${link}`}
          target="_blank"
        >
          <Text style={{ wordBreak: "break-word" }}> {link}</Text>
        </a>
        {isSubmissionPage && (
          <CardFooter style={{ marginTop: "10px" }}>
            <Flex hAlign="end">
              <ArchiveIcon
                onClick={() => {
                  const data = links.filter((a) => a != link);
                  setLinks(data);
                }}
              />
            </Flex>
          </CardFooter>
        )}
      </Card>
    );
  };

  const ReturnVoiceContent = () => {
    return (
      <>
        {!isSubmissionPage ? (
          <div className="justify-center">
            <audio controls src={voiceState.audioUrl} />
          </div>
        ) : (
          <div className="justify-center">
            {(voiceState.isSaved || voiceState.isDone) &&
            voiceState.audioUrl ? (
              <audio
                controls
                src={voiceState.audioUrl ? voiceState.audioUrl : ""}
              />
            ) : !isAssignmentSubmitted ? (
              voiceState.isPause ? (
                <>
                  <Flex column gap="gap.smaller">
                    <Text content={timer} className="justify-center" />
                    <div className="justify-center">
                      <PlayIcon
                        className="play-bg-color icon-padding"
                        circular
                        size="large"
                        onClick={() => {
                          setVoiceState({
                            ...voiceState,
                            isStart: true,
                            isPause: false,
                          });
                          resumeRecording();
                        }}
                      />
                    </div>
                    <Text
                      content={attachmentTranslation.voice.resume}
                      className="justify-center"
                    />
                  </Flex>
                </>
              ) : voiceState.isStart ? (
                <>
                  <Flex column gap="gap.smaller">
                    <Text content={timer} className="justify-center" />
                    <div className="justify-center">
                      <PauseIcon
                        className="pause-bg-color icon-padding"
                        circular
                        size="large"
                        onClick={() => {
                          setVoiceState({
                            ...voiceState,
                            isStart: false,
                            isPause: true,
                          });
                          pauseRecording();
                        }}
                      />
                    </div>
                    <Text
                      content={attachmentTranslation.voice.pause}
                      className="justify-center"
                    />
                  </Flex>
                </>
              ) : (
                <>
                  <Flex column gap="gap.smaller">
                    <Text content={timer} className="justify-center" />
                    <div className="justify-center">
                      <Button
                        icon={
                          <MicIcon
                            className="mic-bg-color icon-padding"
                            circular
                            size="large"
                            style={{ color: "white" }}
                          />
                        }
                        iconOnly
                        title={attachmentTranslation.voice.audio}
                        disabled={isAssignmentSubmitted}
                        onClick={() => {
                          setVoiceState({ ...voiceState, isStart: true });
                          startRecording();
                        }}
                      />
                      {/* <MicIcon
                      className="mic-bg-color icon-padding"
                      circular
                      size="large"
                      disabled={isAssignmentSubmitted}
                      onClick={() => {
                        setVoiceState({ ...voiceState, isStart: true });
                        startRecording();
                      }}
                    /> */}
                    </div>
                    <Text
                      content={attachmentTranslation.voice.start}
                      className="justify-center"
                    />
                  </Flex>
                </>
              )
            ) : null}
          </div>
        )}
      </>
    );
  };

  const getConfirmFlag = (val) => {
    setisConfirmDelete(false);

    if (!val) {
      setVoiceState({
        ...voiceState,
        open: false,
      });

      return;
    }

    setVoiceState({
      ...voiceState,
      isStart: false,
      isPause: false,
      isSaved: false,
      isDone: false,
      open: false,
    });
    setStudentAttachmentsForAssignment((prevData) => [
      ...prevData?.map((item) =>
        item.type === "voice"
          ? {
              ...item,
              info: "",
              isAttachmentContentChanged: true,
            }
          : item
      ),
    ]);
    NotificationAlert(attachmentTranslation.voice.deleted, "success");
  };
  const getConfirmFlagForVideo = (val) => {
    setConfirmVideoDelete(false);

    if (!val) {
      setVideoState({
        ...videoState,
        open: false,
      });

      // return;
    } else {
      setVideoState({
        ...videoState,
        isStart: false,
        isSaved: false,
        isDone: false,
        open: false,
        videoUrl: "",
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      setStudentAttachmentsForAssignment((prevData) => [
        ...prevData?.map((item) =>
          item.type === "video"
            ? {
                ...item,
                info: "",
                isAttachmentContentChanged: true,
              }
            : item
        ),
      ]);
      NotificationAlert(attachmentTranslation.video.deleted, "success");
    }
  };

  const ReturnVideoContent = () => {
    return (
      <>
        {!isSubmissionPage ? (
          <div className="justify-center">
            <video
              controls
              src={videoState.videoUrl}
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </div>
        ) : (
          <div>
            {(videoState.isVideoRecorded ||
              videoState.isDone ||
              videoState.isSaved) &&
            videoState.videoUrl ? (
              <div className="justify-center">
                <video
                  controls
                  src={videoState.videoUrl}
                  style={{ width: "100%", maxWidth: "500px" }}
                />
              </div>
            ) : (
              // <div className="videonote-box">
              //   {videoState.isStart ? (
              //     <Flex column>
              //       <Text className="recording-now-color">
              //         Recording now...
              //       </Text>
              //       <Button
              //         className="stop-button"
              //         iconOnly
              //         icon={<ToDoListIcon className="stop-icon-color" />}
              //         onClick={() => {
              //           onVideoStartHandler();
              //         }}
              //       />
              //     </Flex>
              //   ) : (
              //     <Button
              //       className="start-button"
              //       iconOnly
              //       icon={<CallVideoIcon />}
              //       onClick={() => {
              //         onVideoStartHandler();
              //       }}
              //     />
              //   )}
              // </div>
              <div>
                {!isAssignmentSubmitted && (
                  <Flex>
                    <VideoRecorder
                      onRecordingComplete={(videoBlob) => {
                        if ({ ...videoState }.isClosedWithoutSaved) {
                          setVideoState((prevState) => ({
                            ...prevState,
                            isVideoRecorded: true,
                            isDone: false,
                            isSaved: false,
                            videoUrl: "",
                            isClosedWithoutSaved: false,
                          }));
                        } else {
                          const sizeInGB =
                            videoBlob.size / (1024 * 1024 * 1024);
                          if (
                            storage?.usedStorage + sizeInGB >=
                            storage?.providedStorage
                          ) {
                            setVideoState((prevState) => ({
                              ...prevState,
                              isVideoRecorded: true,
                              isDone: false,
                              isSaved: false,
                              videoUrl: "",
                              isClosedWithoutSaved: false,
                            }));
                            setStorageRequestDialog(true);
                          } else {
                            setVideoState((prevState) => ({
                              ...prevState,
                              videoUrl: videoBlob
                                ? URL.createObjectURL(videoBlob)
                                : "",
                              isVideoRecorded: true,
                            }));
                          }
                        }
                      }}
                      isFlipped={false}
                      className="videonote-box"
                      renderDisconnectedView={() => <div></div>}
                      replayVideoAutoplayAndLoopOff
                      timeLimit={300000}
                    />
                  </Flex>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <StorageRequestDialog
        handleClose={() => {
          setStorageRequestDialog(false);
        }}
        handleOk={() => {
          console.log("Request");
        }}
        open={storageRequestDialog}
        user={props.user}
      />
      <ConfirmDialog
        isConfirmDelete={isConfirmDelete}
        setisConfirmDelete={setisConfirmDelete}
        onConfirm={(val) => getConfirmFlag(val)}
        onCancel={(val) => getConfirmFlag(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <ConfirmDialog
        isConfirmDelete={confirmTextDelete}
        setisConfirmDelete={setConfirmTextDelete}
        onConfirm={(val) => {
          handleDeleteText(val);
        }}
        onCancel={(val) => setConfirmTextDelete(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />
      <ConfirmDialog
        isConfirmDelete={confirmVideoDelete}
        setisConfirmDelete={setConfirmVideoDelete}
        onConfirm={(val) => getConfirmFlagForVideo(val)}
        onCancel={(val) => getConfirmFlagForVideo(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <div className="mt-2 mb-1">
        {/* <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"> */}
        <Flex wrap gap="gap.small">
          {!isSubmissionPage ? (
            <>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn p-3 d-flex justify-content-center align-items-center  ${
                        text ? "btn-bg-primary" : ""
                      }`}
                      icon={<OcrOnIcon />}
                      title={attachmentTranslation.text.textNote}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: text ? "white" : "black",
                      }}
                    />
                  }
                  open={textState.open}
                  onOpen={() => setTextState({ ...textState, open: !!text })}
                  content={
                    <ReturnTextContent
                      val={text}
                      setVal={setText}
                      isActive={isSubmissionPage}
                      attachmentTranslation={attachmentTranslation}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setTextState({ ...textState, open: false });
                    },
                  }}
                  header={attachmentTranslation.text.textNote}
                />
              </Flex.Item>

              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        voiceState.audioUrl ? " btn-bg-primary" : ""
                      }`}
                      icon={<MicIcon />}
                      title={attachmentTranslation.voice.voiceNote}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: voiceState.audioUrl ? "white" : "black",
                      }}
                    />
                  }
                  open={voiceState.open}
                  onOpen={() =>
                    setVoiceState({
                      ...voiceState,
                      open: !!voiceState.audioUrl,
                    })
                  }
                  onCancel={() => setVoiceState({ ...voiceState, open: false })}
                  header={attachmentTranslation.voice.voiceNote}
                  content={<ReturnVoiceContent />}
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setVoiceState({
                        ...voiceState,
                        open: false,
                      });
                    },
                  }}
                />
              </Flex.Item>

              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center
                            ${videoState.videoUrl ? " btn-bg-primary" : ""}`}
                      icon={<CallVideoIcon />}
                      title={attachmentTranslation.video.videoNote}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: videoState.videoUrl ? "white" : "black",
                      }}
                    />
                  }
                  open={videoState.open}
                  onOpen={() =>
                    setVideoState({
                      ...videoState,
                      open: !!videoState.videoUrl,
                    })
                  }
                  onCancel={() => setVideoState({ ...videoState, open: false })}
                  // onConfirm={() => {
                  //   setVideoState({ ...videoState, isSaved: true, open:false})
                  // }}
                  header={attachmentTranslation.video.videoNote}
                  content={<ReturnVideoContent />}
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setVideoState({
                        ...videoState,
                        open: false,
                        // isStart: false,
                        // isStop: false,
                        // isDone: false,
                      });
                    },
                  }}
                />
              </Flex.Item>
              {isShowAvatarVideo && (
                <Flex.Item>
                  <DialogVoice
                    button={
                      <Button
                        className={`p-3 d-flex justify-content-center align-items-center
                            ${avatarVideoUrl ? " btn-bg-primary" : ""}`}
                        icon={<SpeakerPersonIcon />}
                        title={"Avatar Video"}
                        iconOnly
                        style={{
                          padding: "20px",
                          borderRadius: "5px",
                          color: avatarVideoUrl ? "white" : "black",
                        }}
                        onClick={() => {
                          setIsOpenAvatarVideo(true);
                        }}
                      />
                    }
                    open={isOpenAvatarVideo}
                    onCancel={() => setIsOpenAvatarVideo(false)}
                    header={"Avatar Video"}
                    content={
                      <div className="justify-center">
                        <video
                          controls
                          src={avatarVideoUrl}
                          style={{ width: "100%", maxWidth: "500px" }}
                        />
                      </div>
                    }
                    headerAction={{
                      icon: <CloseIcon />,
                      title: translation.close,
                      onClick: () => {
                        setIsOpenAvatarVideo(false);
                      },
                    }}
                  />
                </Flex.Item>
              )}
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center  ${
                        links.length > 0 ? "btn-bg-primary" : ""
                      }`}
                      icon={<LinkIcon />}
                      iconOnly
                      title={attachmentTranslation.link.links}
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: links.length ? "white" : "black",
                      }}
                    />
                  }
                  open={linkState.open}
                  onOpen={() => {
                    setLinkState({
                      ...linkState,
                      open: !!links.length,
                    });
                  }}
                  content={
                    <>
                      <Flex wrap>
                        {links?.length
                          ? links.map((link, index) => {
                              return (
                                <Flex key={index} style={{ margin: "10px" }}>
                                  {linkCards(link)}
                                </Flex>
                              );
                            })
                          : ""}
                      </Flex>
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setLinkState({ ...linkState, open: false });
                    },
                  }}
                  header={attachmentTranslation.link.links}
                />
              </Flex.Item>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        file.length > 0 ? "btn-bg-primary" : ""
                      }`}
                      iconOnly
                      icon={<FilesTextColoredIcon />}
                      title={attachmentTranslation.file.Documents}
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: file.length ? "white" : "black",
                      }}
                    />
                  }
                  open={fileState.open}
                  onOpen={() => {
                    setFileState({
                      ...fileState,
                      open: !!file.length,
                    });
                  }}
                  content={
                    <>
                      <ReturnAttachmentContent />
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setFileState({
                        ...fileState,
                        open: false,
                      });
                    },
                  }}
                  header={attachmentTranslation.file.Documents}
                />
              </Flex.Item>
            </>
          ) : (
            <>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        studentAttachmentsForAssignment?.find(
                          (item) => item.type === "text"
                        )?.info
                          ? "btn-bg-primary"
                          : ""
                      }`}
                      icon={<OcrOnIcon />}
                      title={attachmentTranslation.text.addText}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: studentAttachmentsForAssignment?.find(
                          (item) => item.type === "text"
                        )?.info
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={textState.open}
                  onOpen={() => setTextState({ ...textState, open: true })}
                  onConfirm={() => {
                    handleTextConfirm();
                    // setConfirmTextDelete(true)
                  }}
                  confirmButton={
                    isAssignmentSubmitted
                      ? null
                      : textState.isSave &&
                        !textState.isChanged &&
                        studentAttachmentsForAssignment?.find(
                          (item) => item.type === "text"
                        )?.info
                      ? attachmentTranslation.deleteNote
                      : translation.save
                  }
                  content={
                    <ReturnTextContent
                      val={text}
                      setVal={(val) => setText(val)}
                      isActive={isSubmissionPage && !isAssignmentSubmitted}
                      attachmentTranslation={attachmentTranslation}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let textData = studentAttachmentsForAssignment?.find(
                        (item) => item.type === "text"
                      )?.info;
                      if (textData) {
                        setText(textData);
                      } else {
                        setText("");
                      }

                      setTextState({ ...textState, open: false });
                    },
                  }}
                  header={
                    !isAssignmentSubmitted
                      ? attachmentTranslation.text.addTextNote
                      : attachmentTranslation.text.textNote
                  }
                />
              </Flex.Item>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        voiceState.isDone ? "btn-bg-primary" : ""
                      }`}
                      icon={<MicIcon />}
                      title={attachmentTranslation.voice.addVoice}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: "black",
                        color: voiceState.isDone ? "white" : "black",
                      }}
                    />
                  }
                  open={voiceState.open}
                  onOpen={() => {
                    if (storage?.usedStorage >= storage?.providedStorage) {
                      setStorageRequestDialog(true);
                    } else {
                      setVoiceState({ ...voiceState, open: true });
                    }
                  }}
                  onCancel={() => setVoiceState({ ...voiceState, open: false })}
                  onConfirm={() => {
                    handleRecordConfirm(audioResult);
                    setStudentAttachments((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "voice"
                          ? { ...item, info: audioResult }
                          : item
                      ),
                    ]);
                    setStudentAttachmentsForAssignment((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "voice"
                          ? {
                              ...item,
                              info: audioResult,
                              isAttachmentContentChanged: true,
                            }
                          : item
                      ),
                    ]);
                  }}
                  confirmButton={
                    isAssignmentSubmitted
                      ? null
                      : !isSubmissionPage
                      ? ""
                      : voiceState.isDone
                      ? attachmentTranslation.deleteNote
                      : voiceState.isSaved
                      ? translation.done
                      : translation.save
                  }
                  header={
                    <Flex column>
                      {!isAssignmentSubmitted ? (
                        <>
                          <Text align="start">
                            {attachmentTranslation.voice.addVoiceNote}
                          </Text>
                          <Text weight="light" size="small">
                            {attachmentTranslation.voice.sizeValidation}
                          </Text>
                        </>
                      ) : !!voiceState.audioUrl ? (
                        <Text>{attachmentTranslation.voice.voiceNote}</Text>
                      ) : (
                        <Text>
                          {
                            attachmentTranslation.voice
                              .noAudioAttachmentSubmitted
                          }
                        </Text>
                      )}
                    </Flex>
                  }
                  content={<ReturnVoiceContent />}
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      stopRecording();
                      setVoiceState({
                        ...voiceState,
                        open: false,
                      });
                    },
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        (videoState.isDone || videoState.isSaved) &&
                        videoState.videoUrl
                          ? "btn-bg-primary"
                          : ""
                      }`}
                      icon={<CallVideoIcon />}
                      title={attachmentTranslation.video.addVideo}
                      iconOnly
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color:
                          (videoState.isDone || videoState.isSaved) &&
                          videoState.videoUrl
                            ? "white"
                            : "black",
                      }}
                    />
                  }
                  open={videoState.open}
                  onOpen={() => {
                    if (storage?.usedStorage >= storage?.providedStorage) {
                      setStorageRequestDialog(true);
                    } else {
                      setVideoState({ ...videoState, open: true });
                    }
                  }}
                  onCancel={() => {
                    if (videoState.isVideoRecorded) {
                      setVideoState({
                        ...videoState,
                        videoUrl: null,
                        isVideoRecorded: false,
                      });
                    }
                  }}
                  cancelButton={"Retake video"}
                  onConfirm={() => {
                    handleVideoRecordConfirm();

                    // if (!videoState.isDone) {
                    //   setVideoState({
                    //     ...videoState,
                    //     open: false,
                    //     isStart: false,
                    //     isDone: true,
                    //     isStop: false,
                    //   });
                    // } else {
                    //   stopVideoRecording();
                    //   setVideoState({
                    //     ...videoState,
                    //     open: false,
                    //     isStart: false,
                    //     isDone: false,
                    //     isStop: false,
                    //   });
                    // }
                  }}
                  confirmButton={
                    isAssignmentSubmitted
                      ? null
                      : videoState.isDone
                      ? attachmentTranslation.deleteNote
                      : videoState.isSaved
                      ? translation.done
                      : translation.save
                  }
                  header={
                    <Flex column>
                      {!isAssignmentSubmitted ? (
                        <>
                          <Text align="start">
                            {attachmentTranslation.video.recordVideo}
                          </Text>
                          <Text weight="light" size="small">
                            {attachmentTranslation.video.lengthValidation}
                          </Text>
                        </>
                      ) : !!videoState.videoUrl ? (
                        <Text> {attachmentTranslation.video.videoNote}</Text>
                      ) : (
                        <Text>
                          {
                            attachmentTranslation.video
                              .noVideoAttachmentSubmitted
                          }
                        </Text>
                      )}
                    </Flex>
                  }
                  content={<ReturnVideoContent />}
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      // stopVideoRecording();
                      setVideoState({
                        ...videoState,
                        open: false,
                        // isStart: false,
                        // isStop: false,
                        // isDone: false,
                        isClosedWithoutSaved: true,
                      });
                    },
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        studentAttachmentsForAssignment?.find(
                          (item) => item.type === "link"
                        )?.info?.length > 0
                          ? "btn-bg-primary"
                          : ""
                      }`}
                      icon={<LinkIcon />}
                      iconOnly
                      title={attachmentTranslation.link.addLink}
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: studentAttachmentsForAssignment?.find(
                          (item) => item.type === "link"
                        )?.info?.length
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={linkState.open}
                  onOpen={() => {
                    setLinkState({ ...linkState, open: true });
                  }}
                  onConfirm={() => {
                    setStudentAttachments((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "link" ? { ...item, info: links } : item
                      ),
                    ]);
                    setStudentAttachmentsForAssignment((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "link"
                          ? {
                              ...item,
                              info: links,
                              isAttachmentContentChanged: true,
                            }
                          : item
                      ),
                    ]);
                    setLinkState({
                      ...linkState,
                      open: false,
                      isSave: true,
                    });
                    if (links.length && links.length > 0) {
                      NotificationAlert(
                        attachmentTranslation.link.linkAdded,
                        "success"
                      );
                    }
                  }}
                  confirmButton={
                    isAssignmentSubmitted ? null : translation.save
                  }
                  content={
                    <>
                      {!isAssignmentSubmitted && (
                        <ReturnLinkContent
                          val={linkText}
                          setVal={(val) => setLinkText(val)}
                          linkVal={links}
                          handleAdd={() => {
                            setLinkText("");
                            setLinks([...links, linkText]);
                          }}
                          attachmentTranslation={attachmentTranslation}
                        />
                      )}
                      <div style={{ marginTop: "10px" }}>
                        <Flex wrap>
                          {links && links.length > 0
                            ? links.map((link, index) => {
                                return (
                                  <Flex key={index} style={{ margin: "10px" }}>
                                    {linkCards(link)}
                                  </Flex>
                                );
                              })
                            : ""}
                        </Flex>
                      </div>
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let linkData = studentAttachmentsForAssignment?.find(
                        (item) => item.type === "link"
                      )?.info;
                      if (linkData?.length) {
                        setLinks(linkData);
                      } else {
                        setLinks([]);
                      }
                      setLinkState({ ...linkState, open: false });
                    },
                  }}
                  header={
                    !isAssignmentSubmitted
                      ? attachmentTranslation.link.addLink
                      : !links.length
                      ? attachmentTranslation.link.noLinkAdded
                      : attachmentTranslation.link.links
                  }
                />
              </Flex.Item>
              <Flex.Item>
                <DialogVoice
                  button={
                    <Button
                      className={`p-3 d-flex justify-content-center align-items-center ${
                        studentAttachmentsForAssignment?.find(
                          (item) => item.type === "file"
                        )?.info?.length > 0
                          ? "btn-bg-primary"
                          : ""
                      }`}
                      iconOnly
                      icon={<FilesTextColoredIcon />}
                      title={attachmentTranslation.file.addDocument}
                      style={{
                        padding: "20px",
                        borderRadius: "5px",
                        color: studentAttachmentsForAssignment?.find(
                          (item) => item.type === "file"
                        )?.info?.length
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={fileState.open}
                  onOpen={() => {
                    if (storage?.usedStorage >= storage?.providedStorage) {
                      setStorageRequestDialog(true);
                    } else {
                      setFileState({
                        ...fileState,
                        open: true,
                      });
                    }
                  }}
                  onConfirm={() => {
                    setStudentAttachments((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "file" ? { ...item, info: file } : item
                      ),
                    ]);
                    setStudentAttachmentsForAssignment((prevData) => [
                      ...prevData?.map((item) =>
                        item.type === "file"
                          ? {
                              ...item,
                              info: file,
                              isAttachmentContentChanged: true,
                            }
                          : item
                      ),
                    ]);
                    setFileState({
                      ...fileState,
                      open: false,
                      isSave: true,
                    });
                    if (file && file.length > 0) {
                      NotificationAlert(
                        attachmentTranslation.file.documentsAdded,
                        "success"
                      );
                    }
                  }}
                  content={
                    <>
                      <ReturnAttachmentContent />
                    </>
                  }
                  confirmButton={
                    isAssignmentSubmitted ? null : translation.save
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let fileData = studentAttachmentsForAssignment?.find(
                        (item) => item.type === "file"
                      )?.info;
                      if (fileData?.length) {
                        setFile(fileData);
                      } else {
                        setFile([]);
                      }
                      setFileState({ ...linkState, open: false });

                      setFileState({
                        ...fileState,
                        open: false,
                      });
                    },
                  }}
                  header={
                    !isAssignmentSubmitted
                      ? attachmentTranslation.file.attachDocument
                      : !file.length
                      ? attachmentTranslation.file.noFileAdded
                      : attachmentTranslation.file.fileAttachment
                  }
                />
              </Flex.Item>
            </>
          )}

          {/* </div> */}
        </Flex>
        {/* </div> */}
      </div>
    </div>
  );
};

export default withTranslation()(AttachmentOption);
