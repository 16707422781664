import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  CloseIcon,
  CodeSnippetIcon,
  Dialog,
  DownloadIcon,
  Flex,
  Input,
  Pill,
  SearchIcon,
  ShareAltIcon,
  Text,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

import { getQRCode } from "../../../../utils/generateQRCode";

import "react-toastify/dist/ReactToastify.css";
import "./ShareEvent.scss";

const linkTab = "linkTab";
const iFrameTab = "iFrameTab";

const emailLoading = "emailLoading";

const API_URL = process.env.REACT_APP_EP_URL;

const ShareEvent = ({ eventId, eventTitle, shareButton, user, t }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailInputText, setEmailInputText] = useState("");
  const [emails, setEmails] = useState([]);
  const [isInvalidEmailText, setIsInvalidEmailText] = useState(false);
  const [activeTab, setActiveTab] = useState(linkTab);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [loading, setLoading] = useState({
    type: null,
    isLoading: false,
  });
  const [isEmailsExpanded, setIsEmailsExpanded] = useState(false);

  const translation = t("events");

  const commonTranslation = translation.common;

  const shareEventTranslation = translation.body.shareEvent;

  let eventLongUrl = eventId
    ? `${process.env.REACT_APP_URI}/event/details/${eventId}`
    : null;

  let iFrameLink = `<iframe className="iframe-wrapper" src="${eventLongUrl}" width="768" height="432" allowFullScreen allow="camera; microphone; fullscreen; clipboard-read; clipboard-write" frameborder="0" />`;

  const qrCodeGenerator = () => {
    const qrValue = getQRCode(eventLongUrl);
    return qrValue;
  };

  useEffect(() => {
    if (isOpen) {
      try {
        const qrValue = qrCodeGenerator();
        if (!qrValue) return;
        setQrCodeImage(qrValue);
      } catch (error) {}
    }
  }, [isOpen]);

  const downloadQRImage = useCallback(() => {
    const elm = document.createElement("a");
    elm.href = qrCodeImage;
    elm.download = `${commonTranslation.event}-${
      eventTitle?.length ? eventTitle.slice(0, 15) : ""
    }`;
    elm.click();
  }, [qrCodeImage]);

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
          ...new Set(
            emailInputText
              .toLowerCase()
              .split(";")
              .filter((email) => email)
          ),
        ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
  };

  const shareEventLinkHandler = async () => {
    if (eventId) {
      try {
        navigator.clipboard.writeText(eventLongUrl);
        toast.success(shareEventTranslation.linkCopiedSuccess, {
          closeOnClick: true,
        });
      } catch {
        toast.error(shareEventTranslation.linkCopiedError, {
          closeOnClick: true,
        });
      }
    }
  };

  const copyIframeLinkHandler = async () => {
    if (eventLongUrl) {
      try {
        navigator.clipboard.writeText(iFrameLink);
        toast.success(shareEventTranslation.iframeLinkCopiedSuccessFully);
      } catch {
        toast.error(shareEventTranslation.iframeLinkCopyError);
      }
    }
  };

  const sendEmailInvitationHandler = async () => {
    try {
      setLoading({ type: emailLoading, isLoading: true });

      const payload = {
        emails,
        link: eventLongUrl,
        title: eventTitle,
      };

      const { data } = await axios({
        method: "POST",
        url: `${API_URL}/api/events/send-invitation/:${eventId}${user.slug}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data) {
        if (
          data.status?.toLowerCase() === "success" &&
          typeof data.message === "string"
        ) {
          toast.success(data.message);
          setEmails([]);
        } else if (data.status?.toLowerCase() === "error") {
          toast.error(
            typeof data.message === "string"
              ? data.message
              : shareEventTranslation.invitationFailed
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ type: null, isLoading: false });
    }
  };

  const onShareButtonClickhandler = () => {
    setEmailInputText("");
    setEmails([]);
    setIsInvalidEmailText(false);
  };

  return (
    <Dialog
      className="share-event-dialog"
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onCancel={() => {
        setIsOpen(false);
      }}
      onConfirm={() => {
        setIsOpen(false);
      }}
      content={
        <div className="share-event-wrapper">
          <div className="share-event-tabs">
            <ol className="share-event-tab-list">
              <li
                className={`share-event-tab-list-item ${
                  activeTab === linkTab ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(linkTab);
                }}
              >
                {shareEventTranslation.share}
              </li>
              {/* <li
                className={`share-event-tab-list-item ${
                  activeTab === iFrameTab ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(iFrameTab);
                }}
              >
                {shareEventTranslation.embed}
              </li> */}
            </ol>
            <div className="share-event-tab-content">
              {activeTab === linkTab ? (
                <div className="share-event-link">
                  <div className="share-event-link-container">
                    <div className="email-invite-wrapper">
                      <div className="input-wrapper">
                        <Flex column>
                          <Input
                            className="email-input"
                            fluid
                            icon={<SearchIcon />}
                            value={emailInputText}
                            onChange={(e, { value }) => {
                              setIsInvalidEmailText(false);
                              setEmailInputText(value);
                            }}
                            clearable
                            label={shareEventTranslation.emailsToInvite}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onAddEmailHandler();
                              }
                            }}
                            error={isInvalidEmailText}
                          />
                          {isInvalidEmailText ? (
                            <p className="invalid-text">
                              {shareEventTranslation.emailValidationError}
                            </p>
                          ) : null}
                          <p>{shareEventTranslation.emailAddNote}</p>
                        </Flex>
                      </div>
                      <div className="emails-wrapper">
                        <Flex wrap>
                          {(emails.length > 5
                            ? isEmailsExpanded
                              ? emails
                              : emails.slice(0, 5)
                            : emails
                          ).map((item) => {
                            return (
                              <Pill
                                actionable
                                onDismiss={() => {
                                  setEmails((prev) =>
                                    prev.filter((data) => data !== item)
                                  );
                                }}
                                style={{
                                  minWidth: "initial",
                                  backgroundColor: "#6264A7",
                                  color: "#fff",
                                }}
                              >
                                {item}
                              </Pill>
                            );
                          })}
                        </Flex>
                        {emails.length > 5 && (
                          <Flex hAlign="end">
                            <Button
                              primary
                              onClick={() =>
                                setIsEmailsExpanded((prev) => !prev)
                              }
                            >
                              {isEmailsExpanded
                                ? shareEventTranslation.showLess
                                : shareEventTranslation.showAll}
                            </Button>
                          </Flex>
                        )}
                      </div>
                      <div className="action-button-wrapper">
                        <Flex
                          space="between"
                          className="share-event-action-buttons"
                        >
                          <Button
                            primary
                            icon={<ShareAltIcon />}
                            content={shareEventTranslation.shareLink}
                            onClick={() => {
                              shareEventLinkHandler();
                            }}
                          />
                          <Button
                            primary
                            disabled={
                              !emails.length ||
                              (loading.type === emailLoading &&
                                loading.isLoading)
                            }
                            icon={<UserFriendsIcon />}
                            content={
                              loading.type === emailLoading && loading.isLoading
                                ? commonTranslation.loading
                                : shareEventTranslation.invite
                            }
                            loading={
                              loading.type === emailLoading && loading.isLoading
                            }
                            onClick={sendEmailInvitationHandler}
                          />
                        </Flex>
                      </div>
                    </div>
                    <div className="qr-code-action-wrapper">
                      <div className="qr-image-wrapper">
                        <img src={qrCodeImage} />
                      </div>
                      <div>
                        <Button
                          primary
                          onClick={downloadQRImage}
                          icon={<DownloadIcon />}
                          content={
                            <>
                              <Text>{shareEventTranslation.download} </Text>
                              <Text className="download-extra-text">
                                {shareEventTranslation.QRasPNG}
                              </Text>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
                // <div className="share-event-iframe">
                //   <p className="title">{shareEventTranslation.embedEvent}</p>

                //   <div className="iframe-wrapper">
                //     <iframe
                //       src={eventLongUrl}
                //       width="768"
                //       height="432"
                //       allowFullScreen
                //       allow="camera; microphone; fullscreen; clipboard-read; clipboard-write"
                //       frameborder="0"
                //     />
                //   </div>

                //   <div className="iframe-link-wrapper">
                //     <Input
                //       className="iframe-link"
                //       disabled
                //       fluid
                //       value={iFrameLink}
                //     />
                //   </div>
                //   <Flex
                //     column
                //     hAlign="center"
                //     className="iframe-action-buuton-wrapper"
                //   >
                //     <Button
                //       primary
                //       icon={<CodeSnippetIcon />}
                //       content={shareEventTranslation.CopyEmbedCode}
                //       onClick={copyIframeLinkHandler}
                //     />
                //   </Flex>
                // </div>
              )}
            </div>
          </div>
        </div>
      }
      header={<div> {shareEventTranslation.shareEvent}</div>}
      headerAction={{
        icon: <CloseIcon />,
        title: commonTranslation.close,
        onClick: () => {
          setIsOpen(false);
        },
      }}
      trigger={shareButton({ onShareButtonClickhandler })}
      styles={{
        maxWidth: "700px",
      }}
    />
  );
};

export default withTranslation()(ShareEvent);
