import { useEffect, useMemo, useState } from "react";
import { StorageRequestDialog } from "../../../components/Storage/StorageRequestDialog";
import {
  getStorageAnalytics,
  getStorageDetails,
  getUserUsedStorage,
} from "../../../Assets/api/api";
import DonutChart from "../../Charts/components/ApexCharts/DonutChart/DonutChart";
import { Button, Table, TableCell, Tooltip } from "@fluentui/react-northstar";
import { Pagination } from "@mui/material";

export const StorageAnalytic = ({ user }) => {
  const [storageRequestDialog, setStorageRequestDialog] = useState(false);
  const [shouldRequestStorage, setShouldRequestStorage] = useState(false);
  const [storageDetails, setStorageDetails] = useState();
  const [selectedExtension, setSelectedExtension] = useState("all");
  const [storageData, setStorageData] = useState({
    extension: {
      assignment: 0,
      whiteboard: 0,
      event: 0,
      feedback: 0,
    },
    totalNetStorage: 0,
    providedStorage: 50,
  });
  const [loading, setLoading] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getStorageAnalytics(user.slug, user.mail).then((data) => {
      setStorageData({
        extension: { ...data?.result?.extension } ?? storageData.extension,
        totalNetStorage: data?.result?.totalNetStorage ?? 0,
      });
    });
    getUserUsedStorage(user.slug, user.mail, user.actualRole).then((data) => {
      const usedStorage = data?.result?.usedStorage ?? 0;
      const providedStorage = data?.result?.providedStorage ?? 50;
      const usedStoragePercentage = (usedStorage / providedStorage) * 100;
      if (usedStoragePercentage < 90) {
        setShouldRequestStorage(true);
      }
      setStorageData((prevData) => ({
        ...prevData,
        providedStorage: providedStorage,
      }));
    });
  }, [user]);

  useEffect(() => {
    getStorageDetails(user.slug, user.mail, currentPage, selectedExtension)
      .then((data) => {
        setLoading(false);
        setStorageDetails(data?.result ?? []);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error getting storage details: ", error);
      });
  }, [user, currentPage, selectedExtension]);

  const sendStorageRequest = async () => {
    if (user.actualRole === "SuperAdmin") {
      // window.open("https://localhost:3003/storage", "_blank");
      window.open("https://www.acv.app/storage", "_blank");
    } else {
      setStorageRequestDialog(true);
    }

    return;
  };

  const storageAnalyticsChart = useMemo(() => {
    return (
      <DonutChart
        data={storageData.extension}
        total={storageData.totalNetStorage}
        providedStorage={storageData.providedStorage ?? 50}
        setSelectedExtension={setSelectedExtension}
      />
    );
  }, [storageData]);
  const header = {
    items: ["id", "extension", "feature", "type", "storageSize"],
  };

  // Calculate rows
  const rows = loading
    ? [
        {
          items: [
            <TableCell
              key="loading"
              colSpan={header.length} // Span all columns
              style={{
                textAlign: "center",
                fontStyle: "italic",
                padding: "16px",
              }}
            >
              Loading data...
            </TableCell>,
          ],
        },
      ]
    : storageDetails?.details?.map((item, index) => ({
        key: item.id, // Use a unique key
        items: header.items.map((headerItem) => {
          if (headerItem === "id") {
            // Set 'id' based on index
            return String(index + 1);
          } else if (headerItem === "storageSize") {
            // Format storage size
            return (
              <span
                style={{
                  color: item["status"] === "Deleted" ? "red" : "green",
                  marginRight: "5px",
                }}
              >
                {" "}
                {Number(item[headerItem])?.toFixed(4)}
              </span>
            );
          } else {
            // Fallback for missing data
            return item[headerItem] || "";
          }
        }),
      }));

  // Calculate grand total for storageSize
  const totalStorageSize = (
    storageDetails?.activeTotal - storageDetails?.deletedTotal ?? 0
  ).toFixed(4);

  // Handle page change
  const handlePageChange = (e, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="ms-grid">
      <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
        <h5 className="text-center">My Storage</h5>
        <div className="ms-Grid d-flex justify-content-center">
          {storageAnalyticsChart}
        </div>
        <div className="ms-grid m-3 d-flex justify-content-center">
          {shouldRequestStorage ? (
            <Tooltip
              content="You're using less than 90% of your storage—no need to request extra space yet!"
              trigger={
                <Button
                  content="Request for more space"
                  style={{
                    backgroundColor: "rgb(237, 235, 233)",
                    color: "rgb(200, 198, 196)",
                  }}
                />
              }
            />
          ) : (
            <Button
              content="Request for more space"
              onClick={sendStorageRequest}
            />
          )}
        </div>
        <StorageRequestDialog
          handleClose={() => {
            setStorageRequestDialog(false);
          }}
          handleOk={() => {
            console.log("Request");
          }}
          open={storageRequestDialog}
          user={user}
          title="Storage Request"
          message="Your request will be sent to the superadmin. You will receive the additional storage once the request is approved by the Superadmin."
        />
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
        <h5 className="text-center">
          {selectedExtension.charAt(0).toUpperCase() +
            selectedExtension.slice(1).toLowerCase()}{" "}
          Description
        </h5>
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
          <Table
            header={header}
            rows={rows?.map((row) => ({
              ...row,
              items: row.items.map((item) => {
                // If the item contains HTML (like the "extension" field for Grand Total), render it with dangerouslySetInnerHTML
                return typeof item === "string" && item.includes("<div") ? (
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                ) : (
                  item
                );
              }),
            }))}
            aria-label="Storage Detail"
          />
        </div>
        <div className="ms-grid">
          <div className="ms-Grid-row d-flex justify-content-center m-2">
            <span>Grand Total&nbsp; :</span>
            <span style={{ fontWeight: "bold", margin: "0 5px" }}>
              {isNaN(totalStorageSize) ? 0 : totalStorageSize}
            </span>
          </div>
        </div>
        <div className="ms-grid d-flex justify-content-center m-2">
          <Pagination
            count={storageDetails?.totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
