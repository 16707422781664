import React from "react";
// import Tabs from "../Profile/Tabs/Tabs";
import TutorsList from "./TutorsList";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { Tabs } from "antd";
import TutorRequests from "./TutorRequests/TutorRequests";

const Tutors = (props) => {
  const { t } = useTranslation();
  const requestTranslation = t("booking").setting.tutorRequest.manageRequests;

  return (
    <div className="tutors-tabs-wrapper">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="middle"
        items={[
          {
            label: t("setting").names[10],
            key: "1",
            children: (
              <TutorsList
                user={props.user}
                setAccDlg={(e) => {
                  props.setAccDlg(e);
                }}
                isTabletOrMobileView={props.isTabletOrMobileView}
                isMobileView={props.isMobileView}
              />
            ),
          },
          {
            label: requestTranslation.header,
            key: "2",
            children: <TutorRequests />,
          },
        ]}
      />
    </div>
  );
};

export default Tutors;
