import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import AddTutorsModel from "./AddTutorsModel/AddTutorsModel";
import {
  Button,
  Flex,
  InfoIcon,
  Input,
  ParticipantAddIcon,
  SearchIcon,
  Tooltip,
} from "@fluentui/react-northstar";
import axios from "axios";
import TutorsTable from "./TutorsTable/TutorsTable";
import { useTranslation } from "react-i18next";

const TutorsList = (props) => {
  const { t } = useTranslation();

  const [isOpenAddTutorsModel, setIsOpenAddTutorsModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTeachersAndStudents, setIsLoadingTeachersAndStudents] =
    useState(false);
  const [tutors, setTutors] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [search, setSearch] = useState("");

  const getTeachersAndStudents = async () => {
    setIsLoadingTeachersAndStudents(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_EP_URL}/api/tutor/get-teachers-and-students${props.user.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.user.accessToken,
        },
      };

      const response = await axios.request(config);
      setIsLoadingTeachersAndStudents(false);

      if (response.data?.success) {
        const { students = [], teachers = [] } = response.data?.result;
        setStudents(
          students.map((student) => {
            return {
              key: student.StudentEmailID,
              header:
                student?.StudentName || student?.StudentEmailID?.split("@")[0],
              isBookedSlots: student?.isBookedSlots || false,
            };
          })
        );
        setTeachers(
          teachers.map((teacher) => {
            return {
              key: teacher.TeacherEmailID,
              header:
                teacher?.TeacherName || teacher?.TeacherEmailID?.split("@")[0],
              isBookedSlots: teacher?.isBookedSlots || false,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoadingTeachersAndStudents(false);
    }
  };
  const getTutors = async (search = "") => {
    setIsLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_EP_URL}/api/tutor${props.user.slug}&search=${search}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.user.accessToken,
        },
      };

      const response = await axios.request(config);
      setIsLoading(false);
      if (response.data?.success) {
        const { tutors = [] } = response.data?.result;
        setTutors(tutors);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addNewTutors = (newTutors) => {
    setTutors([...newTutors, ...tutors]);
  };

  useEffect(() => {
    getTeachersAndStudents();
    getTutors();
  }, []);

  const translation = t("booking").manageTutors;

  // useEffect to call detectProvider when email changes
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getTutors(search);
    }, 500); // Debounce of 500ms to prevent API calls on every keystroke

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  return (
    <div className="tutors">
      <AddTutorsModel
        isOpen={isOpenAddTutorsModel}
        onClose={() => setIsOpenAddTutorsModel(false)}
        user={props.user}
        students={students}
        setStudents={setStudents}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
        teachers={teachers}
        setTeachers={setTeachers}
        selectedTeachers={selectedTeachers}
        setSelectedTeachers={setSelectedTeachers}
        addNewTutors={addNewTutors}
      />

      <Flex space="between" style={{ marginBottom: "20px" }}>
        <Flex gap="gap.smaller">
          <Input
            icon={<SearchIcon />}
            value={search}
            onChange={(_, { value }) => {
              // optimizedFn(value);
              setSearch(value);
            }}
            // disabled={isLoading}
            style={{ marginLeft: 12 }}
            placeholder="Search by name, email"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                getTutors(search);
              }
            }}
          />
          <Tooltip
            content="Search by name, email"
            trigger={
              <Button
                iconOnly
                icon={<InfoIcon circular outline size="large" />}
              />
            }
          />
        </Flex>
        <Button
          icon={<ParticipantAddIcon />}
          content={translation.addTutors}
          onClick={() => {
            setIsOpenAddTutorsModel(true);
          }}
          title={translation.addTutors}
          style={{
            maxWidth: "209px",
            marginRight: "10px",
          }}
          primary
          disabled={isLoadingTeachersAndStudents}
        />
      </Flex>
      <TutorsTable
        user={props.user}
        students={students}
        setStudents={setStudents}
        teachers={teachers}
        setTeachers={setTeachers}
        data={tutors}
        setTutors={setTutors}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TutorsList;
