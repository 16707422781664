import {
  AcceptIcon,
  BookmarkIcon,
  Button,
  CallVideoIcon,
  ChevronEndIcon,
  ChevronStartIcon,
  CloseIcon,
  Divider,
  FlagIcon,
  Flex,
  Image,
  ItalicIcon,
  Loader,
  PollIcon,
  PresenterIcon,
  RetryIcon,
  ShiftActivityIcon,
  SurveyIcon,
  Text,
  Tooltip,
  VolumeIcon,
} from "@fluentui/react-northstar";
import React, { useEffect } from "react";
import ProgressChart from "../../../../../components/studentProgress/ProgressChart";
import { useTranslation } from "react-i18next";
import { useChartContext } from "../../../../../context/ChartContext";
import axios from "axios";
import { toast } from "react-toastify";
import { Card } from "antd";

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
};

const StudentProgressCard = (props) => {
  const { selectedStudent: student } = useChartContext();
  const { t } = useTranslation();

  const [loadedInfo, setLoadedInfo] = React.useState(null);
  const [customFieldInfo, setCustomFieldInfo] = React.useState(null);
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );
  const [loader, setLoader] = React.useState(false);

  const getInfo = (email) => {
    setLoader(true);
    setLoadedInfo(null);
    setCustomFieldInfo(null);
    setSelectedChart(chartTypes.attendance);
    axios
      .put(
        `${process.env.REACT_APP_EP_URL}/api/admin/get-student-info/${email}${props.user.slug}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const { assignment, feedback, event, info, chart } = res.data;
        setLoadedInfo({
          ...response,
          Ausente: response?.Ausente ?? 0,
          Justificado: response?.Justificado ?? 0,
          Presente: response?.Presente ?? 0,
          Retardo: response?.Retardo ?? 0,
          StudentEmailId: response?.StudentEmailId ?? "",
          StudentName: response?.StudentName ?? "",
          Total: response?.Total ?? 0,

          assignment: {
            total: assignment?.total ?? 0,
            reSubmit: assignment?.Resubmit ?? 0,
            completed: assignment?.Completed ?? 0,
            pending: assignment?.Pending ?? 0,
            gradesPending: assignment?.["Grades Pending"] ?? 0,
          },
          event: {
            registered: event?.registered ?? 0,
            bookmarked: event?.bookmarked ?? 0,
            organized: event?.organized ?? 0,
            total: event?.total ?? 0,
          },
          feedback: {
            total: feedback?.total ?? 0,
            poll: feedback?.poll ?? 0,
            text: feedback?.text ?? 0,
            audio: feedback?.audio ?? 0,
            video: feedback?.video ?? 0,
            mixed: feedback?.mixed ?? 0,
            any: feedback?.any ?? 0,
          },
          info: {
            ...info,
            subjects:
              info.subjects && Array.isArray(info.subjects)
                ? info.subjects
                : [],
            classNumber: info.classNumber ?? "",
            attendanceAverage: info.attendanceAverage ?? 0,
            assignmentAverage: info.assignmentAverage ?? 0,
          },
          chart,
        });
        setCustomFieldInfo(res.data.custom_field);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message);
        console.log(err);

        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (student) {
      getInfo(student.key);
    }
  }, [student]);

  const uniqueClassNumbers = [...new Set(loadedInfo?.info?.classNumber || [])];

  return (
    <Card>
      <Flex
        gap="gap.medium"
        className="student-data-wrapper"
        wrap={props.isMobileView}
      >
        <Flex column className="student-image-info-wrapper" gap="gap.medium">
          <Image src={student?.image} className="m-img" />
          {loadedInfo ? (
            <Flex column className="student-info-wrapper" gap="gap.smaller">
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[0]}:`}
                  className="Karla fw6 tcap"
                />
                <Tooltip
                  content={student.header}
                  trigger={
                    <Text
                      content={student.header}
                      className="Karla tcap field-value"
                    />
                  }
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[1]}:`}
                  className="Karla fw6 tcap"
                />
                <Tooltip
                  content={student.key}
                  trigger={
                    <Text
                      content={student.key}
                      className="Karla tlow field-value"
                    />
                  }
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[2]}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={uniqueClassNumbers?.join(", ") ?? ""}
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[3]}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={loadedInfo?.info?.attendanceAverage ?? ""}
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[4]}:`}
                  className="Karla fw6 tcap"
                />
                <Text
                  content={loadedInfo?.info?.assignmentAverage ?? ""}
                  className="Karla tcap"
                />
              </Flex>
              <Flex gap="gap.small" wrap>
                <Text
                  content={`${t("setting").student.info[5]}:`}
                  className="Karla fw6 tcap"
                />
                <div className="Karla tcap">
                  {Array.isArray(loadedInfo?.info?.subjects) ? (
                    <Flex column className="subjects-wrapper">
                      {loadedInfo.info.subjects.map((item) => (
                        <span key={item}>{item}</span>
                      ))}
                    </Flex>
                  ) : (
                    <Text
                      content={`${t("setting").student.info[6]}`}
                      className="Karla tcap"
                    />
                  )}
                </div>
              </Flex>
            </Flex>
          ) : (
            ""
          )}

          {loadedInfo?.StudentData ? (
            <Flex gap="gap.large" wrap>
              <Flex column>
                <Divider content={t("setting").student.attendance[1]} />
                {customFieldInfo?.map((cf) => {
                  return (
                    <Text
                      content={
                        <>
                          <FlagIcon outline className="pb2 pr2" />
                          {/* <Status state={cf.IsEnable?"info":"unknown"} className="pb2 pr2"/> */}
                          <Text
                            content={" " + cf.Name}
                            color="brand"
                            className="fw6"
                            disabled={!cf.IsEnable}
                          />{" "}
                          - {loadedInfo.StudentData["CF_" + cf.CustomFieldID]}
                        </>
                      }
                      className="Karla m1 tcap"
                      key={cf.CustomFieldID}
                      disabled={!cf.IsEnable}
                    />
                  );
                })}
              </Flex>
            </Flex>
          ) : null}
        </Flex>
        <Flex column className="info-chart-wrapper">
          {loadedInfo ? (
            <>
              <Flex vAlign="center" hAlign="center">
                {/* <div className="scroll-button-wrapper">
                <Button
                  className="scroll-button"
                  onClick={() => {
                    scrollButtonHandler(false);
                  }}
                >
                  <ChevronStartIcon size="large" />
                </Button>
              </div> */}
                <div
                  className="statictics-container"
                  id="scroll-statictics-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Flex className="statictics-wrapper">
                    <Flex column>
                      <Divider
                        content={t("setting")?.student?.attendance[0]}
                        onClick={() =>
                          chartSelectHandler(chartTypes.attendance)
                        }
                        className={`selectable-option ${
                          selectedChart === chartTypes.attendance
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo.Total}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Presente)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <CloseIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Ausente)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ShiftActivityIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Retardo)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <SurveyIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.attendance[6]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.Justificado)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>

                    <Flex column>
                      <Divider
                        content={t("setting").student.assignment[0]}
                        onClick={() =>
                          chartSelectHandler(chartTypes.assignment)
                        }
                        className={`selectable-option ${
                          selectedChart === chartTypes.assignment
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[1]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo.assignment.total}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.assignment.completed)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <RetryIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.assignment.reSubmit)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ShiftActivityIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.assignment.pending)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <SurveyIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.assignment[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.assignment.gradesPending)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>
                    <Flex column>
                      <Divider
                        content={t("setting").student.event[0]}
                        onClick={() => chartSelectHandler(chartTypes.event)}
                        className={`selectable-option ${
                          selectedChart === chartTypes.event
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <AcceptIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.event.registered)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <BookmarkIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.event.bookmarked)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PresenterIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.event[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.event.organized)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>

                    <Flex column>
                      <Divider
                        content={t("setting").student.feedback[0]}
                        onClick={() => chartSelectHandler(chartTypes.feedback)}
                        className={`selectable-option ${
                          selectedChart === chartTypes.feedback
                            ? "active-chart"
                            : ""
                        }`}
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[1]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {loadedInfo.feedback.total}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <PollIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[2]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.poll)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <ItalicIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[3]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.text)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <VolumeIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[4]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.audio)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                      <Text
                        content={
                          <Flex gap="gap.smaller">
                            <CallVideoIcon outline className="pb2 pr2" />
                            <Text
                              content={t("setting").student.feedback[5]}
                              color="brand"
                              className="fw6"
                            />{" "}
                            - {parseInt(loadedInfo.feedback.video)}
                          </Flex>
                        }
                        className="Karla m1 tcap"
                      />
                    </Flex>
                  </Flex>
                </div>
                {/* <div className="scroll-button-wrapper">
                <Button
                  className="scroll-button"
                  onClick={() => {
                    scrollButtonHandler(true);
                  }}
                >
                  <ChevronEndIcon size="large" />
                </Button>
              </div> */}
              </Flex>

              <ProgressChart
                selectedChart={selectedChart}
                data={loadedInfo.chart}
                chartTypes={chartTypes}
              />
            </>
          ) : (
            loader && (
              <Flex fill hAlign="center" vAlign="center" column>
                <Loader size="small" />
              </Flex>
            )
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default StudentProgressCard;
