import { Button, Tooltip } from "@fluentui/react-northstar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import Chart from "../../Container/Charts";
import { reportTypes } from "../constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

const AssignmentReportByTeacher = (props) => {
  const { user, t } = props;

  const { slug } = user;

  const [chartData, setChartData] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(
      d.getFullYear(),
      d.getMonth() - 3,
      d.getDate(),
      0,
      0,
      0,
      0
    ),
    endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [allClassNames, setAllClassNames] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios({
        url: `${url}/api/chart/get-assignment-chart-by-teacher${slug}`,
        method: "post",
        data: {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          ClassName: selectedClass.length ? selectedClass : null,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });

      if (data?.result) {
        let responseData = data.result.data;
        let classNames = data.result.classData?.length
          ? data.result.classData.map((item) => item.ClassName)
          : [];

        let totalTeachers = responseData?.length || 0;
        let {
          totalAssignment,
          publishedAssignment,
          draftAssignment,
          publishLater,
          createdRubrics,
        } = responseData?.length
          ? responseData.reduce(
              (prev, cur) => {
                prev.totalAssignment =
                  prev.totalAssignment + (Number(cur.Total) || 0);
                prev.publishedAssignment =
                  prev.publishedAssignment + (Number(cur.Published) || 0);
                prev.draftAssignment =
                  prev.draftAssignment + (Number(cur.Draft) || 0);
                prev.publishLater =
                  prev.publishLater + (Number(cur.Scheduled) || 0);
                prev.createdRubrics =
                  prev.createdRubrics + (Number(cur.createdRubrics) || 0);

                return prev;
              },
              {
                totalAssignment: 0,
                publishedAssignment: 0,
                draftAssignment: 0,
                publishLater: 0,
                createdRubrics: 0,
              }
            )
          : {
              totalAssignment: 0,
              publishedAssignment: 0,
              draftAssignment: 0,
              publishLater: 0,
              createdRubrics: 0,
            };

        let boxData = {
          totalTeachers,
          totalAssignment,
          publishedAssignment,
          draftAssignment,
          publishLater,
          createdRubrics,
        };

        let pieData = {
          pie1: responseData
            // Step 1: Sort the array by `Total` in descending order
            .sort((a, b) => b.Total - a.Total)
            // Step 2: Take the first 10 records from the sorted array
            .slice(0, 10)
            // Step 3: Reduce these records into an accumulator object
            .reduce((acc, item) => {
              acc[item.teacher] = item.Total;
              return acc;
            }, {}),
          pie2: {
            published: publishedAssignment,
            draft: draftAssignment,
            publishLater: publishLater,
          },
        };

        let barData = [...responseData];

        let tableData = responseData;

        if (!allClassNames.length) {
          setAllClassNames(classNames);
        }

        setChartData({
          boxData,
          pieData,
          barData,
          tableData,
        });
      } else {
        setChartData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [dateFilter, selectedClass]);

  const filterByHandler = (option) => {
    setFilterBy(option);

    setChartData((prevData) => ({
      ...prevData,
      tableData: prevData.tableData
        .slice()
        .sort((a, b) => b[option] - a[option]),
    }));
  };

  return (
    <Chart
      mainPath={props.mainPath}
      showControls={true}
      isLoading={isLoading}
      report={reportTypes.assignmentTeacher}
      user={props.user}
      logout={() => props.logout()}
      switchDb={props.switchDb}
      getDb={() => props.getDb()}
      path={props.match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      startDate={dateFilter.startDate}
      endDate={dateFilter.endDate}
      startDateLabel={
        t("chart").screens.assignmets.teacher.controls.date.date2[0]
      }
      endDateLabel={
        t("chart").screens.assignmets.teacher.controls.date.date3[0]
      }
      startChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, startDate: selectedDate });
      }}
      endChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, endDate: selectedDate });
      }}
      showClassType={false}
      allClassNames={allClassNames}
      stateChangeHandler={(selectedClass) => {
        setSelectedClass(selectedClass);
      }}
      // customFields={this.state.customFields}
      // showLbl={[this.state.selectedClass?.length ? false : true]}
      boxData={
        chartData.boxData
          ? {
              [t("chart").screens.assignmets.teacher.boxs[0]]:
                chartData.boxData.totalTeachers,
              [t("chart").screens.assignmets.teacher.boxs[1]]:
                chartData.boxData.totalAssignment,
              [t("chart").screens.assignmets.teacher.boxs[2]]:
                chartData.boxData.publishedAssignment,
              [t("chart").screens.assignmets.teacher.boxs[3]]:
                chartData.boxData.draftAssignment,
              [t("chart").screens.assignmets.teacher.boxs[4]]:
                chartData.boxData.publishLater,
              [t("chart").screens.assignmets.teacher.boxs[5]]:
                chartData.boxData.createdRubrics,
            }
          : null
      }
      pieData={{
        pie1: {
          lable: t("chart").screens.assignmets.teacher.pie.pie1.lbl,
          data: chartData.pieData?.pie1,
        },
        pie2: {
          lable: t("chart").screens.assignmets.teacher.pie.pie2.lbl,
          data: chartData.pieData?.pie2
            ? {
                [t("chart").screens.assignmets.teacher.pie.pie2.dataLbl[0]]:
                  chartData.pieData.pie2.published,
                [t("chart").screens.assignmets.teacher.pie.pie2.dataLbl[1]]:
                  chartData.pieData.pie2.draft,
                [t("chart").screens.assignmets.teacher.pie.pie2.dataLbl[2]]:
                  chartData.pieData.pie2.publishLater,
              }
            : null,
        },
      }}
      columnData={{
        stacked: false,
        radius: 10,
        title: t("chart").screens.assignmets.teacher.column.title,
        lableY: t("chart").screens.assignmets.teacher.column.lableY,
        lableX: t("chart").screens.assignmets.teacher.column.lableX,
        series: chartData.barData
          ? [
              {
                name: [t("chart").screens.assignmets.teacher.column.series[0]],
                data: chartData.barData.slice(0, 7).map((data) => {
                  return parseInt(data.Published) || 0;
                }),
              },
              {
                name: [t("chart").screens.assignmets.teacher.column.series[1]],
                data: chartData.barData.slice(0, 7).map((data) => {
                  return parseInt(data.Draft) || 0;
                }),
              },
              {
                name: [t("chart").screens.assignmets.teacher.column.series[2]],
                data: chartData.barData.slice(0, 7).map((data) => {
                  return parseInt(data.Scheduled) || 0;
                }),
              },
            ]
          : [],
        categories: chartData.barData
          ? chartData.barData.slice(0, 7).map((data) => {
              return data?.teacher
                ? document.documentElement.clientWidth < 500
                  ? data.teacher?.split("-")[0] + "..."
                  : [
                      data.teacher?.split("-")[0],
                      data.teacher?.split("-")?.slice(1),
                    ]
                : "N/A";
            })
          : [],
      }}
      tableData={{
        header: {
          key: "Headers",
          items: t("chart").screens.assignmets.teacher.table.header.map(
            (header, key) => {
              return {
                content:
                  key && key === 1 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Published" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Published")}
                    />
                  ) : key === 2 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Draft" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "70px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Draft")}
                    />
                  ) : key === 3 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Scheduled" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "50px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Scheduled")}
                    />
                  ) : key === 4 ? (
                    <Button
                      text
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: "30px",
                        color: filterBy === "Total" ? "#6264A7" : "black",
                      }}
                      content={
                        <Tooltip
                          content={header}
                          position={"below"}
                          trapFocus
                          trigger={
                            <div
                              style={{
                                maxWidth: "50px",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {header}
                            </div>
                          }
                        ></Tooltip>
                      }
                      onClick={() => filterByHandler("Total")}
                    />
                  ) : (
                    header
                  ),
                key: "Header-" + header,
              };
            }
          ),
        },
        rows: chartData.tableData
          ? chartData.tableData.map((data, key) => {
              return {
                key: data.teacher + "key-" + key,
                items: [
                  {
                    content: data?.teacher ? data.teacher : "N/A",
                    key: data.teacher + "TeacherName" + key,
                  },
                  {
                    content: data.Published,
                    key: data.teacher + "Published" + key,
                  },
                  {
                    content: data.Draft,
                    key: data.teacher + "Draft" + key,
                  },
                  {
                    content: data.Scheduled,
                    key: data.teacher + "Scheduled" + key,
                  },
                  {
                    content: data.Total,
                    key: data.teacher + "Total" + key,
                  },
                ],
              };
            })
          : [],
      }}
      selectedExtension={props.selectedExtension}
      setSelectedExtension={props.setSelectedExtension}
    />
  );
};

export default withTranslation()(AssignmentReportByTeacher);
