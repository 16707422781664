import {
  Button,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Input,
  Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useExternalTutors } from "../../context/ExternalTutorsContext";
import { useForm, Controller } from "react-hook-form";
import "./AddExternalTutorModel.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";

const providers = ["Microsoft", "Google"];

const AddExternalTutorModel = () => {
  const {
    isOpenAddExternalTutorsModel,
    setIsOpenAddExternalTutorsModel,
    createExternalTutor,
    setIsLoading,
    isLoading,
  } = useExternalTutors();

  const [isShowEmailError, setIsShowEmailError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const { t } = useTranslation();

  const translation = t("booking").externalTutors.addExternalTutorDialog;
  const formTranslation = translation.form;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const detectProvider = async (email) => {
    if (!email) return false;

    if (!email.includes("@")) {
      setIsValidEmail(false);
      return false;
    }
    setIsShowEmailError(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/user/check-email-provider?email=${email}`
      );

      const data = response?.data;

      if (data?.provider === "Microsoft") {
        setValue("provider", "Microsoft");
        setIsValidEmail(true);
        return true;
      } else if (data?.provider === "Google") {
        setValue("provider", "Google");
        setIsValidEmail(true);
        return true;
      } else {
        setIsValidEmail(false);
        return false;
      }
    } catch (error) {
      setIsValidEmail(false);
      return false;
    }
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setIsLoading(true);
    const isValidEmail = await detectProvider(data.tutorEmail);
    setIsShowEmailError(true);
    if (isValidEmail) {
      await createExternalTutor(data, reset);
      setIsShowEmailError(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpenAddExternalTutorsModel}
      header={translation.heading}
      className="smallDlg"
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex column gap="gap.large">
            <Flex column gap="gap.small">
              {/* Name Field */}
              <Flex column gap="gap.smaller" style={{ width: "100%" }}>
                <p className="sub-title">{formTranslation.name.label}</p>
                <Controller
                  control={control}
                  name="tutorName"
                  rules={{
                    required: formTranslation.name.nameIsRequired,
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={formTranslation.name.placeholder}
                      fluid
                      className="name-input"
                      disabled={isLoading}
                      error={!!errors.tutorName}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                {errors.tutorName && (
                  <Text content={errors.tutorName.message} error />
                )}
              </Flex>

              {/* Email Field */}
              <Flex column gap="gap.smaller" style={{ width: "100%" }}>
                <p className="sub-title">{formTranslation.email.label}</p>
                <Controller
                  control={control}
                  name="tutorEmail"
                  rules={{
                    required: formTranslation.email.emailIsRequired,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: formTranslation.email.enterValidEmail,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={formTranslation.email.placeholder}
                      fluid
                      className="email-input"
                      disabled={isLoading}
                      error={
                        !!errors.tutorEmail ||
                        (isShowEmailError && !isValidEmail)
                      }
                      style={{ width: "100%" }}
                      onBlur={() => {
                        detectProvider(field.value);
                      }}
                    />
                  )}
                />
                {errors.tutorEmail && (
                  <Text content={errors.tutorEmail.message} error />
                )}
                {isShowEmailError && !isValidEmail && (
                  <Text content={formTranslation.email.enterValidEmail} error />
                )}
              </Flex>

              {/* Provider Dropdown */}
              <Flex column gap="gap.smaller">
                <p className="sub-title">{formTranslation.provider.label}</p>
                {providers.length > 0 && (
                  <Controller
                    control={control}
                    name="provider"
                    rules={{
                      required: formTranslation.provider.providerIsRequired,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        fluid
                        items={providers}
                        disabled={isLoading}
                        onChange={(_, { value }) => field.onChange(value)}
                        placeholder={formTranslation.provider.placeholder}
                        noResultsMessage={formTranslation.provider.noResult}
                        className="select-provider-dropdown"
                      />
                    )}
                  />
                )}
                {errors.provider && (
                  <Text content={errors.provider.message} error />
                )}
              </Flex>
            </Flex>
            {/* Action Buttons */}
            <Flex gap="gap.small" hAlign="end">
              <Button
                content={translation.cancelButtonTitle}
                disabled={isLoading}
                onClick={() => {
                  setIsOpenAddExternalTutorsModel(false);
                  reset();
                }}
              />
              <Button
                primary
                content={
                  isLoading
                    ? `${translation.adding}...`
                    : translation.submitButtonTitle
                }
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              />
            </Flex>
          </Flex>
        </form>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "close",
        disabled: isLoading,
        onClick: () => {
          setIsOpenAddExternalTutorsModel(false);
          reset();
        },
      }}
    />
  );
};

export default AddExternalTutorModel;
