import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Image,
  Empty,
  Spin,
  Skeleton,
  Divider,
  Tabs,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import {
  Flex,
  Button,
  SpeakerPersonIcon,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
const { Search } = Input;
const { confirm } = Modal;
const AvatarVideosModel = ({
  open,
  onClose,
  selectedAvatarVideo,
  setSelectedAvatarVideo,
  slug,
  state,
  setState,
  mail,
}) => {
  const [videos, setVideos] = useState([
    { label: "Current Lesson Videos", data: [] },
    { label: "Other Lessons Videos", data: [] },
    { label: "Videos With No Lesson", data: [] },
  ]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAvatarVideos = async (searchValue) => {
    setIsLoading(true);
    const currentLessonId = state.selectedLesson.Title;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_EP_URL
        }/api/avatar-videos${slug}&mail=${mail}&search=${searchValue || ""}`
      );

      if (response?.data?.success) {
        const videos = response?.data?.data.length ? response.data.data : [];
        const currentLessonVideos = videos.filter(
          (video) => video.lessonId === currentLessonId
        );
        const otherLessonsVideos = videos.filter(
          (video) => video.lessonId && video.lessonId !== currentLessonId
        );
        const videosWithoutLesson = videos.filter((video) => !video.lessonId);
        const allVideos = [
          { label: "Current Lesson Videos", data: currentLessonVideos },
          { label: "Other Lessons Videos", data: otherLessonsVideos },
          { label: "Videos With No Lesson", data: videosWithoutLesson },
        ];
        setVideos(allVideos);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    open && state?.selectedLesson?.Title && getAvatarVideos();
  }, [state?.selectedLesson?.Title, open]);

  const handleSearch = (value) => {
    getAvatarVideos(value);
  };

  const handleKeyDown = (e, video) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();
      handleUpdateVideo(video.id, video.title);
      e.target.blur();
    }
  };

  const handleUpdateVideo = async (id, title = "") => {
    try {
      await axios.put(
        `${process.env.REACT_APP_EP_URL}/api/avatar-videos${slug}`,
        { id, fields: { title } }
      );
    } catch (error) {}
  };
  const handleDeleteVideo = async (id, videoUrl, allVideoIndex) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/avatar-videos/${id}${slug}`
      );
      if (res.data.success) {
        let clonedAllVideos = [...videos];
        clonedAllVideos[allVideoIndex].data = clonedAllVideos[
          allVideoIndex
        ].data.filter((video) => video.id !== id);

        setVideos(clonedAllVideos);
        if (videoUrl == selectedAvatarVideo) {
          setSelectedAvatarVideo("");
        }
        toast.success("Video Deleted SuccessFully!");
      } else {
        toast.error("Video Deleting Failed");
      }
    } catch (error) {
      console.log("err", error);
      toast.error("Video Deleting Failed");
    }
  };

  return (
    <Modal
      title="My Avatar Videos"
      open={open}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      centered
      width="80%"
    >
      <Flex hAlign="end">
        <Search
          placeholder="Search videos"
          onSearch={handleSearch}
          enterButton
          style={{ width: 300 }}
          allowClear
          size="middle"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          value={searchText}
        />
        <Button
          style={{ borderRadius: "3px", backgroundColor: "#6264a7" }}
          primary
          onClick={() => {
            setState({ isOpenAvatarGenerator: true });
            onClose();
          }}
        >
          <SpeakerPersonIcon size="medium" style={{ marginRight: "10px" }} />
          Generate Avatar
        </Button>
      </Flex>
      <div
        style={{
          height: "calc(100vh - 400px)",
          overflow: "auto",
          marginTop: "15px",
        }}
      >
        {isLoading ? (
          <Flex vAlign="center" hAlign="center" style={{ height: "100%" }}>
            <Spin size="large" />
          </Flex>
        ) : videos.length ? (
          <>
            <Flex column hAlign="center">
              {selectedAvatarVideo && (
                <>
                  <Flex
                    vAlign="center"
                    hAlign="center"
                    column
                    style={{ width: "30%" }}
                  >
                    <h3>Selected Video</h3>
                    <video
                      src={selectedAvatarVideo}
                      controls
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                    />
                  </Flex>
                  <Button
                    style={{
                      marginTop: "20px",
                      borderRadius: "3px",
                      backgroundColor: "#6264a7",
                    }}
                    primary
                    onClick={() => {
                      setSelectedAvatarVideo("");
                    }}
                  >
                    Remove Video
                  </Button>
                  <Divider />
                </>
              )}
              {/* <Row gutter={[5, 5]} style={{ width: "100%" }}>
                {videos.map((video, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
                    <div
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        minWidth: "100%",
                        height: "100%",
                      }}
                    >
                      <video
                        src={video.url}
                        controls
                        style={{
                          width: "100%",
                          height: "250px", // Set a fixed height for the videos
                          objectFit: "cover",
                        }}
                      />
                      <Flex hAlign="center" style={{ paddingTop: "5px" }}>
                        <Button
                          style={{
                            borderRadius: "3px",
                            backgroundColor: "#6264a7",
                          }}
                          primary
                          onClick={() => {
                            setSelectedAvatarVideo(video.url);
                          }}
                        >
                          {selectedAvatarVideo
                            ? "Switch Video"
                            : "Select video"}
                        </Button>
                      </Flex>
                    </div>
                  </Col>
                ))}
              </Row> */}
              <Tabs
                style={{ width: "100%" }}
                // onChange={onChange}
                type="card"
                items={videos.map((tab, i) => {
                  const id = String(i + 1);
                  return {
                    label: tab.label,
                    key: id,
                    children: tab.data.length ? (
                      <Row gutter={[5, 5]} style={{ width: "100%" }}>
                        {tab.data.map((video, index) => (
                          <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div
                              style={{
                                padding: "5px",
                                textAlign: "center",
                                minWidth: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            >
                              <video
                                src={video.url}
                                title={video?.title || ""}
                                controls
                                onClick={() => {
                                  setSelectedAvatarVideo(video.url);
                                }}
                                style={{
                                  width: "100%",
                                  height: "250px", // Set a fixed height for the videos
                                  objectFit: "cover",

                                  border:
                                    video.url === selectedAvatarVideo
                                      ? "2px solid #fff"
                                      : "",
                                  outline:
                                    video.url === selectedAvatarVideo
                                      ? "3px solid #6264a7"
                                      : "",
                                  borderRadius: 5,
                                  cursor: "pointer",
                                }}
                              ></video>
                              <input
                                style={{
                                  width: "calc(100% - 11px)",
                                  position: "absolute",
                                  top: "7px",
                                  left: "6px",
                                  zIndex: 10,
                                  background: "rgba(0, 0, 0, 0.4)",
                                  color: "white",
                                  padding: "8px ",
                                  marginBottom: 0,
                                  borderRadius: 5,
                                  textAlign: "left",
                                  border: 0,
                                  outline: 0,
                                }}
                                contentEditable
                                onBlur={(e) => {
                                  handleUpdateVideo(video.id, video.title);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, video)}
                                value={video?.title || ""}
                                placeholder={"Rename Your Avatar"}
                                onChange={(e) => {
                                  let clonedVideos = [...tab.data];
                                  clonedVideos[index].title = e.target.value;
                                  let clonedAllVideos = [...videos];
                                  clonedAllVideos[i].data = clonedVideos;
                                  setVideos(clonedAllVideos);
                                }}
                              />

                              <Flex
                                hAlign="center"
                                style={{
                                  paddingTop: "5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>
                                  {moment
                                    .utc(video.createdAt)
                                    .local()
                                    .format("DD-MMM-YYYY HH:mm:ss")}
                                </span>
                                <TrashCanIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    confirm({
                                      icon: <ExclamationCircleOutlined />,
                                      content: (
                                        <p>
                                          Are you sure you want to delete video?
                                        </p>
                                      ),
                                      okButtonProps: {
                                        className: "confirm-delete-button",
                                      },
                                      okType: "danger",
                                      okText: "Delete",
                                      onOk() {
                                        handleDeleteVideo(
                                          video.id,
                                          video.url,
                                          i
                                        );
                                      },
                                      onCancel() {},
                                    });
                                  }}
                                />
                              </Flex>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <Flex
                        vAlign="center"
                        hAlign="center"
                        style={{ height: "100%" }}
                      >
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={"No videos found"}
                        />
                      </Flex>
                    ),
                  };
                })}
              />
            </Flex>
          </>
        ) : (
          <Flex vAlign="center" hAlign="center" style={{ height: "100%" }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"No videos found"}
            />
          </Flex>
        )}
      </div>
    </Modal>
  );
};

export default AvatarVideosModel;
